import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'dm-no-content',
    template: `
        <ng-container *ngIf="type !== 'no-page'; else noPage">
            <div class="error-container">
                <h1>You are not assigned to any sites within this company. No reports are available. Please make sure you are assigned to at
                    least 1 site before accessing reports.</h1>
            </div>
        </ng-container>
        <ng-template #noPage>
            <div class="error-container">
                <h1>404: page is missing</h1>
            </div>
        </ng-template>
    `,
    styles: [
        `.error-container {padding: 5px 15px;}`,
        `.error-container h1 {max-width: 90%;}`
    ]
})
export class NoContentComponent implements OnInit {
    public type = 'no-page';

    constructor(
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if (params['type']) {
                this.type = params['type'];
            }
        });

        this.route.params.subscribe(() => {
            if (this.route.snapshot.url[this.route.snapshot.url.length - 1].path === 'no-sites') {
                this.type = 'no-sites';
            }
        });
    }
}
