<dm-modal-wrapper
    [title]="ExportingModalTitle"
    [isShowModal]="isShowModal"
    [buttons]="buttons" >

    <div class="row vertical-align">
        <div class="col-md-12">
            <div class="row" *ngIf="buttons[0].disabled">
                <div class="content-block">
                    <p class="warning-box" *ngIf="selectedGroupedColumnsToExport.length > 2">You use more than 2 grouped columns. Please, reduce their count</p>
                    <p class="warning-box" *ngIf="oversizeWidthForTable">The columns don't fit in the PDF file. Please, reduce their count</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <span class="columns-list__title">Selected columns:</span>
                    <ul class="columns-list">
                        <li class="export-column" *ngFor="let column of selectedColumnsToExport">
                                <span class="export-column__name">
                                    {{ column.value }}
                                    <a class="export-column__remove-btn" href="javascript: void(0);" (click)="removeColumn(column.id)">remove</a>
                                </span>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-12" *ngIf="selectedGroupedColumnsToExport.length">
                    <span class="columns-list__title">Grouped columns:</span>
                    <ul class="columns-list">
                        <li class="export-column" *ngFor="let column of selectedGroupedColumnsToExport">
                                <span class="export-column__name">
                                    {{ column.value }}
                                    <a class="export-column__remove-btn" href="javascript: void(0);" (click)="removeGroupedColumn(column.id)">remove</a>
                                </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="content-block">
                    <a
                        href="javascript: void(0);"
                        (click)="hiddenBottomModalBlock = !hiddenBottomModalBlock"
                        [ngClass]="{'is-open': !hiddenBottomModalBlock}"
                        class="btn add-more">Add more columns</a>
                    <ul class="columns-list" [ngClass]="{'is-hidden': hiddenBottomModalBlock}">
                        <li class="export-column" *ngFor="let column of hiddenColumnsToExport">
                            <a class="export-column__add-btn" href="javascript: void(0);" (click)="addColumn(column.id)">{{ column.value }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</dm-modal-wrapper>
