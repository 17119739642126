import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { forkJoin, iif, Observable, of, Subject } from 'rxjs';
import { ReportsService } from '@shared/services';
import { ISite, ISiteState } from '@shared/types/site';
import { NgRedux } from '@angular-redux/store';
import * as moment from 'moment';
import 'moment/min/locales';
import { FiltersService } from '@services/filters';
import { PreferencesService } from '@shared/services';
import {
    IDatepickerConfig,
    IMenuItem,
    IDatepickerValue,
    IPeriod
} from '@hc/xenial-ui-shared/modules/xdm-shared-components/components/xux-datepicker/types';
import { XUX_DATEPICKER_PERIODS } from '@hc/xenial-ui-shared/modules/xdm-shared-components/definitions/constants';
import { map, tap, switchMap, takeUntil, filter, distinctUntilChanged } from 'rxjs/operators';
import { FiltersActions, UiActions } from '@store/actions';
import { DateTypeModel } from '@components/report-filters/filters/calendars-filter/dateTypeModel/dateTypeModel';
import { IAppState } from '@store/reducers';
import { IFilterState } from '@shared/types/filters';
import { prepareSitesForRequest } from '@components/report-filters/utils/filter.utils';

@Component({
    selector: 'calendar-filter',
    templateUrl: 'calendars-filter.html',
    styleUrls: ['style.scss'],
})
export class CalendarsFilterComponent implements OnInit, OnDestroy {
    @Input() filter: any;
    @Input() set selectedReport(report) {
        this.typeOfCalendar = report.additional_path_parameters[1]?.calendar_type;
    }
    @Output() handleChanges = new EventEmitter();
    @Output() calendarChanged = new EventEmitter();

    public calendarConfig: IDatepickerConfig = {
        showInputLabel: false,
        dateFormat: {
            long_date_format: 'll',
            short_date_format: 'L'
        },
        autoCorrectEndDate: true,
        availableOptions: [],
        mergeDateFields: true,
        locale: this.preferencesService.timeLocale
    };
    public inputCalendarValue: IDatepickerValue;
    public calendars: IPeriod[] = [];
    public isReady = false;

    private unsubscribe$: Subject<void> = new Subject<void>();
    private filtersState: Observable<IFilterState>;
    private siteState: Observable<ISiteState>;
    private defaultDateType: string = '';
    private businessDateItem: IMenuItem;
    private calendarType: string = '';
    private periodsItem: IMenuItem;
    private multipleSites = false;
    private multipleDates = false;
    private isSiteChanged = false;
    private businessDate: string;
    private typeOfCalendar: string;
    private allSites: Array<ISite>;
    private prevCalendarType;
    private attributes: any;
    private siteId: any;
    private name = '';

    constructor(
        private ngRedux: NgRedux<IAppState | ISiteState | IFilterState>,
        private preferencesService: PreferencesService,
        private filtersService: FiltersService,
        private filtersActions: FiltersActions,
        private reportService: ReportsService,
        private cdRef: ChangeDetectorRef,
        private uiActions: UiActions
    ) {}

    ngOnInit() {
        this.filtersState = this.ngRedux.select('filters');
        this.siteState = this.ngRedux.select('sites');
        this.multipleSites = this.filter['multipleSites'];
        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];

        this.siteState
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged((prev, next) => {
                   return this.checkIfSitePickerNotChanged(prev, next);
                }),
                tap((siteState) => {
                    this.allSites = siteState.sites;
                    this.checkIfSitePickerChanged(siteState);
                    if (siteState.selectedSites && siteState.selectedSite) {
                        this.siteId = prepareSitesForRequest(this.multipleSites ? siteState.selectedSites : [ siteState.selectedSite ]);
                    }
                    this.mapFilterToProps(this.filter);
                }),
                filter(() => this.siteId && !!this.siteId.length),
                switchMap(() => this.filtersState.pipe(
                    takeUntil(this.unsubscribe$),
                    distinctUntilChanged((prev, next) =>
                        prev.selectedDateForSingleDate === next.selectedDateForSingleDate ||
                        prev.selectedDateForMultiDate === next.selectedDateForMultiDate),
                    switchMap((state: IFilterState) => iif(() => {
                        this.prevCalendarType = state.prevCalendarType;
                        if (this.multipleDates) {
                            return !this.isSiteChanged &&
                                this.checkIfDataForMultiDatePickerExist(state);
                        }
                        return !this.isSiteChanged &&
                            this.checkIfDataForSingleDatePickerExist(state);
                        }, of(state).pipe(
                            tap(() => {
                                this.getDataForDatePicker(state);
                                this.handleCalendarChange(
                                    this.multipleDates
                                        ? state.selectedDateForMultiDate
                                        : state.selectedDateForSingleDate
                                );
                            })
                        ),
                       this.getCalendarsAndBusinessDate(state, this.isSiteChanged, this.allSites)
                    ))
                ))
            ).subscribe(() => {
                if (this.multipleDates) {
                    this.updatePeriodsItem();
                }
                this.updateBusinessDateItem();
                this.isReady = true;
                this.uiActions.disableContentLoader();
                this.cdRef.detectChanges();
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public handleCalendarChange(inputCalendarValue, applied = false) {
        this.prevCalendarType = inputCalendarValue.type;
         this.inputCalendarValue = { ...inputCalendarValue };
         const value = {};
         const calendar = inputCalendarValue.value;
         value['Start_Date'] = moment(calendar.dateStart).format('YYYY-MM-DD');

         if (this.attributes.includes('End_Date')) {
            value['End_Date'] = moment(calendar.dateEnd).format('YYYY-MM-DD');
         }

        if (this.attributes.includes('Calendar_Period')) {
            value['Calendar_Period'] = inputCalendarValue.type === 'periods' ||
                inputCalendarValue.type === 'currentPeriod' ||
                inputCalendarValue.type === 'previousPeriod';
        }
        this.handleChanges.emit({ value, name: this.name, type: this.filter['type'] });
        this.filtersActions.setCalendarsAndBusinessDay(
            this.typeOfCalendar,
            this.calendarType,
            this.multipleDates,
            this.businessDate,
            this.calendars,
            inputCalendarValue,
            this.prevCalendarType,
            applied
        );
    }

    public isFilterShown() {
        return this.checkCorrectCalendarType() || this.calendars.length;
    }

    private checkCorrectCalendarType() {
        return [
            'PYRL',
            'FISC',
            'SCHD',
            'INV',
            'SINGLE_DATE',
            'NONE'
        ].includes(this.calendarType);
    }

    private getCalendarsAndBusinessDate(state: IFilterState, isSiteChanged, allSites: ISite[]): Observable<[any, any] | any> {
        this.uiActions.enableContentLoader();
        if (!this.multipleDates) {
            if (state.singleBusinessDate && state.singleBusinessDate[this.typeOfCalendar] && !isSiteChanged) {
                return of([]).pipe(
                    tap(businessDate => {
                        this.businessDate = businessDate;
                        this.setCalendarValue(state.selectedDateForSingleDate.value);
                    })
                );
            } else {
                return this.reportService.fetchBusinessDate(this.filter['business_date_category'], this.siteId, allSites).pipe(
                    map(businessDate => businessDate['business_date'] || null),
                    tap(businessDate => {
                        this.businessDate = businessDate;
                        this.setCalendarValue(state.selectedDateForSingleDate.value);
                    })
                );
            }
        } else {
            if (
                state.multipleCalendars &&
                state.multipleBusinessDate &&
                state.multipleBusinessDate[this.typeOfCalendar] &&
                state.multipleCalendars[this.typeOfCalendar]?.length &&
                !isSiteChanged
            ) {
                return of([]).pipe(tap(() => {
                    this.calendars = [...state.multipleCalendars[this.typeOfCalendar]];
                    this.businessDate = state.multipleBusinessDate[this.typeOfCalendar];
                    this.setCalendarValue(state.selectedDateForMultiDate.value);
                }));
            } else {
                return forkJoin([
                    this.reportService.fetchCalendars(this.siteId, this.calendarType).pipe(
                        tap(calendars => {
                            this.calendars = [ ...calendars ];
                        })
                    ),
                    this.reportService.fetchBusinessDate(this.filter['business_date_category'], this.siteId, allSites).pipe(
                        map(businessDate => businessDate['business_date'] || null),
                        tap(businessDate => {
                            this.businessDate = businessDate;
                        })
                    )
                ]).pipe(
                    tap(() => {
                        let value = null;
                        if (state.selectedDateForMultiDate.value) {
                            value = state.selectedDateForMultiDate.value;
                            this.defaultDateType = 'custom';
                        }
                        this.setCalendarValue(value);
                    })
                );
            }
        }
    }

    private setCalendarValue(value?) {
        switch (this.defaultDateType) {
            case DateTypeModel.CURRENT_PERIOD:
            case DateTypeModel.PREVIOUS_PERIOD:
            case DateTypeModel.PERIODS:
            case DateTypeModel.THIS_MONTH:
            case DateTypeModel.THIS_YEAR:
            case DateTypeModel.CUSTOM:
                const defaultCalendar = this.filtersService.getDefaultPeriod(
                    this.calendars,
                    this.defaultDateType,
                    this.inputCalendarValue
                );
                this.inputCalendarValue = {
                    type: this.getCorrectCalendarType(this.defaultDateType),
                    value: value ? value : { ...defaultCalendar }
                };
                break;
            case DateTypeModel.LATEST_BUSINESS_DAY:
            default:
                this.inputCalendarValue = {
                    type: 'businessDate',
                    value: value ? value : {
                        dateStart: moment(this.businessDate).toDate(),
                        dateEnd: moment(this.businessDate).toDate()
                    }
                };
                break;
        }
        this.handleCalendarChange(this.inputCalendarValue);
    }

    private mapFilterToProps(filter: any) {
        const { calendar_type, default_date, name } = filter;
        this.calendarType = calendar_type;
        this.defaultDateType = default_date;
        this.name = name;
        this.multipleDates = calendar_type !== 'SINGLE_DATE';

        this.updateMenuItems();
    }

    private updatePeriodsItem() {
        this.periodsItem = Object.assign({}, XUX_DATEPICKER_PERIODS.PERIODS, {
            hidden: !this.calendars.length
        });
        this.updateMenuItems();
    }

    private updateBusinessDateItem() {
        this.businessDateItem = {
            name: 'Latest Business Date',
            id: 'businessDate',
            type: this.multipleDates ? 'range' : 'single',
            value: {
                dateStart: moment(this.businessDate).toDate()
            },
            hidden: this.multipleDates  ? !this.businessDate : false,
        };

        if (this.multipleDates ) {
            this.businessDateItem.value['dateEnd'] = moment(this.businessDate).toDate();
        }
        this.updateMenuItems();
    }

    private updateMenuItems() {

        const availableOptions = this.getCalendarOptions();
        const options = {
            availableOptions
        };
        this.calendarConfig = Object.assign({}, this.calendarConfig, options);
    }

    private getCalendarOptions() {
        switch (this.calendarType) {
            case 'NONE':
                return [
                    this.businessDateItem,
                    XUX_DATEPICKER_PERIODS.WEEK,
                    XUX_DATEPICKER_PERIODS.PERIODS_MONTH,
                    XUX_DATEPICKER_PERIODS.PERIODS_YEAR,
                    XUX_DATEPICKER_PERIODS.CUSTOM];
            case 'PYRL':
            case 'INV':
            case 'SCHD':
            case 'FISC':
                return !this.calendars.length
                    ? [
                        this.businessDateItem,
                        XUX_DATEPICKER_PERIODS.PERIODS_MONTH,
                        XUX_DATEPICKER_PERIODS.PERIODS_YEAR,
                        XUX_DATEPICKER_PERIODS.CUSTOM
                    ]
                    : [
                        this.periodsItem,
                        this.businessDateItem,
                        XUX_DATEPICKER_PERIODS.PERIODS_MONTH,
                        XUX_DATEPICKER_PERIODS.PERIODS_YEAR,
                        XUX_DATEPICKER_PERIODS.CUSTOM
                    ];
            case 'SINGLE_DATE':
                return [this.businessDateItem];
            default:
                return [XUX_DATEPICKER_PERIODS.CUSTOM];
        }
    }

    private getCorrectCalendarType(inputType): string {
        switch (inputType) {
            case 'current_period':
            case 'previous_period':
            case 'periods':
                return 'periods';
            default:
                return 'custom';
        }
    }

    private checkIfSitePickerNotChanged(prev, next) {
        if (this.multipleSites) {
            return JSON.stringify(prev.selectedSites) === JSON.stringify(next.selectedSites);
        } else {
            return JSON.stringify(prev.selectedSite) === JSON.stringify(next.selectedSite);
        }
    }

    private checkIfSitePickerChanged(siteState) {
        if (this.multipleSites) {
            return this.isSiteChanged = JSON.stringify(siteState.selectedSites) !== JSON.stringify(siteState.prevSelectedSites);
        } else {
            return this.isSiteChanged = JSON.stringify(siteState.selectedSite) !== JSON.stringify(siteState.prevSelectedSite);
        }
    }

    private checkIfDataForMultiDatePickerExist(state): boolean {
        return !!state.selectedDateForMultiDate &&
        state.multipleBusinessDate && state.multipleBusinessDate[this.typeOfCalendar];
    }

    private checkIfDataForSingleDatePickerExist(state): boolean {
        return !!state.selectedDateForSingleDate &&
        state.singleBusinessDate && state.singleBusinessDate[this.typeOfCalendar];
    }

    private getDataForDatePicker(state) {
        this.calendars = this.multipleDates
            ? state.multipleCalendars[this.typeOfCalendar]
            : null;
        this.businessDate = this.multipleDates
            ? state.multipleBusinessDate[this.typeOfCalendar]
            : state.singleBusinessDate[this.typeOfCalendar];
    }
}
