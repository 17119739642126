import * as Buttons from '../button-templates';

export const MODAL_TEMPLATE_PROMPT = {
  modalType: 'prompt',
  title: 'Prompt',
  buttons: [Buttons.BTN_DONE, Buttons.BTN_CANCEL],
  schema: {
    required: ['name'],
    properties: {
      name: { title: 'Name', type: 'string', minLength: 1 },
    },
  },
};

export const MODAL_TEMPLATE_CONFIRM = {
  modalType: 'confirm',
  icon: 'glyphicon-question-sign',
  title: 'Confirm',
  buttons: [Buttons.BTN_YES, Buttons.BTN_NO],
};

export const MODAL_TEMPLATE_ALERT = {
  modalType: 'alert',
  icon: 'glyphicon-info-sign',
  title: 'Information',
  buttons: [Buttons.BTN_OK],
};
