/**
 * LoginService
 * @module client/src/app/shared/services
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../store/reducers';
import { IUserSession } from '../../../store/reducers/session';
import { filter, map } from 'rxjs/operators';

/**
 * # LoginService
 * Defines the service for establishing if user is logged in
 */
@Injectable()
export class LoginService {
    /**
     * Initialize the service
     * Services need initialization in the constructor.
     */
    constructor(private ngRedux: NgRedux<IAppState>) {}

    /**
     * This getter returns the observable which provides the value
     * for understanding if user is logged in
     */
    public get isLoggedIn(): Observable<boolean> {
        return this.ngRedux.select('userSession').pipe(
            filter((userSession: IUserSession) => userSession != null),
            map((userSession: IUserSession) => (userSession.token ? true : false))
        );
    }
}
