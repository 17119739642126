import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { select } from '@angular-redux/store';
import { environment } from '../../../../environments/environment';
import { XUXDrawerSidebarService } from '../../../xenial-ux/services/xux-drawer-sidebar.service';
import { OpraDataActions, SessionActions } from '../../../store/actions';
import { Router } from '@angular/router';
import { REPORTING_ADMINISTRATOR_PERMISSION } from '../../../../config';

@Component({
    selector: 'dm-main-header',
    styleUrls: ['main-header.style.scss'],
    templateUrl: 'main-header.template.html'
})
export class MainHeaderComponent implements OnInit, OnDestroy {
    public buildVersion: string = environment.version;
    public companies: any[] = [];
    public selectedCompany;
    public user;
    public infoRoute: string = 'detailed-info';
    public showEditReports = false;

    @select(['userSession', 'user']) user$: Observable<any>;
    @select(['userSession', 'companies']) private companies$: Observable<any>;
    @select(['userSession', 'currentCompanyId']) private currentCompanyId$: Observable<any>;
    @select(['userSession', 'userPermissions']) private userPermissions$: Observable<any>;
    private subscription: Subscription[] = [];

    constructor(
        private opraDataActions: OpraDataActions,
        private sessionActions: SessionActions,
        private xuxDrawerSidebarService: XUXDrawerSidebarService,
        private router: Router
    ) {}

    public ngOnInit() {
        this.subscription.push(
            this.user$.subscribe(user => {
                this.user = user;
            })
        );
        this.userPermissions$.pipe(
            filter(permissions => permissions)
        ).subscribe(permissions => {
            if (permissions.items) {
                const items = permissions.items;
                items.forEach(item => {
                    if (item.name === REPORTING_ADMINISTRATOR_PERMISSION) {
                        this.showEditReports = true;
                    }
                });
            }
        });

        combineLatest(this.companies$, this.currentCompanyId$)
            .pipe(
                map(([companies, currentCompanyId]) => {
                    this.companies = companies;
                    return companies.find(company => company.id === currentCompanyId);
                })
            )
            .subscribe(company => {
                this.selectedCompany = company;
            });
    }

    public ngOnDestroy() {
        this.subscription.map(s => s.unsubscribe);
    }

    public showCompanySelector() {
        this.opraDataActions.showCompanySelector();
    }

    public logout() {
        this.xuxDrawerSidebarService.close();
        this.sessionActions.logoutUser();
    }

    public editReports() {
        this.router.navigateByUrl('/custom-reports/editor');
    }
}
