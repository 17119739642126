import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { ISiteState } from '@shared/types/site';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SitesGuard implements CanActivate {
    private sitesState = this.store.select('sites');

    constructor(private store: NgRedux<ISiteState>,
                private router: Router) {
    }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.sitesState.pipe(
        map(({sites}) => {
            if (sites && sites?.length === 0) {
                this.router.navigate(['/custom-reports/no-sites'], { queryParams: { type: 'no-sites' } }).catch();
                return false;
            }
            return true;
        })
    );
  }

}
