<div class="report_calendars_filter">

    <div *ngIf="isLoaded">
        <span class="calendar_label">Job Categories</span>

        <angular2-multiselect [data]="jobs" [(ngModel)]="inputJobValue"
                              [settings]="dropdownSettings"
                              (onSelect)="handleChange()"
                              (onDeSelect)="handleChange()"
                              (onSelectAll)="handleChange()"
                              (onDeSelectAll)="handleChange()"></angular2-multiselect>

    </div>
  <div class="errors-block">
    <div  class="error" *ngFor="let error of errors">{{error}}</div>
  </div>
</div>
