/**
 * Opra data epics
 */

import { Injectable } from '@angular/core';
import { omit } from 'lodash';
import { ActionsObservable, combineEpics, Epic } from 'redux-observable';

import { Observable, of, empty } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { OpraDataService, PermissionService } from '../../../shared/services';
import { HelperService } from '../../../shared/services/helper';
import { PostMessageService } from '../../../shared/services/post-message';
import { ReportsService } from '../../../shared/services/reports';
import { IAction, IEpicsService } from '../../../shared/types';
import { OpraDataActions, SessionActions, SitesActions } from '../../actions';
import { ReportsActions } from '../../actions/reports';

@Injectable()
export class OpraDataEpics implements IEpicsService {
    constructor(
        private opraDataService: OpraDataService,
        private permissionService: PermissionService,
        private reportsService: ReportsService,
        private helperService: HelperService,
        private postMessageService: PostMessageService
    ) {}

    public getEpic(): Epic<any, any> {
        return combineEpics(
            this.fetchCompaniesWithSites.bind(this),
            this.fetchUserPermissions.bind(this),
            this.fetchInfo.bind(this),
            this.fetchCompanyReports.bind(this),
            this.requestRecentSiteList.bind(this),
            this.showSiteSelector.bind(this)
        );
    }

    private requestRecentSiteList(action$: ActionsObservable<IAction>): Observable<any> {
        return action$.ofType(SitesActions.GET_RECENT_SITES_LIST).pipe(
            mergeMap((action: IAction) => {
                this.postMessageService.sendMessage(SitesActions.GET_RECENT_SITES_LIST, action.payload.messageId, null);

                return empty();
            })
        );
    }

    private showSiteSelector(action$: ActionsObservable<IAction>): Observable<any> {
        return action$.ofType(SitesActions.SHOW_SITE_SELECTOR).pipe(
            mergeMap((action: IAction) => {
                this.postMessageService.sendMessage(
                    SitesActions.SHOW_SITE_SELECTOR,
                    action.payload.messageId,
                    omit(action.payload, 'messageId')
                );

                return empty();
            })
        );
    }

    private fetchCompaniesWithSites(action$: ActionsObservable<IAction>) {
        if (!this.helperService.inIframe()) {
            return action$.ofType(SessionActions.LOGIN_USER_SUCCESS).pipe(
                mergeMap((action: IAction) => {
                    return this.opraDataService.fetchCompaniesWithSites().pipe(
                        map(servicePayload => {
                            return OpraDataActions.fetchCompaniesSuccessAction(servicePayload);
                        }),
                        catchError(errors => of(OpraDataActions.fetchCompaniesErrorAction(errors)))
                    );
                })
            );
        } else {
            return empty();
        }
    }

    private fetchInfo(action$: ActionsObservable<IAction>) {
        return action$.ofType(OpraDataActions.FETCH_OPRA_INFO).pipe(
            mergeMap((action: IAction) => {
                return this.opraDataService.getEnvironmentInfo().pipe(
                    map(servicePayload => OpraDataActions.fetchInfoSuccessAction(servicePayload)),
                    catchError(errors => of(OpraDataActions.fetchInfoErrorAction(errors)))
                );
            })
        );
    }

    private fetchUserPermissions(action$: ActionsObservable<IAction>) {
        return action$.ofType(OpraDataActions.FETCH_PERMISSIONS).pipe(
            mergeMap((action: IAction) => {
                return this.permissionService.fetchUserPermissions(action.payload.query).pipe(
                    map(servicePayload => {
                        return OpraDataActions.fetchPermissionsSuccessAction(servicePayload);
                    }),
                    catchError(errors => {
                        console.log(errors);
                        return of(OpraDataActions.fetchPermissionsErrorAction(errors));
                    })
                );
            })
        );
    }

    private fetchCompanyReports(action$: ActionsObservable<IAction>) {
        return action$.ofType(OpraDataActions.FETCH_REPORTS).pipe(
            mergeMap(() => {
                return this.reportsService.fetchReports().pipe(
                    map((reportsPayload: any) => ReportsActions.addReports(reportsPayload.reports)),
                    catchError(errors => of(OpraDataActions.fetchReportsErrorAction(errors)))
                );
            })
        );
    }
}
