import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { iif, Observable, of, Subject } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { ReportsService } from '../../../../shared/services/reports';
import { ISiteState } from '../../../../shared/types/site';
import { SELECT_ALL } from '../../../../../config';
import { distinctUntilChanged, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FiltersActions } from '@store/actions';
import { IFilterState } from '@shared/types/filters';
import { prepareSitesForRequest } from '@components/report-filters/utils/filter.utils';

@Component({
    selector: 'job-codes-filter',
    templateUrl: 'job-codes-filter.html',
    styleUrls: ['style.css']
})
export class SitesJobCodesFilterComponent implements OnInit, OnDestroy {
    @Input() filter: any;
    @Input() type: string = '';
    @Output() handleChanges = new EventEmitter();
    public dropdownSettings = {
        singleSelection: false,
        text: 'Select Jobs',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        classes: 'myclass custom-class'
    };
    public errors: Array<any> = [];
    public inputJobValue: Array<string> | string = [];
    public jobs: Array<any> = [];
    public isReady = false;

    protected label: string = '';
    private attributes: Array<string> = [];
    private name: string = '';
    private siteState$: Observable<ISiteState>;
    private multipleSites = false;
    // private sites$: any[] = [];
    private filtersState: Observable<IFilterState>;
    private isSiteChanged = false;
    private unsubscribe$: Subject<void> = new Subject();

    constructor(
        private reportService: ReportsService,
        private store: NgRedux<ISiteState>,
        private filtersActions: FiltersActions,
        private cdr: ChangeDetectorRef) {
        this.filtersState = store.select(['filters']);
    }

    ngOnInit() {
        this.siteState$ = this.store.select('sites');
        this.multipleSites = this.filter['multipleSites'];

        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];
        this.label = this.filter['label'];

        this.siteState$.pipe(
            takeUntil(this.unsubscribe$),
            distinctUntilChanged((prev, next) => {
                if (this.multipleSites) {
                    return JSON.stringify(prev.selectedSites) === JSON.stringify(next.selectedSites);
                } else {
                    return JSON.stringify(prev.selectedSite) === JSON.stringify(next.selectedSite);
                }
            }),
            tap(siteState => {
                if (this.multipleSites) {
                    this.isSiteChanged = JSON.stringify(siteState.selectedSites) !== JSON.stringify(siteState.prevSelectedSites);
                } else {
                    this.isSiteChanged = JSON.stringify(siteState.selectedSite) !== JSON.stringify(siteState.prevSelectedSite);
                }
            }),
            switchMap((siteState) => this.filtersState.pipe(
                take(1),
                switchMap(({job_codes}) => iif(
                    () => !!job_codes && !this.isSiteChanged,
                    of(job_codes),
                    this.reportService.fetchJobCodes(
                        this.multipleSites ? prepareSitesForRequest(siteState.selectedSites) : siteState.selectedSite.id
                    )
                ))
            ))
        ).subscribe((job_codes) => {
            this.jobs = job_codes;
            this.inputJobValue = [...job_codes];
            this.handleDataChange();
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    protected onItemSelect(item: any) {
        this.handleDataChange();
    }

    protected OnItemDeSelect(item: any) {
        this.handleDataChange();
    }

    protected onSelectAll(items: any) {
        this.handleDataChange();
    }

    protected onDeSelectAll(items: any) {
        this.handleDataChange();
    }

    protected handleDataChange() {
        const value = {};
        const errors = [];

        if (this.inputJobValue.length === this.jobs.length) {
            value[this.attributes[0]] = SELECT_ALL;
        } else {
            value[this.attributes[0]] = this.inputJobValue;
        }
        if (!this.inputJobValue.length) {
            errors.push('Choose job codes please');
        }
        this.errors = errors;
        const res = { value, name: this.name, type: this.filter['type'], errors: errors };
        this.filtersActions.setJobCodes(
            this.inputJobValue
        );
        this.handleChanges.emit(res);
    }
}
