/**
 * Loader component
 */
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { UiActions } from '../../store/actions';
import { takeUntil } from 'rxjs/operators';
import { NgRedux } from '@angular-redux/store';
import { isEmpty } from 'lodash';

@Component({
    selector: 'xux-loader',
    templateUrl: './xux-loader.component.html',
    styleUrls: ['./xux-loader.component.scss']
})
export class XUXLoaderComponent implements OnInit, OnDestroy {
    @Input() loaderState: Observable<any>;
    @Input() contentLoader: boolean;
    @Input() isCancelButtonExist: boolean = true;

    public isVisible: boolean = false;
    public isVisibleCancelButton: boolean = this.isCancelButtonExist;
    protected isActive = true;
    public loaderSubscription: Subscription;
    private dispose$ = new Subject();

    constructor(private uiActions: UiActions, private ngRedux: NgRedux<any>) {}

    public ngOnInit() {
        this.loaderSubscription = this.loaderState.subscribe(state => {
            this.isVisible = !!state;
        });
        this.ngRedux.select(['reports', 'selectedReport'])
            .pipe(takeUntil(this.dispose$))
            .subscribe(report => {
                this.isVisibleCancelButton = !isEmpty(report) && this.isCancelButtonExist;
            });
    }

    public ngOnDestroy() {
        this.isVisible = false;
        this.loaderSubscription.unsubscribe();
        this.dispose$.next();
        this.dispose$.complete();
    }

    close() {
        this.uiActions.disableContentLoader();
    }
}
