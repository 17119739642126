/**
 * The filters of the custom-reports
 */

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ReportsService } from '@shared/services';
import { NgRedux } from '@angular-redux/store';
import { IReport, IReportState } from '../../shared/types/report';
import { CustomHttpClient } from '@services/http-client';
import { PreferencesService } from '@shared/services';
import { FiltersService } from '@services/filters';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { FiltersActions, SitesActions, UiActions } from '@store/actions';
import { isEmpty, isObject } from 'lodash';
import { distinctUntilChanged, filter, first, switchMap, takeWhile, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ALLOWED_STACK_LIST_FOR_DOMAINS } from '@services/constants';
import { ISite, ISiteState } from '@shared/types/site';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

declare function escape(s: string): string;

@Component({
    selector: 'opa-filter-reports',
    templateUrl: 'report-filter.component.html',
    styleUrls: ['filter.scss']
})
export class ReportFilterComponent implements OnInit, OnDestroy {
    public reports;
    public filters: Array<object> = [];
    public reportFilters;
    public selectedReport: any;
    public isLoading$: Observable<boolean>;
    public isSitePickerReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isSitePickerChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public siteState$: Observable<ISiteState>;

    private report: IReport;
    private alive: boolean = true;
    private areFiltersReady = false;
    private filterValues: Array<object> = [];
    private filtersBusinessDateState: Observable<any>;
    private businessDate: string;
    private selectedSite: ISite;
    private selectedSites: Array<ISite>;
    private filterStatesMap = [];
    public reportFilters$;
    private calledReportOnce = false;
    constructor(
        private reportsService: ReportsService,
        private store: NgRedux<IReportState>,
        private filtersService: FiltersService,
        private filtersActions: FiltersActions,
        private uiActions: UiActions,
        private http: CustomHttpClient,
        private preferencesService: PreferencesService,
        private cdRef: ChangeDetectorRef,
        private sitesAction: SitesActions
    ) {
        this.reports = this.store.select('reports');
        this.reports = this.store.select('reports');

        this.reportFilters$ = this.store.select(['reports', 'reportFilters']);
        this.filtersBusinessDateState = store.select(['filters', 'businessDate']);
        this.isLoading$ = store.select(['ui', 'contentLoader']);
        this.siteState$ = store.select(['sites']);
    }

    public ngOnInit() {
        this.uiActions.enableContentLoader();
        this.reports
            .pipe(
                filter(
                    ({ selectedReport }) => !this.report || (selectedReport && selectedReport.id !== this.report.id)
                ),
                first(),
                tap((reportState: IReportState) => {
                    this.filters = this.filtersService.getFilters(reportState);
                    this.filterValues = this.filters.map((item: any) => ({
                        name: item.name,
                        type: item.type,
                        value: item.value,
                        errors: item.errors
                    }));
                    this.selectedReport = reportState.selectedReport;
                    this.selectedReport.additional_path_parameters.forEach(filter => {
                        this.filterStatesMap.push({name: filter.name, ready: filter.name === 'static-params'});
                    });
                }),
                switchMap(({ selectedReport }) => this.siteState$.pipe(
                    distinctUntilChanged((prev, next) => {
                        if (selectedReport.additional_path_parameters[0].multiple) {
                            return JSON.stringify(prev.selectedSites) === JSON.stringify(next.selectedSites);
                        } else {
                            return JSON.stringify(prev.selectedSite) === JSON.stringify(next.selectedSite);
                        }
                    })
                ).pipe(
                    tap((state) => {
                        this.selectedSite = state.selectedSite;
                        this.selectedSites = state.selectedSites;
                        this.isSitePickerReady$.next(!!state.selectedSites.length && !!state.selectedSite);
                    })
                    )
                )
            )
            .subscribe(() => {
                this.areFiltersReady = this.filterStatesMap.every(filter => filter.ready);
                if (this.areFiltersReady) {
                    this.uiActions.disableContentLoader();
                } else {
                    this.uiActions.enableContentLoader();
                }
                this.cdRef.detectChanges();
            });

        this.reportFilters$.pipe(
            takeWhile(() => this.alive),
            distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next))
        ).
        subscribe(reportFilters => {
            if (reportFilters) {
                this.reportFilters = [ ...reportFilters ];
            } else {
                this.reportFilters = [];
            }

            if (this.areValidatedRequiredParams() && this.isValidFilters()) {
                if (this.selectedReport.is_execute_automatically && !this.calledReportOnce) {
                    this.calledReportOnce = true;
                    this.acceptFilters();
                }
            }
        });
    }

    public ngOnDestroy() {
        this.alive = false;
        this.sitesAction.rememberSelectedSites(this.selectedSite, this.selectedSites);
        // this.reportsService.resetFilters();
    }

    public handleFilterChange(data) {
        const filterIndex = this.filterStatesMap.findIndex(filter => filter.name === data.name);
        if (filterIndex !== -1) {
            this.filterStatesMap[filterIndex].ready = true;
        }
        for (const filter of this.filterValues) {
            if (filter['name'] === data['name']) {
                filter['value'] = data['value'];
                filter['errors'] = data['errors'] ? data['errors'] : [];

                return this.reportsService.setFilters(JSON.parse(JSON.stringify(this.filterValues)));
            }
        }
        this.filterValues.push(data);
        this.reportsService.setFilters(JSON.parse(JSON.stringify(this.filterValues)));
        this.cdRef.detectChanges();
    }

    public showFilterByType(item, type) {
        return item.type === type;
    }

    public acceptFilters() {
        this.reportsService.resetErrors();
        let uri: string | {};
        if (this.selectedReport.source === 'ssrs') {
            uri = this.prepareSSRSUri();
        } else if (this.selectedReport.source === 'vega' || this.selectedReport.source === 'ag-grid') {
            uri = this.prepareParamsForCustomReports();
        }
        this.reportsService.acceptFilters(uri);
        this.reportsService.loadReport = true;
    }

    public isValidFilters() {
        if (this.selectedReport.id !== 'sales_analysis') {
            let errors = [];
            this.filterValues.forEach(filter => {
                if (filter['errors']) {
                    errors = errors.concat(filter['errors']);
                }
            });
            this.areFiltersReady = this.filterStatesMap.every(filter => filter.ready);
            return !errors.length && this.isValidCalendarRequiredParams() && this.areFiltersReady;
        } else {
            this.filtersBusinessDateState.subscribe(businessDate => {
                if (businessDate === null) {
                    const setCurrentBDate = () => {
                        this.filtersActions.setBusinessDate(moment().format('YYYY-MM-DD'));
                    };

                    setCurrentBDate();
                    this.uiActions.disableContentLoader();
                } else {
                    this.businessDate = businessDate;
                }
            });
            return !!this.businessDate;
        }
    }

    /**
     * prepare url for ssrs
     * @returns {string}
     */
    private prepareSSRSUri() {
        const tz = moment.tz.guess();
        let result = '';
        if (this.selectedReport) {
            let path = encodeURI(this.selectedReport.path);
             path = this.correctPBIPathIfNecessary(path);
             const authorization = this.http.getAccessToken();
             const additionalParameters = this.getSSRSFilterParameters();
            result = `${path}&rs:embed=true&rc:parameters=false&token=${authorization}&TZ=${tz}`;
            if (this.selectedReport.custom_report_title) {
                result = `${result}&Title=${this.selectedReport.custom_report_title}`;
            }

            if (this.preferencesService.timeLocale) {
                result = `${result}&Locale=${this.preferencesService.timeLocale}`;
            }

            if (additionalParameters) {
                result = `${result}&${additionalParameters}`;
            }
        }
        return result;
    }

    /**
     * prepare params for vega or ag-grid reports
     * @returns {Array}
     */
    private prepareParamsForCustomReports() {
        const results = {};
        for (const filter of this.reportFilters) {
            const keys = Object.keys(filter['value']);
            for (const k of keys) {
                let value;
                if (k === 'Site_Id' || k === 'Exclude_Site_Id') {
                    value = filter['value'][k].value;
                } else {
                    value = filter['value'][k];
                }
                if (typeof value === 'object') {
                    if (Array.isArray(value)) {
                        results[k] = value.map(item => item.id);
                    } else {
                        for (const j of value) {
                            results[k] = this.prepareFilterValue(j);
                        }
                    }
                } else {
                    results[k] = this.prepareFilterValue(value);
                }
            }
        }
        if (this.selectedReport.custom_report_title) {
            results['custom_title'] = this.selectedReport.custom_report_title;
        }
        return results;
    }

    /**
     * validate calendar params
     * @returns {boolean}
     */
    private isValidCalendarRequiredParams() {
        let calendar = this.reportFilters?.filter(item => item.name === 'calendars');

        if (calendar && calendar.length) {
            calendar = calendar[0];

            return calendar.value.Start_Date !== 'Invalid date' && calendar.value.End_Date !== 'Invalid date';
        } else {
            return true;
        }
    }

    /**
     * validate search params
     * @returns {boolean}
     */
    private areValidatedRequiredParams() {
        for (const param of this.selectedReport.additional_path_parameters) {
            const filter = this.getFilterValue(param['name']);
            switch (param['name']) {
                case 'calendars':
                    if (!filter['value'] || !filter['value']['Start_Date']) {
                        return false;
                    }
                    break;
                case 'businessdate':
                    if (!filter['value'] || !filter['value']['Start_Date'] || !filter['value']['End_Date']) {
                        return false;
                    }
                    break;
                case 'site':
                    if (!filter['value'] || !filter['value']['Site_Id']) {
                        return false;
                    }
                    break;
                case 'forecast-definition':
                    if (!filter['value'] || !filter['value']['Forecast_Definition']) {
                        return false;
                    }
                    break;
                case 'job-codes':
                    if (!filter['value'] || !filter['value']['Job_Code']) {
                        return false;
                    }
                    break;
                case 'job-categories':
                    if (!filter['value'] || !filter['value']['Job_Category']) {
                        return false;
                    }
                    break;
                case 'employees':
                    if (isEmpty(filter['value'])) {
                        return false;
                    }
                    break;
                case 'combined-select':
                    if (isEmpty(filter['value'])) {
                        return false;
                    }
                    break;
                default:
                    break;
            }
        }
        return true;
    }

    /**
     * get filter value
     * @param param
     * @returns {any}
     */
    private getFilterValue(param) {
        for (const filter of this.reportFilters) {
            if (filter['name'] === param) {
                return filter;
            }
        }
        return {};
    }

    /**
     * set filter params for ssrs custom-reports
     * @returns {string}
     */
    private getSSRSFilterParameters() {
        const results = [];
        for (const filter of this.reportFilters) {
            if (!isObject(filter['value'])) { return; }
            const keys = Object.keys(filter['value']);
            for (const k of keys) {
                let value;
                if (k === 'Site_Id' || k === 'Exclude_Site_Id') {
                    value = filter['value'][k].value;
                } else {
                    value = filter['value'][k];
                }
                if (typeof value === 'object') {
                    for (const j of value) {
                        if (this.isUneededParamsForSSRS(j)) {
                            results.push(k + '=' + this.prepareFilterValue(j));
                        }
                    }
                } else {
                    if (this.isUneededParamsForSSRS(k)) {
                        results.push(k + '=' + this.prepareFilterValue(value));
                    }
                }
            }
        }
        return results.join('&');
    }

    private isUneededParamsForSSRS(attr) {
        const params = ['dateRange', 'calendar'];
        for (const param of params) {
            if (attr === param) {
                return false;
            }
        }
        return true;
    }

    private prepareFilterValue(val) {
        if (typeof val === 'object') {
            return escape(val['id']);
        } else {
            return escape(val);
        }
    }

    private correctPBIPathIfNecessary(path: string) {
        if (ALLOWED_STACK_LIST_FOR_DOMAINS.includes(environment.envName)) {
            const curDomain = path.split('.')[1];
            let domain = this.http.getHostname();

            // for local development
            if (domain === 'localhost') {
                return path;
            }

            domain = domain.split('.')[1];
            if (curDomain !== domain) {
                return path.replace(curDomain, domain);
            }
        }

        return path;
    }
}
