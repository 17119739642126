<div class="col-md-4 login-component">
    <h1 class="home_heading">
        <img src="assets/img/xenial-logo.png">
    </h1>
    <div id="login-body">
        <form role="form" (submit)="onSubmit()">
            <div class="input-field" *ngIf="errorMessage">
                <p><span class="error_message">{{errorMessage}}</span></p>
            </div>
            <div class="input-field">
                <input type="email"
                       autocomplete="{{isProduction ? 'off': 'on'}}"
                       required
                       #username
                       class="form-control"
                       id="username"
                       placeholder="Username"
                       name="email"
                       [(ngModel)]="client.email">
            </div>
            <div class="input-field">
                <input type="password"
                       autocomplete="{{isProduction ? 'off': 'on'}}"
                       required
                       #password
                       class="form-control"
                       id="password"
                       placeholder="Password"
                       name="password"
                       [(ngModel)]="client.password">
            </div>
            <button class="btn btn-primary" type="submit" name="action" [disabled]="disabledButton">Login</button>
        </form>
    </div>
</div>
