import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
/**
 * Stores service
 */
import { Injectable } from '@angular/core';
import { PortalHttpClient } from '../http-client/portal-http-client.service';
// Mock data
// import { throwError as observableThrowError, Observable, of } from 'rxjs';
// import { delay, map, catchError } from 'rxjs/operators';
// import { DUTCH_BROS_COMPANY } from '../../mock-data/base-companies.mock';

@Injectable()
export class OpraDataService {
    private url = '/me';
    constructor(private http: PortalHttpClient) {}

    public fetchStores(query): Observable<any> {
        const params = new URLSearchParams('');
        if (query) {
            // TODO find better solution for handling params
            if ('limit' in query) {
                params.set('limit', query.limit);
            }
            if ('skip' in query) {
                params.set('skip', query.skip);
            }
        }

        // @ts-ignore-start
        return this.http.get(`${this.url}/sites`, { search: params })
            .pipe(catchError(error => observableThrowError(error.errors)));
        // @ts-ignore-end
    }

    public fetchStoresByCompany(companyId: string): Observable<any> {
        return this.http
            .get(`/companies/${companyId}/sites`)
            .pipe(catchError(error => observableThrowError(error.errors)));
    }

    public getUserInfo(): Observable<any> {
        return this.http.get(`${this.url}`).pipe(catchError(error => observableThrowError(error.errors)));
    }

    public fetchCompaniesWithSites(): Observable<any> {
        // TODO: work with fake data on the dev environment
        // Mock data
        // const response = DUTCH_BROS_COMPANY;
        // return of(response).pipe(
        //     delay(3000),
        //     catchError(error => observableThrowError(error.errors)),
        //     map(responseData => {
        //         return responseData;
        //     })
        // );
        return this.http.get(`${this.url}/companies/sites`, {params: { $select: 'name,id'} }).pipe(
            catchError(error => observableThrowError(error.errors))
        );
    }

    public fetchCompanyRoles(companyId: string): Observable<any> {
        return this.http
            .get(`/companies/${companyId}/roles`)
            .pipe(catchError(error => observableThrowError(error.errors)));
    }

    /**
     * Method for updating company roles
     * @param {Object} data - data object with app_code, name, roles
     *    {
     *       app_code: "string",
     *       name: "string",
     *       roles: [":role1_id", ":role2_id"]
     *     }
     * @returns {Observable<any>}
     */
    public updateCompanyPermission(companyId: string, data): Observable<any> {
        return this.http
            .post(`/companies/${companyId}/permissions`, data)
            .pipe(catchError(error => observableThrowError(error.errors)));
    }

    public getEnvironmentInfo(): Observable<any> {
        return this.http.get(`/info`).pipe(catchError(error => observableThrowError(error.errors)));
    }
}
