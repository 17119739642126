<xux-drawer-container>
  <xux-drawer-sidebar></xux-drawer-sidebar>

  <xux-drawer-content>
    <xux-breadcrumb-navbar class="breadcrumb-nav" [isIframeMode$]="isIframeMode$" *ngIf="(user$ | async)"
                           [selectedMenuItem$]="selectedMenuItem$" [appName]="'Reporting'">
    </xux-breadcrumb-navbar>
    <div class="content-box">
      <dm-notifications></dm-notifications>
      <router-outlet></router-outlet>
    </div>
    <xux-loader [loaderState]="contentLoader$" [contentLoader]="true" [isCancelButtonExist]="false"></xux-loader>
  </xux-drawer-content>
</xux-drawer-container>
<xux-loader [loaderState]="loader$"></xux-loader>
<dm-generic-modal></dm-generic-modal>
<dm-dropdown-menu></dm-dropdown-menu>
