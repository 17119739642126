export const getDefaultGridOptions = config => {
    return {
        pivotPanelShow: 'never',
        rowGroupPanelShow: 'onlyWhenGrouping',

        groupIncludeFooter: true,
        multiSortKey: 'ctrl',
        // groupHideOpenParents: true,
        suppressDragLeaveHidesColumns: true,
        groupUseEntireRow: false,
        groupIncludeTotalFooter: true,
        groupMultiAutoColumn: true,
        pagination: false,
        rowBuffer: 5,
        headerHeight: 40,
        valueCache: true,
        paginationAutoPageSize: true,
        suppressCellSelection: true,
        suppressLoadingOverlay: false,
        alwaysShowHorizontalScroll: true,
        suppressMenuHide: false,
        suppressNoRowsOverlay: false,
        suppressAggFuncInHeader: true,
        tooltipShowDelay: 200,
        colDef: {
            cellRendererParams: {
                footerValueGetter: 'Totals'
            }
        },

        autoGroupColumnDef: {
            minWidth: 150,
            cellRendererParams: {
                suppressCount: true,
                footerValueGetter: config.customFooterValueGetter
            }
        },
        getRowStyle: config.customRowStyles,
        sideBar: {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressSideButtons: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true
                    }
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel'
                }
            ],
            hiddenByDefault: true
        },
        aggFuncs: {
            customSum: config.customSumAggFunc,
            customAvgSum: config.customAvgSumAggFunc,
        }
    };
};
