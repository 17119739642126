<!-- custom-modal -->
<div class="custom-modal"  [ngClass]="{'is-active': popupIsActive}" tabindex="-1" role="dialog" aria-labelledby="orderDetail">
    <div *ngIf="currentOrder && currentOrder.site.length" class="custom-modal-overlay" [@SimpleFadeAnimation]="'in'" (click)="closeModal()"></div>
    <div *ngIf="currentOrder && currentOrder.site.length" class="custom-modal-dialog" [@FadeRightAnimation]="'in'" role="document">
        <div class="custom-modal__content">

            <div class="custom-modal__header">
                <a
                    *ngIf="orderPosition !== 0"
                    (click)="closeOriginalOrder()"
                    href="javascript: void(0);"
                    class="custom-modal__back-link"
                ><i class="icon-arrow-left"></i>back</a>
                <h3 class="custom-modal__title">Order Detail</h3>
                <p class="custom-modal__desc">Store: {{currentOrder.site}}</p>
                <p class="custom-modal__desc" *ngIf="currentOrder.customerFirstName !== '(None)'">
                    Customer: {{currentOrder.customerFirstName}} {{currentOrder.customerLastName}}
                </p>
                <p class="custom-modal__desc" *ngIf="currentOrder.customerFirstName === '(None)'">
                    Customer: {{currentOrder.customerFirstName}}
                </p>
                <div class="custom-modal__actions" >
                    <button
                        class="btn xux-btn btn-default has-icon"
                        (click)="toggleEmailForm()">Email Receipt <i class='fa fa-envelope'></i></button>
                    <a *ngIf="validPermissions" class="btn xux-btn btn-default has-icon" [href]="getLogsLink(currentOrder)" target="_blank">View Logs <i class='fa fa-external-link'></i></a>
                    <a class="btn xux-btn btn-default has-icon"
                       (click)="generatePDF()"
                       href="javascript: void(0);">Export to PDF<i class="icon-file-pdf"></i></a>
                </div>
                <div class="custom-modal__email-form">
                    <form
                        [formGroup]="receiptForm"
                        (ngSubmit)="submitEmail(receiptForm.value.email)"
                        class="email-box"
                        [ngClass]="{'is-active': visibleEmailPanel}">
                        <div class="email-box__input-holder">
                            <input
                                class="email-box__input xux-input"
                                [ngClass]="{'has-errors': receiptForm.dirty && receiptForm.invalid,
                                                'is-correct': receiptForm.dirty && receiptForm.valid}"
                                formControlName="email"
                                type="email"
                                placeholder="customer@email.com"
                                required>
                        </div>
                        <button type="submit" [ngClass]="{'is-disabled': !receiptForm.dirty || receiptForm.invalid}" class="email-box__btn btn xux-btn btn-send">Send</button>
                    </form>
                </div>
            </div>

            <div class="custom-modal__body">
                <table class="custom-modal__top-section">
                    <tr>
                        <td>Order #{{currentOrder.orderNumber}}
                            <a *ngIf="currentOrder.referenceId !== null && this.titleForReferencedOrder.length"
                               (click)="openReferencedOrder()"
                               href="javascript: void(0);"
                               class="custom-modal__link-order"
                            >
                                <i class="icon-link" [title]="titleForReferencedOrder"></i>
                            </a>

                        </td>
                        <td>{{currentOrder.orderType}} - {{currentOrder.orderState}}</td>
                        <td *ngIf="currentOrder.closeTime != 0 && currentOrder.closeTime !== '(None)'">Closed at {{ currentOrder.closeTime }}</td>
                    </tr>
                </table>
                <table class="order-items">
                    <tr>
                        <th class="order-item">Name</th>
                        <th class="order-item">Quantity</th>
                        <th class="order-item">Unit Price</th>
                        <th class="order-item">Extended Price</th>
                        <th class="order-item">Tax</th>
                    </tr>
                    <tr  *ngFor="let item of currentOrder.items">
                        <td [className]="'order-item'"
                            [ngStyle]="item.path.length > 2 ? {'padding-left.px' : (item.path.length / 0.2) + 15 } : {}">
                            <span *ngIf="item.is_donation; else normalName"
                                  class="order-item__has-donation"
                                  title="Donations"
                                    (click)="toggleTooltip($event)">
                                <i class="icon-donation-hand"></i>
                                {{ item.name }}
                                <span>Donations</span>
                            </span>
                            <ng-template #normalName>{{ item.name }}</ng-template>
                        </td>
                        <td class="order-item">{{ numberFormatter(item.quantity) }}</td>
                        <td class="order-item" [innerHTML]="currencyFormatter(item.unit_price, 'detail') | safe:'html'"></td>
                        <td class="order-item" [innerHTML]="currencyFormatter(+item.quantity * +item.unit_price, 'detail') | safe: 'html'"></td>
                        <td class="order-item" [innerHTML]="currencyFormatter(item.item_tax, 'detail') | safe: 'html'"></td>
                    </tr>
                </table>
                <div class="custom-modal__footer">
                    <table class="order-total-values order-totals">
                        <tr>
                            <td>Subtotal:</td>
                            <td>{{currentOrder.subtotal}}</td>
                        </tr>
                        <tr>
                            <td>Tax Total:</td>
                            <td>{{currentOrder.totalTax}}</td>
                        </tr>
                        <tr>
                            <td>Discount and Promotion Total:</td>
                            <td>{{currentOrder.totalDiscount}}</td>
                        </tr>
                        <tr>
                            <td>Order Total:</td>
                            <td>{{currentOrder.total}}</td>
                        </tr>
                        <tr>
                            <td>Tips Total:</td>
                            <td [innerHTML]="currentOrder.tips"></td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="currentOrder.paymentItems && currentOrder.paymentItems.length" class="order-payments">
                    <h3 class="order-payments__title">Payments:</h3>
                    <table class="order-payments-table">
                        <tr>
                            <th>Pay Type</th>
                            <th style="text-align: right;">Rounded Adj</th>
                            <th>Trans Type</th>
                            <th>Auth #</th>
                            <th>Last 4 of CC#</th>
                            <th style="text-align: right;">Payment</th>
                        </tr>
                        <tr *ngFor="let payment of currentOrder.paymentItems">
                            <td>{{ payment.payment_type }}</td>
                            <td [ngStyle]="{'color': (payment.order_type === 'order' || payment.rounding_adjustment >= 0) ? 'green' : 'red'}" style="text-align: right"><i class="fa fa-money" [ngStyle]="{'color': (payment.order_type === 'order' || payment.rounding_adjustment >= 0) ? 'green' : 'red'}" style="text-align: right"></i> {{currencyFormatter(payment.rounding_adjustment, 'detail')}}</td>
                            <td>{{ payment.transaction_type }}</td>
                            <td>{{ payment.auth }}</td>
                            <td>{{ payment.last4 }}</td>
                            <td *ngIf="payment.order_type === 'order' || payment.payment_total >= 0" style="color: green; text-align: right"><i class="fa fa-money" style="color: green; text-align: right"></i> {{currencyFormatter(payment.payment_total, 'detail')}}</td>
                            <td *ngIf="payment.order_type === 'refund' || payment.payment_total < 0" style="color: red; text-align: right"><i class="fa fa-money" style="color: red; text-align: right"></i> {{currencyFormatter(payment.payment_total, 'detail')}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="custom-modal__footer">
                <table class="order-total-values payment-totals">
                    <tr>
                        <td>Payment Total:</td>
                        <td [innerHTML]="currentOrder.paymentTotal"></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
