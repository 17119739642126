import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { iif, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { NgRedux } from '@angular-redux/store';
import { ReportsService } from '@shared/services';
import { ISiteState } from '@shared/types/site';
import { FiltersActions } from '@store/actions';
import { prepareSitesForRequest } from '@components/report-filters/utils/filter.utils';

@Component({
    selector: 'business-rule-group-filter',
    template: '',
    styleUrls: ['style.css']
})
export class BusinessRuleGroupFilterComponent implements OnInit, OnDestroy {
    @Input() filter: any;
    @Input() type: string = '';
    @Output() handleChanges = new EventEmitter();
    protected errors: Array<any> = [];
    protected label: string = '';
    private attributes: Array<string> = [];
    private name: string = '';

    private inputValue: any;
    private filtersState: Observable<any>;
    private sites: Array<any> = [];

    private siteState$: Observable<ISiteState>;
    private isSiteChanged = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private reportService: ReportsService, store: NgRedux<ISiteState>,
        private filtersActions: FiltersActions) {
        this.filtersState = store.select('filters');
        this.siteState$ = store.select('sites');
    }

    ngOnInit() {
        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];
        this.label = this.filter['label'];

        if (this.filter['value']) {
            this.inputValue = this.filter['value'][this.attributes[0]];
        } else {
            this.inputValue = false;
        }

        this.siteState$.pipe(
            takeUntil(this.unsubscribe$),
            distinctUntilChanged((prev, next) => {
                return JSON.stringify(prev.selectedSites) === JSON.stringify(next.selectedSites);
            }),
            tap(siteState => {
                this.isSiteChanged = JSON.stringify(siteState.selectedSites) !== JSON.stringify(siteState.prevSelectedSites);

            }),
            switchMap((siteState) => this.filtersState.pipe(
                take(1),
                switchMap(({ business_rule_groups }) => iif(
                    () => !!business_rule_groups?.value && !this.isSiteChanged,
                    of(business_rule_groups),
                    this.reportService.fetchBusinessRuleGroup(prepareSitesForRequest(siteState.selectedSites))
                ))
            ))
        ).subscribe((business_rule_groups) => {
            this.inputValue = business_rule_groups.value;
            this.handleDataChange();
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    protected handleSiteChange(siteId) {
        this.getBusinessGroupDateData(siteId);
        this.handleDataChange();
    }

    protected handleDataChange() {
        const value = {};
        const errors = [];
        if (this.inputValue !== false && this.inputValue !== true) {
            errors.push('Choose parameter please');
        } else {
            value[this.attributes[0]] = this.inputValue;
        }

        this.errors = errors;
        const res = { name: this.name, type: this.filter['type'], value: value, errors: errors };
        this.filtersActions.setBusinessRuleGroup(this.inputValue);
        this.handleChanges.emit(res);
    }

    private getBusinessGroupDateData(site) {
        this.reportService.resetBusinessRuleGroup();
        let param = site;
        if (site === 'All') {
            param = this.sites;
        } else if (typeof site === 'object') {
            param = prepareSitesForRequest(site);
        }
        if (param) {
            this.reportService.fetchBusinessRuleGroup(param);
        } else {
            this.reportService.resetBusinessRuleGroup();
        }
    }
}
