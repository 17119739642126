import { throwError as observableThrowError, Observable } from 'rxjs';
/**
 * PermissionService
 * @module client/src/app/shared/services
 */
import { Injectable } from '@angular/core';
import { PortalHttpClient } from '../http-client/portal-http-client.service';
import { catchError, map } from 'rxjs/operators';

/**
 * # PermissionService
 * Defines the service for checking user permissions
 */
@Injectable()
export class PermissionService {
    public permissionURL = '/me/permissions';

    constructor(private http: PortalHttpClient) {}

    public fetchUserPermissions(query: string): Observable<any> {
        return this.http.get(this.permissionURL).pipe(
            map((res: any) => {
                return res;
            }),
            catchError(error => observableThrowError(error.errors))
        );
    }
}
