/**
 * Recipe module
 */

import { Routes, RouterModule } from '@angular/router';

// modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { VegaModule } from './d3/vega.module';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { DMSharedComponentsModule } from '@hc/xenial-ui-shared';

// containers
import { ReportsContainerComponent } from './reports.container';
import { ReportContainerComponent } from './report/report.container';
import { CompanySelectionComponent, HomeComponent } from '../../components/app/home';

//  import entity config
import { defaultRoutes } from './reports.routes';
import { DmComponentsModule } from '@components/dm.components.module';
import { EtlStatisticsComponent } from '@components/etl-statistics/etl-statistics.component';
import { IFrameReportComponent } from '@components/i-frame-reports/i-frame-report.component';
import { CustomReportComponent } from '@components/custom-reports/custom-report.component';
import { XenialUxModule } from '../../xenial-ux';
import { XUXDrawerModule } from '../../xenial-ux/xux-drawer';
import { SalesAndLaborComponent } from '@components/vega-reports/sales-and-labor-report/sales-and-labor.component';
import { SpeedOfServiceComponent } from '@components/vega-reports/speed-of-service-report/speed-of-service.component';
import { SalesAnalysisComponent } from '@components/vega-reports/sales-analysis-report/sales-analysis.component';
import { OrderExplorerComponent } from '@components/ag-grid-reports/order-explorer-report/order-explorer.component';
import { OrderExplorerDetailComponent } from '@components/ag-grid-reports/order-explorer-report/order-explorer-detail/order-explorer-detail.component';
import { EnterpriseSalesComponent } from '@components/ag-grid-reports/enterprise-sales-report/enterprise-sales.component';
import { EmployeeDiscountComponent } from '@components/ag-grid-reports/employee-discount-report/employee-discount.component';
import { OrderPaymentsComponent } from '@components/ag-grid-reports/order-payments-report/order-payments.component';
import { PdfExportModalComponent } from '@components/ag-grid-reports/pdf-export-modal/pdf-export-modal.component';

import { SafePipe } from '../../pipes/safe-resourse.pipe';
import { TooltipModule } from 'ngx-bootstrap';

// routes
const routes: Routes = defaultRoutes;

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        DmComponentsModule,
        XenialUxModule.forRoot(),
        XUXDrawerModule.forRoot(),
        CarouselModule,
        VegaModule,
        AngularMultiSelectModule,
        DMSharedComponentsModule,
        TooltipModule
    ],
    declarations: [
        ReportsContainerComponent,
        ReportContainerComponent,
        HomeComponent,
        CompanySelectionComponent,
        EmployeeDiscountComponent,
        EnterpriseSalesComponent,
        EtlStatisticsComponent,
        IFrameReportComponent,
        CustomReportComponent,
        SalesAndLaborComponent,
        SalesAnalysisComponent,
        SpeedOfServiceComponent,
        OrderExplorerComponent,
        OrderExplorerDetailComponent,
        OrderPaymentsComponent,
        PdfExportModalComponent,
        SafePipe
    ],
    exports: [
        RouterModule,
        CommonModule,
        ReportsContainerComponent,
        ReportContainerComponent,
        HomeComponent,
        CompanySelectionComponent,
        DmComponentsModule,
        XenialUxModule,
        XUXDrawerModule
    ]
})
export class ReportsModule {}
