/**
 * Reducers index
 */
/* tslint:disable:object-literal-key-quotes */
import { combineReducers } from 'redux';
import { routerReducer } from '@angular-redux/router';
import { uiReducer, IUiState } from './ui';
import { userSessionReducer, IUserSession } from './session';
import { preferencesReducer, IPreferencesState } from './preferences';
import { notificationsReducer, INotificationsState } from './notifications';
import { filtersReducer } from './filters';
import { customReportsReducer, etlReducer, reportsReducer,  } from './reports';
import { sitesReducer } from './sites/sites.reducer';
import { IFilterState } from '../../shared/types/filters';
import { IReportState } from '../../shared/types/report';
import { ICustomReportState } from '../../shared/types/report';
import { IEtlStatisticsState } from '../../shared/types/report';
import { ISiteState } from '../../shared/types/site';

/**
 * IAction interface
 */
export interface IAction {
    type: string;
    payload?: any;
}

/**
 * IActionReducer interface
 */
export type IActionReducer<T> = (state: T, action: IAction) => T;

/**
 * Application state interface
 */
export interface IAppState {
    entities?: any;
    notifications?: INotificationsState;
    preferences?: IPreferencesState;
    router?: string;
    ui?: IUiState;
    userSession?: IUserSession;
    filters?: IFilterState;
    reports?: IReportState;
    customReports?: ICustomReportState;
    'etl-statistics'?: IEtlStatisticsState;
    sites?: ISiteState;
}

/**
 * Root reducer
 */
export const rootReducer = combineReducers<IAppState>({
    notifications: notificationsReducer,
    preferences: preferencesReducer,
    router: routerReducer as any,
    ui: uiReducer,
    userSession: userSessionReducer as any,

    filters: filtersReducer,
    reports: reportsReducer,
    customReports: customReportsReducer,
    'etl-statistics': etlReducer,
    sites: sitesReducer
});
