import {
    Component,
    ElementRef,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
    EventEmitter
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { find, get, map, isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { tap, combineLatest, map as rxMap } from 'rxjs/operators';
import { FieldPreferences } from '../../shared/services/sheme-form/sheme-form.interface';
import { SelectOption } from '@hc/xenial-ui-shared/modules/xdm-shared-components/definitions/types/common.type';
import { asSelectOptions, controlValueObs } from '../../shared/utils';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Component({
    selector: 'xux-input-select',
    templateUrl: 'xux-input-select.component.html',
    styleUrls: ['xux-input-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class XuxInputSelectComponent implements OnInit {
    @ViewChild('selectBtn', { static: true }) public selectBtn: ElementRef;
    @ViewChild('dropdown', { static: true }) public dropdown: BsDropdownDirective;
    @Input() public field: FieldPreferences = {};
    @Output() changeValue = new EventEmitter();
    public selectOptionsObs: Observable<SelectOption[]>;
    public selectedItemTextObs: Observable<string>;
    public disabled: boolean;
    constructor() {}

    public ngOnInit(): void {
        this.disabled = get(this.field, 'widgetData.disabled', false);
        this.selectOptionsObs = asSelectOptions(this.field.selectOptions).pipe(
            tap(items => this.addOptionValidator(items)),
            combineLatest(controlValueObs(get(this.field, 'control')), (items, selectedItem) =>
                map(items, ({ value, text }) => ({
                    value,
                    text,
                    selected: isEqual(selectedItem, value)
                }))
            )
        );

        this.selectedItemTextObs = this.selectOptionsObs.pipe(rxMap(items => get(find(items, 'selected'), 'text')));
    }

    public setValue(value): void {
        this.selectBtn.nativeElement.focus();
        const control: AbstractControl = get(this, 'field.control');
        if (!control) {
            return;
        }
        control.markAsDirty();
        control.markAsTouched();
        control.setValue(value);
        this.changeValue.emit(value);
    }

    private addOptionValidator(items): void {
        const control: AbstractControl = get(this, 'field.control');
        if (!control) {
            return;
        }

        setTimeout(() => {
            control.setValidators(get(this.field, 'validators', []));
            control.updateValueAndValidity();
        });
    }
}
