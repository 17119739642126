<div class="report_calendars_filter">
  <div class="item_types_block">
    <span class="calendar_label">Departments</span>
    <angular2-multiselect [data]="departments" [(ngModel)]="inputDepartmentsValue"
                          [settings]="dropdownSettings"
                          (onSelect)="onDepartmentChange($event)"
                          (onDeSelect)="onDepartmentChange($event)"
                          (onSelectAll)="onDepartmentChange($event)"
                          (onDeSelectAll)="onDepartmentChange($event)"
                          ngDefaultControl></angular2-multiselect>

  </div>
  <div *ngIf="errors.departments" class="errors-block">
    <div class="error">{{errors.departments}}</div>
  </div>

  <div class="item_types_block">
    <span class="calendar_label">Item Types</span>
    <angular2-multiselect [data]="itemTypes" [(ngModel)]="inputTypesValue"
                          [settings]="dropdownSettings"
                          (onSelect)="onTypeChange($event)"
                          (onDeSelect)="onTypeChange($event)"
                          (onSelectAll)="onTypeChange($event)"
                          (onDeSelectAll)="onTypeChange($event)"
                          ngDefaultControl></angular2-multiselect>
  </div>
  <div *ngIf="errors.types" class="errors-block">
    <div class="error">{{errors.types}}</div>
  </div>
</div>
