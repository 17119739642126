const prepareSitesForRequest = (siteId): Array<string> => {
    if (siteId === 'All') {
        return siteId;
    } else {
        if (typeof siteId === 'string') {
            return [siteId];
        } else if (Array.isArray(siteId)) {
            if (siteId.length === 1) {
                return [siteId[0].id];
            } else {
                return siteId.map(site => site.id);
            }
        } else {
            return siteId;
        }
    }
};

export {
    prepareSitesForRequest
};
