/**
 * Error page component
 */
import { Component, ViewEncapsulation } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { IErrorPageType } from '../../../shared/types';

@Component({
  selector: 'dm-error-page',
  templateUrl: 'error-page.component.html',
  styleUrls: ['error-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DmErrorPageComponent {
  @select(['ui', 'errorPage']) public errorObs: Observable<IErrorPageType>;
  public defaultErrorTitle: string = 'Unexpected Error';
}
