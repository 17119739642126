import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { ReportsService } from '../../../../shared/services/reports';
import { ISiteState } from '../../../../shared/types/site';
import { SELECT_ALL } from '../../../../../config';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'tags-filter',
    templateUrl: 'tags-filter.html',
    styleUrls: ['style.css']
})
export class TagsFilterComponent implements OnInit, OnDestroy {
    @Input() filter: any;
    @Input() type: string = '';
    @Output() handleChanges = new EventEmitter();
    public dropdownSettings = {
        singleSelection: false,
        text: 'Select Tags',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        classes: 'myclass custom-class'
    };
    public errors: Array<any> = [];
    public inputTagValue: Array<string> | string = [];
    public tags: Array<any> = [];
    protected sitesState: Observable<any>;

    private attributes: Array<string> = [];

    private name: string = '';
    private isActive: boolean = true;
    private filtersState: Observable<any>;

    constructor(private reportService: ReportsService, store: NgRedux<ISiteState>) {
        this.sitesState = store.select('sites');
        this.filtersState = store.select('filters');
    }

    ngOnInit() {
        this.getTags();
        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];

        const keys = Object.keys(this.filter['value']);
        this.inputTagValue = JSON.parse(JSON.stringify(this.filter['value'][keys[0]]));

        this.filtersState.pipe(takeWhile(() => this.isActive)).subscribe(({ tags }) => {
            this.tags = tags || [];

            if (this.tags.length && this.inputTagValue === SELECT_ALL) {
                this.inputTagValue = [...this.tags];
            }
        });
    }

    ngOnDestroy() {
        this.isActive = false;
    }

    public handleDataChange() {
        const value = {};
        const errors = [];

        if (this.inputTagValue.length === this.tags.length) {
            value[this.attributes[0]] = SELECT_ALL;
        } else {
            value[this.attributes[0]] = this.inputTagValue;
        }
        if (!this.inputTagValue.length) {
            errors.push('Choose tags please');
        }
        this.errors = errors;
        const res = { name: this.name, type: this.filter['type'], value: value, errors: errors };
        this.handleChanges.emit(res);
    }

    private getTags() {
        this.reportService.resetTags();
        this.reportService.fetchTags();
    }
}
