import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomHttpClient, HttpCancelService } from '../http-client';
import { select } from '@angular-redux/store';

import { catchError } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { ErrorHandlerService } from '../helper';
import { ReportsService } from './reports.service';
import { HttpHeaders } from '@angular/common/http';
import { maxPageSize } from '../../utils';
import { isEqual } from 'lodash';
import { IValuesOfFilters } from '@shared/types/filters';

@Injectable()
export class CustomReportsService {
    @select(['ui', 'contentLoader']) contentLoader$: Observable<any>;
    private isCanceled: boolean;
    private previousRequest = {
        url: '',
        data: {}
    };
    constructor(
        public httpCancelService: HttpCancelService,
        private http: CustomHttpClient,
        private errorHandlerService: ErrorHandlerService,
        private reportsService: ReportsService,
        private router: Router,
    ) {
        this.contentLoader$.subscribe(res => {
            if (this.isCanceled === false && !res) {
                // this.httpCancelService.cancelPendingRequests();
            }
            this.isCanceled = !res;
        });
    }

    // Speed of Service
    public fetchSpeedOfServiceReport(sites: string[] | string, dateFrom: string, dateTo: string) {
        return this.http
            .post('/custom-reports/speed-of-service/data', {
                sites: sites,
                date_from: dateFrom,
                date_to: dateTo
            })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    // Dashboard report
    public fetchDashboardDates(sites, date) {
        return this.http.post('/custom-reports/dashboard-vega/by-dates', { sites: sites, business_date: date })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchDashboardDayParts(sites, date) {
        return this.http.post('/custom-reports/dashboard-vega/by-day-parts', { sites: sites, business_date: date })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchDashboardDestinations(sites, date) {
        return this.http
            .post('/custom-reports/dashboard-vega/by-destinations', { sites: sites, business_date: date })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchDashboardHours(sites, date) {
        return this.http.post('/custom-reports/dashboard-vega/by-hours', { sites: sites, business_date: date })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchDashboardHeader(sites, date) {
        return this.http
            .post('/custom-reports/dashboard-vega/header', {
                sites: sites,
                business_date: date
            })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchDashboard(sites, date) {
        this.fetchDashboardDates(sites, date);
        this.fetchDashboardDayParts(sites, date);
        this.fetchDashboardDestinations(sites, date);
        this.fetchDashboardHours(sites, date);
        this.fetchDashboardHeader(sites, date);
    }

    // Sales and Labor report
    public fetchSalesAndLaborHours(sites, dateFrom, dateTo) {
        return this.http
            .post('/custom-reports/sales-and-labor/by-hours', {
                sites: sites,
                date_from: dateFrom,
                date_to: dateTo
            })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchSalesAndLaborHeader(sites, dateFrom, dateTo) {
        return this.http
            .post('/custom-reports/sales-and-labor/header', {
                sites: sites,
                date_from: dateFrom,
                date_to: dateTo
            })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchSalesAndLabor(sites, dateFrom, dateTo) {
        return forkJoin([
            this.fetchSalesAndLaborHours(sites, dateFrom, dateTo),
            this.fetchSalesAndLaborHeader(sites, dateFrom, dateTo)
        ]).pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    // Sales Analysis report
    public fetchSalesAnalysis(sites, date) {
        let currentSiteIds: string = sites;
        if (
            this.reportsService.getCurrentSiteIds().length &&
            this.reportsService.getCurrentSiteIds() !== currentSiteIds
        ) {
            currentSiteIds = this.reportsService.getCurrentSiteIds();
        }

        return this.http.post('/custom-reports/sales-analysis/data', { sites: currentSiteIds, business_date: date })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    // Enterprise Sales Summary report
    public fetchEnterpriseSalesSummary(request) {
        const { company, sites, dateFrom, dateTo } = request;
        const headers = new HttpHeaders({
            'x-company-id': company,
            'x-site-ids': sites
        });
        const body = {
            date_from: dateFrom,
            date_to: dateTo
        };

        return this.http
            .post('/custom-reports/enterprise-sales/data', body, { headers })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchEnterpriseSalesPayTypes(request) {
        const { company, sites } = request;
        const headers = new HttpHeaders({
            'x-company-id': company,
            'x-site-ids': sites
        });

        const body = {};

        return this.http
            .post('/custom-reports/enterprise-sales/pay-type-list', body, { headers })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    // Order Explorer report
    public fetchOrderExplorer(request) {
        const { company, sites, dateFrom, dateTo, orderNumber, cardNumber, tableNumber, perPage } = request;
        const headers = new HttpHeaders({
            'x-company-id': company,
            'x-site-ids': sites
        });
        const body = {
            date_from: dateFrom,
            date_to: dateTo,
            page_size: perPage || maxPageSize
        };

        if (orderNumber) {
            body['order_number'] = orderNumber;
        }

        if (cardNumber) {
            body['card_number'] = cardNumber;
        }

        if (tableNumber) {
            body['table_number'] = tableNumber;
        }

        return this.http
            .post('/custom-reports/order-explorer/data', body, { headers: headers })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchOrderExplorerOrderCount(request) {
        const { company, sites, dateFrom, dateTo, orderNumber, cardNumber, tableNumber } = request;
        const headers = new HttpHeaders({
            'x-company-id': company,
            'x-site-ids': sites
        });
        const body = {
            date_from: dateFrom,
            date_to: dateTo
        };

        if (orderNumber) {
            body['order_number'] = orderNumber;
        }

        if (cardNumber) {
            body['card_number'] = cardNumber;
        }

        if (tableNumber) {
            body['table_number'] = tableNumber;
        }
        return this.http
            .post('/custom-reports/order-explorer/orders-count', body, { headers })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchOrderExplorerGeneralDetail(company, sites, orderId, businessDate) {
        const headers = new HttpHeaders({
            'x-company-id': company,
            'x-site-ids': sites,
            'x-business-date': businessDate,
        });
        const body = {};
        return this.http
            .post(`/custom-reports/order-explorer/detail/${orderId}`, body, { headers })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchOrderExplorerPaymentsDetails(company, sites, orderId) {
        const headers = new HttpHeaders({
            'x-company-id': company,
            'x-site-ids': sites
        });
        const body = {};
        return this.http
            .post(`/custom-reports/order-explorer/payments/${orderId}`, body, { headers })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchOrderExplorerDetail(company, sites, orderId, businessDate) {
        return forkJoin([
            this.fetchOrderExplorerGeneralDetail(company, sites, orderId, businessDate),
            this.fetchOrderExplorerPaymentsDetails(company, sites, orderId)
        ]).pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    // Employee Discount report
    public fetchEmployeeDiscount(request) {
        const { company, sites, perPage, dateFrom, dateTo } = request;
        const headers = new HttpHeaders({
            'x-company-id': company,
            'x-site-ids': sites
        });
        const body = {
            date_from: dateFrom,
            date_to: dateTo,
            page_size: perPage || maxPageSize
        };

        return this.http
            .post('/custom-reports/employee-discount/data', body, { headers })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchEmployeeDiscountOrderCount(request) {
        const { company, sites, dateFrom, dateTo } = request;
        const headers = new HttpHeaders({
            'x-company-id': company,
            'x-site-ids': sites
        });
        const body = {
            date_from: dateFrom,
            date_to: dateTo
        };

        return this.http
            .post('/custom-reports/employee-discount/orders-count', body, { headers })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    // Order Payments report
    public fetchOrderPayments(request) {
        const { company, sites, perPage, dateFrom, dateTo, transType, paymentStatus } = request;
        const headers = new HttpHeaders({
            'x-company-id': company,
            'x-site-ids': sites
        });
        const body = {
            date_from: dateFrom,
            date_to: dateTo,
            payment_status: paymentStatus,
            trans_type: transType,
            page_size: perPage || maxPageSize
        };

        return this.http
            .post('/custom-reports/order-payments/data', body, { headers })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public fetchOrderPaymentsOrderCount(request) {
        const { company, sites, dateFrom, dateTo, transType, paymentStatus } = request;
        const headers = new HttpHeaders({
            'x-company-id': company,
            'x-site-ids': sites
        });
        const body = {
            date_from: dateFrom,
            date_to: dateTo,
            payment_status: paymentStatus,
            trans_type: transType,
        };

        return this.http
            .post('/custom-reports/order-payments/orders-count', body, { headers })
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public isNewRequestData(newData: IValuesOfFilters | object): boolean {
        const newRequest = {
            data: newData,
            url: this.router.url
        };
        if (isEqual(newRequest, this.previousRequest)) {
            return false;
        }

        this.previousRequest = {...newRequest};
        return true;
    }
}
