import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IFilterState } from '../../../shared/types/filters';
import {
    ADD_DEPARTMENTS, ADD_EMPLOYEES,
    ADD_JOB_CATEGORIES,
    ADD_JOB_CODES,
    ADD_TAGS,
    ADD_TYPES,
    RESET_CALENDARS,
    RESET_FILTERS,
    SET_BUSINESS_DATE,
    SET_BUSINESS_RULE_GROUP,
    SET_CALENDARS, SET_CALENDARS_AND_BUSINESS_DATE, SET_DATE_FOR_SINGLE_PICKER,
    SET_FORECAST_DEFINITIONS
} from '../../reducers/filters';
import { SET_REPORT_URL } from '../../reducers/reports';

@Injectable()
export class FiltersActions {
    constructor(private store: NgRedux<IFilterState>) {}

    setDepartments(departments) {
        this.store.dispatch({ type: ADD_DEPARTMENTS, payload: departments });
    }

    setItemTypes(itemTypes) {
        this.store.dispatch({ type: ADD_TYPES, payload: itemTypes });
    }

    setJobCodes(jobCodes) {
        this.store.dispatch({ type: ADD_JOB_CODES, payload: jobCodes });
    }

    setEmployees(employees) {
        this.store.dispatch({ type: ADD_EMPLOYEES, payload: employees });
    }

    setTags(tags) {
        this.store.dispatch({ type: ADD_TAGS, payload: tags });
    }

    setJobCategories(jobCategories) {
        this.store.dispatch({ type: ADD_JOB_CATEGORIES, payload: jobCategories });
    }

    setCalendars(calendars) {
        this.store.dispatch({ type: SET_CALENDARS, payload: calendars });
    }

    setBusinessDate(date) {
        this.store.dispatch({ type: SET_BUSINESS_DATE, payload: date });
    }

    setCalendarsAndBusinessDay(typeOfCalendar, calendarType, multiple, date, calendars, selectedDate, prevCalendarType, applied) {
        this.store.dispatch(
            {
                type: SET_CALENDARS_AND_BUSINESS_DATE,
                payload: {typeOfCalendar, calendarType, multiple, date, calendars, selectedDate, prevCalendarType, applied }}
        );
    }

    setDateForSinglePicker(date) {
        this.store.dispatch({ type: SET_DATE_FOR_SINGLE_PICKER, payload: date});
    }

    resetCalendars() {
        this.store.dispatch({ type: RESET_CALENDARS });
    }

    setForecastDefinitions(definitions) {
        this.store.dispatch({ type: SET_FORECAST_DEFINITIONS, payload: definitions });
    }

    setBusinessRuleGroup(businessRuleGroup) {
        this.store.dispatch({ type: SET_BUSINESS_RULE_GROUP, payload: businessRuleGroup });
    }

    setReportUri(uri) {
        this.store.dispatch({ type: SET_REPORT_URL, payload: uri });
    }

    resetFilters() {
        this.store.dispatch({ type: RESET_FILTERS });
    }
}
