import { Injectable } from '@angular/core';

@Injectable()
export class CacheService {
    public set(name, value, options: any) {
        options = {
            path: '/',
            ...options
        };

        if (Object.keys(options).length && options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

        for (const optionKey of options) {
            updatedCookie += '; ' + optionKey;
            const optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += '=' + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    public get(name) {
        const matches = document.cookie.match(
            new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
        );
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    public remove() {
        this.set(name, '', {
            'max-age': -1
        });
    }
}
