import { ENTITY_SERVICES } from './entityServices';
import { UTILITY_SERVICES } from './utilityServices';

export * from './session/token-session.service';
export * from './local-storage/local-storage.service';
export * from './local-db-storage/local-db-storage.service';
export * from './session';
export * from './helper';
export * from './opra-data/opra.data-service';
export * from './reports/reports.service';
export * from './reports/custom.reports.service';
export * from './login';
export * from './email-notification/email-notification.service';
export * from './preferences/preferences.service';

export const SERVICE_PROVIDERS: any[] = [
    ...ENTITY_SERVICES,
    ...UTILITY_SERVICES,
    { provide: 'EntityServices', useValue: ENTITY_SERVICES }
];
