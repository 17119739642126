<div class="form-group container">
  <div class="row" *ngIf="errorObs | async as error">
    <div class="row">
      <div *ngIf="error.icon" class="error-page-icon pull-left">
          <i [ngClass]="error.icon" class="glyphicon"> </i>
      </div>
      <div class="pull-left">
        <h1>{{error.title || defaultErrorTitle}}</h1>
        <p *ngIf="error.description">{{error.description}}</p>
      </div>
    </div>
    <div class="row" *ngIf="error.details">
      <div class="col-sm-12">{{error.details}}</div>
    </div>
  </div>
</div>
