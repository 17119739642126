<nav class="main-menu">
  <xux-navigation [items]="menuItems$" *ngIf="needShowReports"></xux-navigation>

  <div class="show_filters_block" *ngIf="needShowBackToCompanies&&needShowReports">
    <div class="back_to_reports_link_block">
      <a (click)="showCompanySelector()" class="back_to_report">
        <i class="fa fa-arrow-left"></i> Select Company
      </a>
    </div>
  </div>

  <div class="show_filters_block" *ngIf="needShowFilters">
    <div class="back_to_reports_link_block"><a (click)="backToReports()" class="back_to_report"><i
      class="fa fa-arrow-left"></i> Back to Reports</a></div>
    <div>
      <opa-filter-reports></opa-filter-reports>
    </div>
  </div>

  <div #modalContainer></div>
</nav>
