/**
 * Reports actions
 */
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { ADD_SITES } from '../../reducers/sites/sites.reducer';
import { ISiteState } from '../../../shared/types/site';
import { IAction, ISite, IIframeMessageType, SiteSelectorOptions } from '../../../shared/types';
import { generateId } from '../../../shared/utils';
import { get, isEmpty, map } from 'lodash';
import { find } from 'rxjs/operators';

@Injectable()
export class SitesActions {
    public static GET_RECENT_SITES_LIST: string = 'GET_RECENT_SITES_LIST';
    public static SHOW_SITE_SELECTOR: string = 'SHOW_SITE_SELECTOR';

    public static GET_RECENT_SITES_LIST_SUCCESS: string = 'GET_RECENT_SITES_LIST_SUCCESS';
    public static SITE_SELECTION_REQUEST = 'SITE_SELECTION_REQUEST';
    public static SITE_SELECTION_SUCCESS: string = 'SITE_SELECTION_SUCCESS';
    public static SITE_SELECTION_ERROR: string = 'SITE_SELECTION_ERROR';
    public static SITE_SELECTION_UNSELECT: string = 'SITE_SELECTION_UNSELECT';
    public static UPDATE_SITE_SELECTION: string = 'UPDATE_SITE_SELECTION';
    public static UPDATE_SITE_SELECTION_SUCCESS: string = 'UPDATE_SITE_SELECTION_SUCCESS';
    public static ADD_SITES_SUCCESS = 'ADD_SITES_SUCCESS';
    public static ADD_SITES = 'ADD_SITES';
    public static REMEMBER_SELECTED_SITES = 'REMEMBER_SELECTED_SITES';
    public static SELECT_STORES: string = 'SELECT_STORES';

    public static updateSelectedSites(payload: object): IAction {
        return {
            type: SitesActions.UPDATE_SITE_SELECTION,
            payload: payload
        };
    }

    public static updateSelectedSitesSuccess(payload: object): IAction {
        return {
            type: SitesActions.UPDATE_SITE_SELECTION_SUCCESS,
            payload: payload
        };
    }

    public static getRecentSiteListAction(msgOptions: object): IAction {
        return {
            type: SitesActions.GET_RECENT_SITES_LIST,
            payload: msgOptions
        };
    }

    public static showSiteSelectorAction(msgOptions: object): IAction {
        return {
            type: SitesActions.SHOW_SITE_SELECTOR,
            payload: msgOptions
        };
    }

    public static getRecentSiteListSuccessAction(msg: IIframeMessageType): IAction {
        return {
            type: SitesActions.GET_RECENT_SITES_LIST_SUCCESS,
            payload: msg
        };
    }

    public static siteSelectionRequestAction(msg: IIframeMessageType): IAction {
        return {
            type: SitesActions.SITE_SELECTION_REQUEST,
            payload: msg
        };
    }

    public static siteSelectionSuccessAction(msg: any): IAction {
        return {
            type: SitesActions.SITE_SELECTION_SUCCESS,
            payload: msg
        };
    }

    public static addSitesSuccessAction(): IAction {
        return {
            type: SitesActions.ADD_SITES_SUCCESS,
        };
    }

    public static siteSelectionErrorAction(msg: IIframeMessageType): IAction {
        return {
            type: SitesActions.SITE_SELECTION_ERROR,
            payload: msg
        };
    }

    public static siteSelectionUnselectAction(): IAction {
        return {
            type: SitesActions.SITE_SELECTION_UNSELECT
        };
    }

    public static selectStoresAction(selectedStores: any[]): IAction {
        return {
            type: SitesActions.SELECT_STORES,
            payload: {
                selectedStores
            }
        };
    }

    constructor(private ngRedux: NgRedux<ISiteState>) {}

    public async postMessageResultAsPromised(messageId: string): Promise<any> {
        const selectionPath: string[] = ['tools', 'iframePostMessageResult'];
        const result = await this.ngRedux
            .select(selectionPath)
            .pipe(find((res: any) => get(res, 'options.messageId') === messageId))
            .toPromise();

        return !isEmpty(result.errors) ? null : result.response.payload;
    }

    public getRecentSiteList(): void {
        const msgOptions = { messageId: generateId() };

        this.ngRedux.dispatch(SitesActions.getRecentSiteListAction(msgOptions));
    }

    public async showSiteSelector(
        options: SiteSelectorOptions = {
            required: false,
            multiselect: false
        }
    ): Promise<ISite[]> {
        const msgOptions = { messageId: generateId() };

        this.ngRedux.dispatch(
            SitesActions.showSiteSelectorAction({
                ...options,
                ...msgOptions
            })
        );

        const result = await this.postMessageResultAsPromised(msgOptions.messageId);

        if (options.applySiteSelection) {
            if (!result) {
                window.history.back();
            } else {
                const storesIds = map(result.items, 'id');
                this.selectStores(storesIds);
            }
        }

        return result ? result.items : null;
    }

    public getRecentSiteListSuccess(msg: IIframeMessageType): void {
        this.ngRedux.dispatch(SitesActions.getRecentSiteListSuccessAction(msg));
    }

    public siteSelectionRequest(msg: IIframeMessageType): void {
        this.ngRedux.dispatch(SitesActions.siteSelectionRequestAction(msg));
    }

    public siteSelectionSuccess(msg: IIframeMessageType): void {
        this.ngRedux.dispatch(SitesActions.siteSelectionSuccessAction(msg));
    }

    public siteSelectionError(msg: IIframeMessageType): void {
        this.ngRedux.dispatch(SitesActions.siteSelectionErrorAction(msg));
    }

    public siteSelectUnselectAll() {
        this.ngRedux.dispatch(SitesActions.siteSelectionUnselectAction());
    }

    public resultAsPromised(requestId): Promise<any> {
        const selectionPath: string[] = ['tools'];

        return this.ngRedux
            .select(selectionPath.concat('operationResult'))
            .pipe(find((result: any) => get(result, 'options.requestId') === requestId))
            .toPromise()
            .then((operationResult: any): any => {
                if (!operationResult.response) {
                    return new Error('operationResult.response is not valid');
                }
                return operationResult.response;
            });
    }

    public selectStores(selectedStores: any[]): void {
        this.ngRedux.dispatch(SitesActions.selectStoresAction(selectedStores));
    }

    public rememberSelectedSites(selectedSite, selectedSites) {
        this.ngRedux.dispatch({
            type: SitesActions.REMEMBER_SELECTED_SITES,
            payload: {
                selectedSite,
                selectedSites
            }
        });
    }

    setSites(sites) {
        this.ngRedux.dispatch({ type: ADD_SITES, payload: sites });
    }
}
