<bs-modal #dmModalWrapper
       [cssClass]="customData?.cssClass"
       [keyboard]="customData?.keyboard || false"
       [backdrop]="customData?.backdrop || 'static'"
       (onDismiss)="onCancel.emit()">
  <bs-modal-header>
    <b>{{title}}</b>
    <div class="pull-right">
      <dm-toolbar [buttons]="headerButtons"></dm-toolbar>
    </div>
  </bs-modal-header>
  <bs-modal-body>
      <ng-content>

      </ng-content>
  </bs-modal-body>
  <bs-modal-footer *ngIf="!!footerButtons.length">
    <dm-toolbar [buttons]="footerButtons"></dm-toolbar>
  </bs-modal-footer>
</bs-modal>
