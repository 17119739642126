import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription, of, merge, combineLatest } from 'rxjs';
import { startWith, map, distinctUntilChanged, filter, pluck, mapTo } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { BsModalComponent } from 'ng2-bs3-modal';
import { NgRedux, select } from '@angular-redux/store';
import { IToolbarButtonDefinition } from '../../shared/types';
import { UiActions } from '../../store/actions';
import { createFormDefinitionsFromSchema, createFormGroupFromSchema } from '../form/services';
import { ValidationService, IErrorDefinition } from '../../components/form/services/validation/validation.service';
import { get, partition } from 'lodash';

const POSITIVE_MODAL_RESULTS = ['ok', 'submit'];
const HEADER_BUTTON_VALUES = ['ok', 'cancel', 'done', 'save', 'yes', 'no'];

@Component({
    selector: 'dm-generic-modal',
    templateUrl: 'generic-modal.component.html',
    styleUrls: ['generic-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DMGenericModalComponent implements OnInit, OnDestroy {
    @select(['ui', 'genericModalVisible']) modalStoreStateObs: Observable<boolean>;
    public errorObs: Observable<IErrorDefinition | string>;
    public visibleSubscription: Subscription;
    public optionSubscription: Subscription;
    public buttonSubscription: Subscription;
    public form: FormGroup;
    public groups;
    public headerButtons: IToolbarButtonDefinition[] = [];
    public footerButtons: IToolbarButtonDefinition[] = [];

    public value: string;
    public options: any;
    @ViewChild('modalWrapper', { static: true }) private modalWindow: BsModalComponent;

    constructor(public ngRedux: NgRedux<any>, public uiActions: UiActions) {}

    ngOnInit() {
        this.optionSubscription = this.ngRedux.select(['ui', 'genericModalOptions']).subscribe((options: any = {}) => {
            this.options = options;
            if (options.schema) {
                this.form = createFormGroupFromSchema(options.schema, options.formRules);

                if (!options.validation) {
                    // TODO: turn off validation for deeply nested controls
                    Object.values(this.form.controls).forEach(control => control.clearValidators());
                } else {
                    this.errorObs = this.form.statusChanges.pipe(
                        map(() =>
                            this.form.valid
                                ? ''
                                : ValidationService.findFormError({ root: this.form }, options.formRules)
                        )
                    );
                    this.buttonSubscription = this.form.statusChanges
                        .pipe(
                            startWith(null),
                            map(() => this.form.invalid || this.form.pending),
                            distinctUntilChanged()
                        )
                        .subscribe(invalid => {
                            this.options.buttons = this.options.buttons.map(button =>
                                Object.assign({}, button, {
                                    disabled: invalid && POSITIVE_MODAL_RESULTS.includes(button.value)
                                })
                            );
                            this.initButtons();
                        });
                }
                this.groups = createFormDefinitionsFromSchema(options.schema, options.formRules, this.form);
                this.groups.forEach(group => (group.name = ''));
                if (options.value) {
                    this.form.patchValue(options.value);
                }
                if (typeof options.onFormReady === 'function') {
                    setTimeout(() => options.onFormReady(this.form), 0);
                }
            } else {
                this.form = null;
                this.errorObs = of('');
            }

            this.initButtons();
        });

        const modalRealStateObs = merge(
            this.modalWindow.onClose.asObservable().pipe(mapTo(false)),
            this.modalWindow.onDismiss.asObservable().pipe(mapTo(false)),
            this.modalWindow.onOpen.asObservable().pipe(mapTo(false))
        ).pipe(startWith(false));

        this.visibleSubscription = combineLatest(this.modalStoreStateObs, modalRealStateObs)
            .pipe(
                filter(([expected, actual]) => Boolean(expected) !== actual),
                pluck('0')
            )
            .subscribe(expected => (expected ? this.modalWindow.open() : this.modalWindow.close()));
    }

    public handleButtonClick = button => {
        // positive modal result requires valid form value
        const confirmed = POSITIVE_MODAL_RESULTS.includes(button);
        if (confirmed && this.options.validation && this.form && !this.form.valid) {
            return;
        }
        this.uiActions.closeGenericModal({
            confirmed,
            modalResult: button,
            value: this.form && this.form.value
        });
    }

    ngOnDestroy() {
        this.optionSubscription.unsubscribe();
        this.visibleSubscription.unsubscribe();
        if (this.buttonSubscription) {
            this.buttonSubscription.unsubscribe();
        }
    }

    public initButtons() {
        [this.headerButtons, this.footerButtons] = partition(get(this.options, 'buttons', []), button =>
            HEADER_BUTTON_VALUES.includes(button.value)
        );
    }
}
