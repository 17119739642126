/**
 * SidebarService
 * @module client/src/app/shared/services
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { NgRedux } from '@angular-redux/store';
import { get, isEmpty } from 'lodash';
import { IAppState } from '../../../store/reducers';
import { IMenuItem } from '../../../shared/types';
export const MENU_ITEMS = [];

/**
 * # SidebarService
 * Defines the service for sidebar data providing
 */
@Injectable()
export class SidebarService {
    private _items: Observable<IMenuItem[]> = this.ngRedux.select(['ui', 'menuItems']);
    private _selectedItem: Observable<IMenuItem> = this.ngRedux.select(['ui', 'selectedMenuItem']);

    /**
     * markItemAsForbidden
     * helper function that returns modified sidebar item with forbidden attributes
     */
    public static markItemAsForbidden(item: IMenuItem, requiredPermissions: string[]): IMenuItem {
        // TODO: this message is temporary in the nearest
        // future we will be hide all menu items which has no all permissions
        // so there is no need to move this message into the global constants
        const hintMessage: string = isEmpty(requiredPermissions)
            ? `You haven't one or more of the required permissions.`
            : `You haven't one or more of the following permissions: `;
        return Object.assign({}, item, {
            hintMessage: hintMessage.concat(requiredPermissions.join(', ')),
            hidden: true,
            icon: 'glyphicon-lock'
        });
    }

    /**
     * SetVisibility for menu item
     * helper function that returns modified sidebar item with hidden attributes
     */
    public static setItemsVisibility(menuItem: IMenuItem, visibility: boolean): IMenuItem {
        return Object.assign({}, menuItem, {
            hidden: !visibility
        });
    }

    /**
     * Initialize the service
     * Services need initialization in the constructor.
     */
    constructor(private ngRedux: NgRedux<IAppState>) {}

    /**
     * This getter returns the sidebar items list
     */
    public get items(): Observable<IMenuItem[]> {
        return this._items;
    }

    /**
     * This setter is needed for setting _items property
     */
    public set items(items) {
        this._items = items;
    }

    /**
     * This getter returns the sidebar selected item
     */
    public get selectedItem(): Observable<IMenuItem> {
        return this._selectedItem;
    }

    /**
     * This function finds menu item with needed route
     * @param {IMenuItem[]} items - item list where function should search
     * @param {string} route - current route by which function should search
     * @returns {IMenuItem}
     */
    public findItemByRoute(menuItems: IMenuItem[] = [], currentRoute: string = ''): IMenuItem {
        const routes = menuItems.map(element => ({ element, path: [element] }));

        for (let i = 0; i < routes.length; i += 1) {
            const currentNode = routes[i];
            const sub = get(currentNode, 'element.sub', []);

            if (currentNode.element.routing === `/${currentRoute}`) {
                currentNode.path.forEach(item => (item.isOpen = true));
                return currentNode.element;
            }
            routes.push(
                ...sub.map(element => ({
                    element,
                    path: currentNode.path.concat(element)
                }))
            );
        }
        return null;
    }

    /**
     * This function returns Promise with found menu item by route
     * @param {string} route - current route by which function should search
     * @returns {Promise<IMenuItem>}
     */
    public getItemByRoute(route: string): Promise<IMenuItem> {
        return this.items
            .pipe(
                map((items: IMenuItem[]) => this.findItemByRoute(items, route)),
                first()
            )
            .toPromise();
    }
}
