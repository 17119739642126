import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ReportsService } from '@shared/services';
import { NgRedux } from '@angular-redux/store';
import { ToasterService } from 'angular2-toaster';
import { ISiteState } from '@shared/types/site';
import { SELECT_ALL } from '../../../../../config';
import { isEmpty } from 'lodash';
import { catchError, distinctUntilChanged, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TruncateEmployeePipe } from '@components/report-filters/filters/employees-filter/pipes/truncate-employee.pipe';
import { IFilterState } from '@shared/types/filters';
import { iif, of, Subject } from 'rxjs';
import { FiltersActions } from '@store/actions';
import { prepareSitesForRequest } from '@components/report-filters/utils/filter.utils';

interface IEmployee {
    itemName: string;
    id: string;
    code: string;
}

interface IDropdownSettings {
    text: string;
    selectAllText: string;
    unSelectAllText: string;
    singleSelection?: boolean;
    enableSearchFilter: boolean;
    badgeShowLimit: number;
    lazyLoading?: boolean;
    maxHeight: number;
    classes: string;
    searchBy: string[];
}

@Component({
    selector: 'employees-filter',
    templateUrl: 'employees-filter.html',
    styleUrls: [ 'employees-filter.scss' ],
    providers: [ TruncateEmployeePipe ]
})
export class EmployeesFilterComponent implements OnInit, OnDestroy {
    @Input() filter: any;
    @Input() type: string = '';
    @Output() handleChanges = new EventEmitter();

    @ViewChild('employeesSelector', {static: true})
    employeesSelector: ElementRef;

    public label: string = '';
    public errors: any[] = [];
    public dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        text: 'Select Employees',
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        enableSearchFilter: true,
        searchBy: [ 'itemName', 'code' ],
        badgeShowLimit: 2,
        // lazyLoading: true,
        maxHeight: 200,
        classes: 'employees-filters custom-class'
    };
    public inputEmployeesValue: IEmployee[] | IEmployee = [];

    private attributes: string[] = [];
    private name: string = '';
    private currentCompanyId$: Observable<any>;
    private currentCompanyId: string;
    public employees: IEmployee[] = [];
    public isLoading = true;

    private filtersState: Observable<IFilterState>;
    private isSiteChanged = false;
    private unsubscribe$: Subject<void> = new Subject();
    private siteState$: Observable<ISiteState>;
    private multipleSites = false;

    constructor(
        private reportService: ReportsService,
        private store: NgRedux<ISiteState>,
        public toasterService: ToasterService,
        private filtersActions: FiltersActions
    ) {
        this.siteState$ = this.store.select('sites');
        this.currentCompanyId$ = this.store.select([ 'userSession', 'currentCompanyId' ]);
        this.filtersState = store.select('filters');
    }

    ngOnInit() {
        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];
        this.label = this.filter['label'];
        this.inputEmployeesValue = this.filter['default_value'];
        this.multipleSites = this.filter['multipleSites'];

        if (!this.filter['multiple']) {
            this.dropdownSettings.singleSelection = true;
        }

        this.siteState$.pipe(
            takeUntil(this.unsubscribe$),
            distinctUntilChanged((prev, next) => {
                if (this.multipleSites) {
                    return JSON.stringify(prev.selectedSites) === JSON.stringify(next.selectedSites);
                } else {
                    return JSON.stringify(prev.selectedSite) === JSON.stringify(next.selectedSite);
                }
            }),
            tap(siteState => {
                if (this.multipleSites) {
                    this.isSiteChanged = JSON.stringify(siteState.selectedSites) !== JSON.stringify(siteState.prevSelectedSites);
                } else {
                    this.isSiteChanged = JSON.stringify(siteState.selectedSite) !== JSON.stringify(siteState.prevSelectedSite);
                }
            }),
            switchMap((siteState) => this.currentCompanyId$.pipe(
                tap(companyId => this.currentCompanyId = companyId),
                switchMap(() => this.filtersState.pipe(
                    take(1),
                    switchMap(({employees}) => iif(
                        () => !!employees && !this.isSiteChanged,
                        of(employees),
                        this.getEmployees(
                            this.multipleSites ? prepareSitesForRequest(siteState.selectedSites) : siteState.selectedSite.id
                        )
                    ))
                ))
            )),
        ).subscribe(data => {
            this.employees = data;
            if (this.employees.length && this.filter['default_value'] === SELECT_ALL && this.filter['multiple']) {
                this.inputEmployeesValue = [ ...this.employees ];
            } else if (this.employees.length && !this.filter['multiple']) {
                this.inputEmployeesValue = this.filter['default_value'] === '' ? [] : [ this.employees[0] ];
            }
            this.handleEmployeesChange();
            this.isLoading = false;
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public getEmployees(site: string | Array<string>): Observable<any> {
        return this.reportService.fetchEmployeeBySiteIds(this.currentCompanyId, site).pipe(
            catchError(error => {
                this.isLoading = false;
                this.employees = [];
                this.toasterService.pop('error', error.statusText || 'Some problems with getting employees');
                return [];
            })
        );
    }

    public isSelectedAllEmployees() {
        if (this.employees.length === 0 || !this.filter['multiple']) {
            return false;
        }

        return (this.inputEmployeesValue as IEmployee[]).length === this.employees.length;
    }

    public handleEmployeesChange(changedSiteState = false) {
        const value = {};
        const errors = [];

        if (this.employees.length === (this.inputEmployeesValue as IEmployee[]).length && this.filter['multiple']) {
            value[this.attributes[0]] = SELECT_ALL;
        } else {
            value[this.attributes[0]] = this.inputEmployeesValue;
        }

        if (isEmpty(this.inputEmployeesValue)) {
            errors.push('Choose Employee please');
        }

        if (!changedSiteState) {
            this.errors = errors;
        }
        const res = {value, errors, name: this.name, type: this.filter['type']};
        this.filtersActions.setEmployees(this.employees);
        this.handleChanges.emit(res);
    }

    public closeEmployeesSelect() {
        this.handleEmployeesChange();

        if (!(this.inputEmployeesValue as IEmployee[]).length) {
            this.inputEmployeesValue = [];
        }

        if (
            (this.inputEmployeesValue as IEmployee[]).length &&
            (this.inputEmployeesValue as IEmployee[]).length === this.employees.length
        ) {
            this.inputEmployeesValue = [];
            this.inputEmployeesValue = [ ...this.employees ];
        }
    }
}
