<div class="employee-filter" [ngClass]="{'selected-all-employees': isSelectedAllEmployees(), 'is-loading' : isLoading}">

    <div *ngIf="!!employees.length">
        <span class="employee-filter__label">{{ label }}</span>

        <angular2-multiselect [data]="employees" [(ngModel)]="inputEmployeesValue"
                              [settings]="dropdownSettings"
                              (onSelect)="handleEmployeesChange()"
                              (onDeSelect)="handleEmployeesChange()"
                              (onSelectAll)="handleEmployeesChange()"
                              (onDeSelectAll)="handleEmployeesChange()"
                              (onClose)="closeEmployeesSelect()" #employeesSelector>
            <c-badge>
                <ng-template let-item="item">
                    <span *ngIf="!isSelectedAllEmployees()">{{item.itemName}}</span>
                    <span *ngIf="isSelectedAllEmployees()">All employees</span>
                </ng-template>
            </c-badge>
            <c-item>
                <ng-template let-item="item">
                    <label [innerHTML]="item.itemName | truncateEmployee: item.code: 40: 6"></label>
                </ng-template>
            </c-item>
        </angular2-multiselect>
        <span class="employee-filter__count">{{ (inputEmployeesValue | getArray).length }}</span>
        <svg class="employee-filter__loader" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
            <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="1s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"/>
            </path>
        </svg>
    </div>
    <div class="errors-block">
        <div class="error" *ngFor="let error of errors">{{error}}</div>
    </div>
</div>
