import { Injectable } from '@angular/core';

Injectable();
@Injectable()
export class ErrorHandlerService {
    private static prepareErrorMessage(error) {
        switch (error.status) {
            case 400:
                error.statusText = 'The server could not understand the request due to invalid syntax.';
                break;
            case 401:
                error.statusText = 'The client should retry the request with a suitable Authorization header.';
                break;
            case 403:
                error.statusText = 'The client does not have access rights to the content.';
                break;
            case 404:
                error.statusText = 'The server can not find requested resource.';
                break;
            case 408:
                error.statusText = 'Don\'t have enough time to send the request.';
                break;
            case 504:
                error.statusText = 'The server cannot get a response in time.';
                break;
            case 500:
                error.statusText =
                    'The server encountered an unexpected condition which prevented it from fulfilling the request.';
                break;
            case 502:
                error.statusText =
                    'The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.';
                break;
            default:
                error.statusText = 'The server has encountered a situation it does not know how to handle';
        }
        return error;
    }
    public getErrorMessage(error) {
        return ErrorHandlerService.prepareErrorMessage(error);
    }
}
