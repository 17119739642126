<div class="row">
    <div *ngFor="let error of errors">
        <div class="error">{{ error.error }}</div>
    </div>
    <div class="col-lg-12" *ngIf="selectedReport">
        <div>
            <div>
                <etl-statistics [report]="selectedReport"></etl-statistics>
            </div>
            <div class="ui segment">
                <div class="ui segment" *ngIf="noDataForReport()">
                    <span>There is no selected report. Select report, please.</span>
                </div>
                <opa-iframe-report *ngIf="showSSRS()" [uri]="reportUrl">
                </opa-iframe-report>
                <opa-custom-report *ngIf="showCustom()" [data]="selectedReport.meta.content">
                </opa-custom-report>
                <sales-and-labor *ngIf="showSalesAndLaborReport()" [data]="reportUrl">
                </sales-and-labor>
                <speed-of-service *ngIf="showSpeedOfServiceReport()" [data]="reportUrl">
                </speed-of-service>
                <sales-analysis *ngIf="showSalesAnalysisReport()" [data]="data">
                </sales-analysis>
                <order-explorer *ngIf="showAgGridReport('order_explorer')" [currentCompanyId]="currentCompanyId"
                                [data]="reportUrl">
                </order-explorer>
                <enterprise-sales-summary *ngIf="showAgGridReport('enterprise_sales_summary')" [currentCompanyId]="currentCompanyId"
                                          [data]="reportUrl">
                </enterprise-sales-summary>
                <employee-discount *ngIf="showAgGridReport('employee_discount_report')" [currentCompanyId]="currentCompanyId"
                                   [data]="reportUrl">
                </employee-discount>
                <order-payments *ngIf="showAgGridReport('order_payments')" [currentCompanyId]="currentCompanyId"
                                   [data]="reportUrl">
                </order-payments>
            </div>
        </div>
    </div>
</div>
