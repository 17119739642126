import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { select } from '@angular-redux/store';
import { ISessionState } from '../../types/session';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    @select(['userSession']) session: Observable<ISessionState>;

    constructor(private router: Router) {}

    canActivate() {
        return this.session.pipe(
            map(session => {
                if (session && session.isAuthenticated) {
                    return true;
                } else {
                    this.router.navigate(['/login']);
                    return false;
                }
            })
        );
    }
}
