import { get } from 'lodash';
import { Reducer } from 'redux';
import { IAction } from '../../shared/types';
export interface IBaseState {
  currentDocument: any;
  entityId: string;
  items: any[];
  errors: any;
  showSitesModal: boolean;
  total: number;
  filter?: string;
  orderby?: string;
  mappedDocuments: any[];
  schema: any;
  isSchemaRequested?: boolean;
  operationResult: any;
  current_page?: number;
  page_count?: number;
  page_size?: number;
}
export const INITIAL_STATE: IBaseState = {
  currentDocument: null,
  entityId: null,
  items: [],
  errors: null,
  showSitesModal: false,
  total: null,
  filter: null,
  orderby: null,
  mappedDocuments: [],
  schema: null,
  operationResult: null,
  current_page: 1,
  page_count: 1,
  page_size: 0,
};

export const applySubReducer =
  (state, propertyName: string, reducer: Reducer<any>, action: IAction) => {
    const subState = get(state, propertyName);
    const newSubstate = reducer(subState, action);
    const hasChanges = subState !== newSubstate;
    return hasChanges ? Object.assign({}, state, { [propertyName]: newSubstate }) : state;
  };
