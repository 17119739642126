import { NgRedux } from '@angular-redux/store';
import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CustomReportsService } from '../../../shared/services';
import { VegaConfigsService } from '../../../shared/services/reports';
import { PreferencesService } from '../../../shared/services/preferences/preferences.service';
import { UiActions } from '../../../store/actions';
import { ToasterService } from 'angular2-toaster';
import { takeWhile } from 'rxjs/operators';
import { isBoolean } from 'util';
import { HttpCancelService } from '../../../shared/services/http-client';

@Component({
    selector: 'speed-of-service',
    templateUrl: 'speed-of-service.component.html',
    styleUrls: ['speed-of-service.component.css']
})
export class SpeedOfServiceComponent implements OnInit, OnDestroy {
    @Input()
    public set data(data) {
        if (this.isNewRequestData(data)) {
            this.activeOrders.length = 0;
            this.dataScreen.length = 0;
            this.averageByProductTime.length = 0;
            this.averageByScreen.length = 0;

            if (data.custom_title) {
                this.customTitle = data.custom_title;
            }

            this.uiActions.enableContentLoader();
            this.fetchSpeedOfServiceData(data);
        }
    }

    public sitesState: Observable<any>;
    protected chartAverageOrderPreparation: any;
    protected chartAverageOrderFulfillment: any;
    protected chartProdactionScreens: any;
    protected chartProducts: any;
    protected headerChart: any;
    protected isActive = true;
    public csvData: any[] = [];
    public dateFrom: string = moment().format('YYYY-MM-DD');
    public dateTo: string = moment().format('YYYY-MM-DD');
    public businessDate = '';
    public customTitle: string = '';
    protected siteIds: string[] | string = 'All';
    protected activeFilters: any;

    protected allData: any[] = [];
    protected activeOrders: any[] = [];
    public activeOrdersTop: any[] = [];
    protected dataScreen: any[] = [];
    public leadFulfilmentProductTime: any[] = [];
    public highestFulfilmentProductTime: any[] = [];
    protected averageByProductTime: any[] = [];
    protected averageByScreen: any[] = [];
    public loader$: Observable<any>;
    private isLoading = false;

    constructor(
        private uiActions: UiActions,
        private reportService: CustomReportsService,
        private vegaConfigService: VegaConfigsService,
        private preferencesService: PreferencesService,
        private store: NgRedux<any>,
        private toasterService: ToasterService,
        private httpCancelService: HttpCancelService,
        private cdRef: ChangeDetectorRef
    ) {
        this.loader$ = this.store.select(['ui', 'contentLoader']);
        this.loader$.pipe(takeWhile(() => this.isActive)).subscribe(value => {
            if (isBoolean(value)) {
                if (this.isLoading === true && value === false) {
                    this.httpCancelService.cancelPendingRequests();
                }
                this.isLoading = value;
            }
        });
    }

    public ngOnInit() {}

    public ngOnDestroy() {
        this.isActive = false;
        this.uiActions.disableContentLoader();
        this.isNewRequestData({});
    }

    public convertSecondsToText(totalSeconds: number = 0): string {
        return this.preferencesService.secondFormatter(totalSeconds);
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event) {
        if (this.chartAverageOrderPreparation) {
            this.chartAverageOrderPreparation
                .signal('width', this.vegaConfigService.getChartWidth('chart_average_order_preparation'))
                .run('enter');
        }
        if (this.chartAverageOrderFulfillment) {
            this.chartAverageOrderFulfillment
                .signal('width', this.vegaConfigService.getChartWidth('chart_average_order_fulfillment'))
                .run('enter');
        }
        if (this.chartProdactionScreens) {
            this.chartProdactionScreens
                .signal('width', this.vegaConfigService.getChartWidth('chart_prodaction_screens'))
                .run('enter');
        }
    }

    public getCorrectLabelForMeasure() {
        return this.preferencesService.getCorrectLabelForTimeMeasure();
    }

    private isNewRequestData(data) {
        return this.reportService.isNewRequestData(data);
    }

    private fetchSpeedOfServiceData(data) {
        this.activeFilters = data;
        this.siteIds = data.Site_Id || 'All';
        this.dateFrom = data.Start_Date;
        this.dateTo = data.End_Date;

        if (data.Calendar_Period === 'true' && data.Start_Date !== data.End_Date) {
            this.dateTo = moment(data.End_Date, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD');
        }
        this.reportService.fetchSpeedOfServiceReport(this.siteIds, this.dateFrom, this.dateTo).subscribe(
            (tempData: any) => {
                this.uiActions.disableContentLoader();
                this.activeOrders.length = 0;
                this.dataScreen.length = 0;
                this.averageByProductTime.length = 0;
                this.averageByScreen.length = 0;

                this.allData = [...tempData];

                this.initChart(tempData);
            },
            error => {
                this.uiActions.disableContentLoader();
                this.toasterService.pop('error', error.statusText);
            }
        );
    }

    private initChart(data) {
        let dataForCSV = {};
        const flags = [],
            l = this.allData.length;
        for (let i = 0; i < l; i++) {
            if (flags[this.allData[i].order_number]) {
                continue;
            }
            flags[this.allData[i].order_number] = true;
            this.activeOrders.push(this.allData[i]);
        }

        this.activeOrdersTop = this.activeOrders
            .sort((a, b) => new Date(b.last_prep_time_order).getTime() - new Date(a.last_prep_time_order).getTime())
            .slice(0, 4);

        this.allData.forEach(item => {
            let itemFind: any = {};
            itemFind = this.averageByProductTime.find(el => el.items__entity_id === item.items__entity_id);
            if (itemFind) {
                itemFind.item_prep_sec += +item.item_prep_sec;
                itemFind.quantity += 1;
            } else {
                this.averageByProductTime.push({
                    items__entity_id: item.items__entity_id,
                    items__name: item.items__name,
                    item_prep_sec: +item.item_prep_sec,
                    quantity: 1
                });
            }

            let itemScreenFind: any = {};
            itemScreenFind = this.averageByScreen.find(el => {
                return el.items__kitchen_timing__screen === item.items__kitchen_timing__screen;
            });
            if (itemScreenFind) {
                itemScreenFind.screen_arrival_sec += +item.screen_arrival_sec;
                itemScreenFind.screen_fullfil_sec += +item.screen_fullfil_sec;
                itemScreenFind.order_prep_sec += +item.order_prep_sec;
                itemScreenFind.quantity += 1;
            } else {
                this.averageByScreen.push({
                    items__kitchen_timing__screen: item.items__kitchen_timing__screen,
                    screen_arrival_sec: +item.screen_arrival_sec,
                    screen_fullfil_sec: +item.screen_fullfil_sec,
                    order_prep_sec: +item.order_prep_sec,
                    quantity: 1
                });
            }
        });
        this.averageByScreen.forEach(item => {
            this.dataScreen.push({
                screen: item.items__kitchen_timing__screen,
                duration: Math.floor(item.screen_fullfil_sec / item.quantity),
                type: 'Average Fulfillment',
                order_prep_sec: Math.floor(item.order_prep_sec / item.quantity),
                screen_arrival_sec: Math.floor(item.screen_arrival_sec / item.quantity),
                screen_fullfil_sec: Math.floor(item.screen_fullfil_sec / item.quantity)
            });
            this.dataScreen.push({
                screen: item.items__kitchen_timing__screen,
                duration: Math.floor(item.screen_arrival_sec / item.quantity),
                type: 'Average Screen Arrival',
                order_prep_sec: Math.floor(item.order_prep_sec / item.quantity),
                screen_arrival_sec: Math.floor(item.screen_arrival_sec / item.quantity),
                screen_fullfil_sec: Math.floor(item.screen_fullfil_sec / item.quantity)
            });
        });
        this.leadFulfilmentProductTime = this.averageByProductTime
            .sort((a, b) => a.item_prep_sec / a.quantity - b.item_prep_sec / b.quantity)
            .slice(0, 4);
        this.highestFulfilmentProductTime = this.averageByProductTime
            .sort((a, b) => b.item_prep_sec / b.quantity - a.item_prep_sec / a.quantity)
            .slice(0, 4);

        this.chartAverageOrderPreparation = this.vegaConfigService.vegaInit(
            'chart_average_order_preparation',
            this.vegaConfigService.getChartBarByWeekDay(
                `Average Order Preparation Time: ${this.getCorrectLabelForMeasure()}`
            ),
            this.activeOrders,
            this.vegaConfigService.getChartWidth('chart_average_order_preparation'),
            this.vegaConfigService.getChartHeight('chart_average_order_preparation')
        );

        this.chartAverageOrderFulfillment = this.vegaConfigService.vegaInit(
            'chart_average_order_fulfillment',
            this.vegaConfigService.getChartBarByHours(
                `Average Order Fulfillment by Hour: ${this.getCorrectLabelForMeasure()}`
            ),
            [...this.activeOrders, ...this.vegaConfigService.getDefaultDataByDay()],
            this.vegaConfigService.getChartWidth('chart_average_order_fulfillment'),
            this.vegaConfigService.getChartHeight('chart_average_order_fulfillment')
        );

        dataForCSV = this.vegaConfigService.prepareCsvForBarChart(data, 'All Data');
        this.csvData = [dataForCSV];

        this.chartProducts = this.vegaConfigService.vegaInit(
            'chart_products',
            this.vegaConfigService.getChartPie('Products'),
            this.allData,
            200,
            this.vegaConfigService.getChartHeight('chart_products')
        );

        this.chartProdactionScreens = this.vegaConfigService.vegaInit(
            'chart_prodaction_screens',
            this.vegaConfigService.getChartStackBarByScreen(`Total Average Fulfillment Time per Screen:
             ${this.getCorrectLabelForMeasure()}`),
            this.dataScreen,
            this.vegaConfigService.getChartWidth('chart_prodaction_screens'),
            300
        );
        this.cdRef.detectChanges();
    }
}
