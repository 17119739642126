import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IMenuItem } from '../interfaces/IMenuItem.type';
import { REPORTS_ROOT_ROUTE } from '../../routes';
import { XUXDrawerSidebarService } from '../services/xux-drawer-sidebar.service';
import { ReportsService } from '../../shared/services/reports';

@Component({
  selector: 'xux-breadcrumb-navbar',
  templateUrl: './xux-breadcrumb-navbar.component.html',
  styleUrls: ['./xux-breadcrumb-navbar.component.scss'],
})
export class XUXBreadcrumbNavbarComponent implements OnInit {

  @Input()
  public isIframeMode$: Observable<any>;

  @Input()
  public selectedMenuItem$: Observable<IMenuItem>;
  @Input()
  public appName: string;
  public breadcrumbs: any[] = [];
  private lastSelectedItem: IMenuItem;

  constructor(
    private drawerSidebarService: XUXDrawerSidebarService,
    private reportService: ReportsService
  ) {
  }

  ngOnInit() {
    this.selectedMenuItem$.subscribe((item) => {
      if (item) {
        this.lastSelectedItem = item;
      } else {
        this.lastSelectedItem = null;
      }
      this.rebuild();
    });
  }

  rebuild() {
    this.breadcrumbs = [];
    this.breadcrumbs.push({
      title: this.appName,
      route: `/${REPORTS_ROOT_ROUTE}`,
      reportLink: false
    });
    if (this.lastSelectedItem) {
      this.breadcrumbs.push({
        title: this.lastSelectedItem.title,
        route: '/' + this.lastSelectedItem.routing,
        reportLink: true
      });
    }
  }

  protected refreshReport() {
    this.reportService.reloadReport();
  }

  protected backToReports() {
    this.reportService.selectReport({});
  }


  public toggleSlide(event: KeyboardEvent) {
    this.drawerSidebarService.toggle();
  }
}
