import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { iif, Observable, of, Subject } from 'rxjs';
import { SELECT_ALL } from '../../../../../config';
import { HelperService, ReportsService } from '../../../../shared/services/';
import { ISiteState } from '@shared/types/site';
import { NgRedux } from '@angular-redux/store';
import { IFilterState } from '@shared/types/filters';
import { take, takeUntil, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { FiltersActions } from '@store/actions';

@Component({
    selector: 'forecast-definition-filter',
    templateUrl: 'forecast-definition-filter.component.html',
    styleUrls: ['style.css']
})
export class ForecastDefinitionFilterComponent implements OnInit, OnDestroy {
    @Input() filter: any;
    @Input() type: string = '';
    @Output() handleChanges = new EventEmitter();
    protected sites: any[] = [];
    public needShow: boolean = true;
    public dropdownSettings = {};
    public items: any[] = [];
    public selectedItems = [];
    public label: string = '';

    private attributes: string[] = [];
    private name: string = '';
    private filtersState: Observable<any>;

    private siteState$: Observable<ISiteState>;
    private isSiteChanged = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(store: NgRedux<IFilterState>,
                private helper: HelperService,
                private reportsService: ReportsService,
                private filtersActions: FiltersActions) {
        this.filtersState = store.select('filters');
        this.siteState$ = store.select('sites');
    }

    ngOnInit() {
        this.selectedItems = [];
        this.dropdownSettings = {
            singleSelection: false,
            text: 'Select Forecast Definition',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true
        };

        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];
        this.label = this.filter['label'];

        this.siteState$.pipe(
            takeUntil(this.unsubscribe$),
            distinctUntilChanged((prev, next) => {
                return JSON.stringify(prev.selectedSite) === JSON.stringify(next.selectedSite);
            }),
            tap(siteState => {
                this.isSiteChanged = JSON.stringify(siteState.selectedSite) !== JSON.stringify(siteState.prevSelectedSite);

            }),
            switchMap((siteState) => this.filtersState.pipe(
                take(1),
                switchMap(({forecastDefinitions}) => iif(
                    () => !!forecastDefinitions && !this.isSiteChanged,
                    of(forecastDefinitions),
                    this.reportsService.fetchForecastDefinitions(siteState.selectedSite.id)
                ))
            ))
        ).subscribe((forecastDefinitions) => {
            this.items = forecastDefinitions;
            this.selectedItems = this.helper.prepareFilterValue(this.items);
            this.onValueChange();
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public onValueChange() {
        const value = {};
        if (this.items.length === this.selectedItems.length) {
            value[this.attributes[0]] = SELECT_ALL;
        } else {
            value[this.attributes[0]] = this.selectedItems;
        }
        const res = {
            value,
            name: this.name,
            type: this.filter['type']
        };
        this.filtersActions.setForecastDefinitions(
            this.selectedItems
        );
        this.handleChanges.emit(res);
    }
}
