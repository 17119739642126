import { DevToolsExtension } from '@angular-redux/store';
import { NgReduxRouter } from '@angular-redux/router';
import { ACTION_PROVIDERS } from './actions';
import { MiddlewaresProvider, MIDDLEWARE_PROVIDERS } from './middleware';
import { EPIC_PROVIDERS } from './epics/index';

export * from './reducers';
export * from './middleware';

export const STORE_PROVIDERS: any[] = [
    { provide: NgReduxRouter, useClass: NgReduxRouter },
    { provide: DevToolsExtension, useClass: DevToolsExtension },
    { provide: MiddlewaresProvider, useClass: MiddlewaresProvider },
  ...ACTION_PROVIDERS,
  ...MIDDLEWARE_PROVIDERS,
  ...EPIC_PROVIDERS,
];
