<div class="report_calendars_filter">
  <div *ngIf="jobs.length">
    <span class="calendar_label">Job Codes</span>

    <angular2-multiselect [data]="jobs" [(ngModel)]="inputJobValue"
                          [settings]="dropdownSettings"
                          (onSelect)="onItemSelect($event)"
                          (onDeSelect)="OnItemDeSelect($event)"
                          (onSelectAll)="onSelectAll($event)"
                          (onDeSelectAll)="onDeSelectAll($event)"></angular2-multiselect>

  </div>
  <div class="errors-block">
    <div  class="error" *ngFor="let error of errors">{{error}}</div>
  </div>
</div>
