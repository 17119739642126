import { Toast } from 'angular2-toaster';
export const TM_MODIFIER_SYSTEM_VARIANT_NOT_REMOVED_WARNING: Toast = {
  type: 'warning',
  body: 'System variants can not unlinked until modifier has other variants',
};

export const TM_MODIFIER_SYSTEM_VARIANT_AUTO_ADD_INFO: Toast = {
  type: 'info',
  body: 'System variants have been added automatically',
};

export const DYNAMIC_MATRIX_ADD_SUCCESS_INFO: Toast = {
  type: 'info',
  body: 'Matrices successfully added',
};

export const DYNAMIC_MATRIX_NOT_ADD_WARNING_INFO: Toast = {
  type: 'info',
  body: 'There are no new unique matrices for adding',
};

export const MAPPING_UPDATE_SUCCESS: Toast = {
  type: 'success',
  body: 'Mappings have been successfuly updated',
  timeout: 1000,
};

export const LIST_VIEW_OPERATION_SUCCESS: Toast = {
  type: 'success',
  body: 'View operation completed successfuly',
  timeout: 2000,
};

export const LIST_VIEW_OPERATION_ERROR: Toast = {
  type: 'warning',
  body: 'View operation failed',
  timeout: 2000,
};
