<bs-modal #modalWrapper [keyboard]="false" [backdrop]="'static'" [cssClass]="options?.modalClass">
  <bs-modal-header>
    <div class="pull-left verticalAlignMiddle">
      <i *ngIf="options?.icon" class="modal-icon glyphicon" [ngClass]="options?.icon"></i>
      <b>{{options?.title}}</b>
    </div>
    <div class="pull-right">
        <dm-toolbar [click]="handleButtonClick" [buttons]="headerButtons"></dm-toolbar>
    </div>
  </bs-modal-header>
  <bs-modal-body>
    <div class="col-sm-12">
      <div *ngIf="errorObs | async as error" class="alert alert-warning alert-dismissable" role="alert">
        <p><strong>{{error.code}}</strong> {{error.message}} </p>
      </div>
    </div>
    {{options?.text}}
    <dm-schema-form *ngIf="!!form" [form]="form" [groups]="groups"></dm-schema-form>
  </bs-modal-body>
  <bs-modal-footer *ngIf="!!footerButtons.length">
    <dm-toolbar [click]="handleButtonClick" [buttons]="footerButtons"></dm-toolbar>
  </bs-modal-footer>
</bs-modal>
