/**
 * Reports actions
 */
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IReportState } from '../../../shared/types/report';
import { ADD_REPORTS, SELECT_REPORT } from '../../reducers/reports/reports.reducer';
import {
    ADD_REPORT_ERROR,
    RESET_REPORT_ERRORS,
    DELETE_REPORT_ERROR,
    SET_REPORT_ETL_STATISTICS
} from '../../reducers/reports';

@Injectable()
export class ReportsActions {
    public static SET_FILTER: string = 'SET_FILTER';

    static addReports(reports) {
        return { type: ADD_REPORTS, payload: reports };
    }

    constructor(private store: NgRedux<IReportState>) {}

    setFilters(filters) {
        this.store.dispatch({ type: ReportsActions.SET_FILTER, payload: filters });
    }

    selectReport(report) {
        this.store.dispatch({ type: SELECT_REPORT, payload: report });
    }

    addError(attr: string, error: string) {
        this.store.dispatch({ type: ADD_REPORT_ERROR, payload: { attr, error } });
    }

    deleteError(attr: string) {
        this.store.dispatch({ type: DELETE_REPORT_ERROR, payload: attr });
    }

    selectReportEtlStatistics(statistics) {
        this.store.dispatch({ type: SET_REPORT_ETL_STATISTICS, payload: statistics });
    }

    resetErrors() {
        this.store.dispatch({ type: RESET_REPORT_ERRORS });
    }
}
