import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpsConverterInterceptor implements HttpInterceptor {
    constructor() { }

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        if (req.url.indexOf('localhost') !== -1) {
            return next.handle(req);
        }
        const httpsReq = req.clone({
            url: req.url.replace('http://', 'https://')
        });

        return next.handle(httpsReq);
    }
}

