/**
 * List of global variables used on our project.
 */

/**
 * Used for server request with specific delay time.
 */
export const SERVER_REQUEST_DELAY_MS = 1000;

/**
 * Used for working with time periods in milliseconds.
 */
export const DAY_DURATION_MS = 86400000;

/**
 * Used for quick-filter component.
 */
export const DEFAULT_FILTER_DEBOUNCE_TIME_MS = 1000;

/**
 * Used for delaying form updating. E.g. We need to wait for user finished make changes in the form.
 */
export const DEFAULT_FORM_UPDATE_DELAY_MS = 500;

/**
 * Used for delaying error validation notifier.
 * E.g. We need to wait for user finished make changes in the form.
 */
export const ERROR_MESSAGE_DEBOUNCE = 1000;

/**
 * The time period after which we remove toaster notification.
 */
export const DEFAULT_TOAST_TIMEOUT = 7000;

/**
 * Used for delaying collapse animation. E.g. We need to wait for until animation is finished.
 */
export const COLLAPSE_ANIMATION_DURATION_MS = 300;

/**
 * Global week days, starts from Sunday
 */
export const WEEK_DAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const WEEK_DAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

/**
 * Global months, starts from January
 */
export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export const TIME_FORMAT = 'HH:mm:ss';

export const DEFAULT_ITEM_BACKGROUND_COLOR = '#eeeeee';
export const DEFAULT_ITEM_TEXT_COLOR = '#000000';

export const APP_CODE = 'XPOS';

export const MAX_IMAGE_DIMENSIONS = 512;
export const MAX_IMAGE_SIZE = 2 * 1024 * 1024;
export const IMAGE_ACCEPT_OPTIONS = 'image/jpeg, image/jpg, image/png, image/gif';

export const IMAGE_ALIGN = ['left', 'center', 'right'];

// default precision for numeric field
export const DEFAULT_NUMBER_PRECISION = 2;

export const TAG_TYPE_IDS = {
    INVENTORY: '58e5478117d69f437348867d',
    PRODUCTS: '59aebf86015ad619587552c9'
};

export const RATE_TYPE_IDS = {
    SHDIF: '58e5472417d69f437348866e',
    CNSDA: '58e546f717d69f4373488667'
};

export const PAY_CODE = {
    0: 'CUSTOM',
    1: 'CASH',
    2: 'CHECK',
    3: 'CREDIT',
    4: 'DEBIT',
    5: 'GIFTCC',
    6: 'GIFT',
    7: 'HOUSEA',
    8: 'SMARTC',
    9: 'EMPLCH',
    10: 'DELCH',
    11: 'FOODST',
    12: 'EBTFST',
    13: 'EBTC'
};

/**
 * List of permissions that are required to work with application
 */
export const APP_LEVEL_PERMISSIONS = ['preferences.View'];

/**
 * Default chunk size for infine scroll widgets
 */
export const DEFAULT_PAGE_SIZE = 50;
/**
 * Page size to be selected from on list container
 */
export const AVAILABLE_PAGE_SIZES: number[] = [10, 50, 100, 200];

/**
 * Availabe permission suffixes
 */
export const PERMISSION_TYPES = {
    Add: 'Add',
    Delete: 'Delete',
    Manage: 'Manage',
    View: 'View'
};

/**
 * Message with missed permissions
 */
export const MISSED_PERMISSIONS_MESSAGE = `You haven't the following permissions: `; // tslint:disable: max-line-length

/**
 * Default recipe measure code.
 */
export const DEFAULT_RECIPE_MESURE_CODE = 'EA';

/**
 * Administrator permission
 */
export const ADMINISTRATOR_PERMISSION = 'Administrator';
export const REPORTING_ADMINISTRATOR_PERMISSION = 'Reporting.Admin';

export const ERRORS: any = {
    INVALID_BUSINESS_DATE: 'No data for current company.',
    NO_CALENDARS: 'There are no calendars for this site. Please choose another site',
    NO_JOB_CODES: 'There are no Job Codes for this site. Please choose another site',
    NO_JOB_CATEGORIES: 'There are no Job Categories for this site. Please choose another site',
    NO_FORECAST_DEFINITIONS: 'There are no Forecast Definitions for this site. Please choose another site',
    NO_TAGS: 'There are no Tags for this site. Please choose another site',
    NO_BUSINESS_RULE_GROUPS: 'There are no business rule groups for. Please choose another site',
    EXPIRED_TOKEN: 'Token is expired'
};

export const SELECT_ALL = 'All';

export const CUSTOM_REPORTS: any = {
    BASE_BAR_CHART: {
        barChartOptions: {
            scaleShowVerticalLines: false,
            responsive: true
        },
        barChartType: 'bar',
        barChartLegend: true,
        barChartLabels: [],
        barChartData: []
    },
    HORIZONTAL_BAR_CHART: {
        barChartOptions: {
            scaleShowVerticalLines: false,
            responsive: true
        },
        barChartType: 'horizontalBar',
        barChartLegend: true,
        barChartLabels: [],
        barChartData: []
    }
};

export const FEATURES = {
    TAX_INCLUSIVE_PRICE: 'TAX_INCLUSIVE_PRICE',
    PORTAL_SITE_SELECTOR: 'PORTAL_SITE_SELECTOR'
};
