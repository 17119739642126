/**
 * Recipe module
 */

import { Routes, RouterModule } from '@angular/router';

// modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// containers

// routes
import { extraRoutes } from './extra.routes';
import { Page404Component } from './page-404/page-404.component';
import { Page500Component } from './page-500/page-500.component';
import { FormsModule } from '@angular/forms';
const routes: Routes = extraRoutes;

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    Page404Component,
    Page500Component,
  ],
  exports: [
    RouterModule,
    FormsModule,
    CommonModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ExtraModule { }
