<div class="report_select_filter" [hidden]="!needShow">
  <div>
    <span>{{label}}</span><br/>
    <angular2-multiselect [data]="items" [(ngModel)]="selectedItems"
                          [settings]="dropdownSettings"
                          (onSelect)="onValueChange()"
                          (onDeSelect)="onValueChange()"
                          (onSelectAll)="onValueChange()"
                          (onDeSelectAll)="onValueChange()"
                          ngDefaultControl></angular2-multiselect>
  </div>
</div>
