import { Component } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'extra-layout',
  templateUrl: 'extra.container.html',
  styleUrls: ['extra.container.scss'],
})
export class ExtraLayoutContainerComponent {
  rtl: boolean;

  constructor( ) {
    this.rtl = false;
  }
}
