/**
 * App Component
 */
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { NgRedux, DevToolsExtension } from '@angular-redux/store';
import { NgReduxRouter } from '@angular-redux/router';

import { IAppState, rootReducer, MiddlewaresProvider } from './store';

import * as _ from 'lodash';
import 'moment/min/locales.min';
import { PostMessageService } from '@services/post-message';
import { HttpCancelService } from '@services/http-client';
import { ReportsService, SessionStorageService } from '@shared/services';
import { PreferencesService } from './shared/services/preferences/preferences.service';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';


/**
 * App Component
 * Top Level Component
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.template.html',
    styleUrls: [ './app.style.scss' ]
})
export class AppComponent implements OnInit, OnDestroy {
    private portalMenuVisibility = false;
    private dispose$ = new Subject();
    private token$: Observable<string>;
    private comapnyId$: Observable<string>;
    private isIframeMode$: Observable<boolean>;
    /**
     * Initialize the component
     * Components need initialization in the constructor.
     */
    constructor(
        private router: Router,
        private ngRedux: NgRedux<IAppState>,
        private ngReduxRouter: NgReduxRouter,
        public devTool: DevToolsExtension,
        public middlewaresProvider: MiddlewaresProvider,
        private postMessageService: PostMessageService,
        private httpCancelService: HttpCancelService,
        private reportsService: ReportsService,
        public preferencesService: PreferencesService,
        private sessionStorageService: SessionStorageService
    ) {
        this.ngRedux.configureStore(rootReducer, this.getInitState(), middlewaresProvider.getMiddlewares(), [
            devTool.isEnabled() ? devTool.enhancer() : f => f
        ]);
    }

    private clearSessionStorage(): void {
        sessionStorage.clear();
    }

    /**
     * This function is called right after the directive's
     * data-bound properties have been checked for the first time,
     * and before any of its children have been checked.
     * It is invoked only once when the directive is instantiated.
     * In this function we listen to route state. And once current url
     * isn't /login then we check if customer is logged in. And if
     * customer isn't logged in then we do navigate to login page.
     * Also in this function we select needed sidebar menu item
     * to update title of the sidebar link
     */

    public ngOnInit() {
        this.ngReduxRouter.initialize();
        this.token$ = this.ngRedux.select([ 'userSession', 'token' ]);
        this.comapnyId$ = this.ngRedux.select([ 'userSession', 'currentCompanyId' ]);
        this.isIframeMode$ = this.ngRedux.select([ 'userSession', 'isIframeMode' ]);
        this.isIframeMode$.pipe(
            switchMap(isIframe => {
                if (isIframe) {
                    return this.token$.pipe(
                        switchMap(() => this.comapnyId$.pipe(
                            distinctUntilChanged((prev, next) => prev !== next),
                            filter((id) => !!id),
                            switchMap((id) => this.reportsService.fetchSites())
                        )),
                    );
                }
                return of(null);
            }
            )).subscribe();

        this.router.events
            .pipe(
                takeUntil(this.dispose$),
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(event => {
                this.httpCancelService.cancelPendingRequests();
            });

        this.router.events
            .pipe(
                takeUntil(this.dispose$),
                filter(event => event instanceof NavigationStart)
            )
            .subscribe(
                (event: NavigationStart) => {
                    if (event.restoredState && event.url === '/custom-reports') {
                        this.reportsService.selectReport({});
                    }
                });
        this.clearSessionStorage();
    }

    /**
     * This function creates initial state
     * @returns {IAppState}
     */
    getInitState(): IAppState {
        return {
            ui: {
                loader: false,
                pendingRequests: 0,
                menuItems: [],
                isShowStoresModal: false,
                selectedMenuItem: null,
                storesModalOptions: {
                    forceSelect: false,
                    masterList: true
                },
                errorPage: {}
            }
        };
    }

    ngOnDestroy(): void {
        this.dispose$.next();
        this.dispose$.complete();
        this.sessionStorageService.removeItem('site_picker_config', 'reporting/');
    }

    @HostListener('document:click', [ '$event.target' ])
    public dispatchClickToParentIframe() {
        if (this.portalMenuVisibility) {
            parent.postMessage('hidePortalSubMenu', '*');
        }
    }

    @HostListener('window:message', [ '$event' ])
    public iframeMessageListener(event: MessageEvent): void {
        if (event && event.data) {
            try {
                const val = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
                this.portalMenuVisibility = _.get(val, 'portalSubMenuVisibility', false);
                this.postMessageService.processPostMessage(val);
            } catch (e) {
                return;
            }
        }
    }
}
