/**
 * file cntains helper functions for schema-form service
 */

import {
    get,
    set,
    has,
    startCase,
    merge,
    kebabCase,
    isObject,
    isArray,
    isBoolean,
    isFunction,
    isEmpty,
    negate
} from 'lodash';
import { FormGroup, Validators, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { defer, Observable, of } from 'rxjs';
import { startWith, debounceTime, map, share } from 'rxjs/operators';
import {
    IFieldPreferences,
    IFormGroupPreferences,
    OptionTextProperty,
    ISchemaNode,
    IFieldList,
    Primitive,
    ICondition,
    IPredicateFn
} from './schema-form-interfaces';
import { ValidationService } from '../validation/validation.service';
import { isObservable } from '../../../../shared/utils';
/* tslint:disable */
/**
 * Return true if field is a reference to another entity
 * @param field
 */
export const isReference = (field: IFieldPreferences) => {
    return get(field, 'objectid_convert', false);
};

/** returns array of angular validators
 * that corresponds the schema node requirements
 * @param {IFieldPreferences} field - combined field preferences with
 * premerged 'required' schema key
 * @returns Function[] - array of validators
 */
export const createSchemaValidators = (field: IFieldPreferences): ValidatorFn[] => {
    const validationService = new ValidationService();
    const validators: ValidatorFn[] = get(field, 'validators', []);
    if (field.required && !validators.includes(Validators.required)) {
        if (field.type === 'string' || field.type === 'array') {
            validators.push(validationService.valueType(field.type));
        } else {
            validators.push(Validators.required);
        }
    }

    if (field.type === 'array') {
        if ('minItems' in field) {
            validators.push(validationService.minArrayLength(field.minItems));
        }
        if ('maxItems' in field) {
            validators.push(validationService.maxArrayLength(field.maxItems));
        }
    }

    if (field.type === 'string') {
        if (field.format) {
            // add here validators for FORMAT property
            if (field.format === 'email') {
                validators.push(validationService.emailValidator);
            }
            // add here validators for FORMAT property
            if (field.format === 'uri' || field.format === 'uri-optional') {
                validators.push(validationService.urlValidator());
            }
            // automatically add minDate and maxDate validators when applicable
            if (field.format === 'date-time') {
                if ('minimum' in field && field.minimum !== false) {
                    validators.push(validationService.minDateValidator(field.minimum));
                }
                if ('maximum' in field && field.maximum !== false) {
                    validators.push(validationService.maxDateValidator(field.maximum));
                }
            }
        }
        if ('pattern' in field) {
            validators.push(Validators.pattern(field.pattern));
        }
        if (field.minLength) {
            // angular fix. minLength does not give any errors for an empty string
            if (!validators.includes(Validators.required)) {
                validators.push(Validators.required);
            }
            validators.push(validationService.minLength(field.minLength));
        }
        if ('maxLength' in field) {
            validators.push(validationService.maxLength(field.maxLength));
        }
    }

    if (field.type === 'number' || field.type === 'integer') {
        if ('minimum' in field && field.minimum !== false) {
            validators.push(validationService.minValidator(field.minimum));
        }
        if ('maximum' in field && field.maximum !== false) {
            validators.push(validationService.maxValidator(field.maximum));
        }
        if ('multipleOf' in field) {
            validators.push(validationService.numberMultipleOfValidator(field.multipleOf));
        }

        if (field.type === 'number') {
            validators.push(validationService.numberValidator());
        }

        if (field.type === 'integer' && !('multipleOf' in field)) {
            validators.push(validationService.numberMultipleOfValidator(1));
        }
    }

    if (field.widget === 'image-url-input') {
        validators.push(validationService.imageURLValidator());
    }

    if (field.widget === 'image-file-input') {
        validators.push(validationService.checkImageFileValidator());
    }

    return validators;
};

export const createSchemaAsyncValidators = (field: IFieldPreferences): AsyncValidatorFn[] => {
    const asyncValidators = [];
    const validationService = new ValidationService();
    if (field.asyncValidators) {
        asyncValidators.push(...field.asyncValidators);
    }

    if (field.widget === 'image-url-input') {
        asyncValidators.push(validationService.checkImageSourceValidator);
    }

    return asyncValidators;
};

/**
 * Returns default value for widget based on schema value type
 * @param {string} field - schema field
 * @returns {any} default value for widget
 *
 */
export const getDefaultValueForField = (field: any): any => {
    if (field.oneOf || field.enum || isReference(field)) {
        return null;
    }
    switch (field.type) {
        case 'array':
            return [];
        case 'number':
        case 'integer':
            return null;
        case 'boolean':
            return false;
        default:
            return '';
    }
};

/**
 * extracting paths for every node in schema using flat recursion
 * @param {object} schema - JSON schema
 * @returns {string[]}
 */
export const extractSchemaFields = (schema: ISchemaNode): IFieldList => {
    const fieldList = {};
    if (!!get(schema, 'properties')) {
        Object.keys(schema.properties).forEach(key => {
            fieldList[key] = schema.properties[key];
            const childList = {};

            if (schema.properties[key].type === 'object') {
                Object.entries(extractSchemaFields(schema.properties[key])).forEach(
                    ([prop, def]) => (childList[`${key}.${prop}`] = def)
                );
            }
            if (schema.properties[key].type === 'array' && get(schema, ['properties', key, 'items', 'properties'])) {
                Object.entries(extractSchemaFields(schema.properties[key].items)).forEach(
                    ([prop, def]) => (childList[`${key}.0.${prop}`] = def)
                );
            }

            Object.assign(fieldList, childList);
        });
    }
    return fieldList;
};

/**
 * return widget type based on field object
 */
export const preferredFieldWidgetType = (field: IFieldPreferences): string => {
    if (field.type === 'object') {
        return null;
    }
    if (field.enum || field.selectOptions || (field.oneOf && Array.isArray(field.oneOf))) {
        return 'select';
    }
    if (field.type === 'boolean') {
        return 'checkbox';
    }
    if (field.format === 'currency') {
        return 'input-currency';
    }
    if (field.type === 'number' || field.type === 'integer') {
        return 'input-number';
    }
    if (isReference(field)) {
        return 'entity-select';
    }
    // TODO: Turn it on once we have entity list fetched from API
    // if (name.endsWith('_entity_id') ||
    //   name.endsWith('_entity_ids')) return 'entity-select';
    return 'input';
};

/**
 * returns schema property by its compact path (with no 'properties')
 */
export const getSchemaProperty = (schema: ISchemaNode, fieldPath: string) =>
    get(schema.properties, fieldPath.split('.').join('.properties.'));

/**
 * updates field preference with widget specific data,
 * @param {IFieldPreferences} field
 * @returns {IFieldPreferences}
 */
export const addWidgetSpecificData = (field: IFieldPreferences): IFieldPreferences => {
    let defaultValues = {};
    const overrides = {};
    switch (field.widget) {
        case 'panel-group':
            defaultValues = {
                show: true,
                customData: { groupId: field.name }
            };
            break;
        case 'toolbar':
        case 'panel':
            defaultValues = { show: true };
            break;
        case 'entity-select':
            const fieldNameBlocks = get(field, 'name', '').split('_entity_id');
            defaultValues = {
                multiselect: fieldNameBlocks.pop() === 's',
                customData: {
                    entity: kebabCase(fieldNameBlocks.shift())
                }
            };
            break;
        default:
    }
    return merge(defaultValues, field, overrides);
};

export const getOptionText = (optionText: OptionTextProperty, value: any, defaultValue: any) =>
    isFunction(optionText) ? optionText(value) : get(optionText, value, defaultValue);

export const composeSelectOptions = (field: IFieldPreferences = {}): IFieldPreferences => {
    // do not change anything in case when field already has selectOptions property
    if (has(field, 'selectOptions')) {
        return field;
    }

    // there are couple of ways to provide the enum options with a schema
    const schemaEnum = get(field, 'enum', get(field, 'items.enum'));
    if (Array.isArray(schemaEnum)) {
        const selectOptions = schemaEnum.map(value => ({
            value,
            text: getOptionText(field.optionText, value, startCase(value))
        }));
        return Object.assign({ selectOptions }, field);
    }

    // enum provided as oneOf field with descriptions
    // optionText still can be provided by form rules and
    // will take precedence over server side description.
    // Uses startCased value as the default when neither
    // description nor optionText is available
    if (Array.isArray(field.oneOf)) {
        const selectOptions = field.oneOf.map(item => {
            const value = get(item, 'enum.0', '');
            return {
                value,
                text: getOptionText(field.optionText, value, get(item, 'description', startCase(value)))
            };
        });
        return Object.assign({ selectOptions }, field);
    }
    return field;
};

export const resolveGroupLinks = (group: IFormGroupPreferences, index: number, allGroups: IFormGroupPreferences[]) => {
    group.fields.forEach(fieldProps => {
        const groupId = get(fieldProps, 'customData.groupId');
        if (groupId) {
            set(fieldProps, 'customData.group', allGroups.find(({ id }) => id === groupId));
            set(fieldProps, 'customData.group.hidden', true);
        }
    });
};

export const getFormControl = (form: FormGroup, fieldPath: string) => {
    if (!form) {
        return null;
    }
    return form.get(fieldPath);
};

/**
 * returns true if field at provided short path is required in JSON scheme
 */
export const isRequired = (schema, fieldPath: string) => {
    const [propertyName, ...parentPathParts] = fieldPath.split('.').reverse();
    const requiredPath = parentPathParts
        .reverse()
        .map(item => `properties.${item}.`)
        .join('')
        .concat('required');
    return get(schema, requiredPath, []).includes(propertyName);
};

/**
 * Creates defered observable starting with the form.value on the moment of subscription
 */
export const createDeferedValueObservable = (form: FormGroup) =>
    defer(() =>
        form.valueChanges.pipe(
            startWith(form.value),
            debounceTime(0)
        )
    );

/** returns an observable for dynamic value based on provided parameter
 * When observable is passed - returns it as is
 * Returns mapped formValue observable when passed argument is a function.
 * Returns; an Observable.of provided value in any other case.
 */
export const dynamicPropertyToObservable = (
    formValueObs: Observable<any>,
    dynamicProperty: Observable<any> | Function | any
): Observable<any> => {
    if (isObservable(dynamicProperty)) {
        return dynamicProperty;
    }
    const normalized = normalizeDynamicProperty(dynamicProperty);
    return isFunction(normalized)
        ? formValueObs.pipe(
              map(normalized),
              share()
          )
        : of(Boolean(normalized));
};

const valueMatches = (expectation: Primitive | Primitive[], actualValue: any) => {
    // When expected value is a Boolean, then cast any value to Boolean type
    // For a strict boolean check the array notation can be used e.g. ([true])
    if (isBoolean(expectation)) {
        return expectation === Boolean(actualValue);
    }

    // when field value is an array - return true if every provided values are included.
    if (isArray(actualValue)) {
        return [].concat(expectation).every(value => actualValue.includes(value));
    }

    // Treat array as allowed values for a non-array form fields
    if (isArray(expectation)) {
        return expectation.includes(actualValue);
    }

    // default value matcher
    return expectation === actualValue;
};

export const normalizeDynamicProperty = (input): IPredicateFn | boolean => {
    if (isFunction(input)) {
        return input;
    }
    if (isObject(input)) {
        return objectPredicateToFunction(input);
    }
    return Boolean(input);
};

export const filterConditions = (values: ICondition[]): ICondition[] => values.filter(isObject).filter(negate(isEmpty));

export const deepConditionCheck = (key: string, expectation, formValue: Object): boolean => {
    const check = condition => checkCondition(condition, formValue);

    switch (key) {
        case 'not':
            return !check(expectation);
        case 'oneOf':
            return 1 === filterConditions(expectation).reduce((count, condition) => count + +check(condition), 0);
        case 'allOf':
            return filterConditions(expectation).every(check);
        case 'anyOf':
            return filterConditions(expectation).some(check);
        default:
            return valueMatches(expectation, get(formValue, key));
    }
};

export const checkCondition = (condition: ICondition, formValue) => {
    return Object.entries(condition).every(([key, expectation]) => deepConditionCheck(key, expectation, formValue));
};

export const objectPredicateToFunction = (config: ICondition | ICondition[]) => {
    // multiple condition sets may be provided as well as a single one
    // .every() returns true when used on an empty array so keep only non-empty objects
    const conditions = filterConditions([].concat(config));

    return (formValue: Object) => {
        return conditions.some(condition => checkCondition(condition, formValue));
    };
};
/* tslint:enable */
