/**
 * UserSessionReducer
 */

import { IAction } from '../';
import { SessionActions, OpraDataActions } from '../../actions';
import { IUser } from '../../../shared/types';
import { OpraSessionService } from '../../../shared/services';
import { get } from 'lodash';

export interface IPermissions {
    app_code: string;
    name: string;
    roles: string[];
}

/**
 * Permissions interface
 */
export interface IUserPermission {
    name: string;
    app_code: string;
    value?: boolean;
    type?: string;
}

export interface IUserPermissions {
    total: number;
    items: IUserPermission[];
}

/**
 * IUserSession interface
 */
export interface IUserSession {
    user?: IUser;
    token?: string;
    errors?: any[];
    companies?: any[];
    isAuthenticated?: boolean;
    companyRoles?: {
        [companyId: string]: {
            roles: object[];
            touchedPermissions?: IPermissions[];
        };
    };
    showCompanySelector?: boolean;
    currentCompanyId?: string;
    isIframeMode?: boolean;
    userPermissions?: IUserPermissions;
    reports: any[];
}

const INITIAL_STATE: IUserSession = {
    token: null,
    errors: null,
    user: null,
    isAuthenticated: false,
    companies: [],
    companyRoles: {},
    showCompanySelector: false,
    currentCompanyId: null,
    isIframeMode: false,
    reports: []
};

export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_BEARER_TOKEN = 'SET_BEARER_TOKEN';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const LOGOUT = 'SET_STATE';
export const IFRAME_INTEGRATION = 'IFRAME_INTEGRATION';
export const SET_REPORTS = 'SET_REPORTS';
export const SESSION_REDUCER_INIT = '@ngrx/store/init';
const KEY = 'sessionObject';

function getTimeStamp() {
    return Math.floor(Date.now() / 1000);
}

/**
 * This function updates user session state
 *
 */
export const userSessionReducer = (state: IUserSession = INITIAL_STATE, action: IAction) => {
    const { payload } = action;
    const expired = 300;
    const timestamp = getTimeStamp();
    const assignTimestamp = timestamp + expired;
    switch (action.type) {
        case SessionActions.TOKEN_RETRIEVED:
            return Object.assign({}, state, {
                errors: [],
                token: payload,
                currentCompanyId: get(OpraSessionService.parseToken(payload), 'user.company_id', null)
            });
        case SessionActions.LOGIN_USER_SUCCESS:
            const userAfterLogin = Object.assign({}, state.user, {
                name: payload.user.name,
                id: payload.user.id,
                email: payload.user.email
            });
            return Object.assign({}, state, {
                errors: [],
                user: userAfterLogin,
                isAuthenticated: true
            });

        case SessionActions.CHECK_USER_AUTH_SUCCESS:
            const userObj = Object.assign({}, state.user, {
                name: payload.user.name,
                id: payload.user.id,
                email: payload.user.email
            });
            const tokenObj = Object.assign({}, state.token, {
                token: payload.token,
            });
            return Object.assign({}, state, {
                errors: [],
                user: userObj,
                token: tokenObj.token,
                isAuthenticated: true
            });
        case SessionActions.CHECK_USER_AUTH_FAILURE:
            return Object.assign({}, state, {
                errors: [],
                isAuthenticated: false
            });
        case SessionActions.LOGIN_USER_ERROR:
            return Object.assign({}, state, {
                errors: payload.errors
            });
        case OpraDataActions.FETCH_COMPANIES_SUCCESS:
            return Object.assign({}, state, {
                companies: payload.items,
                showCompanySelector: true
            });
        case OpraDataActions.SHOW_COMPANY_SELECTOR:
            return Object.assign({}, state, {
                showCompanySelector: true
            });
        case OpraDataActions.HIDE_COMPANY_SELECTOR:
            return Object.assign({}, state, {
                showCompanySelector: false
            });
        case SessionActions.SET_IFRAME_MODE:
            return Object.assign({}, state, {
                isIframeMode: payload
            });
        case OpraDataActions.FETCH_COMPANIES_ERROR:
        case SessionActions.RECEIVE_ACCESS_TOKEN_ERROR:
        case OpraDataActions.FETCH_PERMISSIONS_ERROR:
            return Object.assign({}, state, {
                errors: payload
            });
        case SessionActions.RECEIVE_ACCESS_TOKEN_SUCCESS:
            return Object.assign({}, state, {
                showCompanySelector: false,
                token: get(OpraSessionService.parseToken(payload), 'token', null),
                currentCompanyId: get(OpraSessionService.parseToken(payload), 'user.company_id')
            });

        case SessionActions.SET_COMPANY_SUCCESS:
            return Object.assign({}, state, {
                currentCompanyId: payload,
            });

        case SessionActions.SET_COMPANY_FAILURE:
            return Object.assign({}, state, {
                errors: payload.errors,
            });

        case OpraDataActions.FETCH_PERMISSIONS:
            return Object.assign({}, state, {
                userPermissions: {}
            });

        case OpraDataActions.FETCH_PERMISSIONS_SUCCESS:
            return Object.assign({}, state, {
                userPermissions: {
                    total: payload.total,
                    items: payload.items
                }
            });

        case SessionActions.LOGOUT_USER:
            /**
             * LOGOUT_USER can be dispatched after TOKEN_RETRIEVED, we have to keep errors
             * just to show them on login page.
             */
            return Object.assign({}, INITIAL_STATE, { errors: state.errors });

        case SESSION_REDUCER_INIT:
            let initial: any = localStorage.getItem(KEY);
            if (initial) {
                initial = JSON.parse(initial);
                if (initial.dateUpdate > timestamp) {
                    // initialState = Object.assign({}, state, initial);
                    return true;
                }
            }
            break;
        case SET_USER:
            return Object.assign({}, state, {
                user: action.payload,
                dateUpdate: assignTimestamp
            });
        case SET_TOKEN:
            return Object.assign({}, state, {
                token: action.payload,
                dateUpdate: assignTimestamp
            });
        case SET_ACCESS_TOKEN:
            return Object.assign({}, state, {
                accessToken: action.payload,
                dateUpdate: assignTimestamp
            });
        case SET_BEARER_TOKEN:
            return Object.assign({}, state, {
                bearerToken: action.payload,
                dateUpdate: assignTimestamp
            });
        case IFRAME_INTEGRATION:
            return Object.assign({}, state, {
                iframeIntegration: action.payload,
                dateUpdate: assignTimestamp
            });
        case SET_PERMISSIONS:
            return Object.assign({}, state, {
                permissions: action.payload,
                dateUpdate: assignTimestamp
            });
        default:
            return state;
    }
};
