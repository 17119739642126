/**
 * RouterActions
 */

import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../store/reducers';
import { UPDATE_LOCATION } from '@angular-redux/router';

/**
 * # RouterActions
 * Defines the class for creating router actions
 */

@Injectable()
export class RouterActions {
  public static updateLocationAction(route: string = '') {
    return {
      type: UPDATE_LOCATION,
      payload: `/${route}`,
    };
  }
    /**
     * Initialize the instance
     */
  constructor(
        private ngRedux: NgRedux<IAppState>
    ) {}

    /**
     * Dispatch action for updating location
     *
     */

  public updateLocation(route) {
    this.ngRedux.dispatch(RouterActions.updateLocationAction(route));
  }
}
