<div class="report_select_filter">
  <span>{{label}}</span>
  <span *ngIf="tooltip" class="report_select_filter__tip">
    <span class="report_select_filter__tip-icon"><i class="fa fa-info-circle"></i></span>
    <span class="report_select_filter__tip-info">
      {{ tooltipInfo }}
    </span>
  </span>
  <angular2-multiselect *ngIf="multiple" [data]="items" [(ngModel)]="inputValue"
                        [settings]="dropdownSettings"
                        (onSelect)="onSelectedItemChange($event)"
                        (onDeSelect)="onSelectedItemChange($event)"
                        (onSelectAll)="onSelectedAllStateChange($event)"
                        (onDeSelectAll)="onSelectedAllStateChange($event)"></angular2-multiselect>
  <select *ngIf="!multiple" (change)="handleSelectFilterChange()" [(ngModel)]="inputValue"
          class="report_date_range_filter">
    <option *ngFor="let item of items" [value]="item.value">{{item.name}}</option>
  </select>
</div>
