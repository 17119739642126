/**
 * IframeMode component
 */
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionActions } from '../../../store/actions';
import { IUserSession } from '../../../store/reducers/session/session.reducer';
import { TokenSessionService } from '../../../shared/services/session';
import { get } from 'lodash';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'dm-iframe-mode',
    templateUrl: 'iframe-mode.component.html'
})
export class IframeModeComponent implements OnInit, OnDestroy {
    public error: boolean = false;
    @select(['userSession']) private session$: Observable<IUserSession>;
    private subscription: Subscription[] = [];
    private navigateURL: string;

    constructor(
        private tokenSessionService: TokenSessionService,
        private sessionActions: SessionActions,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    public ngOnInit() {
        this.sessionActions.setIframeMode(this.inIframe());
        this.subscription.push(
            this.route.params.subscribe(params => {
                this.navigateURL = get(params, 'navigateURL', '/custom-reports');
                if (params['accessToken']) {
                    this.setToken(params['accessToken']);
                } else {
                    window.parent.postMessage(
                        {
                            msg_type: 'GET_ACCESS_TOKEN',
                            platform: 'RPT',
                            timestamp: moment().toISOString(),
                            payload: null
                        },
                        '*'
                    );
                }
            })
        );

        this.subscription.push(
            this.session$.pipe(filter(({ user }) => user && !!user.email)).subscribe(() => {
                this.router.navigate([this.navigateURL]);
            })
        );
    }

    @HostListener('window:message', ['$event'])
    public iframeMessageListener(event: MessageEvent) {
        if (event && event.data && event.data['msg_type'] && event.data['msg_type'] === 'GET_ACCESS_TOKEN_SUCCESS') {
            this.setToken(event.data['payload']);
        }
    }

    private setToken(token) {
        try {
            this.tokenSessionService.saveToken(this.createToken(token));
            this.sessionActions.restoreToken();
        } catch (error) {
            this.error = true;
        }
    }

    inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    public ngOnDestroy() {
        this.subscription.map(s => s.unsubscribe());
    }

    private createToken(token) {
        return {
            token,
            user: JSON.parse(atob(token.split('.')[1]))
        };
    }
}
