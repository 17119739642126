<div class="filters-panel">
    <div *ngIf="selected" class="dashboard-filter {{ enableSitePicker ? 'portal_site_picker' : '' }}" (click)="showPortalPicker();">
        <span>Sites</span><br/>
        <angular2-multiselect [data]="sites" [(ngModel)]="selected"
                              [settings]="dropdownSettings"
                              (onSelect)="onItemSelect()"
                              (onDeSelect)="onItemDeselect()"
                              (onSelectAll)="onSelectAll()"
                              (onDeSelectAll)="onDeSelectAll()"
                              ngDefaultControl></angular2-multiselect>
    </div>
    <div class="dashboard-filter">
        <span>Calendar</span><br/>
        <xux-datepicker-input
                [config]="calendarConfig"
                [value]="selectedDate"
                (onChange)="handleCalendarChange($event)">
        </xux-datepicker-input>
    </div>
    <button (click)="onSubmitForm()">Submit</button>
</div>
