import { Component, OnInit, OnDestroy, HostListener, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UiActions, CustomReportsActions, FiltersActions, SitesActions } from '@store/actions';
import { CustomReportsService, ReportsService, VegaConfigsService } from '@shared/services/reports';
import { PreferencesService } from '@shared/services';
import { NgRedux } from '@angular-redux/store';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import * as moment from 'moment';
import { map, takeUntil, switchMap, catchError, filter, tap, distinctUntilChanged } from 'rxjs/operators';
import { ISite, ISiteState } from '@shared/types/site';
import { IDatepickerValue } from '@hc/xenial-ui-shared/modules/xdm-shared-components/components/xux-datepicker/types/index';

@Component({
    selector: 'dashboard.container-vega-report',
    templateUrl: 'dashboard.container.html',
    styleUrls: ['dashboard.container.scss']
})
export class DashboardReportContainerComponent implements OnInit, OnDestroy {

    @ViewChild('visDate') visDate;
    @ViewChild('vis_hour') vis_hour;
    @ViewChild('vis_dest') vis_dest;
    @ViewChild('vis_daypart') vis_daypart;

    public csvData: any[] = [];
    public datesChart: any;
    public dayPartsChart: any;
    public destinationsChart: any;
    public hoursChart: any;
    public headerChart: any[];

    public selectedSites: Array<ISite> = null;
    public sites: Array<ISite>;
    public calendarValue: IDatepickerValue = null;
    public enablePicker$: Observable<boolean>;

    private sitesState$: Observable<ISiteState>;

    private filterStateSingleDate: Observable<IDatepickerValue>;
    private isSubmitted = true;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private customReportService: CustomReportsService,
        private preferencesService: PreferencesService,
        private vegaConfigService: VegaConfigsService,
        private reportsActions: CustomReportsActions,
        private filtersActions: FiltersActions,
        private reportService: ReportsService,
        private sitesAction: SitesActions,
        private cdRef: ChangeDetectorRef,
        private uiActions: UiActions,
        private store: NgRedux<any>
    ) {
        this.sitesState$ = this.store.select('sites');
        this.enablePicker$ = this.store.select(['sites', 'enablePicker']);

        this.filterStateSingleDate = this.store.select(['filters', 'selectedDateForSingleDate']);
    }

    ngOnInit() {
        this.sitesAction.getRecentSiteList();
        this.uiActions.enableContentLoader();
        this.sitesState$
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged((prev, next) => JSON.stringify(prev.selectedSites) === JSON.stringify(next.selectedSites)),
                filter(({selectedSites}) => !!selectedSites.length),
                map(({sites, selectedSites, enablePicker}) => {
                    this.selectedSites = selectedSites;
                    this.sites = sites;
                    return sites.map(site => site.id);
                }),
                switchMap(sitesIds => this.getSingleDatePickerDateFromState(sitesIds)),
                switchMap((sitesIds) => {
                    if (this.isSubmitted) {
                        if (!!this.calendarValue) {
                            return this.fetchDashboard(this.selectedSites.map(site => site.id), this.calendarValue.value.dateStart);
                        }
                        return this.reportService.fetchBusinessDate('Sales', sitesIds, this.sites).pipe(
                            map(res => this.processBusinessDate(res)),
                            switchMap(business_date => this.fetchDashboard(this.selectedSites.map(site => site.id), business_date)),
                        );
                    }
                    return of();
                }),
                filter(data => !!data)
            )
            .subscribe((data: any) => {
                this.updateCharts(data);
            }, () => {}, () => this.uiActions.disableContentLoader() );
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.datesChart) {
            this.datesChart.signal('width', this.vegaConfigService.getChartWidth('vis_date')).run('enter');
        }
        if (this.dayPartsChart) {
            this.dayPartsChart.signal('width', this.vegaConfigService.getChartWidth('vis_daypart')).run('enter');
        }
        if (this.destinationsChart) {
            this.destinationsChart.signal('width', this.vegaConfigService.getChartWidth('vis_dest')).run('enter');
        }
        if (this.hoursChart) {
            this.hoursChart.signal('width', this.vegaConfigService.getChartWidth('vis_hour')).run('enter');
        }
    }

    public onCalendarChanged(event) {
        this.calendarValue = event;
        this.filtersActions.setDateForSinglePicker(this.calendarValue);
    }

    public submitForm() {
        this.uiActions.enableContentLoader();
        const date = moment(this.calendarValue.value.dateStart).format('YYYY-MM-DD');
        this.fetchDashboard(this.prepareSites(this.selectedSites), date).subscribe(
            (data) => { this.updateCharts(data); },
            () => {},
            () => this.uiActions.disableContentLoader());
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.uiActions.disableContentLoader();
    }

    private prepareDashboardDates(dates): { headers: any[], data: any[], name: string } {
        if (dates.length) {
            dates = this.getNormalizeData(dates, true);
            this.datesChart = this.vegaConfigService.vegaInit(
                'vis_date',
                this.vegaConfigService.getChartCustomDashboardByDate('By Date'),
                dates,
                this.vegaConfigService.getChartWidth('vis_date'),
                this.vegaConfigService.getChartHeight('vis_date')
            );
            this.visDate.nativeElement.style.display = 'block';
            return this.vegaConfigService.prepareCsvForBarChart(dates, 'By Dates');
        }
        this.datesChart = null;
        this.visDate.nativeElement.style.display = 'none';
    }

    private prepareDashboardHours(hours): { headers: any[], data: any[], name: string } {
        if (hours.length) {
            const hasLaborCost = this.hasLaborCost(hours);
            hours = this.getNormalizeHoursData(hours);
            this.hoursChart = this.vegaConfigService.vegaInit(
                'vis_hour',
                this.vegaConfigService.getChartBarlineCompareVertical('By Hour', hasLaborCost),
                hours,
                this.vegaConfigService.getChartWidth('vis_hour'),
                this.vegaConfigService.getChartHeight('vis_hour')
            );
            this.vis_hour.nativeElement.style.display = 'block';
            return this.vegaConfigService.prepareCsvForBarChart(hours, 'By Hour');
        }
        this.hoursChart = null;
        this.vis_hour.nativeElement.style.display = 'none';
    }

    private prepareDashboardDestination(destinations): { headers: any[], data: any[], name: string } {
        if (destinations.length) {
            destinations = this.getNormalizeData(destinations);
            this.destinationsChart = this.vegaConfigService.vegaInit(
                'vis_dest',
                this.vegaConfigService.getChartBarCompareVertical('By Destination', 'Destination'),
                destinations,
                this.vegaConfigService.getChartWidth('vis_dest'),
                this.vegaConfigService.getChartHeight('vis_dest')
            );
            this.vis_dest.nativeElement.style.display = 'block';
            return this.vegaConfigService.prepareCsvForBarChart(
                destinations,
                'Destination'
            );
        }
        this.destinationsChart = null;
        this.vis_dest.nativeElement.style.display = 'none';
    }

    private prepareDashboardDayParts(dayParts): { headers: any[], data: any[], name: string } {
        if (dayParts.length) {
            dayParts = this.getNormalizeData(dayParts);
            dayParts = this.preferencesService.sortByDayParts(dayParts, 'group_by');
            this.dayPartsChart = this.vegaConfigService.vegaInit(
                'vis_daypart',
                this.vegaConfigService.getChartBarCompareVertical('By Day Part', 'Day Part'),
                dayParts,
                this.vegaConfigService.getChartWidth('vis_daypart'),
                this.vegaConfigService.getChartHeight('vis_daypart')
            );
            this.vis_daypart.nativeElement.style.display = 'block';
            return this.vegaConfigService.prepareCsvForBarChart(dayParts, 'Day Part');
        }
        this.dayPartsChart = null;
        this.vis_daypart.nativeElement.style.display = 'none';
    }

    private prepareDashboardHeader(header): { headers: any[], data: any[], name: string } {
        this.headerChart = this.vegaConfigService.normalizeHeaderBarData(header);
        return this.vegaConfigService.prepareCsvForBarChart(header, 'Header');
    }

    private hasLaborCost(data): boolean {
        return !!data.filter(item => item.labor_cost_amount !== null && item.labor_cost_amount > 0).length;
    }

    private fetchDashboard (siteIds, businessDate): Observable<any> {
        businessDate = moment(businessDate).format('YYYY-MM-DD');
        return forkJoin([
            this.customReportService.fetchDashboardDates(siteIds, businessDate)
                .pipe(
                    catchError(() => of([]))
                ),
        this.customReportService.fetchDashboardDayParts(siteIds, businessDate)
            .pipe(
                catchError(() => of([]))
            ),
        this.customReportService.fetchDashboardDestinations(siteIds, businessDate)
            .pipe(
                catchError(() => of([]))
            ),
        this.customReportService.fetchDashboardHours(siteIds, businessDate)
            .pipe(
                catchError(() => of([]))
            ),
        this.customReportService.fetchDashboardHeader(siteIds, businessDate)
            .pipe(
                catchError(() => of([]))
            )
        ]).pipe(
            takeUntil(this.unsubscribe$),
            map(([dates, dayParts, destinations, hours, header ]) => {
                this.reportsActions.addDashboardDates(dates);
                this.reportsActions.addDashboardDayParts(dayParts);
                this.reportsActions.addDashboardDestinations(destinations);
                this.reportsActions.addDashboardHours(hours);
                this.reportsActions.addDashboardHeader(header);
                return {dates: dates, dayParts: dayParts, destinations: destinations, hours: hours, header: header};
            }),
        );
    }

    private getNormalizeData(data, isByDate = false) {
        return data.reduce((filtered, item) => {
            const tempObj = {
                ...item,
                amount1: this.preferencesService.correctRounding(item.amount1),
                amount2: this.preferencesService.correctRounding(item.amount2),
            };
            if (isByDate) {
                tempObj.group_by = this.preferencesService.dayAndDateFormatterForVega(tempObj.group_by);
            }
            filtered.push(tempObj);

            return filtered;
        }, []);
    }

    private getNormalizeHoursData(data) {
        return data
            .sort((a, b) => {
                return a.time_order - b.time_order;
            })
            .reduce((filtered, item) => {
                const tempObj = {
                    ...item,
                    avg_amount: this.preferencesService.correctRounding(item.avg_amount),
                    labor_cost_amount: this.preferencesService.correctRounding(item.labor_cost_amount),
                    net_sales_amount: this.preferencesService.correctRounding(item.net_sales_amount),
                    group_by: this.preferencesService.timeFormatterForVega(item.group_by),
                };
                filtered.push(tempObj);

                return filtered;
            }, []);
    }

    private getSingleDatePickerDateFromState(sitesIds): Observable<any> {
        return this.filterStateSingleDate
            .pipe(
                takeUntil(this.unsubscribe$),
                tap((date: IDatepickerValue) => { if (!!date) { this.calendarValue = date; } }),
                map(() => sitesIds)
            );
    }

    private processBusinessDate(res): string {
       const business_date = res['business_date'] || new Date();
       this.calendarValue = {
           type: 'businessDate',
           value: {
               dateStart: business_date
           }
       };
       this.filtersActions.setDateForSinglePicker(this.calendarValue);
       return business_date;
    }

    private updateCharts(data: any): void {
        const { dates, dayParts, destinations, hours, header } = data;
        this.isSubmitted = false;
        this.csvData = [
            this.prepareDashboardHeader(header),
            this.prepareDashboardDates(dates),
            this.prepareDashboardHours(hours),
            this.prepareDashboardDestination(destinations),
            this.prepareDashboardDayParts(dayParts)
        ];
        this.cdRef.detectChanges();
        this.uiActions.disableContentLoader();
    }

    private prepareSites(sites) {
        if (sites) {
            return sites.map(site => {
                return site.id;
            });
        } else {
            return [];
        }
    }
}
