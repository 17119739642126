import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ISiteState } from '@shared/types/site';
import { NgRedux } from '@angular-redux/store';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap, takeWhile, tap, filter, distinctUntilChanged } from 'rxjs/operators';
import { FiltersActions, SitesActions, UiActions } from '@store/actions';
import { ReportsService } from '@shared/services';
import { IAppState } from '@store/reducers';
import { IReport } from '@shared/types/report';
import { SELECT_ALL } from '../../../../../config';

@Component({
    selector: 'site-picker-filter',
    templateUrl: 'portal-site-picker.filter.html',
    styleUrls: [ 'style.css' ],
    changeDetection: ChangeDetectionStrategy.Default
})
export class PortalSitePickerFilterComponent implements OnDestroy {

    public label: string = '';
    public dropdownSettings = {};
    public items: any[] = [];
    public enablePicker: boolean = false;
    public isReady = false;

    public selectedItems = [];
    private sitesState: Observable<any>;
    private isActive: boolean = true;
    private multiple: boolean = true;

    private attributes: string[] = [];
    private name: string = '';
    private reportType: string;

    @Input() filter: object;
    @Input() type: string = '';
    @Output() handleChanges = new EventEmitter();

    @Input() set selectedReport(report: IReport) {
        this.reportType = report.source;
        this.label = this.filter['label'];
        this.multiple = this.filter['multiple'];
        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];

        this.uiActions.enableContentLoader();

        this.dropdownSettings = {
            singleSelection: !this.multiple,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            badgeShowLimit: 4,
            allowSearchFilter: true,
            lazyLoading: true
        };
        this.sitesState = this.ngRedux.select('sites');
        this.sitesState
            .pipe(
                takeWhile(() => this.isActive),
                filter(state => !!state.selectedSite),
                distinctUntilChanged((prev, curr) => { if (report.id === 'sales_analysis') {
                    return prev.selectedSite.id === curr.selectedSite.id;
                } }),
                switchMap(state => iif(
                    () => report.id === 'sales_analysis',
                    this.reportService.fetchBusinessDate('Sales', [state.selectedSite.id], state.sites).pipe(
                        tap(businessDate => {
                            this.filtersActions.setBusinessDate(businessDate['business_date']);
                            this.uiActions.disableContentLoader();
                        }),
                        map(() => state)
                    ),
                    of(state)
                ))
            )
            .subscribe(state => {
                this.enablePicker = state.enablePicker;
                this.items = state.sites;
                this.selectedItems = this.prepareSites(this.multiple ? state.selectedSites : [ state.selectedSite ]);
                this.isReady = true;
                this.handlePickerChanges();
                this.uiActions.disableContentLoader();

                this.cdRef.detectChanges();
            });
    }

    constructor(private sitesAction: SitesActions,
                private reportService: ReportsService,
                private filtersActions: FiltersActions,
                private cdRef: ChangeDetectorRef,
                private uiActions: UiActions,
                private ngRedux: NgRedux<IAppState | ISiteState>) {
    }

    public showPortalPicker() {
        if (this.enablePicker) {
            const selectorOptions = {
                required: true,
                applySiteSelection: true,
                multiselect: this.multiple,
                defaultSelectedSites: this.selectedItems.map(site => site.id)
            };
            this.sitesAction.showSiteSelector(selectorOptions).then();
        } else {
            return true;
        }
    }

    ngOnDestroy() {
        this.isActive = false;
    }

    public onItemSelect() {
        this.ngRedux.dispatch(
            SitesActions.updateSelectedSites({
                sites: this.selectedItems,
                isMultiple: this.multiple
            })
        );
    }

    public onItemDeselect() {
        this.ngRedux.dispatch(
            SitesActions.updateSelectedSites({
                sites: this.selectedItems,
                isMultiple: this.multiple
            })
        );
    }

    public onSelectAll() {
        this.ngRedux.dispatch(
            SitesActions.updateSelectedSites({
                sites: this.selectedItems,
                isMultiple: this.multiple
            })
        );
    }

    public onDeSelectAll() {
        this.ngRedux.dispatch(
            SitesActions.updateSelectedSites({
                sites: [],
                isMultiple: this.multiple
            })
        );
    }

    private prepareSites(sites) {
        if (sites) {
            return sites.map(site => {
                return {
                    id: site.id,
                    itemName: site.name || site.itemName
                };
            });
        } else {
            return [];
        }
    }

    private handlePickerChanges(): void {
        const errors = [];
        const value = {};
        const exclude_site_id = this.getExcluded();
        if (this.multiple) {
            if (this.items.length > 1 && this.items.length === this.selectedItems.length) {
                value[this.attributes[0]] = {value: SELECT_ALL, siteIds: this.selectedItems};
                value[this.attributes[1]] = {value: 'None' };
            } else {
                if (this.selectedItems.length > exclude_site_id.length && this.reportType !== 'ag-grid') {
                    value[this.attributes[0]] = {value: SELECT_ALL, siteIds: this.selectedItems};
                    value[this.attributes[1]] = {value: exclude_site_id };
                } else {
                    value[this.attributes[0]] = {value: this.selectedItems};
                    value[this.attributes[1]] = {value: 'None' };
                }
            }
        } else {
            value[this.attributes[0]] = {value: this.selectedItems};
        }

        if (this.selectedItems.length === 0) {
            errors.push('Sites can not be blank.');
        }
        const res = {
            value,
            name: this.name,
            type: this.filter['type'],
            errors: errors,
        };
        this.handleChanges.emit(res);
    }

    private getExcluded(): Array<{id: string, itemName: string}> {
        return this.items.filter(r => !this.selectedItems.some(el => {
            return el.id === r.id;
        }));
    }
}
