import { Pipe, PipeTransform } from '@angular/core';
import { SlicePipe } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'truncateEmployee'
})
export class TruncateEmployeePipe extends SlicePipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {
        super();
    }

    // @ts-ignore
    transform(value: string, ...args: Array<string | number>): SafeHtml {
        let name: string = value;
        let [ code, , ]: Array<any> = [ ...args ];
        const [ , maxName, maxCode ]: Array<any> = [ ...args ];

        const codeLength = (code as string)?.trim().length;
        const nameLengthWithoutSpaces = name?.trim().replace(/ /g, '').length;

        if (name) {
            if (nameLengthWithoutSpaces > maxName) {
                name = super.transform(name, 0, maxName).trim() + '...';
            }
            name = `<span>${name}</span>`;
        } else {
            name = '';
        }

        if (code) {
            if (codeLength > maxCode) {
                code = super.transform(code as string, 0, maxCode) + '...';
            }
            code = `<span>(${code})</span>`;
        } else {
            code = '';
        }
        return this.sanitized.bypassSecurityTrustHtml(`${name} ${code}`.trim());
    }

}
