<ng-container>
    <div class="ag-grid-wrapper">
        <div class="ag-grid-header">
            <div class="ag-grid-top">
                <h1 class="ag-grid__title">
                    {{ reportTitle }}
                </h1>
                <ul class="ag-grid-header__actions">
                    <li>
                        <a class="ag-grid__export-btn" (click)="toggleDropdownMenu($event)">Export</a>
                        <ul class="ag-grid__links-list"
                            (clickOutside)="closeDropdownMenu()"
                            [ngClass]="{'is-open': openedExportDropdownMenu}">
                            <li ><a class="ag-grid__export-link" (click)="exportReportToCSV()">
                                <span>.csv</span>
                                <img src="assets/icon/csv-file.png" alt="csc file">
                            </a></li>
                            <li ><a class="ag-grid__export-link" (click)="openPdfModal()">
                                <span>.pdf</span>
                                <img src="assets/icon/pdf-file.png" alt="csc file">
                            </a></li>
                            <li><a class="ag-grid__export-link" (click)="exportReportToXLS()">
                                <span>.xls</span>
                                <img src="assets/icon/xls-file.png" alt="xls file">
                            </a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <p class="ag-grid-header__desc" *ngIf="!areEqualDates([dateFrom, dateTo])">Business Date: {{ dateFormatter(dateFrom) }} - {{ dateFormatter(dateTo) }}</p>
            <p class="ag-grid-header__desc" *ngIf="areEqualDates([dateFrom, dateTo])">Business Date: {{ dateFormatter(dateFrom) }}</p>

        </div>
        <ag-grid-angular
            style="width: 100%; height: calc(100vh - 260px);"
            class="ag-theme-balham ag-reporting"
            id="EnterpriseSalesSummary"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData"
            [gridOptions]="gridOptions"
            [modules]="modules"
            [groupIncludeFooter]="true"
            [groupIncludeTotalFooter]="true"
            [domLayout]="domLayout"
            (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
    </div>
    <pdf-export-modal
        [gridOptions]="gridOptionsForExportToPdf"
        [pdfReportOptions]="pdfReportOptions"
        [isShowModal]="_showModal"
        (closeModal)="closeModal()"
    ></pdf-export-modal>
</ng-container>

