import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'lodash';

@Pipe({
  name: 'getArray'
})
export class GetArrayPipe implements PipeTransform {

  constructor() {}

  public transform(value: any): any[] {
    if (isArray(value)) {
        return value;
    } else {
        return [value];
    }
  }

}
