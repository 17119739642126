import { Routes } from '@angular/router';
import { ReportContainerComponent } from './report/report.container';
import { HOME_ROUTE } from '../../routes';
import { HomeComponent } from '../../components/app/home';
import { DashboardReportContainerComponent } from './d3/dashboard_vega/dashboard.container';
import { CompanyGuard } from '@containers/reports/d3/guards/company.guard';

export const defaultRoutes: Routes = [
    {
        path: HOME_ROUTE,
        component: HomeComponent
    },
    {
        path: '',
        component: DashboardReportContainerComponent,
        canActivate: [CompanyGuard]
    },
    { path: 'report/:id', component: ReportContainerComponent }
];
