import { IAction } from '../';
import { IFilterState } from '../../../shared/types/filters';

export const ADD_DEPARTMENTS = 'ADD_DEPARTMENTS';
export const ADD_TYPES = 'ADD_TYPES';
export const ADD_JOB_CODES = 'ADD_JOB_CODES';
export const ADD_JOB_CATEGORIES = 'ADD_JOB_CATEGORIES';
export const ADD_TAGS = 'ADD_TAGS';
export const RESET_TAGS = 'RESET_TAGS';
export const SET_CALENDARS = 'SET_CALENDARS';
export const RESET_CALENDARS = 'RESET_CALENDARS';
export const SET_FORECAST_DEFINITIONS = 'SET_FORECAST_DEFINITIONS';
export const SET_BUSINESS_DATE = 'SET_BUSINESS_DATE';
export const SET_CALENDARS_AND_BUSINESS_DATE = 'SET_CALENDARS_AND_BUSINESS_DATE';
export const SET_BUSINESS_RULE_GROUP = 'SET_BUSINESS_RULE_GROUP';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_DATE_FOR_SINGLE_PICKER = 'SET_DATE_FOR_SINGLE_PICKER';
export const ADD_EMPLOYEES = 'ADD_EMPLOYEES';

const initialState: IFilterState = {
  departments: null,
  item_types: null,
  job_codes: null,
  job_categories: null,
  employees: null,
  multipleCalendars: null,
  forecastDefinitions: null,
  tags: [],
  business_rule_groups: null,
  singleBusinessDate: null,
  multipleBusinessDate: null,
  selectedDateForSingleDate: '',
  selectedDateForMultiDate: '',
  prevCalendarType: null
};

export const filtersReducer = (state: IFilterState = initialState, action: IAction) => {
  switch (action.type) {
    case ADD_DEPARTMENTS:
      return Object.assign({}, state, {
        departments: action.payload,
      });

    case ADD_TYPES:
      return Object.assign({}, state, {
        item_types: action.payload,
      });

    case ADD_JOB_CODES:
      return Object.assign({}, state, {
        job_codes: action.payload,
      });

    case ADD_EMPLOYEES:
      return Object.assign({}, state, {
        employees: action.payload,
      });

    case ADD_JOB_CATEGORIES:
      return Object.assign({}, state, {
        job_categories: action.payload,
      });

    case ADD_TAGS:
      return Object.assign({}, state, {
        tags: action.payload,
      });
    case RESET_TAGS:
      return Object.assign({}, state, {
        tags: [],
      });

    case SET_CALENDARS:
      return Object.assign({}, state, {
        calendars: action.payload,
      });
    case RESET_CALENDARS:
      return Object.assign({}, state, {
        calendars: [],
      });
    case SET_FORECAST_DEFINITIONS:
      return Object.assign({}, state, {
        forecastDefinitions: action.payload,
      });

    case SET_BUSINESS_DATE:
      return Object.assign({}, state, {
        businessDate: action.payload,
      });

    case SET_BUSINESS_RULE_GROUP:
      return Object.assign({}, state, {
        business_rule_groups: action.payload,
      });

    case SET_CALENDARS_AND_BUSINESS_DATE:
      const businessDateState = action.payload.multiple ? { ...state.multipleBusinessDate } : { ...state.singleBusinessDate };
      const calendarsState =  { ...state.multipleCalendars };
      const selectedDate = action.payload.applied ? {
            ...action.payload.selectedDate,
            type: 'custom'
          } : action.payload.selectedDate;
      return Object.assign({}, state, {
            [action.payload.multiple
                ? 'multipleBusinessDate'
                : 'singleBusinessDate']: {
              ...businessDateState,
              [action.payload.typeOfCalendar]: action.payload.date
            },
            ['multipleCalendars']: {
              ...calendarsState,
              [action.payload.typeOfCalendar]: action.payload.calendars
            },
            [action.payload.typeOfCalendar === 'SINGLE_DATE'
                ? 'selectedDateForSingleDate'
                : 'selectedDateForMultiDate']: action.payload.applied ? selectedDate
                : action.payload.multiple ? state.selectedDateForMultiDate : state.selectedDateForSingleDate,
            prevCalendarType: action.payload.applied ? action.payload.calendarType : null
        });

    case SET_DATE_FOR_SINGLE_PICKER:
      return Object.assign({}, state, {
        selectedDateForSingleDate: action.payload,
      });

    case RESET_FILTERS:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
};
