import { IAction } from '../';
import { ICustomReportState } from '../../../shared/types/report';

export const ADD_SPEED_OF_SERVICE_DATA = 'ADD_SPEED_OF_SERVICE_DATA';

export const ADD_SALES_ANALYSIS_DATA = 'ADD_SALES_ANALYSIS_DATA';

export const ADD_DASHBOARD_DATES = 'ADD_DASHBOARD_DATES';
export const ADD_DASHBOARD_DAYPARTS = 'ADD_DASHBOARD_DAYPARTS';
export const ADD_DASHBOARD_DESTINATIONS = 'ADD_DASHBOARD_DESTINATIONS';
export const ADD_DASHBOARD_HOURS = 'ADD_DASHBOARD_HOURS';
export const ADD_DASHBOARD_HEADER = 'ADD_DASHBOARD_HEADERS';

export const ADD_SALES_AND_LABOR_HOURS = 'ADD_SALES_AND_LABOR_HOURS';
export const ADD_SALES_AND_LABOR_HEADER = 'ADD_SALES_AND_LABOR_HEADER';

const initialState: ICustomReportState = {
  speedOfService: {
    speedOfServiceData: []
  },
  salesAnalysis: {
    salesAnalysisData: []
  },
  dashboard: {
    dates: [],
    dayparts: [],
    destinations: [],
    hours: [],
    header: [],
  },
  salesAndLabor: {
    hours: [],
    header: [],
  }
};

export const customReportsReducer = (state: ICustomReportState = initialState, action: IAction) => {
  switch (action.type) {
    case ADD_SPEED_OF_SERVICE_DATA:
      return Object.assign({}, state, {
        speedOfService: {speedOfServiceData: action.payload},
      });
    case ADD_SALES_ANALYSIS_DATA:
      return Object.assign({}, state, {
        salesAnalysis: { salesAnalysisData: action.payload },
      });
    case ADD_DASHBOARD_DATES:
      return Object.assign({}, state, {
        dashboard: {dates: action.payload},
      });
    case ADD_DASHBOARD_DAYPARTS:
      return Object.assign({}, state, {
        dashboard: {dayparts: action.payload},
      });
    case ADD_DASHBOARD_DESTINATIONS:
      return Object.assign({}, state, {
        dashboard: {destinations: action.payload},
      });
    case ADD_DASHBOARD_HOURS:
      return Object.assign({}, state, {
        dashboard: {hours: action.payload},
      });
    case ADD_DASHBOARD_HEADER:
      return Object.assign({}, state, {
        dashboard: {header: action.payload},
      });
    case ADD_SALES_AND_LABOR_HOURS:
      return Object.assign({}, state, {
        salesAndLabor: {hours: action.payload},
      });
    case ADD_SALES_AND_LABOR_HEADER:
      return Object.assign({}, state, {
        salesAndLabor: {header: action.payload},
      });
    default:
      return state;
  }
};
