import { Pipe, PipeTransform } from '@angular/core';
import { IMenuItem } from '../interfaces';

@Pipe({
  name: 'searchMenuItems',
})
export class SearchMenuItemsPipe implements PipeTransform {
  transform(items: IMenuItem[], searchText: string): IMenuItem[] {
    if (!searchText) {
      return items;
    }
    function search(searchItems: IMenuItem[]) {
      let ret: IMenuItem[]  = [];
      for (let i = 0; i < searchItems.length; i += 1) {
        if (Array.isArray(searchItems[i].sub)) {
          ret = ret.concat(search(searchItems[i].sub));
        } else {
          if (searchItems[i].title.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
            ret.push(searchItems[i]);
          }
        }
      }
      return ret;
    }
    return search(items);
  }
}
