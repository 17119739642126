import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'xux-drawer-content',
  templateUrl: './xux-drawer-content.component.html',
  styleUrls: ['./xux-drawer-content.component.scss'],
})
export class XUXDrawerContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
