<div class="combined-select">
  <label class="combined-select__label" for="combined-select__input">{{ label }}</label>
    <div class="input-group xux-input-group combined-select__group">
        <xux-input-select class="combined-select__select" [field]="xuxSelect" (changeValue)="onSelectedItemChanged($event)"></xux-input-select>
        <input
            #defaultFocusElement
            [type]="inputField.inputType"
            [formControl]="inputField.control"
            [placeholder]="inputField.placeholder"
            (input)="changeInputValue($event)"
            maxlength="225"
            id="combined-select__input"
            class="combined-select__input"
        />
    </div>
    <div class="errors-block">
        <div  class="error" *ngIf="error">{{error}}</div>
    </div>
</div>
