/**
 * MIDDLEWARE_PROVIDERS
 */

import { Injectable } from '@angular/core';
import { createLogger } from 'redux-logger';
import { combineEpics, createEpicMiddleware, Epic } from 'redux-observable';
import { SidebarMiddleware } from './sidebar';
import { Middleware } from 'redux';
import { IEpicsService } from '../../shared/types/epics.type';
import { environment } from '../../../environments/environment';
import { SessionEpics, OpraDataEpics, SitesEpics } from '../epics';

@Injectable()
export class MiddlewaresProvider {
    static disableReduxLogger = false;
    private middlewares: Middleware[] = [];

    constructor(
        private sidebarMiddleware: SidebarMiddleware,

        // Epics

        public sessionEpics: SessionEpics,
        public opraDataEpics: OpraDataEpics,
        public sitesEpics: SitesEpics
    ) {
        const epicsServices: IEpicsService[] = [sessionEpics, opraDataEpics, sitesEpics];
        const rootEpic: Epic<any, any> = combineEpics(...epicsServices.map(service => service.getEpic()));
        const epicsMiddleware: Middleware = createEpicMiddleware(rootEpic);

        const mdw = [this.sidebarMiddleware.middleware, epicsMiddleware];

        if (
            (environment.envName === 'development' || environment.envName === 'local') &&
            !MiddlewaresProvider.disableReduxLogger
        ) {
            mdw.push(
                createLogger({
                    level: 'info',
                    collapsed: true,
                    titleFormatter: (action, time, took) => {
                        const parts = [`action`];
                        parts.push(`@ ${time}`);
                        parts.push(action.type);

                        if (action.payload && action.payload.entity_type) {
                            parts.push(`==> ${action.payload.entity_type} <==`);
                        }

                        return parts.join(` `);
                    },
                    colors: {
                        title: action => {
                            if (action.payload && action.payload.entity_type) {
                                return '#286090';
                            }
                        }
                    }
                })
            );
        }

        this.middlewares.push(...mdw);
    }

    getMiddlewares() {
        return this.middlewares;
    }
}

export const MIDDLEWARE_PROVIDERS: any[] = [
    { provide: MiddlewaresProvider, useClass: MiddlewaresProvider },
    { provide: SidebarMiddleware, useClass: SidebarMiddleware }
];
