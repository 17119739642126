/**
 * Dm Toolbar component
 */
import { Component, Input } from '@angular/core';
import { IToolbarButtonDefinition } from '../../../shared/types';
import { isFunction } from 'lodash';

@Component({
  selector: 'dm-toolbar',
  templateUrl: 'toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class DmToolbarComponent {
  @Input() public buttons: IToolbarButtonDefinition[] = [];
  @Input() public click;
  @Input() public disabled: boolean;
  @Input() public title: string;

  handleBtnClick(btn) {
    const value = 'value' in btn ? btn.value : btn.caption;
    if (isFunction(this.click)) {
      this.click(value);
    }
    if (isFunction(btn.click)) {
      btn.click(value);
    }
  }

  public isDisabled(button) {
    return this.disabled ||
      (isFunction(button.disabled) ? button.disabled() : button.disabled);
  }
}
