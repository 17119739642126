/**
 * Company custom-reports page
 */

import { NgRedux } from '@angular-redux/store';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { FiltersService } from '../../../shared/services/filters';
import { ReportsService } from '../../../shared/services/reports';
import { IReport, IReportState } from '../../../shared/types/report';
import { ISiteState } from '../../../shared/types/site';
import { takeWhile, take, filter, tap, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { isEmpty, get, head } from 'lodash';

@Component({
    selector: 'opa-company-report',
    templateUrl: 'report.container.html',
    styleUrls: ['../reports.container.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportContainerComponent implements OnInit, OnDestroy {
    public currentCompanyId$: Observable<string>;
    public currentCompanyId;
    public errors: string[] = [];
    public data: any = {};
    public reportUrl: string = '';
    private reportsState: Observable<any>;
    public selectedReport: IReport;
    private alive: boolean = true;
    private sitesState: Observable<any>;
    private reports: IReport[] = [];
    private reportFilters: IReport[] = [];
    private businessDate: string = '';
    private filtersBusinessDateState: Observable<any>;
    private isSubmitted = false;
    private dataForReport: any = {};

    constructor(
        private store: NgRedux<IReportState>,
        private sites: NgRedux<ISiteState>,
        private reportsService: ReportsService,
        private filterService: FiltersService,
        private route: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
    ) {
        this.reportsState = this.store.select('reports');
        this.sitesState = this.store.select('sites');
        this.currentCompanyId$ = this.store.select(['userSession', 'currentCompanyId']);
        this.filtersBusinessDateState = store.select(['filters', 'businessDate']);
    }

    public ngOnInit() {
        this.reportsService.resetErrors();
        this.listenOnSubmit();

        this.reportsState.pipe(
            takeWhile(() => this.alive),
            tap((reportState) => {
                this.reportUrl = reportState.reportUrl;
                this.reportFilters = reportState.reportFilters;
                this.reports = reportState.reports;
                this.errors = [];
                if (reportState.errors) {
                    this.errors = Object.keys(reportState.errors).map(key => reportState.errors[key]);
                }
            }),
            switchMap(() => this.filtersBusinessDateState)
        ).subscribe((businessDate) => {
            if (
                this.checkTypeReportFiltersSiteId() &&
                this.selectedReport?.id === 'sales_analysis'
            ) {
                this.businessDate = businessDate;

                this.dataForReport['Start_Date'] = this.businessDate;
                this.dataForReport['End_Date'] = this.businessDate;
                this.dataForReport['Calendar_Period'] = false;
                this.dataForReport['Site_Id'] = this.reportFilters[0].value.Site_Id.value.map(item => item.id);
                if (this.selectedReport.custom_report_title) {
                    this.dataForReport['custom_title'] = this.selectedReport.custom_report_title;
                }
                this.reportsService.loadReport = false;
            }
            this.cdRef.detectChanges();
        });

        this.currentCompanyId$.pipe(takeWhile(() => this.alive)).subscribe(companyId => {
            this.currentCompanyId = companyId;
        });

        this.sitesState
            .pipe(takeWhile(() => this.alive))
            .pipe(take(1)).subscribe(({ sites }) => {
                this.sites = sites;
        });

        this.route.params.pipe(filter(({ id }) => id)).subscribe(({ id }) => {
            this.selectedReport = this.reports.find(report => {
                return report.id.replace('/', '_') === id;
            });
            this.cdRef.markForCheck();

            this.reportsService.selectReport({
                report: this.selectedReport,
                filters: this.filterService.setDefaultFilters(this.selectedReport, this.sites)
            });
            window.scrollTo(0, 0);
        });
    }

    private listenOnSubmit(): void {
        this.reportsService.loadReport$.pipe(
            distinctUntilChanged(),
            takeWhile(() => this.alive)
        ).subscribe(value => {
            this.isSubmitted = value;
            if (this.isSubmitted) {
                this.data = {...this.dataForReport};
                this.cdRef.detectChanges();
            }
        });
    }

    public ngOnDestroy() {
        this.alive = false;
    }

    public showSalesAndLaborReport() {
        return this.selectedReport.id === 'sales_and_labor_by_time_visualization' && !isEmpty(this.reportUrl) && this.isSubmitted;
    }

    public showSpeedOfServiceReport() {
        return this.selectedReport.id === 'speed_of_service' && !isEmpty(this.reportUrl);
    }

    public showSalesAnalysisReport() {
        return this.selectedReport.id === 'sales_analysis' && !isEmpty(this.reportUrl) && this.data['Start_Date'];
    }

    public showAgGridReport(name: string): boolean {
        return this.selectedReport.id === name && !isEmpty(this.reportUrl) && this.currentCompanyId;
    }

    public noDataForReport() {
        return !this.showCustom() && !this.showSSRS() && !this.selectedReport;
    }

    public showSSRS() {
        return this.selectedReport.path && this.selectedReport.source === 'ssrs' && this.reportUrl;
    }

    public showCustom() {
        return this.selectedReport.source === 'custom' && this.selectedReport.meta.content;
    }

    private checkTypeReportFiltersSiteId() {
        const siteId = get(head(this.reportFilters), 'value.Site_Id.value', []);
        return (
            siteId !== 'All' &&
            siteId.length > 0
        );
    }
}
