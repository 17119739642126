<h3>
    <b *ngIf="!customTitle; else customTemplateTitle">Sales and Labor by Time Visualization</b>
    <ng-template #customTemplateTitle>
        <b>{{ customTitle }}</b>
    </ng-template>
</h3>
<tools-panel *ngIf="csvData.length" [dateFrom]="dateFrom" [dateTo]="dateTo" [csvData]="csvData"></tools-panel>
<div style="clear: both"></div>
<div class="row" id="ReportCustom">
    <div class="header-box">
        <div id="vis_header">
            <div class="vis_header_item" *ngFor="let item of headerChart">
                <div class="header_label">{{item.label}}</div>
                <div class="header_value">{{item.value}}</div>
            </div>
            <div style="clear: both"></div>
        </div>
    </div>
    <div class="container-box">
        <div id="vega_hours"></div>
    </div>
</div>
