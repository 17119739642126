import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CrossSiteInterceptor implements HttpInterceptor {
    constructor() { }

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        if (req.url.indexOf('localhost') !== -1 && document.cookie.indexOf('SameSite=None') === -1) {
            document.cookie = 'SameSite=None; Secure';
            return next.handle(req);
        }

        if (req.headers.getAll('x-site-ids') !== null && req.headers.getAll('x-site-ids').length > 20) {
            const values = [...req.headers.getAll('x-site-ids')];
            const modified = req.clone({
                setHeaders: { 'x-site-ids': 'unlimitedValue' }
            });
            modified.body['x-site-ids'] = values;
            return next.handle(modified);
        }

        return next.handle(req);
    }
}

