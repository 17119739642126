<div *ngIf="jobStatus && jobStatus.jobStatus && jobStatus.needShow && (jobStatus.jobFinished || jobStatus.jobStarted)" class="etl_statistics">
  <strong>Last Update: </strong>
    <span *ngIf="jobStatus.jobFinished">{{ jobStatus.jobFinished | customDateFormat:'L LT' }},</span>
    <span *ngIf="!jobStatus.jobFinished && jobStatus.jobStarted">{{ jobStatus.jobStarted | customDateFormat:'L LT' }},</span>
    <span>{{ jobStatus.jobStatus | uppercase }}</span>
</div>
<div *ngIf="jobStatus.needShow && (!jobStatus || !jobStatus.jobStatus || (!jobStatus.jobFinished && !jobStatus.jobStarted))"
     class="etl_statistics">
  <strong>Last Update: </strong> <span>N/A</span>
</div>
