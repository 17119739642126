import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IMenuItem } from '../interfaces';

@Component({
    selector: 'xux-menu-item',
    templateUrl: './xux-menu-item.component.html',
    styleUrls: ['./xux-menu-item.component.scss']
})
export class XUXMenuItemComponent implements OnInit {
    @ViewChild('popover', { static: true })
    popover: any;

    @Input()
    depth: Number;

    @Input()
    item: IMenuItem;

    // tslint:disable-next-line:no-output-on-prefix
    @Output()
    onTogglePin = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    closePopover(event: Event) {}

    togglePin(payload: any): void {
        this.onTogglePin.emit({ menuItem: payload.menuItem, event: payload.event });
    }

    clickPin(menuItem: IMenuItem, event: Event) {
        this.onTogglePin.emit({ menuItem, event });
        event.stopPropagation();
        return false; // prevent pin click from hitting anchor
    }

    toggleSubmenu(event: Event) {
        this.item.isOpen = !this.item.isOpen;
        event.stopPropagation();
    }

    /**
     * @param event
     * @description hover over run pop flyout menu
     */
    mouseEnter(event: Event): void {
        const hoverElement = event.target as HTMLInputElement;
        const popover = hoverElement.querySelector('.popover-wrapper') as HTMLElement;
        if (popover) {
            // hide element with opacity so it isn't visible before position is set
            popover.style.opacity = '0';
            popover.style.display = 'block';
            // timeout is needed because the element doesn't have height while hidden
            setTimeout(() => {
                const hoverElementBounds = hoverElement.getBoundingClientRect();
                const popoverHeight = popover.offsetHeight;
                let newPopoverTop = hoverElementBounds.top - popoverHeight * 0.5 + 18;
                if (newPopoverTop + popoverHeight > window.innerHeight) {
                    newPopoverTop = window.innerHeight - popoverHeight;
                }
                // don't position above top of page
                newPopoverTop = Math.max(newPopoverTop, 0);
                popover.style.top = newPopoverTop + 'px';
                popover.style.left = hoverElementBounds.left + hoverElementBounds.width - 20 + 'px';
                // show the popover now that it is positioned correctly
                popover.style.opacity = '1';
            }, 100);
        }
    }

    mouseLeave(event: Event): void {
        if (event) {
            const hoverElement = event.target as HTMLInputElement;
            const popover = hoverElement.querySelector('.popover-wrapper') as HTMLElement;
            if (popover) {
                popover.style.display = 'none';
            }
            event.stopPropagation();
        }
    }
}
