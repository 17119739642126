/**
 * SessionEpics handles async session actions
 */
import { Injectable } from '@angular/core';
import { find, get } from 'lodash';
import { Store } from 'redux';
import { ActionsObservable, combineEpics, Epic, ofType } from 'redux-observable';

import { of, empty } from 'rxjs';
import { map, catchError, filter, mergeMap, flatMap } from 'rxjs/operators';
import { OpraDataService, OpraSessionService, TokenSessionService } from '../../../shared/services';
import { IAction } from '../../../shared/types';
import { IEpicsService } from '../../../shared/types/epics.type';
import { OpraDataActions, PreferencesActions, SessionActions } from '../../actions';
import { IAppState } from '../../reducers/index';
import { IFRAME_MODE } from '../../../routes';

@Injectable()
export class SessionEpics implements IEpicsService {
    constructor(
        private tokenSessionService: TokenSessionService,
        private opraSessionService: OpraSessionService,
        private opraDataService: OpraDataService
    ) {}

    /**
     * Return combined session epics
     * @return { Epic }
     */
    public getEpic(): Epic<any, any> {
        return combineEpics(
            this.restoreToken.bind(this),
            this.login.bind(this),
            this.getOpraToken.bind(this),
            this.removeToken.bind(this),
            this.receiveAccessToken.bind(this),
            this.retrievePermissions.bind(this),
            this.fetchPreferencesOnCompanyChange.bind(this),
            this.retrieveReports.bind(this),
            this.checkUserAuth.bind(this),
            this.setCompany.bind(this)
        );
    }

    public fetchPreferencesOnCompanyChange(action$: ActionsObservable<IAction>, store: Store<IAppState>) {
        return action$.pipe(
            ofType(
                SessionActions.RECEIVE_ACCESS_TOKEN_SUCCESS,
                SessionActions.TOKEN_RETRIEVED,
                OpraDataActions.FETCH_COMPANIES_SUCCESS
            ),
            flatMap(() => {
                const state = store.getState();
                const currentCompanyId = get(state, ['userSession', 'currentCompanyId'], null);
                const companies = get(state, ['userSession', 'companies'], []);
                const currentCompany = find(companies, { id: currentCompanyId });

                if (!currentCompany) {
                    return empty();
                }

                return of(PreferencesActions.fetchPreferencesForCompanyAction());
            })
        );
    }

    private checkUserAuth(action$: ActionsObservable<IAction>) {
        const user = localStorage.getItem('user');
        const token = localStorage.getItem('global/AUTH_KEY');
        return action$.ofType(SessionActions.CHECK_USER_AUTH).pipe(
            mergeMap(() => {
                if (!user || !token) {
                    return of(SessionActions.checkUserAuthFailure());
                }
                return of(SessionActions.checkUserSuccessAction(JSON.parse(user), token));
            })
        );
    }

    private getOpraToken(action$: ActionsObservable<IAction>) {
        return action$.ofType(SessionActions.LOGIN_USER).pipe(
            mergeMap(({ payload }) => {
                return this.opraSessionService.login(payload).pipe(
                    map(servicePayload => {
                        this.tokenSessionService.saveToken(servicePayload);
                        return SessionActions.tokenRetrievedAction(servicePayload.token);
                    }),
                    catchError(errors => of(SessionActions.loginUserErrorAction(errors)))
                );
            })
        );
    }

    private receiveAccessToken(action$: ActionsObservable<IAction>) {
        return action$.ofType(SessionActions.RECEIVE_ACCESS_TOKEN).pipe(
            mergeMap(({ payload }) => {
                return this.opraSessionService.receiveAccessToken(payload).pipe(
                    map(servicePayload => {
                        const externalLogin = (location.hash || '').startsWith(`#/${IFRAME_MODE}`);
                        if (!externalLogin) {
                            localStorage.setItem('global/AUTH_KEY', servicePayload.token);
                        }
                        this.tokenSessionService.saveToken(servicePayload);
                        return SessionActions.receiveAccessTokenSuccessAction(servicePayload.token);
                    }),
                    catchError(errors => of(SessionActions.receiveAccessTokenErrorAction(errors)))
                );
            })
        );
    }

    private setCompany(action$: ActionsObservable<IAction>) {
        return action$.ofType(SessionActions.SET_COMPANY).pipe(
            mergeMap(({ payload }) => {
                if (payload) {
                    return of(SessionActions.setCompanySuccessAction(payload));
                } else {
                    return of(SessionActions.setCompanyFailureAction());
                }
            })
        );
    }

    /**
     * Trying to get token when `RESTORE_TOKEN` action was dispatched
     * If token was found it dispatches `TOKEN_RETRIEVED` action with token data in a payload
     // tslint:disable-next-line:no-redundant-jsdoc
     * @param { ActionsObservable } action$ - Observable for actions
     */
    private restoreToken(action$: ActionsObservable<IAction>) {
        return action$.ofType(SessionActions.RESTORE_TOKEN).pipe(
            map(() => this.tokenSessionService.getToken('AUTH_KEY')),
            filter(token => token !== null),
            mergeMap(tokenPayload => of(SessionActions.tokenRetrievedAction(tokenPayload)))
        );
    }

    /**
     * Trying to get token when `RESTORE_TOKEN` action was dispatched
     * If token was found it dispatches `TOKEN_RETRIEVED` action with token data in a payload
     * @param { ActionsObservable } action$ - Observable for actions
     */
    private login(action$: ActionsObservable<IAction>) {
        return action$.ofType(SessionActions.TOKEN_RETRIEVED).pipe(
            mergeMap((action: IAction) => {
                return this.opraDataService.getUserInfo().pipe(
                    map(user => {
                        localStorage.setItem('user', JSON.stringify(user));
                        return SessionActions.loginUserSuccessAction(user);
                    }),
                    catchError(errors => {
                        console.log(errors);
                        return of(SessionActions.loginUserErrorAction(errors));
                    })
                );
            })
        );
    }

    /**
     * Remove token from session when `LOGOUT_USER` action was dispatched
     * It dispatches `TOKEN_REMOVED` action
     * @param { ActionsObservable } action$ - Observable for actions
     */
    private removeToken(action$: ActionsObservable<IAction>) {
        return action$.ofType(SessionActions.LOGOUT_USER).pipe(
            mergeMap(() => {
                this.tokenSessionService.removeToken();
                localStorage.removeItem('user');
                return of(SessionActions.tokenRemovedAction());
            })
        );
    }

    private retrievePermissions(action$: ActionsObservable<IAction>) {
        return action$
            .ofType(SessionActions.LOGIN_USER_SUCCESS, SessionActions.RECEIVE_ACCESS_TOKEN_SUCCESS)
            .pipe(map(() => OpraDataActions.fetchPermissionsAction()));
    }

    private retrieveReports(action$: ActionsObservable<IAction>) {
        return action$
            .ofType(SessionActions.RECEIVE_ACCESS_TOKEN_SUCCESS, SessionActions.TOKEN_RETRIEVED)
            .pipe(map(() => OpraDataActions.fetchReportsAction()));
    }
}
