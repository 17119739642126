import {
    Component,
    Input,
    Output,
    OnDestroy,
    OnInit,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EmailNotificationServer, HelperService, CustomReportsService } from '../../../../shared/services';
import { PreferencesService } from '../../../../shared/services/preferences/preferences.service';
import { IOrderDetail } from '../../../../shared/types/report';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { get } from 'lodash';
import { environment } from '../../../../../environments/environment';

const {v4: uuidv4} = require('uuid');
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'order-explorer-detail',
    templateUrl: 'order-explorer-detail.component.html',
    styleUrls: [ 'order-explorer-detail.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('FadeRightAnimation', [
            state(
                'in',
                style({
                    opacity: 1,
                    transform: 'translateX(-100%)'
                })
            ),
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateX(-50%)'
                }),
                animate('0.3s ease-out')
            ]),
            transition(
                ':leave',
                animate(
                    '0.3s ease-in',
                    style({
                        opacity: 0,
                        transform: 'translateX(-50%)'
                    })
                )
            )
        ]),
        trigger('SimpleFadeAnimation', [
            state(
                'in',
                style({
                    opacity: 1
                })
            ),
            transition(':enter', [
                style({
                    opacity: 0
                }),
                animate('0.3s ease-out')
            ]),
            transition(
                ':leave',
                animate(
                    '0.15s ease-in',
                    style({
                        opacity: 0
                    })
                )
            )
        ])
    ],
    providers: [ EmailNotificationServer ]
})
export class OrderExplorerDetailComponent implements OnInit, OnDestroy {
    @Input()
    public set currentOrder(currentOrder) {
        this._currentOrder = Array.isArray(currentOrder) ? this.normalizeOrderDetailData(currentOrder) : currentOrder;
        if (this.currentOrder.referenceId) {
            this.generateLinkAndReferencedOrder();
        }
    }

    public get currentOrder(): IOrderDetail {
        return this._currentOrder;
    }

    constructor(
        private formBuilder: FormBuilder,
        private emailNotificationService: EmailNotificationServer,
        private toasterService: ToasterService,
        private helperService: HelperService,
        private reportService: CustomReportsService,
        private preferencesService: PreferencesService,
        private cdRef: ChangeDetectorRef,
    ) {
    }

    @Input() popupIsActive;
    @Output() closePopup = new EventEmitter<boolean>();
    public receiptForm;
    public visibleEmailPanel = false;
    public validPermissions = false;
    public titleForReferencedOrder = '';
    public orderPosition = 0;
    protected defaultEmailState: string;
    private _currentOrder: IOrderDetail;
    private originalOrders: any[] = [];
    private unsubscribe$: Subject<void> = new Subject<void>();

    private static isMobileDevice() {
        return typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
    }

    public ngOnInit() {
        this.defaultEmailState = this.currentOrder.customerEmail !== '(None)' ? this.currentOrder.customerEmail : '';
        this.receiptForm = this.formBuilder.group({
            email: new FormControl(this.defaultEmailState, [
                Validators.required,
                Validators.minLength(8),
                Validators.email
            ])
        });

        this.checkUserPermissionsForLogs();
        this.originalOrders.push(this.currentOrder);
    }

    public ngOnDestroy(): void {
        this.visibleEmailPanel = false;
        this.titleForReferencedOrder = '';

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public currencyFormatter(params, type = 'grid') {
        return this.preferencesService.currencyFormatterForAgGrid(params, type);
    }

    public numberFormatter(val) {
        return this.preferencesService.numberFormatterForAgGrid(val);
    }

    public submitEmail(customerEmail) {
        const data = this.getNormalizeEmailData(customerEmail);
        this.emailNotificationService.sendReceiptForCustomer(data).pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
            response => {
                this.toasterService.pop('success', 'Successfully sent message');
            },
            error => {
                this.toasterService.pop('error', 'Some problems with sending');
            }
        );
    }

    public closeModal() {
        if (this.originalOrders.length !== 0) {
            this.originalOrders = [];
            this.orderPosition = 0;
        }
        this.closePopup.emit(false);
        this.ngOnDestroy();
    }

    public getLogsLink(order) {
        let url = `${environment.logViewerURI}/#/logs?`;
        const params = Object.entries(this.normalizeParamsObjForLogViewer(order));

        params.forEach((item, i) => {
            if (i !== 0) {
                url += `&${item[0]}=${item[1]}`;
            } else {
                url += `${item[0]}=${item[1]}`;
            }
        });

        return url;
    }

    public generateLinkAndReferencedOrder() {
        this.reportService
            .fetchOrderExplorerDetail(
                this.currentOrder.companyId,
                this.currentOrder.siteId,
                this.currentOrder.referenceId,
                this.currentOrder.businessDate
            ).pipe(
            takeUntil(this.unsubscribe$)
        )
            .subscribe((order: any[]) => {
                if (order[0].data && order[0]?.data.length) {
                    const res = order;
                    const payments = order[1].data;
                    const totalPayment =
                        payments.length === 0 ? 0 : payments.reduce((val, item) => val + Number(item.payment_total), 0);
                    res.push(totalPayment);
                    this.originalOrders.push(Array.isArray(res) ? this.normalizeOrderDetailData(res) : res);

                    const orderNumber = order[0]?.data[0]?.order_number;

                    if (this.currentOrder) {
                        this.generateLinkTitleToReferencedOrder(orderNumber);
                    }
                } else {
                    this.toasterService.pop('error', 'Oops... sorry! No details information on this reference order.');
                }
                this.cdRef.detectChanges();
            });
    }

    public openReferencedOrder() {
        ++this.orderPosition;
        this.currentOrder = this.originalOrders[this.orderPosition];
        if (this.currentOrder.referenceId !== null && this.originalOrders.length - 1 <= this.orderPosition) {
            this.titleForReferencedOrder = '';
            this.generateLinkAndReferencedOrder();
        }
    }

    public closeOriginalOrder() {
        this.generateLinkTitleToReferencedOrder(this.currentOrder.orderNumber);
        this.orderPosition--;
        this.currentOrder = this.originalOrders[this.orderPosition];
        this.originalOrders.pop();
    }

    public toggleTooltip(event) {
        if (OrderExplorerDetailComponent.isMobileDevice()) {
            const elem: HTMLElement = event.currentTarget;

            if (elem.classList.contains('is-active')) {
                elem.classList.remove('is-active');
            } else {
                elem.classList.add('is-active');
            }
        }
    }

    public toggleEmailForm() {
        const input: HTMLInputElement = document.querySelector('.email-box__input');
        this.visibleEmailPanel = !this.visibleEmailPanel;
        if (this.visibleEmailPanel) {
            input.focus();
        } else {
            input.blur();
        }
    }

    public generatePDF() {
        const doc = new jsPDF();

        doc.setFontSize(16);
        doc.text('Order Detail', 14, 18);

        doc.setFontSize(9);
        doc.setLineHeightFactor(1.2);
        doc.setTextColor(153, 153, 153);
        doc.text('Store: ' + this.currentOrder.site, 14, 22);
        doc.text('Customer: ' + this.getCustomerName(), 14, 25);

        doc.autoTable(this.getOptionsForTopTable());
        doc.autoTable(this.getOptionsForTableByClass('.order-items', doc));
        doc.autoTable(this.getOptionsForTotalTableByClass('.order-totals', doc));

        if (this.currentOrder?.paymentItems?.length) {
            const title = 'Payments:';
            doc.setTextColor('#333333');
            doc.setFontSize(8);
            doc.setFontStyle('bold');
            doc.text(title.toUpperCase(), 14, doc.autoTable.previous.finalY + 10);

            doc.autoTable({
                ...this.getOptionsForTableByClass('.order-payments-table', doc, 15),
                columnStyles: {4: {halign: 'right'}},
            });
        }

        doc.autoTable(this.getOptionsForTotalTableByClass('.payment-totals', doc));

        doc.save('Order Detail report.pdf');
    }

    private checkUserPermissionsForLogs() {
        this.validPermissions = this.helperService.checkPermission('XLOG', 'Logs.View');
    }

    private getCustomerName() {
        if (this.currentOrder.customerFirstName === '(None)') {
            return '(None)';
        }

        return `${this.currentOrder.customerFirstName} ${this.currentOrder.customerLastName}`;
    }

    private normalizeOrderDetailData(response) {
        if (!response || response === null) {
            return null;
        }

        const self = this;

        const detail = response[0].data[0];
        const orderItems = response[0].data;
        const payments = response[1].data;
        const paymentTotal = response[2];

        return {
            companyId: detail.company_id,
            customerFirstName: detail.customer__first_name,
            customerLastName: detail.customer__last_name,
            customerEmail: detail.customer__email,
            openTime: self.dateAndTimeFormater(detail.order_time),
            orderId: detail.order_id,
            orderNumber: detail.order_number,
            orderType: detail.order_type,
            orderState: detail.order_state,
            closeTime: self.dateAndTimeFormater(detail.close_time),
            site: detail.site || '(None)',
            siteId: detail.site_id,
            storeNumber: detail.store_number,
            items: self.normalizeDetailDataItems(orderItems),
            paymentItems: payments,
            paymentStatus: payments.lenght ? payments[0].payment_status : '(None)',
            subtotal: self.currencyFormatter(detail.item_subtotal !== null ? detail.item_subtotal : '0', 'detail'),
            totalTax: self.currencyFormatter(detail.item_tax_total !== null ? detail.item_tax_total : '0', 'detail'),
            totalDiscount: self.currencyFormatter(
                detail.item_discount_total !== null ? detail.item_discount_total : '0', 'detail'
            ),
            tips: self.currencyFormatter(detail.item_tips_total !== null
                ? (detail.order_type === 'refund' ? '-' + detail.item_tips_total
                : detail.item_tips_total) : '0'),
            total: self.currencyFormatter(detail.total !== null ? detail.total : '0', 'detail'),
            paymentTotal: self.currencyFormatter(paymentTotal !== null ? paymentTotal : '0', detail.order_type),
            referenceId: detail.reference_id,
            businessDate: detail.business_date
        };
    }

    private generateLinkTitleToReferencedOrder(orderNumber) {
        this.titleForReferencedOrder =
            this.currentOrder.orderType === 'refund'
                ? `Refunded from Order #${orderNumber}`
                : `Original Order #${orderNumber}`;
    }

    private getNormalizeEmailData(email) {
        const tempPayload = {
            _id: this.currentOrder.orderId,
            state: this.currentOrder.orderState,
            payment_status: this.currentOrder.paymentStatus,
            timestamp: moment()
                .utc()
                .format(),
            notification_status: 'send-receipt',
            origin: 'reporting',
            site_id: this.currentOrder.siteId,
            company_id: this.currentOrder.companyId,
            store_number: this.currentOrder.storeNumber,
            order_number: this.currentOrder.orderNumber,
            email: email
        };

        return [
            {
                platform: 'reporting',
                platform_version: '1.0.0',
                msg_type: 'moapi.notification',
                msg_id: uuidv4(),
                content_type: 'application/json',
                company_id: this.currentOrder.companyId,
                site_id: this.currentOrder.siteId,
                payload: JSON.stringify(tempPayload)
            }
        ];
    }

    private normalizeParamsObjForLogViewer(order: IOrderDetail) {
        const dateFrom =
            order.openTime !== '(None)' || ''
                ? moment(order.openTime, 'MM/DD/YYYY HH:mm A')
                    .add(-1, 'days')
                    .format('YYYY-MM-DD')
                : moment()
                    .add(-1, 'days')
                    .format('YYYY-MM-DD');
        const dateTo =
            order.closeTime !== '(None)' || ''
                ? moment(order.closeTime, 'MM/DD/YYYY HH:mm A')
                    .add(1, 'days')
                    .format('YYYY-MM-DD')
                : moment()
                    .add(1, 'days')
                    .format('YYYY-MM-DD');
        const params = {
            dateFrom: `${dateFrom}T00:00:00.000Z`,
            dateTo: `${dateTo}T23:59:59.999Z`,
            sort: encodeURIComponent('created_at desc'),
            query_string: `"${order.orderId}"`,
            page: '1'
        };

        return params;
    }

    private normalizeDetailDataItems(array) {
        array.sort((a, b) => {
            if (+a.path[0] < b.path[0]) {
                return -1;
            } else if (+a.path[0] > b.path[0]) {
                return 1;
            } else {
                if (a.path.length < b.path.length) {
                    return -1;
                } else if (a.path.length > b.path.length) {
                    return 1;
                } else {
                    return a.path[a.path.length - 1] > b.path[b.path.length - 1] ? 1 : -1;
                }
            }
        });

        return array;
    }

    private dateAndTimeFormater(value) {
        if (value === undefined || value === null) {
            return '';
        } else if (value === '(None)') {
            return '(None)';
        } else {
            return this.preferencesService.dateAndTimeFormatter(value);
        }
    }

    private getOptionsForTopTable(): UserOptions {
        return {
            html: '.custom-modal__top-section',
            startY: 30,
            margin: 0,
            theme: 'plain',
            styles: {
                overflow: 'ellipsize',
                cellWidth: 'wrap',
                fontSize: 8,
                textColor: '#333333',
                fillColor: '#f7f7f7',
                fontStyle: 'bold'
            },
            willDrawCell: (data) => {
                if (data.row.section === 'body') {
                    data.cell.text[0] = data.cell.text[0].toUpperCase();
                }
            },
        };
    }

    private getOptionsForTableByClass(selector, doc, verticalShift = 0): UserOptions {
        return {
            html: selector,
            startY: verticalShift ? doc.autoTable.previous.finalY + verticalShift : doc.autoTable.previous.finalY,
            margin: 0,
            theme: 'plain',
            // Default for all columns
            styles: {
                overflow: 'ellipsize',
                cellWidth: 'wrap',
                fontSize: 6,
            },
            columnStyles: {0: {halign: 'left'}},
            bodyStyles: {
                fillColor: '#f7f7f7',
                halign: selector === '.order-items' ? 'right' : 'left',
            },
            alternateRowStyles: {
                fillColor: '#ffffff',

            },
            willDrawCell: (data) => {
                if (data.row.section === 'body') {
                    const className = get(data, 'cell.raw.className', '');
                    if (className.indexOf('has-indent-') > 0) {
                        const indent = className.substring(22);
                        if (indent > 1) {
                            data.cell.text[0] = Array(Number(indent) * 2).fill('\xa0').join('') + data.cell.text[0];
                        }
                    }

                    if (data.cell.text[0].match(/[(|-]/gm)) {
                        doc.setTextColor(231, 76, 60);
                    }
                }
            },
        };
    }

    private getOptionsForTotalTableByClass(selector, doc): UserOptions {
        return {
            html: selector,
            startY: doc.autoTable.previous.finalY,
            margin: 0,
            styles: {
                overflow: 'ellipsize',
                cellWidth: 'wrap',
                fontSize: 8,
                fontStyle: 'bold',
                fillColor: '#e1e1e1',
            },
            alternateRowStyles: {
                fillColor: '#e1e1e1',
            },
            columnStyles: {1: {halign: 'right'}},
            willDrawCell: (data) => {
                doc.setLineWidth(2);
                doc.setDrawColor('#cccccc');
                doc.line(data.cursor.x, data.cursor.y, data.cursor.x + data.cell.width, data.cursor.y);
                doc.setDrawColor('#f7f7f7');
                doc.line(data.cursor.x, data.cursor.y + data.row.height,
                    data.cursor.x + data.cell.width, data.cursor.y + data.row.height);

                if (data.row.section === 'body') {
                    if (data.cell.text[0].match(/[(|-]/gm)) {
                        doc.setTextColor(231, 76, 60);
                    }
                }
            },
        };
    }
}
