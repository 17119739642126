/**
 * Data management components module
 */

// modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';
import { BsModalModule } from 'ng2-bs3-modal';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainHeaderComponent } from './app/main-header/main-header.component';
import { ModalWrapperComponent } from './modal-wrapper/modal-wrapper.component';
import { DMGenericModalComponent } from './generic-modal/generic-modal.component';
import { formComponents, ValidationService } from './form/index';
import { ToolsPanelComponent } from './tools_panel/tools.panel.component';
import { DateFormatPipe } from '../pipes/date-format.pipe';

@NgModule({
    imports: [
        AgGridModule.withComponents([]),
        CommonModule,
        RouterModule,
        BsModalModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [ValidationService, CurrencyPipe, DatePipe],
    declarations: [
        MainHeaderComponent,
        ModalWrapperComponent,
        DMGenericModalComponent,
        ...formComponents,
        ToolsPanelComponent,
        DateFormatPipe,
    ],
    exports: [
        MainHeaderComponent,
        BsModalModule,
        ReactiveFormsModule,
        AgGridModule,
        ModalWrapperComponent,
        DMGenericModalComponent,
        ...formComponents,
        ToolsPanelComponent,
        DateFormatPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DmComponentsModule {}
