/**
 * Defines the UI reducer.
 */
import { IAction, IMenuItem, IDropdownMenuOption } from '../../../shared/types';
import { UPDATE_LOCATION } from '@angular-redux/router';
import { get } from 'lodash';
import { IErrorPageType } from '../../../shared/types/error.type';
import { IUIListState, LIST_INITIAL_STATE, uiListReducer } from './ui.list.reducer';
import { applySubReducer } from '../base-reducer';
import {
  UiActions,
} from '../../actions';


export interface IUiState {
  loader: boolean;
  pendingRequests: number;
  isShowStoresModal?: boolean;
  storesModalOptions?: any;
  menuItems?: IMenuItem[];
  selectedMenuItem?: IMenuItem;
  genericModalVisible?: boolean;

  dropdownMenuOptions?: IDropdownMenuOption[];
  dropdownMenuPositionX?: number;
  dropdownMenuPositionY?: number;
  dropdownMenuVisible?: boolean;
  errorPage?: IErrorPageType;
  list?: IUIListState;
}

const INITIAL_STATE: IUiState = {
  loader: false,
  pendingRequests: 0,
  isShowStoresModal: false,
  storesModalOptions: {
    forceSelect: false,
    masterList: true,
  },
  menuItems: null,
  selectedMenuItem: null,

  dropdownMenuOptions: [],
  dropdownMenuPositionX: 0,
  dropdownMenuPositionY: 0,
  dropdownMenuVisible: false,
  errorPage: {},
  list: LIST_INITIAL_STATE,
};

export function uiReducer(inputState: IUiState = INITIAL_STATE, action: IAction) {
  const state = applySubReducer(inputState, 'list', uiListReducer, action);

  const { payload } = action;
  switch (action.type) {

    // Store selector Modal
    case UiActions.SHOW_STORES_MODAL:
      return Object.assign({}, state, {
        isShowStoresModal: true,
        storesModalOptions: payload.options,
      });

    case UiActions.HIDE_STORES_MODAL:
      return Object.assign({}, state, {
        isShowStoresModal: false,
        storesModalOptions: {
          forceSelect: false,
          masterList: true,
        },
      });

    case UiActions.SHOW_SITES_MODAL:
      return Object.assign({}, state, {
        showSitesModal: true,
      });

    case UiActions.HIDE_SITES_MODAL:
      return Object.assign({}, state, {
        showSitesModal: false,
      });

    case UPDATE_LOCATION:
      return Object.assign({}, state);

    case UiActions.SELECT_SIDEBAR_ITEM:
      return Object.assign({}, state, { selectedMenuItem: payload.route });

    case UiActions.SHOW_GENERIC_MODAL:
      return Object.assign({}, state, {
        genericModalVisible: true,
        genericModalResult: null,
        genericModalOptions: Object.assign({ modalResult: null, value: null }, payload),
      });

    case UiActions.HIDE_GENERIC_MODAL:
      return Object.assign({}, state, {
        genericModalVisible: false,
        genericModalResult: payload,
      });

    case UiActions.SHOW_DROPDOWN_MENU:
      return Object.assign({}, state, {
        dropdownMenuVisible: true,
        dropdownMenuResult: null,
        dropdownMenuOptions: payload.options,
        dropdownMenuPositionX: get(payload, 'x', state.dropdownMenuPositionX),
        dropdownMenuPositionY: get(payload, 'y', state.dropdownMenuPositionY),
      });


    case UiActions.HIDE_DROPDOWN_MENU:
      return Object.assign({}, state, {
        dropdownMenuVisible: false,
        dropdownMenuResult: payload,
      });

    case UiActions.UPDATE_LOADER:
      return Object.assign({}, state, { loader: payload.loader });

    case UiActions.UPDATE_CONTENT_LOADER:
      return Object.assign({}, state, { contentLoader: payload.contentLoader });

    case UiActions.SHOW_ERROR_PAGE_ACTION:
      return Object.assign({}, state, {
        errorPage: payload.errorPage,
      });

    case UiActions.UPDATE_SIDEBAR_ITEMS:
      return Object.assign({}, state, {
        menuItems: payload.items,
      });
    case UiActions.STORE_ENTITY_LIST_FILTER_TEXT:
      return Object.assign({}, state, {
        listFilterText: payload.text,
        listFilterEntityType: payload.entity_type,
      });

    default:
      return state;
  }
}
