import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
/**
 * OpraSessionService provides API for OPRA backend sign-in.
 */
import { Injectable } from '@angular/core';

import * as config from '../../../../config';
import { PortalHttpClient } from '../http-client';

@Injectable()
export class OpraSessionService {
    public config = config;
    public static parseToken(token: string) {
        return {
            token,
            user: JSON.parse(atob(token.split('.')[1]))
        };
    }

    constructor(private http: PortalHttpClient) {}

    public login(payload: any): Observable<any> {
        return this.http.post('/token', { user: payload.user, password: payload.password }).pipe(
            map((token: string) => {
                return OpraSessionService.parseToken(token);
            }),
            map(({ token }) => ({ token })),
            catchError(error => {
                console.log(error);
                return observableThrowError(
                    error.errors.map(err => {
                        return Error.call(err, err.meta.message);
                    })
                );
            })
        );
    }

    public receiveAccessToken(payload: any): Observable<any> {
        return this.http.post('/me/access-token', { company_id: payload }).pipe(
            map((token: string) => OpraSessionService.parseToken(token)),
            map(({ token }) => ({ token })),
            catchError(error => observableThrowError(error.errors.map(err => new Error(err))))
        );
    }
}
