<header class="navbar">
  <!-- <div class="navbar-header">
    <a href="#" class="navbar-brand">
      <img  class="mt-15" src="{{'assets/img/xenial-logo-small.png' | dmSourcePath}}">
    </a>
    <a routerLink="{{infoRoute}}" >
      <p class="navbar-text">{{buildVersion}}</p>
    </a>
  </div> -->
  <div class="">
    <ul class="nav navbar-nav navbar-right" [hidden]="!(user$ | async)">
      <li class="dropdown">
        <a href="#" class="dropdown-toggle user-name" data-toggle="dropdown">
          {{user?.name}}
          <span class="caret"></span>
        </a>
        <ul class="dropdown-menu">
          <li class="e2e-company-check" *ngIf="companies.length > 1 && selectedCompany">
            <a (click)="showCompanySelector()">
              Company :  {{selectedCompany?.name}}
            </a>
          </li>
          <li class="dropdown-header e2e-company-check" *ngIf="companies.length === 1">
            Company :{{selectedCompany?.name}}
          </li>
          <li class="divider"></li>
          <li class="logout" *ngIf="showEditReports">
            <a style="cursor: pointer;" (click)="editReports()">Edit Reports</a>
          </li>
          <li *ngIf="showEditReports" class="divider"></li>
          <li [hidden]="!selectedCompany" class="logout" (click)="logout()">
            <a href>
              <i class="glyphicon glyphicon-log-out"></i>
              Logout
            </a>
          </li>
        </ul>
      </li>

    </ul>
  </div>
</header>
