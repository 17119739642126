<ng-container *ngIf="item.sub && !item.hidden">
  <ng-container *ngIf="depth < 1 && item.sub.length > 1">
    <!-- first level expands on desktop and mobile -->
    <div class="item-title chevron" [ngClass]="{ 'chevron-toggled': item.isOpen }" (click)="toggleSubmenu($event)">
      {{item.title}}
    </div>
    <ul class="depth-{{depth+1}}" *ngIf="item.isOpen">
      <li *ngFor="let subItem of item.sub">
        <xux-menu-item [item]="subItem" [depth]="(depth+1)" (onTogglePin)="togglePin($event)"></xux-menu-item>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="depth>=1 && item.sub.length > 1">
    <!-- second level and above will use popover on desktop... -->
    <div class="item-title chevron desktop-show" (mouseleave)="mouseLeave($event);" (mouseenter)="mouseEnter($event);">
      <span (click)="clickPin(item, $event);" class="pin" [ngClass]="{'pinned': item.pinned}">
          <i class="icon-pin"></i>
        </span> {{item.title}}
      <ul class="popover-wrapper popover right depth-{{depth+1}}" #popover>
        <li *ngFor="let subItem of item.sub" routerLinkActive="active">
          <xux-menu-item [item]="subItem" [depth]="(depth+1)"
          (onTogglePin)="togglePin($event)"></xux-menu-item>
        </li>
      </ul>
    </div>
    <!-- ...and expand on mobile -->
    <div class="item-title chevron mobile-show" [ngClass]="{ 'chevron-toggled': item.isOpen }" (click)="toggleSubmenu($event)">
      <span (click)="clickPin(item, $event);" class="pin" [ngClass]="{'pinned': item.pinned}">
          <i class="icon-pin"></i>
        </span> {{item.title}}
    </div>
    <ul class="mobile-show depth-{{depth+1}}" *ngIf="item.isOpen">
      <li *ngFor="let subItem of item.sub" routerLinkActive="active">
        <xux-menu-item [item]="subItem" [depth]="(depth+1)"
        (onTogglePin)="togglePin($event)"></xux-menu-item>
      </li>
    </ul>
  </ng-container>
    <ng-container *ngIf="item.sub.length == 1">
        <a class="item-title is-top-link" [routerLink]="item.sub[0].routing" routerLinkActive="active" (click)="closePopover($event)">
            {{item.title}}
        </a>
    </ng-container>
</ng-container>

<div *ngIf="!item.sub" class="item-link">
  <a [routerLink]="item.routing" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: item.exactActive }" (click)="closePopover($event)">
    <!-- <span *ngIf="" (click)="clickPin(item, $event);" class="pin" [ngClass]="{'pinned': item.pinned}">
      <i class="icon-pin"></i>
    </span> -->
    {{item.title}}<i *ngIf="item.icon" class="menu-item-icon glyphicon" [ngClass]="item.icon"></i>
  </a>
</div>
