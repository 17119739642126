<div id="sidebar">
  <div class="search-nav-bar">
    <div class="input-group">
      <span class="icon-search" *ngIf="!searchText"></span>
      <i class="icon-close" *ngIf="searchText" (click)="searchText=''"></i>
      <input type="search" class="form-control" [(ngModel)]="searchText" placeholder="Search Reports">
    </div>
  </div>
  <div class="pinned-nav-bar" *ngIf="!searchText && pinnedItems.length > 1">
    <ul class="depth-1">
      <li *ngFor="let item of (pinnedItems)">
        <xux-menu-item [item]="item" [depth]="1" (onTogglePin)="togglePin($event)"></xux-menu-item>
      </li>
    </ul>
  </div>
  <nav class="nav">
    <ul class="depth-0">
      <ng-template ngFor let-item [ngForOf]="(managedItems | searchMenuItems:searchText)">
        <li *ngIf="!item.hidden">
          <xux-menu-item [item]="item" [depth]="0" (onTogglePin)="togglePin($event)"></xux-menu-item>
        </li>
      </ng-template>
    </ul>
  </nav>
</div>
