import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment/min/locales';
import { PreferencesService } from '../preferences/preferences.service';
import * as currency from 'currency.js';
import { some, isArray, isNull, isEmpty, isNumber } from 'lodash';
import { Column, RowNode, GridOptions } from '@ag-grid-enterprise/all-modules';

@Injectable({
    providedIn: 'root',
})
export class AgGridReportsService {
    private totalOrdersAvg = 0;
    private totalGuestsAvg = 0;
    private generatedPopupContent = '';
    private agGrid = null;
    constructor(private preferencesService: PreferencesService) {
    }
    static isDateTypeOfData(value: string): boolean {
        const formats = [moment.ISO_8601, 'MM-DD-YYYYHH*mm*ss'];

        return moment(value, formats, true).isValid();
    }

    public customNumberComparator(valueA, valueB) {
        if ((valueA === '(None)' && valueB === '(None)') || (valueA === null && valueB === null)) {
            return 0;
        }
        if (valueA === '(None)' || valueA === null) {
            return -1;
        }
        if (valueB === '(None)' || valueB === null) {
            return 1;
        }

        return AgGridReportsService.isDateTypeOfData(valueA)
            ? moment.utc(valueA).diff(moment.utc(valueB))
            : Number(valueA) - Number(valueB);
    }

    public customStringComparator(valueA, valueB) {
        if ((valueA === '(None)' && valueB === '(None)') || (valueA === null && valueB === null)) {
            return 0;
        }
        if (valueA === '(None)' || valueA === null) {
            return -1;
        }
        if (valueB === '(None)' || valueB === null) {
            return 1;
        }

        return valueA > valueB ? 1 : valueA === valueB ? 0 : -1;
    }

    public numberFormatter(val) {
        return this.preferencesService.formatToNumber(val);
    }

    public currencyFormatter(params, type = 'grid') {
        return this.preferencesService.currencyFormatterForAgGrid(params, type);
    }

    public customerNameFormatter(params) {
        let customerName;
        if (params.data && params.data.customer__first_name === '(None)') {
            customerName = params.data.customer__last_name === '(None)' ? '(None)' : params.data.customer__last_name;
        } else if (params.data) {
            customerName = params.data.customer__first_name;
            customerName =
                params.data.customer__last_name === '(None)'
                    ? customerName
                    : `${customerName} ${params.data.customer__last_name}`;
        }
        return customerName;
    }

    public dateCellRenderer = (params) => {
        if (params.value === undefined || params.value === null) {
            return '';
        } else if (params.value === '(None)') {
            return '(None)';
        } else {
            return this.preferencesService.dateFormatter(params.value);
        }
    }

    public dateAndTimeCellRenderer = (params) => {
        if (params.value === undefined || params.value === null) {
            return '';
        } else if (params.value === '(None)') {
            return '(None)';
        } else {
            return this.preferencesService.dateAndTimeFormatter(params.value);
        }
    }

    public customFooterValueGetter(params) {
        if (params.value) {
            const value = AgGridReportsService.isDateTypeOfData(params.value)
                ? moment(params.value).format('L')
                : params.value;
            return 'Total for ' + value;
        } else {
            return 'Total';
        }
    }

    public customRowStyles = (params) => {
        if (isNumber(+params?.node?.id)) {
            const rowNode = params.api.getRowNode(params.node.id);
            const nextRowNode = params.api.getRowNode(+params.node.id + 1);
            const prevRowNode = params.api.getRowNode(+params.node.id - 1);

            if (this.isNearbyRowsWithSameValuesForColumn(rowNode, nextRowNode)) {
                return { background: 'rgba(100, 217, 65, 0.2)' };
            }

            if (this.isNearbyRowsWithSameValuesForColumn(rowNode, prevRowNode)) {
                return {
                    background: 'rgba(100, 217, 65, 0.2)',
                    'border-bottom': '1px solid rgba(0, 0, 0, 0.5)'
                };
            }
        }

        return {};
    }

    public customSumAggFunc = (arr) => {
        if (arr?.values && arr?.values?.length) {
            const tempSum = arr.values.map(item => +this.preferencesService.correctRounding(item));

            return this.sum(tempSum) === 0 ? '0.00' : this.sum(tempSum);
        } else if (arr?.length) {
            const tempSum = arr.map(item => +this.preferencesService.correctRounding(item));

            return this.sum(tempSum) === 0 ? '0.00' : this.sum(tempSum);
        } else {
            return '0.00';
        }
    }

    public customAvgAggFunction = (params) => {
        let result = 0;

        if (params.colDef.field === 'orders_avg') {
            if (params.values.length && params.rowNode.aggData) {
                result = +params.rowNode.aggData['net_sales'] === 0 ? 0
                    : +params.rowNode.aggData['orders'] === 0 ? 0
                        : this.preferencesService.correctRounding(+params.rowNode.aggData['net_sales'])
                        / this.preferencesService.correctRounding(+params.rowNode.aggData['orders']);

                if (params.rowNode.level === -1 && this.totalOrdersAvg === 0) {
                    this.totalOrdersAvg = result;
                }
            }
        } else if (params.colDef.field === 'guest_avg') {
            if (params.values.length && params.rowNode.aggData) {
                result = +params.rowNode.aggData['net_sales'] === 0 ? 0
                    : +params.rowNode.aggData['guests'] === 0 ? 0
                        : this.preferencesService.correctRounding(+params.rowNode.aggData['net_sales'])
                        / this.preferencesService.correctRounding(+params.rowNode.aggData['guests']);

                if (params.rowNode.level === -1 && this.totalGuestsAvg === 0) {
                    this.totalGuestsAvg = result;
                }
            }
        }
        return result;
    }

    public getTotalOrdersAvg() {
        return this.totalOrdersAvg;
    }

    public getTotalGuestsAvg() {
        return this.totalGuestsAvg;
    }

    public areEqualDates(dates: any[]): boolean {
        if (some(dates, '(None)')) {
            return false;
        }
        return moment(dates[0]).isSame(dates[1], 'day');
    }

    public generateDisplayConflictsPopupContent(options): void {
        const { filters, count } = options;
        this.agGrid = options.api;
        let columns = '';
        const length = filters.length;
        switch (length) {
            case 1:
                columns = `${filters[0]} ` + ' column';
                break;
            case 2:
                columns =  `${filters[0]}, ${filters[1]} ` + ' columns';
                break;
            default:
                columns = `${filters[0]}, ${filters[1]} `
                    + ` and +${filters.length - 2} other columns`;
                break;
        }

        const content = `<p>You have a column filter applied to:</p>
            <p>${columns}.</p>
            <p>This results in ${count} orders that are not currently shown.</p>`;
        this.generatedPopupContent = content;
    }

    public resetFilters(): void {
        this.agGrid.api.setFilterModel(null);
    }

    public getGeneratedPopupContent() {
        return this.generatedPopupContent;
    }

    public isDateValueInField(item, value): boolean {
        return isArray(item?.userProvidedColDef?.cellClass) && item?.userProvidedColDef?.cellClass[0].indexOf('custom-date') >= 0 &&
            moment(value).format() !== 'Invalid date';
    }

    public prepareCurrencyField(field: string, isRefund = false): string {
        if (isNull(field) || isEmpty(field)) {
            return '0.0000';
        }
        const index = field.indexOf('.');
        if (!isRefund) {
            return  field.slice(0, index + 4);
        } else {
            const value = `${Math.abs(+field) * -1}`;
            return value.slice(0, index + 4);
        }
    }

    public exportReportToCSV(gridOptions: GridOptions, reportTitle: string = 'Report') {
        const colsNames = this.getExportColumnNames(gridOptions);

        const params = {
            allColumns: false,
            columnGroups: false,
            onlySelected: false,
            skipFooters: true,
            skipGroups: true,
            skipHeader: false,
            skipPinnedBottom: true,
            skipPinnedTop: true,
            suppressQuotes: true,
            fileName: `${reportTitle}.csv`,
            columnSeparator: ',',
            columnKeys: colsNames,
            processHeaderCallback: function(values) {
                return '"' + values.column.getColDef().headerName + '"';
            }
        };

        gridOptions.api.exportDataAsCsv(params);
    }

    public exportReportToXLS(gridOptions: GridOptions, reportTitle: string = 'Report') {
        const colsNames = this.getExportColumnNames(gridOptions);

        const params = {
            allColumns: false,
            columnGroups: false,
            onlySelected: false,
            skipFooters: true,
            skipGroups: true,
            skipHeader: false,
            skipPinnedBottom: true,
            skipPinnedTop: true,
            suppressQuotes: true,
            fileName: `${reportTitle}.xls`,
            sheetName: name,
            columnKeys: colsNames
        };

        gridOptions.api.exportDataAsExcel(params);
    }

    public addCustomBottomLabel(name: string, rowData: RowNode[]): void {
        const curElem = [...Array.from(document.querySelectorAll<HTMLElement>('.ag-custom-label'))];

        if (curElem) {
            curElem.forEach((item, index) => {
                if (index === 0) {
                    item.hidden = true;
                } else {
                    item.remove();
                }
            });
        }

        if (!rowData.length) {
            return;
        }

        setTimeout(() => {
            const tempElem: HTMLElement = document.querySelector('.ag-center-cols-clipper');
            const heightElem = tempElem.style.height.slice(0, -2);
            if (curElem.length === 0) {
                const elem = document.querySelector('.ag-body-viewport');
                const label = document.createElement('span');

                label.style.top = +heightElem - 28 + 'px';
                label.classList.add('ag-custom-label');
                label.innerHTML = name;

                elem.appendChild(label);
            } else {
                curElem[0].hidden = false;
                curElem[0].style.top = +heightElem - 25 + 'px';
            }
        }, 100);
    }

    private sum(array: number[]): number {
        return array.reduce((a, b) => currency(a, {precision: 3}).add(b).value, 0);
    }

    private isNearbyRowsWithSameValuesForColumn(firstRow, secondRow, columnName: string = 'order_number'): boolean {
        if (
            firstRow?.data[columnName] === secondRow?.data[columnName] &&
            (firstRow?.childIndex === secondRow?.childIndex + 1 || firstRow?.childIndex === secondRow?.childIndex - 1)
        ) {
            return true;
        }
        return false;
    }

    private getExportColumnNames(gridOptions: GridOptions): string[] {
        const columnsToShow = [];
        const allColumns: Column[] = gridOptions.columnApi.getAllColumns();
        for (let i = 0; i < allColumns.length; i++) {
            if (allColumns[i].isVisible()) {
                columnsToShow.push(allColumns[i].getColId());
            }
        }

        return columnsToShow;
    }
}
