import { HttpClient } from '@angular/common/http';
import { LoginService } from './login';
import { SidebarService } from './sidebar';
import { CustomHttpClient, opraHttpFactory } from './http-client/http-client.service';
import { PortalHttpClient, portalHttpFactory } from './http-client/portal-http-client.service';
import { LocalStorageService } from './local-storage/local-storage.service';
import { TokenSessionService, OpraSessionService, PermissionService } from './session';
import { OpraDataService } from './opra-data/opra.data-service';
import { HelperService, ErrorHandlerService } from './helper';
import { SharedService } from './shared-service';
import { FiltersService } from './filters';
import { PostMessageService } from './post-message';
import { HttpCancelService } from './http-client';
import { WINDOW } from '@services/window';

export const UTILITY_SERVICES: any[] = [
    OpraDataService,
    OpraSessionService,
    PermissionService,
    LocalStorageService,
    TokenSessionService,
    LoginService,
    SidebarService,
    FiltersService,
    HelperService,
    ErrorHandlerService,
    SharedService,
    PostMessageService,
    HttpCancelService,
    {
        provide: CustomHttpClient,
        useFactory: opraHttpFactory,
        deps: [HttpClient, WINDOW, HttpCancelService]
    },
    {
        provide: PortalHttpClient,
        useFactory: portalHttpFactory,
        deps: [HttpClient, HttpCancelService]
    }
];
