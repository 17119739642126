/**
 * ExpiringStorageService provides the ability to store local data for a set amount of time
 * Uses localForage library to store in indexedDB when available
 */
import { Injectable } from '@angular/core';
import * as localforage from 'localforage';
@Injectable()
export class LocalDbStorageService {
    public namespace: string = 'rpt_';
    /**
     * Storage getter.
     * @param {string} key - property name.
     * @return Parsed data.
     */
    public getItem(key: string) {
        return localforage
            .getItem(this.namespace + key)
            .then((wrapperObject: any) => {
                if (wrapperObject) {
                    return wrapperObject.value;
                } else {
                    return undefined;
                }
            })
            .catch(err => {
                return undefined;
            });
    }
    /**
     * Storage setter.
     * @param {string} key - property name.
     * @param {string} value - property value.
     * @return Parsed data.
     */
    public setItem(key: string, value) {
        const wrapperObject = {
            value
        };
        return localforage
            .setItem(this.namespace + key, wrapperObject)
            .then(setResult => {
                return setResult.value;
            })
            .catch(err => {
                return undefined;
            });
    }
    /**
     * Remove item from storage by property name.
     * @param {string} key - property name.
     */
    public removeItem(key: string) {
        return localforage
            .removeItem(this.namespace + key)
            .then(() => {
                return true;
            })
            .catch(err => {
                return false;
            });
    }
}
