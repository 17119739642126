<div class="burger-menu" (click)="toggleSlide($event)">
  <span class="icon-pancake"></span>
</div>
<div class='bread-crumb-trail'>
  <ol class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs">
      <a [routerLink]="[breadcrumb.route]" (click)="backToReports()" *ngIf="!breadcrumb.reportLink">
        {{ breadcrumb.title }}
      </a>
      <a [routerLink]="[breadcrumb.route]" (click)="refreshReport()" *ngIf="breadcrumb.reportLink">
        {{ breadcrumb.title }}
      </a>
    </li>
  </ol>
  <div class="dm-menu" *ngIf="!(isIframeMode$ | async)">
    <dm-main-header></dm-main-header>
  </div>
</div>
