import { IAction, INotificationItem } from '../../../shared/types';
import { generateId } from '../../../shared/utils/';
import { get } from 'lodash';

import {
  SessionActions,
  PreferencesActions,
  OpraDataActions,
  UiActions,
} from '../../actions';

export interface INotificationsState {
  notifications: INotificationItem[];
  latestNotification?: INotificationItem;
}

const INITIAL_STATE: INotificationsState = {
  notifications: [],
};

const getErrorTitle = (payload) => {
  return get(payload, 'errors.title') ||
    get(payload, 'errors.code') ||
    get(payload, 'errors.meta.code') ||
    get(payload, 'errors.status') || 'UNEXPECTED ERROR';
};

const getErrorBody = (payload) => {
  if (get(payload, 'errors.title') === get(payload, 'errors.meta.message')) {
    return '';
  }

  return get(payload, 'errors.message') ||
    get(payload, 'errors.meta.message') ||
    get(payload, 'errors.statusText') || 'UNEXPECTED ERROR';
};

export const prepareNotificationErrors = (payload) => {
  return {
    id: get(payload, 'errors.id') || generateId(),
    title: getErrorTitle(payload),
    body: getErrorBody(payload),
    entityType: get(payload, 'entity_type'),
    type: 'error',
  };
};

export const prepareNotificationSuccess = (payload) => {
  return {
    id: generateId(),
    title: get(payload, 'title') || 'SUCCESS',
    body: get(payload, 'message') || 'Action was successfully done!',
    entityType: get(payload, 'entity_type'),
    type: 'success',
  };
};

export function notificationsReducer(state: INotificationsState = INITIAL_STATE, action: IAction) {
  const { payload } = action;

  const stateWithNewNotification = (notification): INotificationsState => {
    return Object.assign({}, state, {
      notifications: [...state.notifications, notification],
      latestNotification: notification,
    });
  };

  switch (action.type) {
    // Saving requests notifications to the store
    case PreferencesActions.FETCH_PREFERENCES_FOR_COMPANY_ERROR:
    case PreferencesActions.FETCH_PREFERENCES_FOR_SITE_ERROR:
    case PreferencesActions.UPDATE_PREFERENCES_FOR_COMPANY_ERROR:
    case PreferencesActions.UPDATE_PREFERENCES_FOR_SITE_ERROR:
    case SessionActions.LOGIN_USER_ERROR:
    case SessionActions.RECEIVE_ACCESS_TOKEN_ERROR:
    case OpraDataActions.FETCH_COMPANIES_ERROR:
    case UiActions.GENERIC_ERROR_ACTION:
    case 'SEND_HTTP_REQUEST_ERROR':
      const errorNotification = prepareNotificationErrors(payload);
      return stateWithNewNotification(errorNotification);

    case PreferencesActions.UPDATE_PREFERENCES_FOR_COMPANY_SUCCESS:
      const successNotificationForCompany = prepareNotificationSuccess(
        {
          message: 'Company preferences were successfully updated!',
        }
      );
      return stateWithNewNotification(successNotificationForCompany);
    case PreferencesActions.UPDATE_PREFERENCES_FOR_SITE_SUCCESS:
      const successNotificationForSite = prepareNotificationSuccess(
        {
          message: 'Site preferences were successfully updated!',
        });
      return stateWithNewNotification(successNotificationForSite);
    case UiActions.GENERIC_SUCCESS_ACTION:
      const notification = prepareNotificationSuccess(
        Object.assign({ message: 'success' }, payload));
      return stateWithNewNotification(notification);

    case UiActions.GENERIC_TOASTER_ACTION:
      return stateWithNewNotification(payload);
    default:
      return state;
  }
}
