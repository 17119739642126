/**
 * Stores actions
 */

import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IAppState } from '../../../store/reducers';
import { get } from 'lodash';

@Injectable()
export class OpraDataActions {
    public static FETCH_COMPANIES = 'FETCH_COMPANIES';
    public static FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
    public static FETCH_COMPANIES_ERROR = 'FETCH_COMPANIES_ERROR';
    public static SELECT_STORES = 'SELECT_STORES';
    public static SHOW_STORES_MODAL = 'SHOW_STORES_MODAL';
    public static HIDE_STORES_MODAL = 'HIDE_STORES_MODAL';
    public static SHOW_COMPANY_SELECTOR = 'SHOW_COMPANY_SELECTOR';
    public static HIDE_COMPANY_SELECTOR = 'HIDE_COMPANY_SELECTOR';

    public static FETCH_OPRA_INFO = 'FETCH_OPRA_INFO';
    public static FETCH_OPRA_INFO_SUCCESS = 'FETCH_OPRA_INFO_SUCCESS';
    public static FETCH_OPRA_INFO_ERROR = 'FETCH_OPRA_INFO_ERROR';

    public static FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
    public static FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
    public static FETCH_PERMISSIONS_ERROR = 'FETCH_PERMISSIONS_ERROR';

    public static FETCH_REPORTS = 'FETCH_REPORTS';
    public static FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
    public static FETCH_REPORTS_ERROR = 'FETCH_REPORTS_ERROR';

    public static FETCH_SITES = 'FETCH_SITES';
    public static FETCH_SITES_SUCCESS = 'FETCH_SITES_SUCCESS';
    public static FETCH_SITES_ERROR = 'FETCH_SITES_ERROR';

    /**
     * Modals
     */

    public static showCompanySelectorAction() {
        return {
            type: OpraDataActions.SHOW_COMPANY_SELECTOR
        };
    }

    public static hideCompanySelectorAction() {
        return {
            type: OpraDataActions.HIDE_COMPANY_SELECTOR
        };
    }

    /**
     * Companies
     */

    public static fetchCompaniesWithSitesAction() {
        return {
            type: OpraDataActions.FETCH_COMPANIES
        };
    }

    public static fetchCompaniesSuccessAction(servicePayload) {
        return {
            type: OpraDataActions.FETCH_COMPANIES_SUCCESS,
            payload: servicePayload
        };
    }

    public static fetchCompaniesErrorAction(errors) {
        return {
            type: OpraDataActions.FETCH_COMPANIES_ERROR,
            payload: {
                errors
            }
        };
    }

    /*
     * Environment information
     */

    public static fetchInfoAction() {
        return {
            type: OpraDataActions.FETCH_OPRA_INFO
        };
    }

    public static fetchInfoSuccessAction(info) {
        return {
            type: OpraDataActions.FETCH_OPRA_INFO_SUCCESS,
            payload: {
                info
            }
        };
    }

    public static fetchInfoErrorAction(errors) {
        return {
            type: OpraDataActions.FETCH_OPRA_INFO_ERROR,
            payload: {
                errors
            }
        };
    }

    /**
     * User permissions fetch
     */

    public static fetchPermissionsAction(query?: any) {
        return {
            type: OpraDataActions.FETCH_PERMISSIONS,
            payload: {
                query
            }
        };
    }

    public static fetchPermissionsSuccessAction(servicePayload) {
        return {
            type: OpraDataActions.FETCH_PERMISSIONS_SUCCESS,
            payload: servicePayload
        };
    }

    public static fetchPermissionsErrorAction(errors) {
        return {
            type: OpraDataActions.FETCH_PERMISSIONS_ERROR,
            payload: {
                errors
            }
        };
    }

    public static fetchReportsAction() {
        return {
            type: OpraDataActions.FETCH_REPORTS
        };
    }

    public static fetchSitesAction() {
        return {
            type: OpraDataActions.FETCH_SITES
        };
    }

    public static fetchReportsErrorAction(errors) {
        return {
            type: OpraDataActions.FETCH_REPORTS_ERROR,
            payload: {
                errors
            }
        };
    }

    constructor(private ngRedux: NgRedux<IAppState>) {}

    public fetchCompaniesWithSites() {
        this.ngRedux.dispatch(OpraDataActions.fetchCompaniesWithSitesAction());
    }

    public showCompanySelector() {
        this.ngRedux.dispatch(OpraDataActions.showCompanySelectorAction());
    }
    public hideCompanySelector() {
        this.ngRedux.dispatch(OpraDataActions.hideCompanySelectorAction());
    }

    /* helpers */
    public getCurrentCompanyId() {
        const session = this.ngRedux.getState().userSession;
        return session.currentCompanyId;
    }

    public getCompanyRoles(companyId) {
        const session = this.ngRedux.getState().userSession;
        const roles = get(session, ['companyRoles', companyId, 'roles']);
        return roles;
    }

    public getCompanyPermissionNames(companyId) {
        const session = this.ngRedux.getState().userSession;
        const names = get(session, ['companyRoles', companyId, 'touchedPermissions']);
        return names;
    }
}
