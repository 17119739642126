<div class="report_calendars_filter">
  <div *ngIf="isFilterShown() && isReady">
    <span class="calendar_label">Calendar</span>

    <xux-datepicker-input
      [config]="calendarConfig"
      [periods]="calendars"
      [value]="inputCalendarValue"
      (onChange)="handleCalendarChange($event, true)">
    </xux-datepicker-input>
  </div>
</div>
