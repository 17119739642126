import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { XUXMenuPinnedService } from '../services/xux-menu-pinned.service';
import { IMenuItem } from '../interfaces';

@Component({
    selector: 'xux-navigation',
    templateUrl: './xux-navigation.component.html',
    styleUrls: ['./xux-navigation.component.scss'],
    providers: [XUXMenuPinnedService],
    encapsulation: ViewEncapsulation.None
})
export class XUXNavigationComponent implements OnInit, OnDestroy {
    public searchText: string;
    public managedItems: IMenuItem[];

    @Input()
    items: Observable<IMenuItem[]>;

    // tslint:disable-next-line:no-output-on-prefix
    @Output()
    onTogglePin = new EventEmitter();

    public pinnedItems: string[] = [];
    public itemsSubscription: Subscription;

    constructor(private menuPinnedService: XUXMenuPinnedService) {}

    public ngOnInit() {
        this.itemsSubscription = this.items.subscribe(menuItems => {
            const checkHidden = (item: IMenuItem) => {
                const result = Object.assign({}, item);
                if (result.sub && result.sub.length) {
                    // depth first traversal
                    result.sub = result.sub.map(checkHidden).sort(this.acsSort);
                    result.sub = result.sub.filter(e => e.hidden !== true);
                    // array had items when we started, but all were filtered. Disable current item.
                    if (result.sub.length === 0) {
                        result.hidden = true;
                    }
                }
                return result;
            };
            // hide disabled items
            // prune branches/parents that have no non-disabled child items
            this.managedItems = menuItems.map(checkHidden).sort(this.acsSort);

            this.synchronizePins();
        });
    }

    public ngOnDestroy() {
        this.itemsSubscription.unsubscribe();
    }

    public synchronizePins() {
        const pinnedMenu: string[] = this.menuPinnedService.getPinnedMenu();
        this.pinnedItems = [];
        const setPinned = (item: IMenuItem) => {
            if (pinnedMenu.indexOf(item.title) > -1) {
                item.pinned = true;
                this.pinnedItems.push((Object as any).assign({}, item, { isOpen: false }));
            } else {
                item.pinned = false;
            }
            if (item.sub) {
                item.sub.map(setPinned);
            }
        };
        this.managedItems.map(setPinned);
    }

    public togglePin(payload: any) {
        this.menuPinnedService.togglePin(payload.menuItem);
        this.synchronizePins();
    }

    private acsSort(a, b): number {
        if (a.index === null) {
            return 100;
        }
        return +a.index - +b.index;
    }
}
