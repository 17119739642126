import { IErrorPageType } from '../../shared/types';

export const NOT_PERMITTED_ERROR_PAGE_TEMPLATE: IErrorPageType = {
  title: 'Not permitted',
  icon: 'glyphicon-minus-sign',
  description: 'You are not permitted to perform the requested operation.',
};

export const GENERIC_ERROR_PAGE_TEMPLATE: IErrorPageType = {
  title: 'Unexpected error',
  icon: 'glyphicon-remove-sign',
  description: 'Unexpected error occurs.',
};

export const NOT_FOUND_ERROR_PAGE_TEMPLATE: IErrorPageType = {
  title: '404: page missing',
  icon: 'glyphicon-minus-sign',
  description: 'The page you are looking for cannot be found.',
};

export const NO_REPORTS_ERROR_PAGE_TEMPLATE: IErrorPageType = {
  title: 'No custom-reports available',
  icon: 'glyphicon-minus-sign',
  description: 'Please make sure you have sufficient privileges and at least one site assigned.',
};
