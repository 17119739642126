import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { find, isEmpty } from 'lodash';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';

interface IPermissions {
    total: number;
    items: IPermission[];
}

interface IPermission {
    app_code: string;
    name: string;
    value: number;
    type: string;
}

@Injectable()
export class HelperService {
    constructor() {
        this.permissions$.subscribe((perms: IPermissions) => {
            if (!isEmpty(perms) && perms.items) {
                this.permissions = perms;
            }
        });
    }
    private businessDate: string = null;
    private permissions: IPermissions;
    @select(['userSession', 'userPermissions']) private permissions$: Observable<IPermissions>;

    static roundToTwo(num: number) {
        return +(Math.round(Number(num + 'e+2')) + 'e-2');
    }

    public setBusinessDate(date) {
        this.businessDate = date;
    }

    public getTodayDate() {
        return moment().format('YYYY-MM-DD');
    }

    public convertDateToString(date) {
        return moment(date).format('ddd, MMM DD, YYYY');
    }

    public numFormatter(num, digits) {
        const val = num || 0;
        const si = [
            { value: 1, symbol: '' },
            { value: 1e3, symbol: 'K' },
            { value: 1e6, symbol: 'MM' },
            { value: 1e9, symbol: 'G' },
            { value: 1e12, symbol: 'T' },
            { value: 1e15, symbol: 'P' },
            { value: 1e18, symbol: 'E' }
        ];
        const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        for (i = si.length - 1; i > 0; i--) {
            if (val >= si[i].value) {
                break;
            }
        }

        return (val / si[i].value).toFixed(digits).replace(regex, '$1') + si[i].symbol;
    }

    public roundValue(_val) {
        const val = _val || 0;
        return parseFloat(val).toFixed(2);
    }

    public roundPercentsValue(_val) {
        let val = _val || 0;
        val = parseFloat(val) * 100;
        return val.toFixed(2);
    }

    public formatNumberValueWithComma(_val) {
        const val = _val || 0;
        return val
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            .replace(/(\.00$)/, '');
    }

    public convertValuesToNumber(obj, keys: string[]) {
        const source = keys.reduce((result, key) => {
            if (typeof obj[key] === 'string') {
                result[key] = Number(obj[key]);
            }
            return result;
        }, {});
        return Object.assign({}, obj, source);
    }

    public getDatesByRange(range) {
        switch (range) {
            case 'business_date':
                return {
                    dateStart: this.businessDate,
                    dateStop: this.businessDate
                };
            case 'today':
                return {
                    dateStart: moment().format('YYYY-MM-DD'),
                    dateStop: moment().format('YYYY-MM-DD')
                };
            case 'yesterday':
                return {
                    dateStart: moment()
                        .subtract(1, 'days')
                        .format('YYYY-MM-DD'),
                    dateStop: moment()
                        .subtract(1, 'days')
                        .format('YYYY-MM-DD')
                };
            case 'month':
                return {
                    dateStart: moment()
                        .startOf('month')
                        .format('YYYY-MM-DD'),
                    dateStop: moment().format('YYYY-MM-DD')
                };
            case 'year':
                return {
                    dateStart: moment()
                        .startOf('year')
                        .format('YYYY-MM-DD'),
                    dateStop: moment().format('YYYY-MM-DD')
                };
            case 'prev_year':
                return {
                    dateStart: moment()
                        .subtract(1, 'year')
                        .startOf('year')
                        .format('YYYY-MM-DD'),
                    dateStop: moment()
                        .subtract(1, 'year')
                        .endOf('year')
                        .format('YYYY-MM-DD')
                };
            case 'week':
                return {
                    dateStart: moment()
                        .startOf('week')
                        .format('YYYY-MM-DD'),
                    dateStop: moment().format('YYYY-MM-DD')
                };
            default:
                return {
                    dateStart: moment().format('YYYY-MM-DD'),
                    dateStop: moment().format('YYYY-MM-DD')
                };
        }
    }

    public prepareFilterValue(data) {
        return JSON.parse(JSON.stringify(data));
    }

    public inIframe(): boolean {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    public checkPermission(appCode, permission) {
        // @ts-ignore
        return !!find(this.permissions.items, { name: permission, app_code: appCode, value: true });
    }
}
