import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'text-input-filter',
    templateUrl: 'text-input-filter.html',
    styleUrls: ['style.css']
})
export class TextInputFilterComponent implements OnInit {
    @Input() filter: object;
    @Input() type: string = '';
    @Output() handleChanges = new EventEmitter();

    public label: string = '';
    public placeholder: string = '';
    public name: string = '';
    public inputValue: string = '';
    private attributes: string[] = [];

    ngOnInit() {
        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];
        this.label = this.filter['label'];
        this.placeholder = this.filter['placeholder'];
        if (this.filter['value']) {
            this.inputValue = this.filter['value'][this.attributes[0]];
            this.handleTextFilterChange();
        } else {
            this.inputValue = '';
            this.handleTextFilterChange();
        }
    }

    public handleTextFilterChange() {
        const value = {};
        value[this.attributes[0]] = this.inputValue;
        const res = { value, name: this.name, type: this.filter['type'] };
        this.handleChanges.emit(res);
    }
}
