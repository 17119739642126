/**
 * UI actions:
 * Store selector,
 * Sidebar actions,
 * Loader actions
 */
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IAppState } from '../../../store/reducers';
import { IModalDialogOptions, IModalDialogResult, IDropdownMenuOptions } from '../../../shared/types/ui';

import { Toast } from 'angular2-toaster';
import { generateId } from '../../../shared/utils';
import { MODAL_TEMPLATES } from '../../../text-content';
import { IErrorPageType } from '../../../shared/types';
import { find } from 'rxjs/operators';

@Injectable()
export class UiActions {
    // site selector modal
    public static SHOW_STORES_MODAL = 'SHOW_STORES_MODAL';
    public static HIDE_STORES_MODAL = 'HIDE_STORES_MODAL';
    public static SELECT_SIDEBAR_ITEM = 'SELECT_SIDEBAR_ITEM';
    public static UPDATE_LOADER = 'UPDATE_LOADER';
    public static UPDATE_CONTENT_LOADER = 'UPDATE_CONTENT_LOADER';
    public static SHOW_GENERIC_MODAL = 'SHOW_GENERIC_MODAL';
    public static HIDE_GENERIC_MODAL = 'HIDE_GENERIC_MODAL';
    public static SHOW_DROPDOWN_MENU = 'SHOW_DROPDOWN_MENU';
    public static HIDE_DROPDOWN_MENU = 'HIDE_DROPDOWN_MENU';
    // mapping sites modal
    public static SHOW_SITES_MODAL = 'SHOW_SITES_MODAL';
    public static HIDE_SITES_MODAL = 'HIDE_SITES_MODAL';
    public static GENERIC_SUCCESS_ACTION = 'GENERIC_SUCCESS_ACTION';
    public static GENERIC_ERROR_ACTION = 'GENERIC_ERROR_ACTION';
    public static GENERIC_TOASTER_ACTION = 'GENERIC_TOASTER_ACTION';

    public static SHOW_ERROR_PAGE_ACTION = 'SHOW_ERROR_PAGE_ACTION';
    public static UPDATE_SIDEBAR_ITEMS = 'UPDATE_SIDEBAR_ITEMS';

    public static STORE_ENTITY_LIST_FILTER_TEXT = 'STORE_ENTITY_LIST_FILTER_TEXT';

    // list view
    public static RESTORE_ENTITY_LIST_VIEW = 'RESTORE_ENTITY_LIST_VIEW';
    public static RESTORE_DEFAULT_LIST_VIEW = 'RESTORE_DEFAULT_LIST_VIEW';
    public static RESTORE_USER_LIST_VIEW = 'RESTORE_USER_LIST_VIEW';
    public static SAVE_USER_LIST_VIEW = 'SAVE_USER_LIST_VIEW';
    public static REMOVE_USER_LIST_VIEW = 'REMOVE_USER_LIST_VIEW';
    public static UPDATE_LIST_VIEW_DATA = 'UPDATE_LIST_VIEW_DATA';
    public static SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

    /**
     * Show store selector modal action
     */
    public static showStoresModalAction(options) {
        return {
            type: UiActions.SHOW_STORES_MODAL,
            payload: { options }
        };
    }

    /**
     * Hide store selector modal action
     */
    public static hideStoresModalAction() {
        return {
            type: UiActions.HIDE_STORES_MODAL
        };
    }

    /**
     * Select sidebar item action
     */
    public static selectSidebarItemAction(route: string) {
        return {
            type: UiActions.SELECT_SIDEBAR_ITEM,
            payload: { route }
        };
    }

    /**
     * Update loader action
     */
    public static updateLoaderAction(loader: boolean) {
        return {
            type: UiActions.UPDATE_LOADER,
            payload: { loader }
        };
    }

    /**
     * Update content loader action
     */
    public static updateContentLoaderAction(contentLoader: boolean) {
        return {
            type: UiActions.UPDATE_CONTENT_LOADER,
            payload: { contentLoader }
        };
    }

    /**
     * show generic modal action
     */
    public static showGenericModalDialogAction(payload) {
        return {
            payload,
            type: UiActions.SHOW_GENERIC_MODAL
        };
    }

    /**
     * hide generic modal action
     */
    public static hideGenericModalDialogAction(payload) {
        return {
            payload,
            type: UiActions.HIDE_GENERIC_MODAL
        };
    }

    public static showDropdownMenuAction(payload) {
        return {
            payload,
            type: UiActions.SHOW_DROPDOWN_MENU
        };
    }

    public static hideDropdownMenuAction(payload) {
        return {
            payload,
            type: UiActions.HIDE_DROPDOWN_MENU
        };
    }

    public static showErrorToasterMessageAction(payload) {
        return {
            payload,
            type: UiActions.GENERIC_ERROR_ACTION
        };
    }

    public static showToasterMessageAction(payload) {
        return {
            payload,
            type: UiActions.GENERIC_TOASTER_ACTION
        };
    }

    public static showErrorPageAction(errorPage) {
        return {
            payload: { errorPage },
            type: UiActions.SHOW_ERROR_PAGE_ACTION
        };
    }

    public static updateSidebarItemsAction(items) {
        return {
            payload: { items },
            type: UiActions.UPDATE_SIDEBAR_ITEMS
        };
    }

    /**
     * Actions for update listFilterText
     * @param entityType: string
     * @param text: string
     * @returns {Action}
     */
    public static storeEntityListFilterTextAction(entityType: string, text: string) {
        return {
            payload: {
                text,
                entity_type: entityType
            },
            type: UiActions.STORE_ENTITY_LIST_FILTER_TEXT
        };
    }

    /**
     * Actions for update list view data
     */
    public static restoreEntityListViewAction(entityType: string) {
        return {
            payload: {
                entity_type: entityType
            },
            type: UiActions.RESTORE_ENTITY_LIST_VIEW
        };
    }

    /**
     * Actions for update list view data
     */
    public static restoreDefaultListViewAction(entityType: string) {
        return {
            payload: {
                entity_type: entityType
            },
            type: UiActions.RESTORE_DEFAULT_LIST_VIEW
        };
    }

    public static saveUserListViewAction(viewName: string) {
        return {
            payload: {
                viewName
            },
            type: UiActions.SAVE_USER_LIST_VIEW
        };
    }

    public static removeUserListViewAction(viewName: string) {
        return {
            payload: {
                viewName
            },
            type: UiActions.REMOVE_USER_LIST_VIEW
        };
    }

    public static restoreUserListViewAction(viewName: string) {
        return {
            payload: {
                viewName
            },
            type: UiActions.RESTORE_USER_LIST_VIEW
        };
    }

    public static updateListViewDataAction(payload) {
        return {
            payload,
            type: UiActions.UPDATE_LIST_VIEW_DATA
        };
    }

    public static setCurrentPageAction(pageNumber: number) {
        return {
            payload: {
                pageNumber
            },
            type: UiActions.SET_CURRENT_PAGE
        };
    }

    constructor(private ngRedux: NgRedux<IAppState>) {}

    /**
     * Dispatch show store selector modal action
     */
    public showStoresModal(options = { forceSelect: false, masterList: true }) {
        this.ngRedux.dispatch(UiActions.showStoresModalAction(options));
    }

    /**
     * Dispatch hide store selector modal action
     */
    public hideStoresModal() {
        this.ngRedux.dispatch(UiActions.hideStoresModalAction());
    }

    /**
     * Dispatch select sidebar item action
     * && route.startsWith('/')
     */
    public selectSidebarItem(route: string) {
        let routeSelectSidebarItem = route;
        if (route.length >= 1 && route.startsWith('/')) {
            routeSelectSidebarItem = route.substring(1);
        }
        this.ngRedux.dispatch(UiActions.selectSidebarItemAction(routeSelectSidebarItem));
    }

    /**
     * Dispatch action for enabling loader
     *
     */
    enableLoader() {
        this.ngRedux.dispatch(UiActions.updateLoaderAction(true));
    }

    /**
     * Dispatch action for disabling loader
     */
    disableLoader() {
        this.ngRedux.dispatch(UiActions.updateLoaderAction(false));
    }

    /**
     * Dispatch action for enabling content loader
     *
     */
    enableContentLoader() {
        this.ngRedux.dispatch(UiActions.updateContentLoaderAction(true));
    }

    /**
     * Dispatch action for disabling loader
     */
    disableContentLoader() {
        this.ngRedux.dispatch(UiActions.updateContentLoaderAction(false));
    }

    /**
     * Confirmation Modal dialog action dispatcher
     * Completes provided options with all necessary fields to open generic Confirm dialog
     * @returns Promise<IModalDialogResult>
     */
    public confirm(userOptions: IModalDialogOptions | string): Promise<IModalDialogResult> {
        const options = Object.assign(
            {},
            MODAL_TEMPLATES.MODAL_TEMPLATE_CONFIRM,
            typeof userOptions === 'string' ? { text: userOptions } : userOptions
        );
        return this.modalDialog(options);
    }

    /**
     * Prompt Modal dialog action dispatcher
     * Provided default options with all values necessary for generic Prompt dialog.
     * @returns Promise<IModalDialogResult>
     */
    public prompt(userOptions: IModalDialogOptions): Promise<IModalDialogResult> {
        const options = Object.assign({}, MODAL_TEMPLATES.MODAL_TEMPLATE_PROMPT, userOptions);
        // if (userOptions.)
        return this.modalDialog(options);
    }

    /**
     * modal dialog action dispatcher.
     * Returns promise for modal dialog result
     */
    public modalDialog(options: IModalDialogOptions): Promise<IModalDialogResult> | any {
        // force close of currently open modal`
        if (this.ngRedux.getState().ui.genericModalVisible) {
            this.closeGenericModal({ modalResult: 'none' });
        }
        this.ngRedux.dispatch(UiActions.showGenericModalDialogAction(options));
        return this.ngRedux
            .select(['ui', 'genericModalResult'])
            .pipe(find(Boolean))
            .toPromise();
    }

    /** close generic modal action disatcher */
    public closeGenericModal(result: IModalDialogResult) {
        this.ngRedux.dispatch(UiActions.hideGenericModalDialogAction(result));
    }

    /**
     * Information Modal dialog action dispatcher
     * Completes provided options with all necessary fields to open generic Alert dialog
     * @returns Promise<IModalDialogResult>
     */
    public alert(userOptions: IModalDialogOptions | string): Promise<IModalDialogResult> {
        const options = Object.assign(
            {},
            MODAL_TEMPLATES.MODAL_TEMPLATE_ALERT,
            typeof userOptions === 'string' ? { text: userOptions } : userOptions
        );
        return this.modalDialog(options);
    }

    public showDropdownMenu(options: IDropdownMenuOptions) {
        this.ngRedux.dispatch(UiActions.showDropdownMenuAction(options));
        return this.ngRedux
            .select(['ui', 'dropdownMenuResult'])
            .pipe(find(Boolean))
            .toPromise();
    }

    public hideDropdownMenu(payload: any = null) {
        this.ngRedux.dispatch(UiActions.hideDropdownMenuAction(payload));
    }

    public showErrorToasterMessage(payload: any = null) {
        this.ngRedux.dispatch(UiActions.showErrorToasterMessageAction(payload));
    }

    public showToasterMessage(message: Toast | string) {
        const toast = Object.assign(
            { type: 'info', toastId: generateId() },
            typeof message === 'string' ? { body: message } : message
        );
        this.ngRedux.dispatch(UiActions.showToasterMessageAction(toast));
    }

    public showErrorPage(options: string | IErrorPageType) {
        const newOptions = Object.assign({}, typeof options === 'string' ? { text: options } : options);
        this.ngRedux.dispatch(UiActions.showErrorPageAction(newOptions));
    }

    /**
     * Method for dispatching storeEntityListFilterTextAction action
     * @param text: string
     * @param listFilterText: string - filter text for entity list string
     */
    public storeEntityListFilterText(entityType: string, text: string) {
        this.ngRedux.dispatch(UiActions.storeEntityListFilterTextAction(entityType, text));
    }

    public restoreEntityListView(entityType: string) {
        this.ngRedux.dispatch(UiActions.restoreEntityListViewAction(entityType));
    }

    public restoreDefaultListView(entityType: string) {
        this.ngRedux.dispatch(UiActions.restoreDefaultListViewAction(entityType));
    }

    public saveUserListView(viewName: string) {
        this.ngRedux.dispatch(UiActions.saveUserListViewAction(viewName));
    }

    public restoreUserListView(viewName: string) {
        this.ngRedux.dispatch(UiActions.restoreUserListViewAction(viewName));
    }

    public setCurrentPage(pageNumber: number) {
        this.ngRedux.dispatch(UiActions.setCurrentPageAction(pageNumber));
    }

    public setSidebarItems(items) {
        this.ngRedux.dispatch(UiActions.updateSidebarItemsAction(items));
    }
}
