import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgReduxModule } from '@angular-redux/store';
// Ag-Grid
import { AgGridModule } from '@ag-grid-community/angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToasterModule, ToasterService } from 'angular2-toaster';
/*
 * Platform and Environment providers/directives/pipes
 */
import { SERVICE_PROVIDERS } from '@services/index';
import { HELPER_PROVIDERS } from '@shared/helpers';
import { HTTP_INTERCEPTOR_PROVIDERS } from '@services/interceptors';
import { WINDOW_PROVIDERS } from '@services/window';
import { ROUTES } from './app.routes';
// App is our top level component
import { AppComponent } from './app.component';
import { NoContentComponent } from './components/app/no-content';
import { LoginComponent } from './components/app/login';
import { IframeModeComponent } from './components/app/iframe-mode';
import { LogoutPageComponent } from './components/app/login/logout.page';
import { NotificationsContainerComponent } from './containers/notifications';
import { XenialUxModule } from './xenial-ux';
// app modules
import { DmComponentsModule } from '@components/dm.components.module';

/*
 * @angular-redux/store Store
 */
import { STORE_PROVIDERS } from './store';

// Custom pipes
import { ExtraLayoutContainerComponent } from '@containers/layouts/extra/extra.container';
import { DmErrorPageComponent } from '@components/app/error-page';
import { DefaultLayoutComponent } from '@containers/layouts/default/default.component';

// Directives
import { ClickOutsideModule } from '@directives/click-outside';

// Entry components
import { DisplayingConflictPopupComponent } from '@components/ag-grid-reports/order-explorer-report/displaying-conflict-popup/displaying-conflict-popup.component';

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        LogoutPageComponent,
        NoContentComponent,
        LoginComponent,
        IframeModeComponent,
        NotificationsContainerComponent,
        DmErrorPageComponent,

        ExtraLayoutContainerComponent,
        DefaultLayoutComponent,
        LoginComponent,
        DisplayingConflictPopupComponent
    ],
    imports: [
        // import Angular's modules
        BrowserModule,
        TooltipModule.forRoot(),
        DmComponentsModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NgReduxModule,
        RouterModule.forRoot(ROUTES, { useHash: true }),
        AgGridModule.withComponents([]),
        BrowserAnimationsModule,
        ToasterModule.forRoot(),
        XenialUxModule.forRoot(),
        ClickOutsideModule
    ],
    providers: [
        // expose our Services and Providers into Angular's dependency injection
        SERVICE_PROVIDERS,
        HELPER_PROVIDERS,
        STORE_PROVIDERS,
        HTTP_INTERCEPTOR_PROVIDERS,
        WINDOW_PROVIDERS,
        ToasterService
    ],
    entryComponents: [
        DisplayingConflictPopupComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
