<div *ngIf="isReady" class="report_select_filter {{ enablePicker ? 'portal_site_picker' : '' }}" (click)="showPortalPicker();">
  <span>{{label}}</span><br/>
  <angular2-multiselect *ngIf="selectedItems" [data]="items" [(ngModel)]="selectedItems"
                        [settings]="dropdownSettings"
                        (onSelect)="onItemSelect()"
                        (onDeSelect)="onItemDeselect()"
                        (onSelectAll)="onSelectAll()"
                        (onDeSelectAll)="onDeSelectAll()"
                        ngDefaultControl></angular2-multiselect>
</div>
