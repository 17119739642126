import { Injectable } from '@angular/core';
import { isEmpty, isObject, sum, isUndefined } from 'lodash';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { GridOptions } from '@ag-grid-enterprise/all-modules';
import { PreferencesService } from '@shared/services';
import { AgGridReportsService } from '../reports';

export interface IPdfReportOptions {
    reportTitle: string;
    ordersCount?: number;
    dateTo: string;
    dateFrom: string;
}

@Injectable({
    providedIn: 'root',
})
export class AgGridPdfExportService {
    private pdfFooterColumns: any[] = [];

    constructor(
        private agGridReportsService: AgGridReportsService,
        private preferencesService: PreferencesService,
    ) {
    }

    public generatePDF(gridOptions: GridOptions, reportOptions: IPdfReportOptions): void {
        let gridWidth = 0;
        this.pdfFooterColumns = [];
        const headerColumns = [];
        const body = [];
        const footerColumns = [];
        let groupedColumnsForExporting = 0;

        gridOptions.columnApi.getAllDisplayedColumns()
            .forEach((tempItem, index) => {
                let item;
                let isGrouped = false;
                if (tempItem.getUserProvidedColDef()) {
                    item = tempItem;
                } else {
                    const groupColumnId = tempItem.getColId().slice(19, tempItem.getColId().length);
                    item = gridOptions.columnApi
                        .getAllColumns()
                        .filter(item => item.getColId() === groupColumnId)[0];
                    isGrouped = true;
                    groupedColumnsForExporting++;
                }

                headerColumns.push(item.userProvidedColDef.headerName);
                gridWidth += item.minWidth;
                gridOptions.api.forEachNodeAfterFilterAndSort((rowNode, indexNode) => {
                    if (index === 0) {
                        body.push([]);
                    }

                    if (isObject(rowNode.data) && item.colId in rowNode.data) {
                        this.fillArrayWithValues(item, index, rowNode, indexNode);

                        switch (item.colId) {
                            case 'customer__first_name':
                                rowNode.data[item.colId] !== '(None)' ?
                                    body[indexNode].push(`${rowNode.data[item.colId]} ${rowNode.data['customer__last_name']}`) :
                                    body[indexNode].push(this.formatterForFieldsInPdf(item, index, rowNode));
                                break;
                            default:
                                if (!isGrouped) {
                                    body[indexNode].push(this.formatterForFieldsInPdf(item, index, rowNode));
                                } else {
                                    body[indexNode].push('');
                                }
                        }
                    } else {
                        if (isGrouped && rowNode.groupData) {
                            body[indexNode].push(rowNode.groupData[tempItem.getColId()] ?
                                this.formatterForGroupFieldsInPdf(tempItem, rowNode.groupData)
                                : rowNode.groupData[tempItem.getColId()]);
                        } else if (item.colId in rowNode.aggData) {
                            body[indexNode].push(this.formatterForGroupFieldsInPdf(item, rowNode.aggData, true));
                        } else {
                            body[indexNode].push('');
                        }
                    }
                });

                if (index === 0) {
                    footerColumns.push('Total');
                } else {
                    footerColumns.push(this.formatterForFieldsInPdf(item, index, {}, true));
                }
            });

        if (body.length === 0) {
            footerColumns.length = 0;
        }

        const doc = this.initPdfDoc({
            gridWidth,
            groupedColumnsForExporting,
            headerColumns,
            footerColumns,
            body,
        }, reportOptions);

        doc.save(reportOptions.reportTitle + '.pdf');
    }

    private initPdfDoc(options, reportOptions: IPdfReportOptions) {
        const {
            gridWidth,
            groupedColumnsForExporting,
            headerColumns,
            footerColumns,
            body
        } = options;
        const minLandscapeWidth = 900;
        const totalPagesExp = '{total_pages_count_string}';
        let doc;
        if (gridWidth >= minLandscapeWidth) {
            doc = new jsPDF('l');
        } else {
            doc = new jsPDF();
        }

        doc.setFontSize(16);
        doc.text(reportOptions.reportTitle, 14, 18);
        doc.setFontSize(9);
        doc.setLineHeightFactor(1.2);
        doc.setTextColor(153, 153, 153);
        let headerText = `Business Date: ${this.agGridReportsService.areEqualDates([ reportOptions.dateFrom, reportOptions.dateTo ])
            ? this.dateFormatter(reportOptions.dateFrom)
            : this.dateFormatter(reportOptions.dateFrom) + ' - ' + this.dateFormatter(reportOptions.dateTo)
        }`;
        if (!isUndefined(reportOptions.ordersCount)) {
            headerText = headerText + `
Order Count Total: ${this.numberFormatter(reportOptions.ordersCount)}`;
        }

        doc.text(headerText, 14, 22);

        doc.autoTable({
            head: [ headerColumns ],
            body,
            foot: [ footerColumns ],
            ...this.getDefaultParamsForColumns(),
            didParseCell: (data) => {
                if (groupedColumnsForExporting > 0 && data.row.section === 'body') {
                    if (!isEmpty(data.row.raw[0]) && isEmpty(data.row.raw[1])) {
                        data.cell.styles.cellWidth = 'auto';
                    } else if (groupedColumnsForExporting === 2 &&
                        (isEmpty(data.row.raw[0]) && !isEmpty(data.row.raw[1]))) {
                        data.cell.styles.cellWidth = 'auto';
                    }
                }
                if (data.row.section === 'body') {
                    if (data.cell.text < 0) {
                        data.cell.styles.cellWidth = 22;
                    }
                }
            },
            willDrawCell: (data) => {
                if (data.row.section === 'body') {
                    if (data.cell.raw < 0) {
                        doc.setTextColor(231, 76, 60);
                        data.cell.text = this.currencyFormatter(data.cell.raw, 'detail');
                    }
                }

                if (data.row.section === 'foot') {
                    if (data.cell.raw?.indexOf('red') > -1) {
                        doc.setTextColor(231, 76, 60);
                        data.cell.text[0] = data.cell.raw.slice(data.cell.raw.indexOf('>') + 1, data.cell.raw.indexOf('</'));
                    }
                }

                if (groupedColumnsForExporting > 0) {
                    if (!isEmpty(data.row.raw[0])) {
                        doc.setFont('Helvetica', 'Bold');
                        if (data.cell.text[0].indexOf('...') !== -1 && data.row.section === 'body') {
                            data.cell.text[0] = data.cell.text[0].slice(0, -4) + '...';
                        }
                    } else if (groupedColumnsForExporting === 2 &&
                        (isEmpty(data.row.raw[0]) && !isEmpty(data.row.raw[1]))) {
                        doc.setFont('Helvetica', 'Bold');
                        if (data.cell.text[0].indexOf('...') !== -1 && data.row.section === 'body') {
                            data.cell.text[0] = data.cell.text[0].slice(0, -4) + '...';
                        }
                    }
                }
            },
            didDrawPage: (data) => {
                let str = 'Page ' + doc.internal.getNumberOfPages();
                if (typeof doc.putTotalPages === 'function') {
                    str = str + ' of ' + totalPagesExp;
                }
                doc.setFontSize(10);

                const pageSize = doc.internal.pageSize;
                const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);
            }
        });

        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        return doc;
    }

    private getDefaultParamsForColumns(): UserOptions {
        return {
            showFoot: 'lastPage',
            startY: 30,
            margin: 0,
            // Default for all columns
            styles: {
                overflow: 'ellipsize',
                cellWidth: 'wrap',
                fontSize: 6
            },
            headStyles: {
                fillColor: '#ffffff',
                textColor: '#333333',
                fontSize: 7,
                cellWidth: 'auto',
            },
            footStyles: {
                fillColor: '#e1e1e1',
                textColor: '#000',
                fontSize: 8,
                cellWidth: 'auto'
            },
            // Override the default above for the text column
            columnStyles: {
                site: {cellWidth: 'auto'},
                employee: {cellWidth: 'auto'},
                customer__first_name: {cellWidth: 'auto'},
            },
        };
    }

    private fillArrayWithValues(item, index: number, rowNode, indexNode: number): void {
        if (!this.pdfFooterColumns[index]) {
            this.pdfFooterColumns[index] = [];
        }

        item.userProvidedColDef.cellClass &&
        (item.userProvidedColDef.cellClass[0].indexOf('custom-price') >= 0
            || item.userProvidedColDef.cellClass[0].indexOf('custom-count') >= 0) ?
            this.pdfFooterColumns[index].push(rowNode.data[item.colId]) :
            this.pdfFooterColumns[index].push('');
    }

    private formatterForFieldsInPdf(item, index, rowNode: any = {}, footer = false): string | number {
        if (item.userProvidedColDef && item.userProvidedColDef.cellClass
            && item.userProvidedColDef.cellClass[0].indexOf('custom-price') >= 0) {
            if (!footer) {
                return rowNode.data[item.colId] >= 0 ? this.currencyFormatter(rowNode.data[item.colId])
                    : rowNode.data[item.colId];
            } else {
                if (!item.aggFunc) {
                    return '';
                }

                return item.aggFunc === 'customAvgSum'
                    ? this.currencyFormatter(this.getTotalAvgValueById(item.colId))
                    : this.currencyFormatter(this.agGridReportsService.customSumAggFunc(this.pdfFooterColumns[index]));
            }
        } else if (item.userProvidedColDef && item.userProvidedColDef.cellClass
            && item.userProvidedColDef.cellClass[0].indexOf('custom-count') >= 0) {
            return !footer ?
                this.numberFormatter(rowNode.data[item.colId]) :
                this.numberFormatter(sum(this.pdfFooterColumns[index]?.map(item => +item)));
        } else {
            if (footer) {
                return '';
            }

            if (item.colId === 'business_date') {
                return this.preferencesService.dateFormatter(rowNode.data[item.colId]);
            } else if (this.agGridReportsService.isDateValueInField(item, rowNode.data[item.colId]) && item.colId.indexOf('ag-Grid-AutoColumn') === -1) {
                return this.preferencesService.dateAndTimeFormatter(rowNode.data[item.colId]);
            } else {
                return rowNode.data[item.colId];
            }
        }
    }

    private formatterForGroupFieldsInPdf(item, data: any = {}, aggregatedData = false): string | number {
        if (aggregatedData && item.userProvidedColDef.cellClass[0].indexOf('custom-price') >= 0) {
            return data[item.colId] >= 0 ? this.currencyFormatter(data[item.colId]) : data[item.colId];
        } else if (aggregatedData && item.userProvidedColDef.cellClass[0].indexOf('custom-count') >= 0) {
            return this.numberFormatter(data[item.colId]);
        } else {
            if (item.colId.indexOf('business_date') > -1) {
                return this.preferencesService.dateFormatter(data[item.colId]);
            } else if (this.agGridReportsService.isDateValueInField(item, data[item.colId])) {
                return this.preferencesService.dateAndTimeFormatter(data[item.colId]);
            } else {
                return data[item.colId];
            }
        }
    }

    private dateFormatter(value): string {
        if (value === undefined || value === null) {
            return '';
        } else if (value === '(None)') {
            return '(None)';
        } else {
            return this.preferencesService.dateFormatter(value);
        }
    }

    private numberFormatter(val): string | number {
        return this.preferencesService.numberFormatterForAgGrid(val);
    }

    private currencyFormatter(params, type = 'grid') {
        return this.preferencesService.currencyFormatterForAgGrid(params, type);
    }

    private getTotalAvgValueById(id: string): number {
        if (id === 'orders_avg') {
            return this.agGridReportsService.getTotalOrdersAvg();
        } else if (id === 'guest_avg') {
            return this.agGridReportsService.getTotalGuestsAvg();
        } else {
            return 0;
        }
    }
}
