<div *ngIf="customMessage"  style="margin-top: 30px">
  <span>{{customMessage}}</span>
</div>
<div *ngIf="!customMessage">
  <iframe [hidden]="!showIframe" id="pentaho-report-frame"
          height="1000"
          width="100%"
          [src]="iframeSrc"
          frameborder="0">
  </iframe>
</div>
