import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class XUXDrawerSidebarService {
  slideState: string = 'closed';
  slideStateSub = new BehaviorSubject<string>(this.slideState);

  public open(): void {
    this.slideState = 'open';
    this.slideStateSub.next(this.slideState);
  }
  public close(): void {
    this.slideState = 'closed';
    this.slideStateSub.next(this.slideState);
  }
  public toggle(): void {
    this.slideState = this.slideState === 'closed' ? 'open' : 'closed';
    this.slideStateSub.next(this.slideState);
  }
  public openOnDesktop(): void {
    if (window.innerWidth >= 768) {
      this.slideState = 'open';
    }
    this.slideStateSub.next(this.slideState);
  }
  public closeOnMobile(): void {
    if (window.innerWidth < 768) {
      this.slideState = 'closed';
    }
    this.slideStateSub.next(this.slideState);
  }
}
