import { Component, OnInit } from '@angular/core';
import { AgGridReportsService } from '../../../../shared/services/reports';

@Component({
    selector: 'dynamic-component',
    templateUrl: './displaying-conflict-popup.component.html'
})
export class DisplayingConflictPopupComponent implements OnInit {
    public generatedContent = '';
    constructor(public agGridReportsService: AgGridReportsService) {}

    ngOnInit() {
        this.generatedContent = this.agGridReportsService.getGeneratedPopupContent();
    }

    public resetNecessaryFilters() {
        this.agGridReportsService.resetFilters();
    }
}
