import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { XUXDrawerSidebarService } from '../../services/xux-drawer-sidebar.service';
import {
  Router,
  NavigationStart,
} from '@angular/router';
@Component({
  selector: 'xux-drawer-container',
  templateUrl: './xux-drawer-container.component.html',
  styleUrls: ['./xux-drawer-container.component.scss'],
})
export class XUXDrawerContainerComponent implements OnInit {
  public slideState: string = 'open';
  @HostBinding('class') sidebarClass: string = 'sidebar-open';

  constructor(private router: Router,
    private drawerSidebarService: XUXDrawerSidebarService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.drawerSidebarService.closeOnMobile();
      }
    });
  }
  ngOnInit() {
    this.drawerSidebarService.slideStateSub
      .subscribe((val) => {
        this.slideState = val;
        this.sidebarClass = 'sidebar-' + val;
      });
  }
  onBackdropClicked() {
    this.drawerSidebarService.close();
  }
  @HostListener('window:resize') onresize() {
    this.drawerSidebarService.closeOnMobile();
  }
}
