import {
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { CustomReportsService } from '../../../shared/services';
import { PreferencesService } from '../../../shared/services/preferences/preferences.service';
import { LocalDbStorageService } from '../../../shared/services/local-db-storage';
import { AgGridReportsService, AgGridPdfExportService, IPdfReportOptions } from '../../../shared/services/reports';
import { getDefaultGridOptions, ReportColumnDefsService } from '../helpers';
import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { UiActions } from '../../../store/actions';
import { GridOptions, AllModules , Module, RowNode } from '@ag-grid-enterprise/all-modules';
import { isBoolean, isString, isNull, uniqBy } from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { HttpCancelService } from '../../../shared/services/http-client';

interface PayType {
    pay_type: string;
}

@Component({
    selector: 'enterprise-sales-summary',
    templateUrl: 'enterprise-sales.component.html',
    styleUrls: ['enterprise-sales.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnterpriseSalesComponent implements OnInit, OnDestroy {
    @Input()
    set currentCompanyId(val: string) {
        this._currentCompanyId = val;
        this.changeDetectorRef.markForCheck();
    }
    get currentCompanyId(): string {
        return this._currentCompanyId;
    }
    @Input()
    public set data(data) {
        if (this.isNewRequestData(data)) {
            if (data.custom_title) {
                this.reportTitle = data.custom_title;
                this.changeDetectorRef.markForCheck();
            }
            this.getEnterpriseSalesData(data);
        }
    }

    @Input()
    public set gridOptions(value: GridOptions) {
        this._gridOptions = value;
        this.changeDetectorRef.markForCheck();
    }

    /**
     * This getter is needed for getting the _gridOptions property
     * @returns {GridOptions}
     */
    public get gridOptions(): GridOptions {
        return this._gridOptions;
    }

    private gridApi;
    private gridColumnApi;
    private loadedDBStorageConfig = false;
    private payTypes: PayType[] = [];
    private columnsState: any[] = [];
    private dutchBrodCompanies: string[] = ['5c33ca29868b8b00142213c4', '5d4a90251dcf80001f31cf37', '5e624d93d73ffc0006262aeb'];
    private buildVersion = '';
    private _currentCompanyId: string;
    private possibleBuildConflicts = true;
    protected businessDate: string = '';
    public reportTitle = 'Enterprise Sales Summary';
    public dateFrom: string = '';
    public dateTo: string = '';
    protected siteIds: string[] | string = 'All';
    protected isActive = true;
    public popupIsActive = false;

    public openedExportDropdownMenu = false;

    // Modal props
    public _showModal = false;
    public pdfReportOptions: IPdfReportOptions;
    public gridOptionsForExportToPdf: GridOptions;

    // Ag-Grid configs
    public domLayout;
    public rowData: RowNode[] = [];
    public defaultColDef;
    public columnDefs;
    public _gridOptions;
    public modules: Module[] = AllModules;
    public loader$: Observable<any>;
    private isLoading = false;

    constructor(
        private uiActions: UiActions,
        private reportService: CustomReportsService,
        private toasterService: ToasterService,
        public agGridReportsService: AgGridReportsService,
        public localDbStorageService: LocalDbStorageService,
        private agGridPdfExportService: AgGridPdfExportService,
        private httpCancelService: HttpCancelService,
        private reportColumnDefsService: ReportColumnDefsService,
        private preferencesService: PreferencesService,
        private ngRedux: NgRedux<any>,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.loader$ = this.ngRedux.select(['ui', 'contentLoader']);
        this.loader$
            .pipe(takeWhile(() => this.isActive))
            .subscribe(
            value => {
                if (isBoolean(value)) {
                    if (this.isLoading === true && value === false) {
                        this.httpCancelService.cancelPendingRequests();
                    }
                    this.isLoading = value;
                    this.changeDetectorRef.markForCheck();
                }
            }
        );
        this._gridOptions = {
            ...getDefaultGridOptions({
                customFooterValueGetter: this.agGridReportsService.customFooterValueGetter,
                customSumAggFunc: this.agGridReportsService.customSumAggFunc,
                customAvgSumAggFunc: this.agGridReportsService.customAvgAggFunction
            }),
            ...this.generateAllGridEvents()
        };

        this.columnDefs = this.reportColumnDefsService.getEnterpriseSalesColumnDefs();

        this.defaultColDef = {
            sortable: true,
            resizable: true,
            filter: true
        };
    }

    public ngOnInit() {}

    @HostListener('window:resize', ['$event'])
    public onResize(event) {}

    public ngOnDestroy() {
        this.isActive = false;
        this.uiActions.disableContentLoader();
        this.isNewRequestData({});
    }

    public autoSizeAll() {
        this.gridApi.sizeColumnsToFit();
        const allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(column => {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
    }

    public dateFormatter(value) {
        if (value === undefined || value === null) {
            return '';
        } else if (value === '(None)') {
            return '(None)';
        } else {
            return this.preferencesService.dateFormatter(value);
        }
    }

    public areEqualDates(dates: any[]): boolean {
        return this.agGridReportsService.areEqualDates(dates);
    }

    public numberFormatter(val) {
        return this.preferencesService.numberFormatterForAgGrid(val);
    }

    public currencyFormatter(params, type = 'grid') {
        return this.preferencesService.currencyFormatterForAgGrid(params, type);
    }

    // Exporting
    // *******************************
    public exportReportToPDF() {
        this.agGridPdfExportService.generatePDF(this.gridOptions, {
            reportTitle: this.reportTitle,
            dateTo: this.dateTo,
            dateFrom: this.dateFrom,
        });
    }

    public exportReportToCSV() {
        this.agGridReportsService.exportReportToCSV(this.gridOptions, 'Enterprise Sales Summary Report');
    }

    public exportReportToXLS() {
        this.agGridReportsService.exportReportToXLS(this.gridOptions, 'Enterprise Sales Summary Report');
    }

    public openPdfModal() {
        this._gridOptions.columnApi.getDisplayedLeftColumns().forEach(column => {
            this._gridOptions.columnApi.setColumnPinned(column.colId, false);
        });

        this._gridOptions.columnApi.getDisplayedRightColumns().forEach(column => {
            this._gridOptions.columnApi.setColumnPinned(column.colId, false);
        });

        this.gridOptionsForExportToPdf = this.gridOptions;

        this.pdfReportOptions = {
            reportTitle: this.reportTitle,
            dateTo: this.dateTo,
            dateFrom: this.dateFrom,
        };

        this._showModal = true;
    }

    public closeModal(): void {
        this._showModal = false;
        this.gridOptionsForExportToPdf = {};
    }

    public closeDropdownMenu(): void {
        if (!this.openedExportDropdownMenu) {
            return;
        }
        this.openedExportDropdownMenu = false;
    }

    public toggleDropdownMenu(event): void {
        this.openedExportDropdownMenu = !this.openedExportDropdownMenu;
        event.stopPropagation();
    }

    public onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.domLayout = 'normal';
    }

    public sortByDefault() {
        const sort = [
            {
                colId: 'ag-Grid-AutoColumn-state_name',
                sort: 'asc'
            },
            {
                colId: 'ag-Grid-AutoColumn-store_number',
                sort: 'asc'
            }
        ];
        this._gridOptions.api.setSortModel(sort);
    }

    public generateAllGridEvents() {
        return {
            onColumnRowGroupChanged: params => {
                params.columnApi.getRowGroupColumns().forEach(column => {
                    params.columnApi.setColumnVisible(column.colId, false);
                });
                params.api.sizeColumnsToFit();
                this.addCustomBottomLabel('Total');
                this.syncReportState(true);
            },
            onRowGroupOpened: params => {
                this.addCustomBottomLabel('Total');
            },
            onFilterChanged: params => {
                this.addCustomBottomLabel('Total');
                this.syncReportState(true);
            },
            onSortChanged: params => {
                this.syncReportState(true);
            },
            onDisplayedColumnsChanged: params => {
                params.api.sizeColumnsToFit();
                if (this.loadedDBStorageConfig) {
                    this.syncReportState(true);
                }
            },
            onColumnPinned: params => {
                this.syncReportState(true);
            },
            onColumnMoved: params => {
                this.syncReportState(true);
            },
            onColumnEverythingChanged: params => {
                if (params.source === 'contextMenu') {
                    this.resetToDefault();
                }
            },
            onExpandOrCollapseAll: () => {
                this.addCustomBottomLabel('Total');
            }
        };
    }

    private isNewRequestData(data) {
        return this.reportService.isNewRequestData(data);
    }

    private resetToDefault(): void {
        this.clearFilters();
        this.groupedByDefault();
        this.sortByDefault();
        this.showSpecificColumnForDutchBros('net_total');
        const allColumns = this.gridColumnApi.getAllColumns();
        const index = allColumns.findIndex(item => {
            return item.colId === 'payment_total';
        });
        this.gridColumnApi.moveColumns(
            this.payTypes.map(item => item.pay_type),
            index + 3
        );
    }

    private async initGrid(tempData) {
        this.rowData = this.getNormalizeData(tempData);
        this.changeDetectorRef.markForCheck();

        this.addPayTypesColumnsToGrid();
        await this.syncReportState();
        this.expandGroupedColumnByName('state_name');

        this.addCustomBottomLabel('Total');
    }

    private showSpecificColumnForDutchBros(name: string): void {
        if (this.dutchBrodCompanies.includes(this.currentCompanyId)) {
            this._gridOptions.columnApi.setColumnVisible(name, true);
        }
    }

    private addPayTypesColumnsToGrid (): void {
        const cols = [];
        const necPayTypes = [...this.payTypes.filter((item: PayType) => {
            return !this.columnsState.find(column => {
                return column.colId === item.pay_type;
            });
        })];

        if (necPayTypes.length) {
            necPayTypes.forEach(item => {
                const tempColumn = {
                    headerName: 'Payments: ' + item.pay_type
                        .split(' ')
                        .map(word => word[0].toUpperCase() + word.slice(1))
                        .join(' '),
                    field: item.pay_type,
                    colId: item.pay_type,
                    hide: true,
                    aggFunc: 'customSum',
                    cellClass: ['custom-full-width custom-price'],
                    cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                    comparator: this.agGridReportsService.customNumberComparator,
                    minWidth: 150
                };

                cols.push({...tempColumn});
            });

            const allColumns = this.gridColumnApi.getAllColumns();
            this.gridApi.setColumnDefs([]);
            this.gridApi.setColumnDefs([
                ...allColumns.map(item => item.colDef),
                ...cols
            ]);
            const index = allColumns.findIndex(item => {
                return item.colId === 'payment_total';
            });
            this.gridColumnApi.moveColumns(
                this.payTypes.map(item => item.pay_type),
                index + 3
            );
        }
    }

    private getNormalizeData(data) {
        return data.reduce((filtered, item) => {
            const tempObj = {
                ...item,
                liability_gift_card: this.agGridReportsService.prepareCurrencyField(item.liability_gift_card),
                liability_gift_certificate: this.agGridReportsService.prepareCurrencyField(item.liability_gift_certificate),
                deposit_actual: this.agGridReportsService.prepareCurrencyField(item.deposit_actual),
                deposit_expected: this.agGridReportsService.prepareCurrencyField(item.deposit_expected),
                deposit_variance: this.agGridReportsService.prepareCurrencyField(item.deposit_variance),
                paid_in: this.agGridReportsService.prepareCurrencyField(item.paid_in),
                paid_out: this.agGridReportsService.prepareCurrencyField(item.paid_out)
            };
            filtered.push(tempObj);

            return filtered;
        }, []);
    }

    private async syncReportState(changed = false) {
        const currentState = await this.localDbStorageService.getItem('enterprise_summary_config');
        if (this.loadedDBStorageConfig === false) {
            this.loadedDBStorageConfig = true;
        }
        const filters = this._gridOptions.api.getFilterModel();
        const selectedColumns = this._gridOptions.columnApi.getAllDisplayedColumns().map(item => item.colId);
        this.columnsState = this.getPreparedSyncColumnsState([...this._gridOptions.columnApi.getColumnState()]);
        const sorts = this._gridOptions.api.getSortModel();

        const configObj = {
            filterModel: filters,
            sortModel: sorts,
            columnsState: this.columnsState,
            selectedColumns: selectedColumns,
        };

        if (!currentState) {
            this.groupedByDefault();
            this.sortByDefault();
            this.showSpecificColumnForDutchBros('net_total');
            configObj['buildVersion'] = this.buildVersion;
            this.localDbStorageService.setItem('enterprise_summary_config', configObj);
        } else {
            // check if deployed app version has conflicts with sync app version
            if (currentState && currentState.buildVersion) {
                if (currentState.buildVersion !== this.buildVersion && this.possibleBuildConflicts) {
                    this._gridOptions.columnApi.resetColumnState();
                    this.resetToDefault();
                    this.possibleBuildConflicts = false;
                    this.changeDetectorRef.markForCheck();
                }
                configObj['buildVersion'] =  currentState.buildVersion !== this.buildVersion
                    ? this.buildVersion : currentState.buildVersion;
            } else {
                configObj['buildVersion'] = this.buildVersion;
            }
        }

        if (changed) {
            this.localDbStorageService.setItem('enterprise_summary_config', configObj);
        } else if (currentState) {
            this._gridOptions.api.setFilterModel(currentState.filterModel);
            this._gridOptions.api.setSortModel(currentState.sortModel);
            this._gridOptions.columnApi.setColumnState(this.getPreparedSyncColumnsState(currentState.columnsState));
        }
    }

    private getPreparedSyncColumnsState(columnsState) {
        const predefinedColumnsIds = this.columnDefs.map(item => item.field);
        const dynamicFields = this.payTypes.map(item => item.pay_type);
        let lastWrongIndexOfColumn = null;

        // remove extra columns
        let allColumnsForOrder = [...columnsState
            .filter((column) => {
                if (column.colId.indexOf('_1') !== -1) {
                    return false;
                }

                return [...predefinedColumnsIds, ...dynamicFields].includes(column.colId);
            })];

        // check columns with wrong order
        allColumnsForOrder.forEach((column, index) => {
            if (dynamicFields.includes(column.colId) && index < 8) {
                lastWrongIndexOfColumn = index;
            }
        });

        if (lastWrongIndexOfColumn !== null) {
            const movableColumns = allColumnsForOrder.splice(0, lastWrongIndexOfColumn + 1);
            const necIndex = allColumnsForOrder.findIndex(item => item.colId === 'payment_total');
            allColumnsForOrder = this.insertArrayAt(allColumnsForOrder, necIndex + 1, movableColumns);
            this._gridOptions.columnApi.setColumnState(allColumnsForOrder);
            this.changeDetectorRef.detectChanges();
        }

        return allColumnsForOrder;
    }

    private groupedByDefault() {
        this.expandGroupedColumnByName('state_name');
        this._gridOptions.columnApi.getRowGroupColumns().forEach(column => {
            this._gridOptions.columnApi.setColumnVisible(column.colId, false);
        });
    }

    private insertArrayAt(array, index, arrayToInsert) {
        Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
        return array;
    }

    private expandGroupedColumnByName(name: string): void {
        this._gridOptions.api.collapseAll();
        this._gridOptions.api.forEachNode(function(node) {
            if (node.field === `ag-Grid-AutoColumn-${name}` || node.field === name) {
                node.setExpanded(true);
            }
        });
        this._gridOptions.api.onGroupExpandedOrCollapsed();
    }

    private getEnterpriseSalesData(data) {
        this.uiActions.enableContentLoader();
        this.siteIds = data.Site_Id || 'All';
        this.dateFrom = data.Start_Date;
        this.dateTo = data.End_Date;
        if (data.Calendar_Period === 'true' && data.Start_Date !== data.End_Date) {
            this.dateTo = moment(data.End_Date, 'YYYY-MM-DD')
                .subtract(1, 'day')
                .format('YYYY-MM-DD');
        }

        const request = {
            company: this.currentCompanyId,
            sites: this.siteIds,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo
        };
        this.changeDetectorRef.markForCheck();

        this.reportService.fetchEnterpriseSalesSummary(request)
            .pipe(takeWhile(() => this.isActive))
            .subscribe(
            (tempData: any) => {
                this.buildVersion = tempData.build;
                this.initGrid(tempData.data).then(() => {
                    this.uiActions.disableContentLoader();
                    this.changeDetectorRef.markForCheck();
                });
            },
            error => {
                this.uiActions.disableContentLoader();
                this.toasterService.pop('error', error.statusText);
            }
        );

        this.reportService.fetchEnterpriseSalesPayTypes(request)
            .pipe(takeWhile(() => this.isActive))
            .subscribe(
                (tempData: any) => {
                    this.payTypes = tempData
                        .filter((item: any) => {
                            if (isNull(item.pay_type)) {
                                return false;
                            }

                            if (item === 'dummy' || item.pay_type === 'dummy') {
                                return false;
                            }

                            return true;
                        })
                        .map(payType => {
                            if (isString(payType.pay_type)) {
                                payType.pay_type = payType.pay_type.trim().toLowerCase();
                            }
                            return payType;
                        });

                    this.payTypes = uniqBy(this.payTypes, 'pay_type');
                    this.changeDetectorRef.markForCheck();
                },
                error => {
                    this.toasterService.pop('error', error.statusText);
                }
            );
    }

    private clearFilters() {
        this._gridOptions.api.setFilterModel(null);
    }

    private addCustomBottomLabel(name: string) {
        this.agGridReportsService.addCustomBottomLabel(name, this.rowData);
    }
}
