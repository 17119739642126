import { Routes } from '@angular/router';
import { DashboardReportContainerComponent } from './dashboard_vega/dashboard.container';
import { SitesGuard } from '@containers/reports/d3/guards/sites.guard';
import { CompanyGuard } from '@containers/reports/d3/guards/company.guard';

export const vegaRoutes: Routes = [
  {
      path: 'vega/dashboard',
      component: DashboardReportContainerComponent,
      canActivate: [SitesGuard, CompanyGuard],
  }
];
