/**
 * Defines a component for rendering Vega and Ag-grid custom-reports
 */
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { REQUEST_QUERY_LIMIT_SIZE } from '@services/constants';
import { ToasterService, Toast } from 'angular2-toaster';

@Component({
    selector: 'opa-iframe-report',
    templateUrl: 'i-frame-report.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IFrameReportComponent {
    public iframeSrc: SafeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('about:blank');
    public showIframe: boolean = true;
    public customMessage: string = null;

    constructor(private domSanitizer: DomSanitizer, private toasterService: ToasterService) {}

    @Input()
    public set uri(link: string) {
        if (link.length >= REQUEST_QUERY_LIMIT_SIZE) {
            this.customMessage = 'Your filter holds up to too many values.';
            const toast: Toast = {
                type: 'error',
                title: `Whoa! That's Alot of Values!`,
                body: `Please select 300 or less values or click "Select All" to select all (more than 300) values.`,
                timeout: 15000
            };

            this.toasterService.pop(toast);
        } else {
            this.customMessage = null;
            this.toasterService.clear();
            this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(link || 'about:blank');
        }
    }
}
