import * as ToasterMessages from './toaster-messages';
import * as ButtonTemplates from './button-templates';
import * as ModalTemplates from './modal-templates';
import * as ErrorPageTemplates from './error-page-templates';
import * as APIErrorCodes from './api-error-types';

export const TOASTER_MESSAGES = ToasterMessages;
export const BUTTON_TEMPLATES = ButtonTemplates;
export const MODAL_TEMPLATES = ModalTemplates;
export const ERROR_PAGE_TEMPLATES = ErrorPageTemplates;
export const API_ERROR_CODES = APIErrorCodes;
