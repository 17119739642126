/**
 * LocalStorageService wraps up window.localStorage object.
 */
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    public namespace: string = 'global/';
    /**
     * Storage getter.
     * @param {string} key - property name.
     * @param {string} nameSpace - property nameSpace
     * @return Parsed data.
     */
    public getItem(key: string, nameSpace: string = null) {
        const namespace = nameSpace || this.namespace;
        let value;
        try {
            value = localStorage.getItem(namespace + key);
        } catch (e) {}
        return value ? JSON.parse(value) : null;
    }
    /**
     * Storage setter.
     * @param {string} key - property name.
     * @param {any} value - property value.
     * @param {string} nameSpace - property nameSpace
     * @return Parsed data.
     */
    public setItem(key: string, value: any, nameSpace: string = null) {
        const namespace = nameSpace || this.namespace;
        const data = JSON.stringify(value);
        let result = null;
        try {
            localStorage.setItem(namespace + key, data);
            result = JSON.parse(data);
        } catch (e) {}
        return result;
    }
    /**
     * Remove item from storage by property name.
     * @param {string} key - property name.
     * @param {string} nameSpace - property nameSpace
     */
    public removeItem(key, nameSpace: string = null) {
        const namespace = nameSpace || this.namespace;
        try {
            localStorage.removeItem(namespace + key);
        } catch (e) {}
    }
}

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    public namespace: string = 'reporting/';
    /**
     * Storage getter.
     * @param {string} key - property name.
     * @param {string} nameSpace - property nameSpace
     * @return Parsed data.
     */
    public getItem(key: string, nameSpace: string = null) {
        const namespace = nameSpace || this.namespace;
        let value;
        try {
            value = sessionStorage.getItem(namespace + key);
        } catch (e) {}
        return value ? JSON.parse(value) : null;
    }
    /**
     * Storage setter.
     * @param {string} key - property name.
     * @param {any} value - property value.
     * @param {string} nameSpace - property nameSpace
     * @return Parsed data.
     */
    public setItem(key: string, value: any, nameSpace: string = null) {
        const namespace = nameSpace || this.namespace;
        const data = JSON.stringify(value);
        let result = null;
        try {
            sessionStorage.setItem(namespace + key, data);
            result = JSON.parse(data);
        } catch (e) {}
        return result;
    }
    /**
     * Remove item from storage by property name.
     * @param {string} key - property name.
     * @param {string} nameSpace - property nameSpace
     */
    public removeItem(key, nameSpace: string = null) {
        const namespace = nameSpace || this.namespace;
        try {
            sessionStorage.removeItem(namespace + key);
        } catch (e) {}
    }
}
