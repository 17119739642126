import { Injectable } from '@angular/core';
import * as vegaTooltip from 'vega-tooltip';
import embed from 'vega-embed';

@Injectable()
export class VegaLiteConfigsService {
    constructor() {}

    public vegaLiteInit(id: string, vegaSpec, data, width, height) {
        const optionsTooltip = {
            offsetX: 6,
            offsetY: 10,
            theme: 'xsTooltip'
        };

        const handlerTooltip = new vegaTooltip.Handler(optionsTooltip);
        if (vegaSpec && vegaSpec['data'].length) {
            vegaSpec['data'][0]['values'] = data;
        }

        const options: any = {
            actions: false,
            width: width,
            height: height,
            tooltip: handlerTooltip.call,
            renderer: 'svg'
        };
        return embed('#' + id, vegaSpec, options);

        /* new vega.View(vega.parse(vegaSpec))
      .tooltip(handlerTooltip.call)
      .renderer('svg')          // set renderer (canvas or svg
      .initialize('#' + id)// initialize view within parent DOM container
      .resize()
      .width(width)               // set chart width
      .height(height)              // set chart height
      .hover()                  // enable hover encode set processing
      .run() */
    }

    // If full text contains a dollar sign then format for currency
    public getFormatFromText(measure_fulltext) {
        let fmt = '0,.0f';

        if (measure_fulltext.indexOf('Amt') > -1 || measure_fulltext.indexOf('Net') > -1) {
            fmt = '$0,.2f';
        }

        return fmt;
    }

    // Returns crossfilter vega chart spec with the 2 measures passed.
    public getChartCustomCrossfilterSales(
        measure_fieldname1 = 'net_sales',
        measure_fulltext1 = 'Net Sales',
        measure_fieldname2 = 'order_qty',
        measure_fulltext2 = 'Order Qty'
    ) {
        const width_full = 740; // Specify width of crossfilter
        const width_half = (width_full - 100) / 2;
        const measure_text1 = measure_fulltext1;
        const measure_text2 = measure_fulltext2;
        const meas_format1 = this.getFormatFromText(measure_fulltext1);
        const meas_format2 = this.getFormatFromText(measure_fulltext2);
        const meas_fullformat1 = this.getFormatFromText(measure_fulltext1);
        const meas_fullformat2 = this.getFormatFromText(measure_fulltext2);

        return {
            config: {
                view: { stroke: 'transparent' },
                mark: { tooltip: null },
                background: 'white',
                scale: { bandPaddingInner: 0.05 }
            },
            vconcat: [
                {
                    mark: { type: 'bar', opacity: 0.85 },
                    encoding: {
                        color: {
                            value: '#dcdcdc',
                            condition: { value: '#185b88', selection: 'selector_bdate' }
                        },
                        tooltip: [
                            { type: 'nominal', field: 'date_short', title: 'Title' },
                            {
                                type: 'quantitative',
                                aggregate: 'sum',
                                field: measure_fieldname1,
                                format: meas_fullformat1,
                                title: measure_text1
                            }
                        ],
                        x: {
                            type: 'nominal',
                            axis: {
                                grid: false,
                                labelAngle: 315,
                                tickMinStep: 1,
                                ticks: true
                            },
                            field: 'date_short',
                            sort: { field: 'business_date:N' },
                            title: null
                        },
                        y: {
                            type: 'quantitative',
                            aggregate: 'sum',
                            axis: { format: meas_format1, labelFlush: false },
                            field: measure_fieldname1,
                            scale: { zero: true },
                            title: null
                        }
                    },
                    height: 100,
                    selection: {
                        selector_bdate: { type: 'multi', empty: 'all', encodings: ['x'] }
                    },
                    title: 'By Business Date: ' + measure_fulltext1,
                    width: width_full
                },
                {
                    hconcat: [
                        {
                            vconcat: [
                                {
                                    layer: [
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#dcdcdc' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'site_name',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname1,
                                                        format: meas_fullformat1,
                                                        title: measure_text1
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format1,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname1,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'site_name',
                                                    sort: null,
                                                    title: null
                                                }
                                            },
                                            selection: {
                                                selector_dims: {
                                                    type: 'multi',
                                                    empty: 'all',
                                                    encodings: ['y']
                                                }
                                            },
                                            title: 'Store: ' + measure_fulltext1,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.site_name != ""' }
                                            ],
                                            width: width_half
                                        },
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#9cc568' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'site_name',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname1,
                                                        format: meas_fullformat1,
                                                        title: measure_text1
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format1,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname1,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'site_name',
                                                    sort: null,
                                                    title: null
                                                }
                                            },
                                            title: 'Store: ' + measure_fulltext1,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.site_name != ""' },
                                                { filter: { selection: 'selector_dims' } }
                                            ],
                                            width: width_half
                                        }
                                    ]
                                },
                                {
                                    layer: [
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#dcdcdc' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'destination',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname1,
                                                        format: meas_fullformat1,
                                                        title: measure_text1
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format1,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname1,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'destination',
                                                    title: null
                                                }
                                            },
                                            selection: {
                                                selector_dims: {
                                                    type: 'multi',
                                                    empty: 'all',
                                                    encodings: ['y']
                                                }
                                            },
                                            title: 'By Destination: ' + measure_fulltext1,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.destination != ""' }
                                            ],
                                            width: width_half
                                        },
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#9cc568' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'destination',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname1,
                                                        format: meas_fullformat1,
                                                        title: measure_text1
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format1,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname1,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'destination',
                                                    title: null
                                                }
                                            },
                                            title: 'By Destination: ' + measure_fulltext1,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.destination != ""' },
                                                { filter: { selection: 'selector_dims' } }
                                            ],
                                            width: width_half
                                        }
                                    ]
                                },
                                {
                                    layer: [
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#dcdcdc' },
                                                tooltip: [
                                                    { type: 'nominal', field: 'daypart', title: 'Title' },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname1,
                                                        format: meas_fullformat1,
                                                        title: measure_text1
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format1,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname1,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'daypart',
                                                    sort: null,
                                                    title: null
                                                }
                                            },
                                            selection: {
                                                selector_dims: {
                                                    type: 'multi',
                                                    empty: 'all',
                                                    encodings: ['y']
                                                }
                                            },
                                            title: 'By Day Part: ' + measure_fulltext1,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.daypart != ""' }
                                            ],
                                            width: width_half
                                        },
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#9cc568' },
                                                tooltip: [
                                                    { type: 'nominal', field: 'daypart', title: 'Title' },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname1,
                                                        format: meas_fullformat1,
                                                        title: measure_text1
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format1,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname1,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'daypart',
                                                    sort: null,
                                                    title: null
                                                }
                                            },
                                            title: 'By Day Part: ' + measure_fulltext1,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.daypart != ""' },
                                                { filter: { selection: 'selector_dims' } }
                                            ],
                                            width: width_half
                                        }
                                    ]
                                },
                                {
                                    layer: [
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#dcdcdc' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'time_hour',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname1,
                                                        format: meas_fullformat1,
                                                        title: measure_text1
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format1,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname1,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'time_hour',
                                                    sort: null,
                                                    title: null
                                                }
                                            },
                                            selection: {
                                                selector_dims: {
                                                    type: 'multi',
                                                    empty: 'all',
                                                    encodings: ['y']
                                                }
                                            },
                                            title: 'By Hour: ' + measure_fulltext1,
                                            transform: [{ filter: { selection: 'selector_bdate' } }],
                                            width: width_half
                                        },
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#9cc568' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'time_hour',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname1,
                                                        format: meas_fullformat1,
                                                        title: measure_text1
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format1,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname1,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'time_hour',
                                                    sort: null,
                                                    title: null
                                                }
                                            },
                                            title: 'By Hour: ' + measure_fulltext1,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: { selection: 'selector_dims' } }
                                            ],
                                            width: width_half
                                        }
                                    ]
                                },
                                {
                                    layer: [
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#dcdcdc' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'terminal',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname1,
                                                        format: meas_fullformat1,
                                                        title: measure_text1
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format1,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname1,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'terminal',
                                                    title: null
                                                }
                                            },
                                            selection: {
                                                selector_dims: {
                                                    type: 'multi',
                                                    empty: 'all',
                                                    encodings: ['y']
                                                }
                                            },
                                            title: 'By Terminal: ' + measure_fulltext1,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.terminal != ""' }
                                            ],
                                            width: width_half
                                        },
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#9cc568' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'terminal',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname1,
                                                        format: meas_fullformat1,
                                                        title: measure_text1
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format1,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname1,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'terminal',
                                                    title: null
                                                }
                                            },
                                            title: 'By Terminal: ' + measure_fulltext1,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.terminal != ""' },
                                                { filter: { selection: 'selector_dims' } }
                                            ],
                                            width: width_half
                                        }
                                    ]
                                },
                                {
                                    layer: [
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#dcdcdc' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'employee',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname1,
                                                        format: meas_fullformat1,
                                                        title: measure_text1
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format1,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname1,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'employee',
                                                    title: null
                                                }
                                            },
                                            selection: {
                                                selector_dims: {
                                                    type: 'multi',
                                                    empty: 'all',
                                                    encodings: ['y']
                                                }
                                            },
                                            title: 'By Employee: ' + measure_fulltext1,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.employee != ""' }
                                            ],
                                            width: width_half
                                        },
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#9cc568' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'employee',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname1,
                                                        format: meas_fullformat1,
                                                        title: measure_text1
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format1,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname1,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'employee',
                                                    title: null
                                                }
                                            },
                                            title: 'By Employee: ' + measure_fulltext1,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.employee != ""' },
                                                { filter: { selection: 'selector_dims' } }
                                            ],
                                            width: width_half
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            vconcat: [
                                {
                                    layer: [
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#dcdcdc' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'site_name',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname2,
                                                        format: meas_fullformat2,
                                                        title: measure_text2
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format2,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname2,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'site_name',
                                                    sort: null,
                                                    title: null
                                                }
                                            },
                                            selection: {
                                                selector_dims: {
                                                    type: 'multi',
                                                    empty: 'all',
                                                    encodings: ['y']
                                                }
                                            },
                                            title: 'Store: ' + measure_fulltext2,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.site_name != ""' }
                                            ],
                                            width: width_half
                                        },
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#7cc0e9' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'site_name',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname2,
                                                        format: meas_fullformat2,
                                                        title: measure_text2
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format2,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname2,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'site_name',
                                                    sort: null,
                                                    title: null
                                                }
                                            },
                                            title: 'Store: ' + measure_fulltext2,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.site_name != ""' },
                                                { filter: { selection: 'selector_dims' } }
                                            ],
                                            width: width_half
                                        }
                                    ]
                                },
                                {
                                    layer: [
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#dcdcdc' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'destination',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname2,
                                                        format: meas_fullformat2,
                                                        title: measure_text2
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format2,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname2,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'destination',
                                                    title: null
                                                }
                                            },
                                            selection: {
                                                selector_dims: {
                                                    type: 'multi',
                                                    empty: 'all',
                                                    encodings: ['y']
                                                }
                                            },
                                            title: 'By Destination: ' + measure_fulltext2,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.destination != ""' }
                                            ],
                                            width: width_half
                                        },
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#7cc0e9' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'destination',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname2,
                                                        format: meas_fullformat2,
                                                        title: measure_text2
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format2,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname2,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'destination',
                                                    title: null
                                                }
                                            },
                                            title: 'By Destination: ' + measure_fulltext2,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.destination != ""' },
                                                { filter: { selection: 'selector_dims' } }
                                            ],
                                            width: width_half
                                        }
                                    ]
                                },
                                {
                                    layer: [
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#dcdcdc' },
                                                tooltip: [
                                                    { type: 'nominal', field: 'daypart', title: 'Title' },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname2,
                                                        format: meas_fullformat2,
                                                        title: measure_text2
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format2,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname2,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'daypart',
                                                    sort: null,
                                                    title: null
                                                }
                                            },
                                            selection: {
                                                selector_dims: {
                                                    type: 'multi',
                                                    empty: 'all',
                                                    encodings: ['y']
                                                }
                                            },
                                            title: 'By Day Part: ' + measure_fulltext2,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.daypart != ""' }
                                            ],
                                            width: width_half
                                        },
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#7cc0e9' },
                                                tooltip: [
                                                    { type: 'nominal', field: 'daypart', title: 'Title' },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname2,
                                                        format: meas_fullformat2,
                                                        title: measure_text2
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format2,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname2,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'daypart',
                                                    sort: null,
                                                    title: null
                                                }
                                            },
                                            title: 'By Day Part: ' + measure_fulltext2,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.daypart != ""' },
                                                { filter: { selection: 'selector_dims' } }
                                            ],
                                            width: width_half
                                        }
                                    ]
                                },
                                {
                                    layer: [
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#dcdcdc' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'time_hour',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname2,
                                                        format: meas_fullformat2,
                                                        title: measure_text2
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format2,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname2,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'time_hour',
                                                    sort: null,
                                                    title: null
                                                }
                                            },
                                            selection: {
                                                selector_dims: {
                                                    type: 'multi',
                                                    empty: 'all',
                                                    encodings: ['y']
                                                }
                                            },
                                            title: 'By Hour: ' + measure_fulltext2,
                                            transform: [{ filter: { selection: 'selector_bdate' } }],
                                            width: width_half
                                        },
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#7cc0e9' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'time_hour',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname2,
                                                        format: meas_fullformat2,
                                                        title: measure_text2
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format2,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname2,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'time_hour',
                                                    sort: null,
                                                    title: null
                                                }
                                            },
                                            title: 'By Hour: ' + measure_fulltext2,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: { selection: 'selector_dims' } }
                                            ],
                                            width: width_half
                                        }
                                    ]
                                },
                                {
                                    layer: [
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#dcdcdc' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'terminal',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname2,
                                                        format: meas_fullformat2,
                                                        title: measure_text2
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format2,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname2,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'terminal',
                                                    title: null
                                                }
                                            },
                                            selection: {
                                                selector_dims: {
                                                    type: 'multi',
                                                    empty: 'all',
                                                    encodings: ['y']
                                                }
                                            },
                                            title: 'By Terminal: ' + measure_fulltext2,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.terminal != ""' }
                                            ],
                                            width: width_half
                                        },
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#7cc0e9' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'terminal',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname2,
                                                        format: meas_fullformat2,
                                                        title: measure_text2
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format2,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname2,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'terminal',
                                                    title: null
                                                }
                                            },
                                            title: 'By Terminal: ' + measure_fulltext2,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.terminal != ""' },
                                                { filter: { selection: 'selector_dims' } }
                                            ],
                                            width: width_half
                                        }
                                    ]
                                },
                                {
                                    layer: [
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#dcdcdc' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'employee',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname2,
                                                        format: meas_fullformat2,
                                                        title: measure_text2
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format2,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname2,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'employee',
                                                    title: null
                                                }
                                            },
                                            selection: {
                                                selector_dims: {
                                                    type: 'multi',
                                                    empty: 'all',
                                                    encodings: ['y']
                                                }
                                            },
                                            title: 'By Employee: ' + measure_fulltext2,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.employee != ""' }
                                            ],
                                            width: width_half
                                        },
                                        {
                                            mark: 'bar',
                                            encoding: {
                                                color: { value: '#7cc0e9' },
                                                tooltip: [
                                                    {
                                                        type: 'nominal',
                                                        field: 'employee',
                                                        title: 'Title'
                                                    },
                                                    {
                                                        type: 'quantitative',
                                                        aggregate: 'sum',
                                                        field: measure_fieldname2,
                                                        format: meas_fullformat2,
                                                        title: measure_text2
                                                    }
                                                ],
                                                x: {
                                                    type: 'quantitative',
                                                    aggregate: 'sum',
                                                    axis: {
                                                        format: meas_format2,
                                                        labelFlush: false,
                                                        tickMinStep: 1
                                                    },
                                                    field: measure_fieldname2,
                                                    scale: { zero: true },
                                                    title: null
                                                },
                                                y: {
                                                    type: 'nominal',
                                                    axis: { maxExtent: 50, minExtent: 50 },
                                                    field: 'employee',
                                                    title: null
                                                }
                                            },
                                            title: 'By Employee: ' + measure_fulltext2,
                                            transform: [
                                                { filter: { selection: 'selector_bdate' } },
                                                { filter: 'datum.employee != ""' },
                                                { filter: { selection: 'selector_dims' } }
                                            ],
                                            width: width_half
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            data: { values: [] },
            $schema: 'https://vega.github.io/schema/vega-lite/v4.13.0.json'
        };
    }
}
