import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FieldPreferences } from '../../../../shared/services/sheme-form/sheme-form.interface';
import { LocalDbStorageService } from '../../../../shared/services/local-db-storage';

interface ICombinedFilterData {
    name: string;
    value: string;
    placeholder?: string;
}

@Component({
    selector: 'combined-select-filter',
    templateUrl: 'combined-select-filter.html',
    styleUrls: ['combined-select-filter.scss']
})
export class CombinedSelectFilterComponent implements OnInit {
    @Input()
    public set filter(filter: any) {
        this.prepareSelectOptions(filter.data);
        this._filter = filter;
    }

    public get filter() {
        return this._filter;
    }

    @Input() type: string = '';
    @Output() handleChanges = new EventEmitter();
    protected items: ICombinedFilterData[] = [];
    public label: string = '';
    public error: string = '';
    protected _filter: object;
    private attributes: string[] = [];
    private name: string = '';
    private inputValue: any = '';
    public inputField = {
        control: new FormControl(),
        placeholder: '',
        inputType: 'text'
    };
    public xuxSelect: FieldPreferences = {
        control: new FormControl(),
        required: false,
        defaultValue: '',
        itemClass: 'combined-select__select',
        selectOptions: []
    };

    constructor(public localDbStorageService: LocalDbStorageService) {}

    ngOnInit() {
        this.syncReportFiltersState();
    }

    public changeInputValue(event) {
        this.inputValue = event.target.value;
        this.checkValidators(this.attributes[0]);
        this.handleSelectFilterChange();
    }

    protected handleSelectFilterChange() {
        const value = {};
        if (this.inputValue.length) {
            value[this.attributes[0]] = this.inputValue;
        } else {
            value[this.attributes[0]] = '';
        }

        const res = this.error.length
            ? { value, errors: [this.error], name: this.name, type: this.filter['type'] }
            : { value, name: this.name, type: this.filter['type'] };
        this.handleChanges.emit(res);
    }

    public onSelectedItemChanged(data) {
        const elem = this.items.find(item => item.value === data);
        this.prepareInputOption(elem.placeholder, elem.value);
        this.attributes[0] = elem.value;

        const configObj = {
            searchByNumber: elem.value
        };

        this.localDbStorageService.setItem('order_explorer_filters', configObj);
        this.handleSelectFilterChange();
    }

    private prepareSelectOptions(data) {
        this.xuxSelect.selectOptions = data.map(item => {
            if (item.value === 'Order_Number') {
                this.attributes[0] = item.value;
                this.xuxSelect.control.setValue('Order_Number');
                this.prepareInputOption(item.placeholder, item.value);
            }
            return {
                value: item.value,
                text: item.name
            };
        });
    }

    private async syncReportFiltersState() {
        const currentState = await this.localDbStorageService.getItem('order_explorer_filters');

        if (currentState) {
            this.xuxSelect.control.setValue(currentState.searchByNumber);
            this.attributes[0] = currentState.searchByNumber;
        } else {
            this.attributes[0] = this.filter['attributes'][0];
        }

        this.name = this.filter['name'];
        this.label = this.filter['label'];

        if (this.filter['data']) {
            this.items = this.filter['data'];
            if (currentState) {
                const elem = this.items.find(item => item.value === currentState.searchByNumber);
                this.prepareInputOption(elem.placeholder, elem.value);
            }

            this.handleSelectFilterChange();
        }
    }

    private prepareInputOption(placeholder: string, value: string) {
        this.inputField.placeholder = placeholder;

        this.setValidators(value);
        this.checkValidators(value);
    }

    private setValidators(value: string) {
        const control = this.inputField.control;

        if (!control) {
            return;
        }
        switch (value) {
            case 'Order_Number':
                control.setValidators([
                    Validators.minLength(1),
                    Validators.maxLength(225),
                    Validators.pattern('[a-zA-Z0-9]*')
                ]);
                break;
            case 'Card_Number':
                control.setValidators([Validators.minLength(4), Validators.maxLength(4), Validators.pattern('[0-9]*')]);
                break;
            case 'Table_Number':
                control.setValidators([
                    Validators.minLength(1),
                    Validators.maxLength(225),
                    Validators.pattern('[a-zA-Z0-9]*')
                ]);
                break;
            default:
                control.setValidators(Validators.maxLength(225));
        }

        if (this.inputValue.length) {
            control.markAsDirty();
            control.markAsTouched();
        }

        control.updateValueAndValidity();
    }

    private checkValidators(type: string) {
        const control = this.inputField.control;
        if ((control.dirty && control.valid) || (!control.dirty && !control.valid)) {
            this.error = '';
            return;
        }
        if (!control.errors) {
            this.error = '';
            return;
        }

        switch (type) {
            case 'Order_Number':
                this.error = control.errors.pattern
                    ? 'Value should consist of only digits and letters'
                    : 'Value must be at most 15 characters long.';
                break;
            case 'Card_Number':
                this.error = control.errors.pattern
                    ? 'Value should consist of only digits'
                    : 'Value must be at 4 characters long.';
                break;
            default:
                this.error = 'Invalid value';
        }
    }
}
