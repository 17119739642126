import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { IMenuItem } from '../../interfaces';
import { IReport, IReportState } from '@shared/types/report';
import { ISessionState } from '@shared/types/session';
import { ICompanyState } from '@shared/types/company';
import { UiActions } from '@store/actions';
import { ReportsService, PreferencesService } from '@shared/services';
import { OpraDataActions } from '@store/actions';
import { takeWhile, filter } from 'rxjs/operators';
import { isNumber } from 'lodash';

@Component({
    selector: 'xux-drawer-sidebar',
    templateUrl: './xux-drawer-sidebar.component.html',
    styleUrls: ['./xux-drawer-sidebar.component.scss']
})
export class XUXDrawerSidebarComponent implements OnInit {
    // @ts-ignore
    @ViewChild('modalContainer', { read: ViewContainerRef }) modals;
    @select(['ui', 'menuItems']) menuItems$: Observable<any>;
    @select(['reports']) reports$: Observable<any>;

    public menuItems: IMenuItem[];
    public reportsState: Observable<IReportState>;
    public sessionState: Observable<ISessionState>;
    public companiesState: Observable<ICompanyState>;
    public reports: IReport[] = [];
    public needShowReports: boolean = true;
    public needShowFilters: boolean = true;
    public needShowBackToCompanies: boolean = false;
    private alive: boolean = true;

    constructor(
        private router: Router,
        private store: NgRedux<any>,
        private ui: UiActions,
        private opraDataActions: OpraDataActions,
        private reportsService: ReportsService,
        private preferencesService: PreferencesService,
    ) {
        this.reportsState = this.store.select('reports');
        this.sessionState = this.store.select('userSession');
        this.companiesState = this.store.select('companies');
    }

    ngOnInit(): void {
        this.reports$.pipe(
            takeWhile(() => this.alive),
            filter(reportState => reportState?.reports && this.reports !== reportState?.reports)
        ).subscribe(reportState => {
            this.prepareMenuItems(reportState.reports);

            const isReportSelected = reportState.selectedReport && reportState.selectedReport['path'];
            this.needShowFilters = isReportSelected;
            this.needShowReports = !isReportSelected;
        });

        this.companiesState.pipe(takeWhile(() => this.alive)).subscribe(companies => {
            if (companies && companies.companies && companies.companies.length) {
                this.needShowBackToCompanies = true;
            } else {
                this.needShowBackToCompanies = false;
            }
        });
    }

    public backToReports() {
        this.reportsService.selectReport({});
        this.router.navigate(['custom-reports']);
    }

    public showCompanySelector() {
        this.opraDataActions.showCompanySelector();
    }

    private prepareMenuItems(reports: IReport[]) {
        this.reports = [ ...reports ];
        const newMenuItems = [];
        this.reports.forEach(report => {
            const reportItem = {
                title: report['custom_navigation_title'] || report.name,
                routing: report.url || '/custom-reports/report/' + report.id.replace('/', '_'),
                index: isNumber(report['index']) ? report['index'] : null
            };
            const menuItem = {
                title: report['category'],
                index: isNumber(report['category_index']) ? report['category_index'] : null,
                active: false,
                sub: []
            };
            const categoryItem = newMenuItems.find(obj => obj.title === report['category']);
            if (!categoryItem) {
                menuItem.sub.push(reportItem);
                newMenuItems.push(menuItem);
            } else {
                categoryItem.sub.push(reportItem);
            }

            if (report.id === 'sales_detail_report') {
                this.checkAndSaveDayPartProperties(report);
            }
        });
        this.ui.setSidebarItems(newMenuItems);
    }

    private checkAndSaveDayPartProperties(report: IReport) {
        if (report?.day_parts.length && !this.preferencesService.dayPartOrderingPreferences) {
            this.preferencesService.setDayPartOrderingPreferences(report.day_parts);
        }
    }
}

