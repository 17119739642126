import { IToolbarButtonDefinition } from '../../shared/types';

export const BTN_OK: IToolbarButtonDefinition = {
  caption: 'Ok',
  value: 'ok',
  class: 'pull-right btn-default',
};

export const BTN_YES: IToolbarButtonDefinition = {
  caption: 'Yes',
  value: 'ok',
  class: 'pull-right btn-primary',
};

export const BTN_NO: IToolbarButtonDefinition = {
  caption: 'No',
  value: 'cancel',
  class: 'pull-right btn-default',
};

export const BTN_DONE: IToolbarButtonDefinition = {
  caption: 'Done',
  value: 'ok',
  class: 'pull-right btn-primary',
  glyphicon: 'glyphicon-ok-sign white',
};

export const BTN_CANCEL: IToolbarButtonDefinition = {
  caption: 'Cancel',
  value: 'cancel',
  class: 'pull-right btn-default',
  glyphicon: 'glyphicon-remove-sign red',
};

export const BTN_SELECT_SITES: IToolbarButtonDefinition = {
  value: 'sites',
  caption: 'Select Sites',
  class: 'btn btn-default',
  disabled: false,
  glyphicon: 'glyphicon glyphicon-th-list',
};

export const BTN_EDIT: IToolbarButtonDefinition = {
  value: 'edit',
  caption: 'Edit',
  class: 'btn btn-default',
  disabled: false,
  glyphicon: 'glyphicon glyphicon-pencil',
};

export const BTN_VIEW: IToolbarButtonDefinition = {
  value: 'view',
  caption: 'View',
  class: 'btn btn-default',
  disabled: false,
  glyphicon: 'glyphicon glyphicon-eye-open',
};

export const BTN_REMOVE: IToolbarButtonDefinition = {
  value: 'remove',
  caption: 'remove',
  class: 'btn btn-default',
  disabled: false,
  glyphicon: 'glyphicon glyphicon-remove-sign',
};

export const BTN_CREATE: IToolbarButtonDefinition = {
  value: 'create',
  caption: 'Create',
  class: 'btn btn-default',
  disabled: false,
  glyphicon: 'glyphicon glyphicon-plus-sign',
};

export const BTN_MAPPING: IToolbarButtonDefinition = {
  value: 'mapping',
  caption: 'site mapping',
  class: 'btn btn-default',
  disabled: false,
  glyphicon: 'glyphicon glyphicon-pushpin',
};
