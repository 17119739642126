import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { PreferencesService } from '../shared/services/preferences/preferences.service';

@Pipe({
    name: 'customDateFormat'
})
export class DateFormatPipe implements PipeTransform {

    constructor(private preferencesService: PreferencesService) {}

    public transform(value: Date | moment.Moment | string | number, format: string): string {
        const timeLocale = this.preferencesService.timeLocale;
        moment.locale(timeLocale);
        if (format === 'L LT') {
            return `${moment(value).local().format('L')} ${moment(value).local().format(this.preferencesService.correctMomentTimeFormat('LT'))}`;
        }

        return moment(value).local().format(format);
    }

}
