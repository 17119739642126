/**
 * helper function that creates formDefinition object from JSON schema and
 * additional user settings (formRules)
 */
import { omit, get, set, map, startCase, merge, isBoolean, has } from 'lodash';
import { FormGroup } from '@angular/forms';
import { of } from 'rxjs';

import { IFieldPreferences, IFormRules, IFormGroupPreferences } from './schema-form-interfaces';

import {
    extractSchemaFields,
    preferredFieldWidgetType,
    addWidgetSpecificData,
    resolveGroupLinks,
    getFormControl,
    composeSelectOptions,
    isRequired,
    createDeferedValueObservable,
    dynamicPropertyToObservable,
    normalizeDynamicProperty
} from './schema-form-functions';

import { widgetOption } from './widget-options';
import { isObservable } from '../../../../shared/utils';
import { distinctUntilChanged, share, map as ObservaleMap } from 'rxjs/operators';

/**
 * Create form definition from scheme and form rules.
 * @returns {IFormGroupPreferences[]}
 */
export const createFormDefinitionsFromSchema = (
    schema: Object = {},
    rules: IFormRules = {},
    form: FormGroup = null
): IFormGroupPreferences[] => {
    const { groups: originalGroups = [], fields = {}, defaultGroup = 'General' } = rules || {};

    // adds default empty fields array into every group
    const groups = originalGroups.map(group =>
        Object.assign(
            {
                fields: [],
                hiddenObs: null,
                disabledObs: null
            },
            group
        )
    );

    const allFields = extractSchemaFields(schema);
    const allFieldPaths = Object.keys(allFields);

    // Observable used as the source of formValues for dynamic properties mappers
    const formValueObs = createDeferedValueObservable(form);

    /**
     * fillFieldDefaults
     *
     * combines following data into a single object:
     *   default field preferences,
     *   schema node data,
     *   user defined data from rules
     * @returns {IFieldPreferences}
     */

    const composeFieldProperties = (path): IFieldPreferences => {
        const name = path.split('.').pop();
        const schemaNode = allFields[path] || {};
        const schemaPrefs = merge({ path, name }, omit(schemaNode, 'properties', 'required'));
        const fieldRules = get(fields, path, {});
        const widget = get(fieldRules, 'widget', get(schemaNode, 'widget', preferredFieldWidgetType(schemaPrefs)));
        const preferences = Object.assign(
            {
                widget,
                control: getFormControl(form, path),
                title: widgetOption(widget).autoTitle ? startCase(name) : '',
                schema: schemaNode,
                required: isRequired(schema, path)
            },
            schemaPrefs,
            fieldRules
        );

        return preferences;
    };

    const hideDeprecatedFields = (field: IFieldPreferences): IFieldPreferences => {
        if (has(field, 'deprecationInfo')) {
            return Object.assign({}, field, {
                show: false,
                widget: 'input'
            });
        }
        return field;
    };

    const addVisibilityHandler = (fieldProps: IFieldPreferences) => {
        // The default show value is a function that returns true.
        // In opposite to a plain value, function predicate will
        // pay attention to control presence and status.
        const { control, show = () => true, widget, hiddenObs } = fieldProps;
        // Use hiddenObs observable if provided. Ignore show parameter.
        if (isObservable(hiddenObs)) {
            return fieldProps;
        }
        const needsControl = widgetOption(widget).hasControl;
        const showPredicate = normalizeDynamicProperty(show);

        const getHiddenObs = () => {
            // Always use static 'show' value if provided.
            // The control presence and state will be ignored.
            // WARNING. It is possible to display widgets, that are not intended
            // to be used without a control.
            if (isBoolean(showPredicate)) {
                return of(!showPredicate);
            }

            // Always hide a field if it needs a control but does not have one.
            // Not necessary but saves some resources by preventing excessive emits
            if (needsControl && !control) {
                return of(true);
            }

            // Common case.
            // Sometimes it looks like (needsControl && control.disabled) does not
            // need to be update on each formValue change, but it DOES.
            // Disabled controls values are excluded from the combined form value, so watching
            // form.valueChanges is an easy way to also react to control.disabled state changes.
            return formValueObs.pipe(
                ObservaleMap(formValue => (needsControl && control.disabled) || !showPredicate(formValue)),
                distinctUntilChanged(),
                share()
            );
        };

        return Object.assign(fieldProps, { hiddenObs: getHiddenObs() });
    };

    const addDisabledHandler = (fieldProps: IFieldPreferences): IFieldPreferences => {
        if (isObservable(get(fieldProps, 'disabledObs'))) {
            return fieldProps;
        }
        const disabledObs = dynamicPropertyToObservable(formValueObs, get(fieldProps, 'disabled'));

        return Object.assign(fieldProps, { disabledObs });
    };

    /**
     * fills each field in fields property with default data
     */
    const fillGroupFields = (group: IFormGroupPreferences): IFormGroupPreferences =>
        Object.assign({}, group, {
            fields: group.fields
                .map(composeFieldProperties)
                .map(hideDeprecatedFields)
                .map(addWidgetSpecificData)
                .map(composeSelectOptions)
                .map(addDisabledHandler)
                .map(addVisibilityHandler)
        });

    const userDefinedFieldPaths = [].concat(...map(groups, 'fields'));

    const defaultFieldPaths = allFieldPaths.filter(fieldPath => !userDefinedFieldPaths.includes(fieldPath));

    // TODO refactor the defaultGroup options to point on group ID instead of name
    if (defaultGroup !== false) {
        const defaultGroupObject = groups.find(({ name, id }) => name === defaultGroup || id === 'default');

        if (defaultGroupObject) {
            defaultGroupObject.fields = (defaultGroupObject.fields || []).concat(defaultFieldPaths);
        } else {
            groups.push({
                id: 'default',
                name: defaultGroup.toString(),
                fields: defaultFieldPaths,
                hiddenObs: null,
                disabledObs: null
            });
        }
    }

    const mappedGroups = groups.map(fillGroupFields);
    mappedGroups.forEach(resolveGroupLinks);

    mappedGroups.forEach(group => {
        const { hidden = false, disabled = false } = group;
        set(group, 'hiddenObs', dynamicPropertyToObservable(formValueObs, hidden));
        set(group, 'disabledObs', dynamicPropertyToObservable(formValueObs, disabled));
    });

    return mappedGroups;
};
