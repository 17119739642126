/**
 * SidebarMiddleware
 */

import { Injectable } from '@angular/core';

import { UiActions } from '../../actions';
import { IMenuItem } from '../../../shared/types';
import { SidebarService } from '../../../shared/services/sidebar';

/**
 * # SidebarMiddleware
 * Defines the class for handling sidebar actions
 */

@Injectable()
export class SidebarMiddleware {
    /**
     * Initialize the instance
     */
  constructor(
        private _sidebarService: SidebarService
    ) {}

    /**
     * This function handle all dispatched actions of sidebar.
     * If action type is SELECT_SIDEBAR_ITEM then function gets
     * menu item by route and pass it down the chain.
     * Otherwise function just pass action down the chain.
     *
     */
  middleware = store => next => (action) => {
    switch (action.type) {
      case UiActions.SELECT_SIDEBAR_ITEM:
        this._sidebarService.getItemByRoute(action.payload.route)
                    .then((route: IMenuItem) => {
                      action.payload = { route };
                      return next(action);
                    });
        break;
      default:
        return next(action);
    }
  }
}
