/**
 * Validation service
 */
import { Injectable } from '@angular/core';
import { FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { pick, isFunction, get, find, startCase, isFinite, isNaN, isNull, has, isEmpty } from 'lodash';
import { isCloseToInteger } from '../../../../shared/utils';

import { IMAGE_ACCEPT_OPTIONS, MAX_IMAGE_SIZE } from '../../../../../config';
import { IAbstractControls } from '../../../../shared/types/ui/utility.type';
/* tslint:disable */
export interface IErrorDefinition {
    code: string;
    message?: string;
}

export type ComparatorFn = (actual: any, expected: any) => boolean;

/**
 * Converts JSON pointer to a FormControl path accessor array
 * e.g. 2/some/field to [parent,parent,controls,some,controls,field,value]
 */
const jsonPointerPath = pointer => {
    const [level, ...path] = pointer.split('/');
    return []
        .concat(level === '' ? 'root' : Array(+level).fill('parent'))
        .concat(...path.map(node => ['controls', node]), 'value');
};

/**
 * @param input - a static sample, function or a subject to which control value is to be compared
 * @param controlValue - current control value, that may be used to compose messages or validators
 */
const dynamicResolver = (input, control?) => {
    if (isFunction(input)) {
        return input(control);
    }
    // subject-like object or formControl
    if (has(input, 'observers')) {
        return input.value;
    }

    if (has(input, '$data')) {
        const path = jsonPointerPath(input.$data);
        return get(control, path);
    }
    return input;
};

const errorResolver = (input, actual?, expected?) => {
    return isFunction(input) ? input(actual, expected) : input;
};

const createValidator = (errorType, comparator: ComparatorFn) => {
    return (inputSample?, errorMessage = ValidationService.messages[errorType]) => {
        return control => {
            const expected = dynamicResolver(inputSample, control);
            const actual = control.value;
            const isValid = comparator(actual, expected);
            return isValid ? null : { [errorType]: errorResolver(errorMessage, actual, expected) };
        };
    };
};

@Injectable()
export class ValidationService {
    constructor() {}
    public static messages = {
        emailValidationError: 'Email address is not valid.',
        invalidImageURL: 'Image format is not supported.',
        invalidURL: 'Url format is not correct.',
        minValue: (actual, expected) => `Value should be at least ${expected}.`,
        maxValue: (actual, expected) => `Value should not be greater than ${expected}.`,
        strictMinValue: (actual, expected) => `Value should be more than ${expected}.`,
        strictMaxValue: (actual, expected) => `Value should be less than ${expected}.`,
        minTimeValue: (actual, expected) => `Value should be at least ${ValidationService.formatTime(expected)}.`,
        maxTimeValue: (actual, expected) =>
            `Value should not be greater than ${ValidationService.formatTime(expected)}.`,
        minDateValue: (actual, expected) => `Value should be at least ${new Date(expected).toLocaleDateString()}.`,
        maxDateValue: (actual, expected) =>
            `Value should not be greater than ${new Date(expected).toLocaleDateString()}.`,
        invalidYear: 'Year is not valid',
        invalidTime: 'Time is not valid',
        minLength: (actual, expected) => `Field needs to be at least ${expected} characters.`,
        maxLength: (actual, expected) => `Field needs to be no longer than ${expected} characters.`,
        minArrayLength: (actual, expected) => `Array field should have at least ${expected} elements.`,
        maxArrayLength: (actual, expected) => `Array field should have no more than ${expected} elements.`,
        invalidJsonDataParse: 'JSON data parse is not valid.',
        invalidJsonDataCompile: 'JSON data compile is not valid.',
        invalidNumericValue: () => 'Invalid numeric value.',
        atLeastOneRequired: (actual, expected) => `At least one field of ${expected} should be filled in.`,
        requiredValues: (actual, expected) => `This values should be selected ${expected.join(', ')}.`,
        requiredValue: 'This value should be selected.',
        oneOf: 'Invalid value',
        equalTo: 'Invalid value',
        notEqualTo: 'Invalid value',
        valueType: (actual, expected) => `Type mismatch. Expected type: "${expected}", actual: ${typeof actual}`,
        wrongDates: row => `Start date should be less then end date. Row : ${row}`,
        imageLoadError: 'Image source is not correct.',
        imageFileSizeError: `Image size is too big, it should be less than ${MAX_IMAGE_SIZE / (1024 * 1024)} Mb`,
        imageFileTypeError: `Unsupported file type. Use images of the next types: ${IMAGE_ACCEPT_OPTIONS}`,
        invalidIPAddress: 'IP address is not valid',
        invalidNutritionalRangeValues: 'Check Min Value and Max Value in highlighted rows'
    };

    public minValidator = createValidator('minValue', (actual, expected) => isNull(actual) || actual >= expected);

    public maxValidator = createValidator('maxValue', (actual, expected) => isNull(actual) || actual <= expected);

    public timeValidator = createValidator(
        'invalidTime',
        actual => isFunction(get(actual, 'getHours')) && !isNaN(actual.getHours())
    );

    public yearValidator = createValidator('invalidYear', actual => actual && actual.match(/^([0-9]\d*){4}$/i))();

    public emailValidator = createValidator(
        'emailValidationError',
        value => !Validators.email({ value } as AbstractControl)
    )();

    public strictMinValidator = createValidator(
        'strictMinValue',
        (actual, expected) => isNull(actual) || actual > expected
    );

    public strictMaxValidator = createValidator(
        'strictMaxValue',
        (actual, expected) => isNull(actual) || actual < expected
    );

    public minTimeValidator = createValidator(
        'minTimeValue',
        (actual, expected) => actual === '' || expected === '' || actual >= expected
    );

    public maxTimeValidator = createValidator(
        'maxTimeValue',
        (actual, expected) => actual === '' || expected === '' || actual <= expected
    );

    public minArrayLength = createValidator(
        'minArrayLength',
        (actual, expected) => !actual || actual.length >= expected
    );

    public maxArrayLength = createValidator(
        'maxArrayLength',
        (actual, expected) => !actual || actual.length <= expected
    );

    public minDateValidator = createValidator(
        'minDateValue',
        (actual, expected) => actual === '' || expected === '' || actual >= expected
    );

    public maxDateValidator = createValidator(
        'maxDateValue',
        (actual, expected) => actual === '' || expected === '' || actual <= expected
    );

    public minLength = createValidator('minLength', (actual, expected) => isEmpty(actual) || actual.length >= expected);

    public maxLength = createValidator('maxLength', (actual, expected) => isEmpty(actual) || actual.length <= expected);

    public oneOf = createValidator('oneOf', (actual, expected) => expected.includes(actual));

    public equalTo = createValidator('equalTo', (actual, expected) => actual === expected);

    public notEqualTo = createValidator('notEqualTo', (actual, expected) => actual !== expected);

    public requiredValuesValidator = createValidator(
        'requiredValues',
        (actual, expected) => isNull(actual) || (expected || []).every(c => actual.includes(c))
    );

    public requiredValueValidator = createValidator('requiredValue', (actual, expected) => !!actual);

    public valueType = createValidator('valueType', (actual, expected) => {
        const controlValueType = Array.isArray(actual) ? 'array' : typeof actual;
        return controlValueType === expected;
    });

    public numberValidator = createValidator(
        'invalidNumericValue',
        (actual, expected) => isNull(actual) || isFinite(actual)
    );

    public numberMultipleOfValidator = createValidator(
        'invalidNumericValue',
        (actual, expected) => isNull(actual) || (isFinite(actual) && isCloseToInteger(actual / expected))
    );

    // Time formatter helper
    public static formatTime(time: string): string {
        if (!time) {
            return 'EMPTY';
        }
        const hours = +time.split(':')[0];
        const pmTime = time.replace(/\d{1,2}/, `${hours - 12}`);
        return hours <= 12 ? `${time} AM` : `${pmTime} PM`;
    }

    /**
     * Return errors provided by ValidationService from form control
     * @returns {Array}
     */
    public static getErrorsFormControl(control) {
        let result = [];
        if (control.dirty && control.errors) {
            result = Object.keys(control.errors)
                .map(key => control.errors[key])
                .filter(error => typeof error !== 'boolean');
        }
        if (control instanceof FormGroup || control instanceof FormArray) {
            result = result.concat(ValidationService.getErrorsFormGroup(control));
        }
        return result;
    }

    /**
     * Return errors provided by ValidationService from form control
     * @param group: FormGroup | FormArray
     * @returns {Array<>}
     */
    public static getErrorsFormGroup(group: FormGroup | FormArray) {
        let result = [];
        let groupErrors = [];
        let controlsErrors = [];
        if (group.errors) {
            groupErrors = Object.keys(group.errors)
                .map(key => group.errors[key])
                .filter(error => typeof error !== 'boolean');
        }
        if (!group.valid && group.controls) {
            controlsErrors = Object.keys(group.controls)
                .map(key => group.controls[key])
                .map(control => ValidationService.getErrorsFormControl(control))
                .filter(errors => errors.length)
                .reduce((acc, errors) => acc.concat(errors), []);
            result = [...groupErrors, ...controlsErrors];
        }
        return result;
    }

    /**
     * Transforms form errors object to a unified errors object with code and message
     * @param {object} formErrors
     * returns {IErrorDefinition}
     */
    public static formValidationErrorMapper(formErrors): IErrorDefinition {
        if (!formErrors) {
            return null;
        }
        const message = Object.entries(formErrors)
            .map(([errorType, details]) => {
                switch (errorType) {
                    case 'required':
                        return 'Field is required';
                    case 'minlength':
                    case 'maxlength':
                        return `${errorType} ${details['requiredLength']} is required`;
                    default:
                        return typeof details === 'string' ? details : 'Validation Error';
                }
            })
            .join(', ');
        return {
            message,
            code: 'Form Validation '
        };
    }

    /**
     * Transforms API errors object to a unified errors object with code and message
     * @param {object} apiErrors
     * returns {IErrorDefinition}
     */
    public static apiErrorMapper(apiErrors): IErrorDefinition {
        if (!apiErrors) {
            return null;
        }
        return Object.assign(
            {
                code: 'none',
                message: 'Unexpected Error'
            },
            pick(apiErrors, ['code', 'message']),
            pick(apiErrors.meta, ['code', 'message'])
        );
    }

    /*
   Prepearing errors message to show
   */
    public static prepareErrorMessage(field, formRules, error) {
        const groups = get(formRules, 'groups');
        // @ts-ignore
        const sectionName = Array.isArray(groups) ? get(find(groups, { fields: [field] }), 'name', 'General')
            : 'General';

        const indexOfNesting = field.lastIndexOf('.');
        const fieldName = get(
            formRules,
            ['fields', field, 'title'],
            startCase(indexOfNesting === -1 ? field : field.slice(indexOfNesting))
        );

        return {
            code: error.code,
            message: `"${sectionName}" > "${fieldName}" ${error.message} \n`
        };
    }

    /**
     * searches for errors details in FormGroup or FormComponent
     */
    public static findFormError(controls: IAbstractControls, formRules) {
        function iter(obj: IAbstractControls, path = '') {
            for (const [fieldName, control] of Object.entries(obj)) {
                const fullPath = path ? `${path}.${fieldName}` : fieldName;

                if (control.errors) {
                    const error = ValidationService.formValidationErrorMapper(control.errors);
                    return ValidationService.prepareErrorMessage(fullPath, formRules, error);
                }

                if (has(control, 'controls')) {
                    const error = iter(control['controls'], fullPath);
                    if (error) {
                        return error;
                    }
                }
            }
        }

        return iter(controls) || '';
    }

    public nutritionalRangeValidator(predicate) {
        return createValidator(
            'invalidNutritionalRangeValues',
            actualList => !actualList.some(item => predicate(item))
        )();
    }

    public imageURLValidator() {
        const regexValidator = Validators.pattern(/^(https?:\/\/.*\.(?:png|jpg|jpeg|gif)$)/i);
        return control =>
            !regexValidator(control) ? null : { invalidImageURL: ValidationService.messages.invalidImageURL };
    }

    /*
     * Is used for url validation or empty string
     */
    public urlValidator() {
        // tslint:disable-next-line:max-line-length
        const regexValidator = Validators.pattern(
            /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|^$/i
        );
        return control => (!regexValidator(control) ? null : { invalidURL: ValidationService.messages.invalidURL });
    }

    /*
     * Is used for IPv4 validation
     */
    public ipAddressValidator() {
        const regexValidator = Validators.pattern(/^(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/);
        return control =>
            !regexValidator(control) ? null : { invalidIPAddress: ValidationService.messages.invalidIPAddress };
    }

    public checkDates() {
        return control => {
            let row = '';
            const validationResult = control.value.every((element, index) => {
                const start = element.start_business_date_time;
                const end = element.end_business_date_time;
                if (!!start && !!end && start <= end) {
                    return true;
                }
                row = index + 1;
                return false;
            });
            return validationResult
                ? null
                : {
                      checkDates: ValidationService.messages.wrongDates(row)
                  };
        };
    }

    /**
     * Is used to attach errors class if control is touched and valid.
     * @param control: FormControl
     * @returns {{ errors: (any|boolean)}}
     */
    public getErrorClass(control) {
        return {
            'has-error': !control.valid
        };
    }

    public atLeastOneRequired(formGroup: FormGroup) {
        const valid = Object.values(formGroup.controls).some(control => control.value);
        return valid
            ? null
            : {
                  atleastOneRequired: ValidationService.messages.atLeastOneRequired(
                      formGroup.value,
                      Object.keys(formGroup.controls).join(', ')
                  )
              };
    }

    // A wrapper function that provides an ability to check inner
    // object properties with any existing validator
    public propertyAt(propertyPath, validator) {
        return control => {
            const value = get(control.value, propertyPath);
            return validator({ value });
        };
    }

    public checkImageSourceValidator(control) {
        const src = control.value;
        return new Promise(resolve => {
            const img = new Image();
            img.onload = () => resolve();
            img.onerror = () =>
                resolve({
                    imageLoadError: ValidationService.messages.imageLoadError
                });
            img.src = src;
        });
    }

    public checkImageFileValidator() {
        return control => {
            const imageDescriptor: File = control.value;

            if (imageDescriptor && imageDescriptor.size > MAX_IMAGE_SIZE) {
                return { imageFileError: ValidationService.messages.imageFileSizeError };
            }
            if (imageDescriptor && IMAGE_ACCEPT_OPTIONS.indexOf(imageDescriptor.type) === -1) {
                return { imageFileError: ValidationService.messages.imageFileTypeError };
            }

            return null;
        };
    }
}
/* tslint:enable */
