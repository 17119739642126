import { Injectable } from '@angular/core';
import { CustomHttpClient } from '../http-client';
import { catchError } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { ErrorHandlerService } from '../helper';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as currency_ from 'currency.js';
import { isNumber, isObject, isString, isNull, isUndefined, some, includes } from 'lodash';
import * as moment_ from 'moment';
import 'moment/min/locales';
import { IDayPartOptions } from '@shared/types/report';
//
const moment = moment_;
const currency = currency_;

export interface ICompanyPreferences {
    currencyAndNumbers: ICurrencyAndNumbersPreferences;
    dateAndTime: IDateAndTimePreferences;
}

export interface IDateAndTimePreferences {
    locale: string;
}

export interface ICurrencyAndNumbersPreferences {
    currency: string;
    decimalSeparator: string;
    negatives: string;
    precision: number;
    roundingMethod: string;
    symbol: string;
    symbolPositioning: string;
    thousandsSeparator: string;
}

interface ICurrencyOptions {
    symbol?: string;
    separator: string;
    decimal?: string;
    precision: number;
    pattern?: string;
    negativePattern?: string;
}

@Injectable({
    providedIn: 'root',
})
export class PreferencesService {
    private _currencyAndNumbersPreferences: ICompanyPreferences = null;
    private _dayPartOrderingPreferences: IDayPartOptions[];

    get currencyAndNumbersPreferences(): ICurrencyAndNumbersPreferences {
        return this?._currencyAndNumbersPreferences?.currencyAndNumbers;
    }

    get timeLocale(): string {
        return this?._currencyAndNumbersPreferences?.dateAndTime.locale;
    }

    get dayPartOrderingPreferences(): IDayPartOptions[] {
        return this._dayPartOrderingPreferences;
    }

    constructor(private http: CustomHttpClient, private errorHandlerService: ErrorHandlerService) {
    }

    public getCompanyPreferences(company): Observable<any> {
        const headers = new HttpHeaders({
            'x-company-id': company,
            'x-site-ids': 'All'
        });
        return this.http.get('/preferences/by-company', {headers})
            .pipe(catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error))));
    }

    public currencyFormatterForAgGrid = (params, type = 'grid'): string => {
        let currVal;

        if (isObject(params) && params.value === undefined) {
            return;
        }
        if (params && (params.value || params.value === null)) {
            let tempVal;

            if (params.data && params.data.order_type === 'refund' && params.value >= 0) {
                tempVal = Math.abs(params.value) * -1;
            } else {
                tempVal = params.value;
            }
            currVal = tempVal !== null ? tempVal : '0';
        } else {
            currVal = !isObject(params) ? params !== null ? params : '0' : '0';
        }


        if (currVal < 0 && type !== 'detail') {
            return `<span class="red">${this.formatToCurrency(currVal)}</span>`;
        }

        return this.formatToCurrency(currVal);
    }

    public numberFormatterForAgGrid = (val): number | string => {
        if (isNull(val) || isUndefined(val)) {
            return this.formatToNumber(val);
        }
        if (isObject(val) && val.value === undefined) {
            return '';
        }
        const value = isNumber(val) || isString(val) ? val : val.value;
        return this.formatToNumber(value);
    }

    public correctRounding = (value): number => {
        let exp = this.currencyAndNumbersPreferences?.precision === 0 ? 0 : -(this.currencyAndNumbersPreferences?.precision);
        const type = this.defineMathMethodForRounding(this.currencyAndNumbersPreferences?.roundingMethod);
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }

        value = Number(value);
        exp = +exp;
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        value = value.toString().split('e');

        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    public setCompanyPreferences(data: ICompanyPreferences) {
        this._currencyAndNumbersPreferences = data;
    }

    public formatToCurrency(val: string | number): string {
        const options: ICurrencyOptions = this.defineCurrencyOptions(this.currencyAndNumbersPreferences);
        if (options.decimal === ':') {
            return currency(this.correctRounding(val), options).format().replace(':', '');
        }
        return currency(this.correctRounding(val), options).format();
    }

    public formatToNumber(val: string | number): number | string {
        const options: ICurrencyOptions = this.defineNumberOptions(this.currencyAndNumbersPreferences);

        return currency(this.correctRounding(val), {...options}).format();
    }

    public formatToFloatNumber(val: string | number): number | string {
        const options: ICurrencyOptions = this.defineFloatingNumberOptions(this.currencyAndNumbersPreferences);

        return currency(this.correctRounding(val), {...options}).format();
    }

    public defineLocaleForVegaCharts() {
        const options = {
            decimal: this.currencyAndNumbersPreferences.decimalSeparator,
            thousands: this.currencyAndNumbersPreferences.thousandsSeparator,
            grouping: [ 3 ],
            currency: this.defineCurrencyPatternForVega(),
        };

        const negatives = this.currencyAndNumbersPreferences.negatives;
        if (negatives.indexOf('(') === -1) {
            options['minus'] = negatives.indexOf(' ') !== -1 ? '-\u00a0' : '-';
        }
        return options;
    }

    public dateFormatter(value, format = 'L'): string {
        return moment(value).locale(this.timeLocale).format(format);
    }

    public timeFormatterForVega(value, format: moment_.LongDateFormatKey = 'LT', shortValue = true) {
        const correctFormat = this.correctMomentTimeFormat(format);
        if (shortValue) {
            moment(value, 'hh A', 'en').locale(this.timeLocale).format();
            let time = moment(value, 'hh A', 'en').locale(this.timeLocale).format(correctFormat);
            if (some([ 'AM', 'PM', 'A', 'P' ], (el) => includes(time, el))) {
                time = time.replace(/\:00/g, '');
            }
            return time;
        } else {
            return moment(value, 'hh::mmA', 'en').locale(this.timeLocale).format(correctFormat);
        }
    }

    public dayAndDateFormatterForVega(value) {
        moment.locale(this.timeLocale);
        const match = moment.localeData().longDateFormat('L').match(/MM[.\/\-]DD|DD[.\/\-]MM/gi);

        if (match === null) {
            return moment(value).locale(this.timeLocale).format('ddd MM/DD');
        }

        const format = 'ddd ' + match;
        return moment(value).locale(this.timeLocale).format(format);
    }

    public dateAndTimeFormatter(value) {
        moment.locale(this.timeLocale);
        return `${moment(value).format('L')} ${moment(value).format(this.correctMomentTimeFormat('LT'))}`;
    }

    public secondFormatter(seconds: number | string) {
        const format = this.hasDotsInTimeFormat() ? 'HH.MM.SS' : 'HH:MM:SS';
        const formatted = moment.utc(+seconds * 1000).locale(this.timeLocale).format(format);
        return formatted;
    }

    public getCorrectLabelForTimeMeasure() {
        moment.locale(this.timeLocale);
        const label = this.hasDotsInTimeFormat() ? 'HH.MM.SS' : 'HH:MM:SS';
        return label;
    }

    public hasDotsInTimeFormat() {
        moment.locale(this.timeLocale);

        const hasDots = moment.localeData().longDateFormat('LTS').indexOf(('.')) !== -1;

        return hasDots;
    }

    public correctMomentTimeFormat(format: moment_.LongDateFormatKey) {
        return moment.localeData().longDateFormat(format)
            .replace(/\bh:/g, 'hh:')
            .replace(/\bh\[e\]/g, 'hh\[e\]')
            .replace(/\bH:/g, 'HH:')
            .replace(/\bH\[e\]/g, 'HH\[e\]');
    }

    public setDayPartOrderingPreferences(options: IDayPartOptions[]) {
        this._dayPartOrderingPreferences = options;
    }

    public sortByDayParts(array: any[], sortByParam: string) {
        if (this.dayPartOrderingPreferences) {
            return array.sort((a, b) => {
                const first = this.dayPartOrderingPreferences.find(item => item.day_part_type_entity_id === a.daypart_entity_id);
                const second = this.dayPartOrderingPreferences.find(item => item.day_part_type_entity_id === b.daypart_entity_id);
                if (!first) {
                    return 1;
                }
                if (!second) {
                    return -1;
                }
                return first?.priority - second?.priority;
            });
        } else {
            return array.sort((a, b) => {
                if (a[sortByParam] === '(None)') {
                    return 1;
                }
                if (b[sortByParam] === '(None)') {
                    return -1;
                }
                if (a[sortByParam] < b[sortByParam]) {
                    return -1;
                }
                if (a[sortByParam] > b[sortByParam]) {
                    return 1;
                }
                return 0;
            });
        }
    }

    private defineCurrencyOptions(preferences: ICurrencyAndNumbersPreferences): ICurrencyOptions {
        const options: ICurrencyOptions = {
            symbol: preferences.symbol,
            separator: preferences.thousandsSeparator,
            decimal: preferences.decimalSeparator === '' ? ':' : preferences.decimalSeparator,
            precision: preferences.precision,
            pattern: this.defineCurrencyPattern(preferences.symbolPositioning),
            negativePattern: this.defineCurrencyNegativePattern(preferences.negatives, preferences.symbolPositioning)
        };

        return options;
    }

    private defineNumberOptions(preferences: ICurrencyAndNumbersPreferences): ICurrencyOptions {
        const options: ICurrencyOptions = {
            symbol: '',
            precision: 0,
            separator: preferences.thousandsSeparator
        };

        return options;
    }

    private defineFloatingNumberOptions(preferences: ICurrencyAndNumbersPreferences): ICurrencyOptions {
        const options: ICurrencyOptions = {
            symbol: '',
            precision: preferences.precision,
            separator: preferences.thousandsSeparator,
            decimal: preferences.decimalSeparator,
            negativePattern: this.defineCurrencyNegativePattern(preferences.negatives, preferences.symbolPositioning)
        };

        return options;
    }

    private defineCurrencyPatternForVega() {
        if (this.currencyAndNumbersPreferences.symbolPositioning === 'right') {
            return [ '', `${this.currencyAndNumbersPreferences.symbol}` ];
        } else {
            if (this.currencyAndNumbersPreferences.symbolPositioning === 'left') {
                return [ `${this.currencyAndNumbersPreferences.symbol}`, '' ];
            } else {
                return [ `${this.currencyAndNumbersPreferences.symbol}\u00a0`, '' ];
            }
        }
    }

    private defineCurrencyPattern(symbolPositioning: string = 'left'): string {
        let pattern: string;
        switch (symbolPositioning) {
            case 'left':
                pattern = '!#';
                break;
            case 'right':
                pattern = '#!';
                break;
            case 'separator':
                pattern = '!#';
                break;
            default:
                pattern = '!#';
        }

        return pattern;
    }

    private defineCurrencyNegativePattern(negatives: string, symbolPositioning: string = 'left'): string {
        let negativePattern: string;

        if (negatives.indexOf('(') !== -1) {
            negativePattern = symbolPositioning === 'left' ? '(!#)' : symbolPositioning === 'right' ? '(#!)' : '(!#)';
        } else if (negatives.indexOf(' ') !== -1) {
            negativePattern = symbolPositioning === 'left' ? '- !#' : symbolPositioning === 'right' ? '- #!' : '- !#';
        } else {
            negativePattern = symbolPositioning === 'left' ? '-!#' : symbolPositioning === 'right' ? '-#!' : '-!#';
        }

        return negativePattern;
    }

    private defineMathMethodForRounding(type: string) {
        switch (type) {
            case 'nearest':
                return 'round';
            case 'roundup':
                return 'ceil';
            case 'rounddown':
                return 'floor';
            default:
                return 'round';
        }
    }
}
