import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { ICustomReportState } from '../../../shared/types/report';
import {
  ADD_SPEED_OF_SERVICE_DATA, ADD_DASHBOARD_DATES, ADD_DASHBOARD_DAYPARTS,
  ADD_DASHBOARD_DESTINATIONS, ADD_DASHBOARD_HOURS,
  ADD_DASHBOARD_HEADER, ADD_SALES_AND_LABOR_HEADER, ADD_SALES_AND_LABOR_HOURS,
  ADD_SALES_ANALYSIS_DATA
} from '../../reducers/reports/custom.reports.reducer';

@Injectable()
export class CustomReportsActions {
  constructor(private store: NgRedux<ICustomReportState>) {
  }

  // Speed Of Service
  addSpeedOfServiceData(data) {
    this.store.dispatch({type: ADD_SPEED_OF_SERVICE_DATA, payload: data});
  }

  // Sales Analysis
  addSalesAnalysiseData(data) {
    this.store.dispatch({type: ADD_SALES_ANALYSIS_DATA, payload: data});
  }

  // Dashboard
  addDashboardDates(data) {
    this.store.dispatch({type: ADD_DASHBOARD_DATES, payload: data});
  }

  addDashboardDayParts(data) {
    this.store.dispatch({type: ADD_DASHBOARD_DAYPARTS, payload: data});
  }

  addDashboardDestinations(data) {
    this.store.dispatch({type: ADD_DASHBOARD_DESTINATIONS, payload: data});
  }

  addDashboardHours(data) {
    this.store.dispatch({type: ADD_DASHBOARD_HOURS, payload: data});
  }

  addDashboardHeader(data) {
    this.store.dispatch({type: ADD_DASHBOARD_HEADER, payload: data});
  }

  // Sales and Labor
  addSalesAndLaborHours(data) {
    this.store.dispatch({type: ADD_SALES_AND_LABOR_HOURS, payload: data});
  }

  addSalesAndLaborHeader(data) {
    this.store.dispatch({type: ADD_SALES_AND_LABOR_HEADER, payload: data});
  }
}
