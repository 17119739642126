import { Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { CustomHttpClient } from '../../../../shared/services/http-client';
import { SELECT_ALL } from '../../../../../config';

@Component({
    selector: 'select-input-filter',
    templateUrl: 'select-input-filter.html',
    styleUrls: ['select-input-filter.scss']
})
export class SelectInputFilterComponent implements OnInit, AfterViewChecked {
    @Input() filter: object;
    @Input() type: string = '';
    @Output() handleChanges = new EventEmitter();
    public items: any[] = [];
    public label: string = '';
    public tooltip: boolean = false;
    public tooltipInfo: string = '';
    public inputValue: any = '';
    public multiple: boolean = false;
    public dropdownSettings = {
        singleSelection: false,
        text: 'Select...',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        classes: 'myclass custom-class'
    };
    private attributes: string[] = [];
    private name: string = '';
    private selectAll: boolean = false;

    constructor(private http: CustomHttpClient,
                private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {
        this.multiple = this.filter['multiple'];
        if (this.multiple) {
            this.inputValue = [];
        }
        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];
        this.label = this.filter['label'];
        this.tooltip = this.filter['tooltip'];
        this.tooltipInfo = this.filter['tooltip-info'];
        if (this.filter['data']) {
            this.items = this.prepareData(this.filter['data']);
        }
        if (this.filter['value']) {
            this.inputValue = this.filter['value'][this.attributes[0]];
            this.handleSelectFilterChange();
        }
        if (this.inputValue === SELECT_ALL && this.multiple) {
            this.inputValue = JSON.parse(JSON.stringify(this.items));
            this.selectAll = true;
        }
        if (this.filter['source']) {
            const url = this.filter['source']['url'];
            const attributes = this.filter['source']['attributes'];
            this.http.get(url).subscribe(res => {
                const results = [];
                if (Array.isArray(res) && res.length !== 0) {
                    res.forEach(result => {
                        if (result[attributes.name] && result[attributes.value]) {
                            results.push({
                                name: result[attributes.name],
                                value: result[attributes.value]
                            });
                        }
                    });
                    this.items = this.prepareData(results);
                    if (this.multiple && this.selectAll) {
                        this.inputValue = JSON.parse(JSON.stringify(this.items));
                    }
                }
            });
        }
    }

    public handleSelectFilterChange() {
        const value = {};

        if (Array.isArray(this.inputValue) && this.inputValue.length && this.inputValue.length === this.items.length) {
            value[this.attributes[0]] = SELECT_ALL;
        } else {
            if (this.name === 'group_by' && this.inputValue.length === 0) {
                value[this.attributes[0]] = 'Nothing';
            } else {
                value[this.attributes[0]] = this.inputValue;
            }
        }
        const res = { value, name: this.name, type: this.filter['type'] };
        this.handleChanges.emit(res);
        this.changeDetector.detectChanges();
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }

    public onSelectedItemChange(data) {
        this.handleSelectFilterChange();
        return data;
    }

    public onSelectedAllStateChange(data) {
        this.inputValue = data;
        this.handleSelectFilterChange();
        return data;
    }

    private prepareData(data) {
        if (this.multiple) {
            return data.map(element => {
                return { itemName: element.name, id: element.value };
            });
        }
        return data;
    }
}
