<div class="filters-panel-container">
    <app-dashboard-filters class="col-sm-12 col-md-6 row" *ngIf="calendarValue && !!selectedSites"
                           [datePickerValue]="calendarValue"
                           [enableSitePicker]="enablePicker$ | async"
                           [sites]="sites"
                           [selectedSites]="selectedSites"
                           (change)="onCalendarChanged($event)"
                           (submitForm)="submitForm()"
    ></app-dashboard-filters>
    <tools-panel class="col-xs-12 col-md-6" *ngIf="csvData.length" [csvData]="csvData"></tools-panel>
</div>

<div style="clear: both"></div>
<div class="row" id="ReportCustom">
  <div class="header-box">
    <div id="vis_header">
      <div class="vis_header_item" *ngFor="let item of headerChart">
        <div>
          <div class="header_label">{{item.label}}</div>
          <div class="header_value">{{item.value}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-box">
    <div #visDate id="vis_date"></div>
    <div *ngIf="!datesChart">By Date - No Data Available</div>
  </div>
  <div class="container-box">
    <div #vis_hour id="vis_hour"></div>
    <div *ngIf="!hoursChart">By Hour - No Data Available</div>
  </div>
  <div class="container-box">
    <div #vis_dest id="vis_dest"></div>
    <div *ngIf="!destinationsChart">By Destination - No Data Available</div>
  </div>
  <div class="container-box">
    <div #vis_daypart id="vis_daypart"></div>
    <div *ngIf="!dayPartsChart">By Day Part - No Data Available</div>
  </div>
</div>
