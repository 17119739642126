import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'above-between-below-filter',
    templateUrl: 'above-between-below-filter.html',
    styleUrls: ['style.css']
})
export class AboveBetweenBelowFilterComponent implements OnInit {
    @Input() filter: any;
    @Input() type: string = '';
    @Output() handleChanges = new EventEmitter();
    public label: string = '';
    public items: any[] = [];
    public inputValue: string = '';
    public above: string = '';
    public below: string = '';

    protected attributes: string[] = [];
    private name: string = '';

    constructor() {}

    ngOnInit() {
        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];
        this.label = this.filter['label'];
        if (this.filter['data']) {
            this.items = this.filter['data'];
        }
        if (this.filter['value']) {
            this.inputValue = this.filter['value']['Range'] ? this.filter['value']['Range'] : '';
            this.above = this.filter['value']['Above'] ? this.filter['value']['Above'] : '';
            this.below = this.filter['value']['Below'] ? this.filter['value']['Below'] : '';
            this.handleRangeChange();
        }
    }

    public handleAboveBelowChange() {
        const errors = [];
        const value = {};
        switch (this.inputValue) {
            case 'Above':
                if (this.above) {
                    value['Above'] = this.above;
                } else {
                    errors.push('Above can not be blank and should be numeric.');
                }
                break;
            case 'Below':
                if (this.below) {
                    value['Below'] = this.below;
                } else {
                    errors.push('Below can not be blank and should be numeric.');
                }
                break;
            case 'Between':
                if (this.above && this.below) {
                    value['Below'] = this.below;
                    value['Above'] = this.above;
                } else {
                    errors.push('Above and Below can not be blank and should be numeric.');
                }
                break;
            default: {
            }
        }
        value['Range'] = this.inputValue;
        const res = { value, errors, name: this.name, type: this.filter['type'] };
        this.handleChanges.emit(res);
    }

    public handleRangeChange() {
        switch (this.inputValue) {
            case 'All':
                const res = { name: this.name, type: this.filter['type'], value: { Range: 'All' } };
                this.handleChanges.emit(res);
                break;
            default: {
            }
        }
    }
}
