import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
/**
 * email-notification service
 */
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { CustomHttpClient } from '../http-client';
import { environment } from './../../../../environments/environment';

@Injectable()
export class EmailNotificationServer {
    private baseUrl = `${environment.emailNotifierURI}`;
    constructor(private http: CustomHttpClient) {}

    sendReceiptForCustomer(data) {
        const apiUrl = `${this.baseUrl}/moapi.notification`;
        const requestData = {
            data: data,
            url: apiUrl
        };
        const headers = new HttpHeaders({
            'x-company-id': data[0].company_id,
            'x-site-ids': data[0].site_id
        });

        return this.http
            .post('/send-emails', requestData, { headers })
            .pipe(catchError(error => observableThrowError(error)));
    }
}
