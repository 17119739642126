import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    IDatepickerConfig,
    IDatepickerValue
} from '@hc/xenial-ui-shared/modules/xdm-shared-components/components/xux-datepicker/types/index';
import { PreferencesService } from '@shared/services';
import { ISite, ISiteState } from '@shared/types/site';
import { SitesActions } from '@store/actions';
import { NgRedux } from '@angular-redux/store';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.scss']
})
export class DashboardFiltersComponent implements OnInit {
    @Input() datePickerValue: IDatepickerValue;
    @Input() enableSitePicker: boolean;
    @Input() set selectedSites(sites: Array<ISite>) {
        this.selected = this.prepareSites([...sites]);
    }
    @Input() sites: Array<ISite>;
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() submitForm: EventEmitter<any> = new EventEmitter();

  public calendarConfig: IDatepickerConfig = {
      showInputLabel: false,
      dateFormat: {
          long_date_format: 'll',
          short_date_format: 'L'
      },
      autoCorrectEndDate: true,
      availableOptions: [],
      mergeDateFields: true,
      locale: this.preferencesService.timeLocale
  };

  public dropdownSettings = {
        singleSelection: false,
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        badgeShowLimit: 1,
        maxHeight: 200
  };
  public selected;
  public selectedDate: IDatepickerValue;

  constructor(
      private preferencesService: PreferencesService,
      private sitesAction: SitesActions,
      private ngRedux: NgRedux<ISiteState>,
      private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
      this.selectedDate = this.datePickerValue;
      this.selectedDate.value.dateStart = moment(this.selectedDate.value.dateStart).toDate();
      this.calendarConfig.availableOptions.push({
          name: 'Latest Business Date',
          id: 'businessDate',
          type: 'single',
          value: {
              dateStart: this.datePickerValue.value.dateStart,
              dateEnd: this.datePickerValue.value.dateStart
          },
          hidden: false
      });
  }

  public handleCalendarChange(event): void {
      this.selectedDate = event;
      const date = {...event};

      this.change.emit(date);
  }

  public onSubmitForm(): void {
      this.submitForm.emit();
  }

  public showPortalPicker() {
      if (this.enableSitePicker) {
          const selectorOptions = {
              required: true,
              applySiteSelection: true,
              multiselect: true,
              defaultSelectedSites: this.selected.map(site => site.id)
          };
          this.sitesAction.showSiteSelector(selectorOptions).catch();
      } else {
          return true;
      }
  }

  public onItemSelect() {
      this.ngRedux.dispatch(
          SitesActions.updateSelectedSites({
              sites: this.selected,
              isMultiple: true
          })
      );
      this.cdRef.detectChanges();
  }

  public onItemDeselect() {
      this.ngRedux.dispatch(
          SitesActions.updateSelectedSites({
              sites: this.selected,
              isMultiple: true
          })
      );
      this.cdRef.detectChanges();
  }

  public onSelectAll() {
      this.ngRedux.dispatch(
          SitesActions.updateSelectedSites({
              sites: this.selected,
              isMultiple: true
          })
      );
      this.cdRef.detectChanges();
  }

  public onDeSelectAll() {
      this.ngRedux.dispatch(
          SitesActions.updateSelectedSites({
              sites: [],
              isMultiple: true
          })
      );
      this.cdRef.detectChanges();
  }

  private prepareSites(sites) {
      if (sites) {
          return sites.map(site => {
              return {
                  id: site.id,
                  itemName: site.name || site.itemName
              };
          });
      } else {
          return [];
      }
  }

}
