import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpCancelService } from '../http-client';
import { environment } from '../../../../environments/environment';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
    constructor(private httpCancelService: HttpCancelService) { }

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        if (req.url.indexOf(environment.portalBackendURI) !== -1) {
            return next.handle(req);
        }
        if (req.url.indexOf('sites') !== -1 || req.url.match(/reports$/)) {
            return next.handle(req);
        }
        if (req.url.indexOf('by-company') !== -1) {
            return next.handle(req);
        }
        return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
    }
}

