import { NgRedux } from '@angular-redux/store';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CustomReportsService } from '../../../shared/services';
import { VegaConfigsService } from '../../../shared/services/reports';
import { PreferencesService } from '../../../shared/services/preferences/preferences.service';
import { UiActions } from '../../../store/actions';
import { ToasterService } from 'angular2-toaster';
import { takeWhile } from 'rxjs/operators';
import { isBoolean } from 'util';
import { HttpCancelService } from '../../../shared/services/http-client';

@Component({
    selector: 'sales-and-labor',
    templateUrl: 'sales-and-labor.component.html',
    styleUrls: ['sales-and-labor.component.css']
})
export class SalesAndLaborComponent implements OnInit, OnDestroy {
    @Input()
    public set data(data) {
        if (this.isNewRequestData(data)) {
            this.activeFilters = data;
            this.siteIds = data.Site_Id || 'All';
            this.dateFrom = data.Start_Date;
            this.dateTo = data.End_Date;
            if (data.Calendar_Period === 'true' && data.Start_Date !== data.End_Date) {
                this.dateTo = moment(data.End_Date, 'YYYY-MM-DD')
                    .subtract(1, 'day')
                    .format('YYYY-MM-DD');
            }

            if (data.custom_title) {
                this.customTitle = data.custom_title;
            }

            this.uiActions.enableContentLoader();
            this.getSalesAndLaborData(this.siteIds, this.dateFrom, this.dateTo);
        }
    }

    public reportsState: Observable<any>;
    public sitesState: Observable<any>;
    protected hoursChart: any;
    public headerChart: any;
    protected isActive: boolean = true;
    public csvData: any[] = [];
    public businessDate: string = '';
    public dateFrom: string = moment().format('YYYY-MM-DD');
    public dateTo: string = moment().format('YYYY-MM-DD');
    public customTitle: string = '';
    protected siteIds: string[] | string = '';
    protected activeFilters: any;
    public loader$: Observable<any>;
    private isLoading = false;

    constructor(
        private uiActions: UiActions,
        private reportService: CustomReportsService,
        private vegaConfigService: VegaConfigsService,
        private preferencesService: PreferencesService,
        private toasterService: ToasterService,
        private store: NgRedux<any>,
        private httpCancelService: HttpCancelService,
    ) {
        this.sitesState = this.store.select('sites');
        this.loader$ = this.store.select(['ui', 'contentLoader']);
        this.loader$
            .pipe(takeWhile(() => this.isActive))
            .subscribe(
                value => {
                    if (isBoolean(value)) {
                        if (this.isLoading === true && value === false) {
                            this.httpCancelService.cancelPendingRequests();
                        }
                        this.isLoading = value;
                    }
                }
            );
    }

    public ngOnInit() {

    }

    @HostListener('window:resize', ['$event'])
    public onResize(event) {
        if (this.hoursChart) {
            this.hoursChart.signal('width', this.vegaConfigService.getChartWidth('vega_hours')).run('enter');
        }
    }

    public ngOnDestroy() {
        this.isActive = false;
        this.uiActions.disableContentLoader();
        this.isNewRequestData({});
    }

    private isNewRequestData(data) {
        return this.reportService.isNewRequestData(data);
    }

    private getSalesAndLaborData(siteIds, dateFrom, dateTo) {
        this.reportService
            .fetchSalesAndLabor(siteIds, dateFrom, dateTo)
            .pipe(takeWhile(() => this.isActive))
            .subscribe(
                response => {
                    this.renderCharts(response);
                },
                error => {
                    this.uiActions.disableContentLoader();
                    this.toasterService.pop('error', error.statusText);
                }
            );
    }

    private getNormalizeData(data) {
        return data
            .sort((a, b) => {
                return moment(a.group_by, 'hh A').diff(moment(b.group_by, 'hh A'));
            })
            .reduce((filtered, item) => {
                const tempObj = {
                    ...item,
                    amount1: this.preferencesService.correctRounding(item.amount1),
                    amount2: this.preferencesService.correctRounding(item.amount2),
                    amount3: this.preferencesService.correctRounding(item.amount3),
                    amount4: this.preferencesService.correctRounding(item.amount4),
                    group_by: this.preferencesService.timeFormatterForVega(item.group_by),
                };
                filtered.push(tempObj);

                return filtered;
            }, []);
    }

    private renderCharts(response) {
        let [hours, header]: any[] = response;
        if (hours?.length) {
            hours = this.getNormalizeData(hours);
            this.hoursChart = this.vegaConfigService.vegaInit(
                'vega_hours',
                this.vegaConfigService.getChartBarbardashlineVertical('By Hour'),
                hours,
                this.vegaConfigService.getChartWidth('vega_hours'),
                this.vegaConfigService.getChartHeight('vega_hours')
            );
            hours = this.vegaConfigService.prepareCsvForBarChart(
                hours,
                'By Hours'
            );
        }
        if (header && header.length) {
            this.headerChart = this.vegaConfigService.normalizeHeaderBarData(header);
            header = this.vegaConfigService.prepareCsvForBarChart(
                header,
                'Header'
            );
        }
        this.csvData = [header, hours];
        this.uiActions.disableContentLoader();
    }
}
