import { IWidgetOption, IWidgetOptions } from './schema-form-interfaces';

const widgetOptions: IWidgetOptions = {
  default: {
    autoTitle: true,
    hasControl: true,
  },
  toolbar: {
    autoTitle: false,
    hasControl: false,
  },
  'button-group': {
    autoTitle: false,
  },
  'panel-group': {
    autoTitle: false,
    hasControl: false,
  },
  panel: {
    hasControl: false,
  },
};

export const widgetOption = (widgetType: string): IWidgetOption => Object.assign(
  {}, widgetOptions.default, widgetOptions[widgetType]);
