import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IBaseEntityType, IToolbarButtonDefinition } from '../../shared/types';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Subscription } from 'rxjs';
import { get, partition } from 'lodash';
import { filter } from 'rxjs/operators';

const BOOTSTRAP_MODAL_ON_DISMISS_RESULT = 2;
const HEADER_BUTTON_VALUES = ['ok', 'cancel', 'done', 'save'];

@Component({
    selector: 'dm-modal-wrapper',
    templateUrl: 'modal-wrapper.component.html'
})
export class ModalWrapperComponent implements OnChanges, OnInit, OnDestroy {
    @Input() public title: string;
    @Input() public customData: {
        cssClass?: string;
        keyboard?: boolean;
        backdrop?: boolean | 'static';
    } = {};
    @Input() public isShowModal: boolean;
    @Input() public buttons: IToolbarButtonDefinition[] = [
        { value: 'ok', caption: 'Ok', class: 'btn-primary pull-right', click: () => this.onSubmit.emit() },
        { value: 'cancel', caption: 'Cancel', class: 'btn-default pull-right', click: () => this.onCancel.emit() }
    ];

    // tslint:disable-next-line:no-output-on-prefix
    @Output() onSubmit = new EventEmitter<IBaseEntityType>();
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onCancel = new EventEmitter<IBaseEntityType>();

    public headerButtons: IToolbarButtonDefinition[] = [];
    public footerButtons: IToolbarButtonDefinition[] = [];
    @ViewChild('dmModalWrapper', { static: true }) private modalWindow: BsModalComponent;
    private subscriptions: Subscription[] = [];

    public ngOnInit() {
        // @ts-ignore
        /**
         * TODO: delete this code if the new version of ng2-bs3-modal has fix for onDismiss emitter
         * onDismiss eventEmitter doesn't work correctly for now
         */
        this.subscriptions.push(
            this.modalWindow.onHide
                .pipe(filter(result => result === BOOTSTRAP_MODAL_ON_DISMISS_RESULT))
                .subscribe(result => {
                    this.onCancel.emit();
                })
        );
        if (this.buttons) {
            this.initButtons();
        }
    }

    public ngOnChanges(changes) {
        if ('isShowModal' in changes) {
            if (this.isShowModal) {
                this.modalWindow.open();
            } else {
                this.modalWindow.close();
            }
        }

        if ('buttons' in changes) {
            if (get(changes, ['buttons', 'currentValue']) && !get(changes, ['buttons', 'previousValue'])) {
                [this.headerButtons, this.footerButtons] = partition(
                    get(changes, ['buttons', 'currentValue'], []),
                    button => HEADER_BUTTON_VALUES.includes(button.value)
                );
            }
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public initButtons() {
        [this.headerButtons, this.footerButtons] = partition(get(this, 'buttons', []), (button: any) =>
            HEADER_BUTTON_VALUES.includes(button.value)
        );
    }
}
