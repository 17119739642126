/**
 * ACTION_PROVIDERS
 */

import { SessionActions } from './session';
import { RouterActions } from './router';
import { UiActions } from './ui';
import { OpraDataActions } from './opra-data';
import { PreferencesActions } from './preferences';
import { FiltersActions } from './filters';
import { ReportsActions, CustomReportsActions } from './reports';
import { SitesActions } from './sites';

export * from './session';
export * from './router';
export * from './opra-data';
export * from './ui';
export * from './preferences';

export * from './filters';
export * from './reports';
export * from './sites';

export const ACTION_PROVIDERS: any[] = [
  UiActions,
  OpraDataActions,
  SessionActions,
  PreferencesActions,
  RouterActions,
  FiltersActions,
  ReportsActions,
  CustomReportsActions,
  SitesActions,
];
