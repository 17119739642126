/**
 * Home component
 */
import { NgRedux } from '@angular-redux/store';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { HelperService } from '../../../shared/services/helper';
import { SessionActions } from '../../../store/actions';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'dm-home',
    styleUrls: ['./home.component.scss'],
    templateUrl: './home.component.html',
    providers: [HelperService]
})
export class HomeComponent implements OnDestroy {
    public user$: Observable<any>;
    public companies$: Observable<any>;
    public currentCompanyId$: Observable<any>;
    public showCompanySelector$: Observable<any>;
    public subscription: Subscription[] = [];

    constructor(private router: Router, private sessionActions: SessionActions, private ngRedux: NgRedux<any>) {
        this.showCompanySelector$ = this.ngRedux.select(['userSession', 'showCompanySelector']);
        this.currentCompanyId$ = this.ngRedux.select(['userSession', 'currentCompanyId']);
        this.companies$ = this.ngRedux.select(['userSession', 'companies']);
        this.user$ = this.ngRedux.select(['userSession', 'user']);
        this.subscription.push(
            this.companies$
                .pipe(filter(companies => {
                    return companies.length;
                }))
                .subscribe(companies => {
                    if (companies.length === 1) {
                        this.getAccessToken(companies[0].id);
                    }
                })
        );
    }

    public selectCompany(companyId) {
        this.getAccessToken(companyId);

        this.currentCompanyId$.subscribe(currentId => {
            if (currentId !== null) {
                this.router.navigate(['/custom-reports']);
            }
        });
    }

    protected getAccessToken(companyId) {
        this.sessionActions.getAccessToken(companyId);
        this.sessionActions.setCompany(companyId);
    }

    public ngOnDestroy() {
        this.subscription.map(s => s.unsubscribe());
    }
}
