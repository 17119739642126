/**
 * Post message service
 */
import { Injectable } from '@angular/core';
import { IIframeMessageType } from '../../types';
import { SitesActions, UiActions } from '../../../store/actions';
import { get, set } from 'lodash';

@Injectable()
export class PostMessageService {
  public platform: string = 'XDM';
  public portalPlatform: string = 'XPRT';

  constructor(public sitesActions: SitesActions,
              public uiActions: UiActions) {
  }

  public sendMessage(msgType: string,
                     messageId: string,
                     payload: object): void {
    const message: IIframeMessageType = {
      messageId,
      payload: {
        options: payload,
        ...payload,
      },
      msg_type: msgType,
      platform: this.platform,
      timestamp: (new Date()).toISOString(),
    };

    // Fix wrong option name and property reading in portal site selector
    if (get(payload, 'multiselect') === true) {
      set(message, 'payload.multiply', true);
      set(message, 'payload.options.multiply', true);
    }

    parent.postMessage(JSON.stringify(message), '*');
  }

  public processPostMessage(msg: IIframeMessageType): void {
      if (msg.platform === this.portalPlatform) {
        switch (msg.msg_type) {
        case SitesActions.GET_RECENT_SITES_LIST_SUCCESS:
            this.sitesActions.getRecentSiteListSuccess(msg);
          break;

        case SitesActions.SITE_SELECTION_SUCCESS:
          if (get(msg, 'payload.modalResult') === 'cancel') {
            return;
          }
          this.sitesActions.siteSelectionRequest(msg);
          break;
        default:
          break;
      }
    }
  }
}
