import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { XUXLoaderComponent } from './xux-loader';
import { XUXDrawerModule } from './xux-drawer';
import { SearchMenuItemsPipe } from './pipes';
import { XUXMenuPinnedService } from './services/xux-menu-pinned.service';
import { XUXDrawerSidebarService } from './services/xux-drawer-sidebar.service';
import { XUXBreadcrumbNavbarComponent } from './xux-breadcrumb-navbar/xux-breadcrumb-navbar.component';
import { DmComponentsModule } from '../components/dm.components.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

export * from './xux-breadcrumb-navbar';
export * from './xux-navigation';
export * from './xux-menu-item';
export * from './services';
export * from './interfaces';
export * from './pipes';
export * from './xux-drawer';
export * from './xux-loader';
export * from './xux-input-select';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        XUXDrawerModule.forRoot(),
        DmComponentsModule,
        BsDropdownModule.forRoot()
    ],
    declarations: [XUXBreadcrumbNavbarComponent, XUXLoaderComponent],
    exports: [
        XUXBreadcrumbNavbarComponent,
        XUXLoaderComponent,
        XUXDrawerModule,
        SearchMenuItemsPipe,
        DmComponentsModule
    ],
    providers: []
})
export class XenialUxModule {
    public static forRoot(): ModuleWithProviders<XenialUxModule> {
        return {
            ngModule: XenialUxModule,
            providers: [XUXMenuPinnedService, XUXDrawerSidebarService]
        };
    }
}
