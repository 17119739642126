import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgRedux, select } from '@angular-redux/store';
import * as _ from 'lodash';
import { SharedService } from '../../../shared/services/shared-service';
import { TokenSessionService } from '../../../shared/services';
import { Observable } from 'rxjs';
import { IMenuItem } from '../../../xenial-ux';
import { IFRAME_MODE, LOGIN_ROUTE } from '../../../routes';
import { IAppState } from '../../../store';
import { LocalStorageService } from '../../../shared/services';
import { XUXDrawerSidebarService } from '../../../xenial-ux/services';
import { SessionActions, UiActions } from '../../../store/actions';

@Component({
    moduleId: module.id,
    selector: 'default-layout',
    templateUrl: 'default.component.html',
    providers: [SharedService]
})
export class DefaultLayoutComponent implements OnInit {
    pageTitle: any;
    boxed: boolean;
    compress: boolean;
    menuStyle: string;
    rtl: boolean;
    changedLoaderStatus = false;
    @Input() openedSidebar: boolean;
    @select(['userSession', 'user']) user$: Observable<any>;
    @select(['userSession', 'errors']) userSessionErrors$: Observable<any>;
    @select(['userSession', 'isIframeMode']) isIframeMode$: Observable<any>;
    @select(['ui', 'loader']) loader$: Observable<any>;
    @select(['ui', 'contentLoader']) contentLoader$: Observable<any>;
    @select(['ui', 'selectedMenuItem']) selectedMenuItem$: Observable<IMenuItem>;

    constructor(
        private ngRedux: NgRedux<IAppState>,
        private sessionActions: SessionActions,
        private uiActions: UiActions,
        private localStorageService: LocalStorageService,
        private router: Router,
        private drawerSidebarService: XUXDrawerSidebarService,
        private tokenSessionService: TokenSessionService,
        private _sharedService: SharedService
    ) {
        this.openedSidebar = false;
        this.boxed = false;
        this.compress = false;
        this.menuStyle = 'style-3';
        this.rtl = false;

        this._sharedService.changeEmitted$.subscribe(title => {
            this.pageTitle = title;
        });
    }

    /**
     * This function is called right after the directive's
     * data-bound properties have been checked for the first time,
     * and before any of its children have been checked.
     * It is invoked only once when the directive is instantiated.
     * In this function we listen to route state. And once current url
     * isn't /login then we check if customer is logged in. And if
     * customer isn't logged in then we do navigate to login page.
     * Also in this function we select needed sidebar menu item
     * to update title of the sidebar link
     */

    ngOnInit() {
        this.sessionActions.restoreToken();
        const externalLogin = (location.hash || '').startsWith(`#/${IFRAME_MODE}`);
        // TODO fix app navigating if user delete token from localStore
        if (!this.localStorageService.getItem('AUTH_KEY') && !externalLogin) {
            this.router.navigateByUrl('/login');
        }

        this.ngRedux.select('router').subscribe((route: string) => {
            this.uiActions.selectSidebarItem(route);
        });

        this.userSessionErrors$.subscribe(errors => {
            if (!externalLogin && _.get(errors, '0.meta.code') === 'INVALID_TOKEN') {
                this.router.navigateByUrl('/login');
            }
        });
        this.loader$.subscribe(value => {
            this.changedLoaderStatus = !value;
        });
        this.user$.subscribe(user => {
            if (user) {
                this.drawerSidebarService.openOnDesktop();
            }
        });

        setTimeout(() => {
            if (!this.changedLoaderStatus) {
                this.tokenSessionService.removeToken();
                this.router.navigate([LOGIN_ROUTE]);
            }
        }, 10000);
    }

    getClasses() {
        const menu: string = this.menuStyle;

        return {
            ['menu-' + menu]: menu,
            boxed: this.boxed,
            'compress-vertical-navbar': this.compress,
            'open-sidebar': this.openedSidebar,
            rtl: this.rtl
        };
    }

    sidebarState() {
        this.openedSidebar = !this.openedSidebar;
    }
}
