import { IFilterModel, ISortModelField, IAction } from '../../../shared/types';
import { Reducer } from 'redux';
import { UiActions } from '../../actions';
import { get, pick } from 'lodash';

const payloadItems = [
  'userViews', 'filterModel', 'sortModel', 'pageSize', 'pageNumber', 'quickFilter', 'currentPage',
];

export interface IUIListState {
  entityType?: string;
  quickFilter?: string;
  userViews: string[];
  filterModel?: IFilterModel;
  sortModel?: ISortModelField[];
  currentPage: number;
  pageSize: number;
}

export const LIST_INITIAL_STATE: IUIListState = {
  quickFilter: '',
  userViews: [],
  currentPage: 1,
  pageSize: 50,
};

export const uiListReducer: Reducer<IUIListState> = (
  state: IUIListState = LIST_INITIAL_STATE, action: IAction) => {

  const { payload } = action;
  const entityType = get(payload, 'entity_type', state.entityType);

  switch (action.type) {
    case UiActions.UPDATE_LIST_VIEW_DATA:

      return Object.assign(
        {},
        state,
        { entityType },
        pick(payload, payloadItems)
      );

    case UiActions.SET_CURRENT_PAGE:
      return Object.assign({}, state, {
        currentPage: payload.pageNumber,
      });

    default:
      return state;
  }
};



