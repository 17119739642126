/**
 * Company selection  component.
 */
import { Component, Input, EventEmitter, Output, ViewEncapsulation, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { GridOptions } from '@ag-grid-enterprise/all-modules';
import { IToolbarButtonDefinition } from '../../../shared/types';
import { OpraDataActions, SessionActions } from '../../../store/actions/';
import { filter, switchMap } from 'rxjs/operators';
import { ReportsService } from '@shared/services';
import { NgRedux } from '@angular-redux/store';
import { ISessionState } from '@shared/types/session';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';

@Component({
    selector: 'dm-company-selection',
    templateUrl: 'company-selection.component.html',
    styleUrls: ['company-selection.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CompanySelectionComponent implements OnInit, OnChanges, OnDestroy {
    @Output() public selectedItem = new EventEmitter();
    @Input() public companiesList: any[];
    @Input() public currentCompanyId: string;
    @Input() public showCompanySelector: boolean = false;
    public _showModal: boolean = false;
    public selectedItemFromList: any;
    public title: string = 'Select a company:';
    public buttons: IToolbarButtonDefinition[] = [
        {
            caption: 'Done',
            value: 'done',
            disabled: true,
            class: 'btn-primary pull-right',
            click: () => this.selectCompany()
        },
        {
            caption: 'Cancel',
            value: 'cancel',
            class: 'btn-default pull-right',
            click: () => this.closeCompanySelector()
        }
    ];
    public headerNames: any[] = [
        { headerName: 'Company name', field: 'name', unSortIcon: true },
        { headerName: 'Number of sites', field: 'sites.length', unSortIcon: true }
    ];

    public listGridOptions: GridOptions = {
        rowSelection: 'single',
        suppressCellSelection: true,
        onGridSizeChanged: () => this.resizeGrid(),
        onModelUpdated: () => this.resizeGrid(),
        onRowClicked: event => this.selectItem(event.data),
        onRowDoubleClicked: () => this.selectCompany()
    };

    private token$: Observable<string>;
    private unsubscribe$: Subject<void> = new Subject();

    constructor(
        private opraDataActions: OpraDataActions,
        private sessionActions: SessionActions,
        private ngRedux: NgRedux<ISessionState>,
        private reportsService: ReportsService) {
    }

    public ngOnInit() {
        this.token$ = this.ngRedux.select([ 'userSession', 'token' ]);
        this.token$.pipe(
            // takeUntil(this.unsubscribe$),
            filter(() => !!this.selectedItemFromList),
            switchMap(() => this.reportsService.fetchSites())
        ).subscribe(() => this.resetForm());
    }
    public ngOnChanges(changes) {
        if ('showCompanySelector' in changes && this.companiesList.length > 1) {
             this._showModal = this.showCompanySelector;
        }
    }

    public selectItem(data) {
        this.selectedItemFromList = data;
        this.buttons.find(b => b.caption === 'Done').disabled = true;
        if (this.selectedItemFromList) {
            this.buttons.find(b => b.caption === 'Done').disabled = false;
        }
    }

    public selectCompany() {
        if (this.selectedItemFromList) {
            this.selectedItem.emit(this.selectedItemFromList.id);
        }
    }

    public onListQuickFilterChanged(event) {
        if (this.listGridOptions.api) {
            this.listGridOptions.api.setQuickFilter(event.target.value);
        }
    }
    public closeCompanySelector() {
        if (!this.currentCompanyId) {
            // logout user after login on cancel button click
            this.sessionActions.logoutUser();
            this._showModal = false;
        }
        this.resetForm();
        this.opraDataActions.hideCompanySelector();
    }
    private resetForm() {
        this.buttons.find(b => b.caption === 'Done').disabled = true;
        this.selectedItemFromList = null;
    }

    private resizeGrid() {
        if (this.listGridOptions.api) {
            this.listGridOptions.api.sizeColumnsToFit();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
