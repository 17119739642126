import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { isEqual, isNumber } from 'lodash';
import * as moment from 'moment';
import { SELECT_ALL } from '../../../../config';
import { of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class FiltersService {
    constructor(private store: NgRedux<any>) {}

    public getFilters(reportState) {
        if (!reportState) {
            return;
        }

        const filters = [];

        if (reportState.selectedReport && reportState.selectedReport.path) {
            if (
                reportState.selectedReport.additional_path_parameters &&
                reportState.selectedReport.additional_path_parameters.length
            ) {
                for (const parameter of reportState.selectedReport.additional_path_parameters) {
                    const res = {};
                    res['value'] = null;
                    for (const filter of reportState.reportFilters) {
                        if (filter.name === parameter.name && filter.type === parameter.type) {
                            res['value'] = filter.value;
                        }
                    }
                    res['name'] = parameter.name;
                    res['type'] = parameter.type;
                    res['label'] = parameter.label;
                    res['attributes'] = parameter.attributes;

                    const prevState: any = [];
                    res['dependencies$'] = of([]);
                    if (parameter.dependencies && parameter.dependencies.length) {
                        res['dependencies$'] = this.store.select(['reports', 'reportFilters']).pipe(
                            filter(
                                (filtersConfig: any[]) =>
                                    !!filtersConfig &&
                                    parameter.dependencies.every((dependencyKey, index) => {
                                        const filter = filtersConfig.find(config => !!config.value[dependencyKey]);
                                        const valueWasChanged =
                                            !prevState[index] ||
                                            !isEqual(
                                                prevState[index].value[dependencyKey],
                                                filter.value[dependencyKey]
                                            );
                                        prevState[index] = filter;
                                        return !!filter && !!filter.value[dependencyKey] && valueWasChanged;
                                    })
                            ),
                            map((filtersConfig: any[]) => {
                                const deps = parameter.dependencies
                                    ? parameter.dependencies.map(dependencyKey => {
                                          const filter = filtersConfig.find(item => !!item.value[dependencyKey]);
                                          if (filter.type === 'sites' && filter.value[dependencyKey] === 'All') {
                                              return !!filter && {
                                                  value: filter.value[dependencyKey],
                                                  siteIds: filter.value[dependencyKey].siteIds
                                              };
                                          }  else {
                                              return !!filter && filter.value[dependencyKey];
                                          }
                                       // return !!filter && filter.value[dependencyKey];
                                    })
                                    : [];
                                return deps;
                            }),
                        );
                    }
                    switch (res['type']) {
                        case 'select':
                            res['data'] = typeof parameter['data'] !== 'undefined' ? parameter['data'] : null;
                            res['source'] = typeof parameter['source'] !== 'undefined' ? parameter['source'] : null;
                            res['multiple'] =
                                typeof parameter['multiple'] !== 'undefined' ? parameter['multiple'] : false;
                            res['tooltip'] = typeof parameter['tooltip'] !== 'undefined' ? parameter['tooltip'] : false;
                            res['tooltip-info'] =
                                typeof parameter['tooltip-info'] !== 'undefined' ? parameter['tooltip-info'] : null;
                            break;
                        case 'above-between-below':
                            res['data'] = typeof parameter['data'] !== 'undefined' ? parameter['data'] : null;
                            break;
                        case 'calendars':
                            res['calendar_type'] =
                                typeof parameter['calendar_type'] !== 'undefined' ? parameter['calendar_type'] : null;
                            res['calendar_options'] =
                                typeof parameter['calendar_options'] !== 'undefined'
                                    ? parameter['calendar_options']
                                    : null;
                            res['default_date'] = typeof parameter['default_date'] !== 'undefined'
                                ? parameter['default_date']
                                : null;
                            res['business_date_category'] =
                                typeof parameter['business_date_category'] !== 'undefined'
                                    ? parameter['business_date_category']
                                    : null;
                            res['multipleSites'] = reportState.selectedReport.additional_path_parameters[0]['multiple'];
                            break;
                        case 'items':
                        case 'sites':
                            res['multiple'] = parameter['multiple'];
                            break;
                        case 'employees':
                            res['default_value'] = parameter['default_value'];
                            res['multiple'] = parameter['multiple'];
                            break;
                        case 'string':
                            res['placeholder'] =
                                typeof parameter['placeholder'] !== 'undefined' ? parameter['placeholder'] : null;
                            break;
                        case 'job-codes':
                            res['calendar_type'] =
                                typeof parameter['calendar_type'] !== 'undefined' ? parameter['calendar_type'] : null;
                            break;
                        case 'combined-select':
                            res['data'] = typeof parameter['data'] !== 'undefined' ? parameter['data'] : null;
                            break;
                        default:
                            res['errors'] = typeof parameter['errors'] !== 'undefined' ? parameter['errors'] : [];
                    }
                    filters.push(res);
                }
            }
        }

        return filters;
    }

    public getDefaultPeriod(calendars, type, prevInputValue: any | null = null) {
        if (!calendars.length && !type) {
            const today = new Date();
            return { dateStart: today, dateEnd: today };
        }
        if (prevInputValue && prevInputValue.value) {
            if (
                calendars.find(calendar => {
                    return (
                        moment(calendar.dateStart).isSame(prevInputValue.value.dateStart) &&
                        moment(calendar.dateEnd).isSame(prevInputValue.value.dateEnd)
                    );
                })
            ) {
                return prevInputValue.value;
            }
        }
        let defaultValue;
        if (type === 'current_period') {
            defaultValue = calendars.find(item =>
                        moment().isBetween(item.dateStart, moment(item.dateEnd).subtract(1, 'd'), 'day', '[]')
                );
        }

        if (type === 'previous_period') {
            let index: number | null = null;
             calendars.find((item, i) => {
                if (moment().isBetween(item.dateStart, moment(item.dateEnd).subtract(1, 'd'), 'day', '[]')) {
                    index = i - 1;
                }
            });

            if (isNumber(index)) {
                defaultValue = calendars[index];
            }
        }

        if (defaultValue) {
            defaultValue = {
                ...defaultValue
            };
        } else {
            defaultValue = {
                ...calendars[calendars.length - 1]
            };
        }


        return defaultValue;
    }
    public setDefaultFilters(report, sites) {
        const results = [];
        if (report && report.additional_path_parameters) {
            let dateStart = 'businessdatestart';
            let dateStop = 'businessdateend';
            if (report.source === 'ssrs') {
                dateStart = 'Start_Date';
                dateStop = 'End_Date';
            }
            report.additional_path_parameters.forEach(param => {
                const values = {};
                switch (param.type) {
                    case 'calendars':
                        values[dateStart] = '';
                        values[dateStop] = '';
                        values['calendar'] = '';
                        values['business_date_category'] = param['business_date_category'] || 'Payroll';
                        results.push({
                            name: 'calendars',
                            type: 'calendars',
                            value: values
                        });
                        break;
                    case 'sites':
                        values['Site_Id'] = SELECT_ALL;
                        values['exclude_site_id'] = [];
                        results.push({
                            name: 'sites',
                            type: 'sites',
                            value: values
                        });
                        break;
                    case 'select':
                        switch (param.name) {
                            case 'sites':
                                values['Site_Id'] = sites.length !== 0 ? sites[0]['id'] : '';
                                results.push({
                                    name: 'sites',
                                    type: 'select',
                                    value: values
                                });
                                break;
                            default:
                                if (
                                    param['default_value'] ||
                                    param['default_value'] === false ||
                                    param['default_value'] === 0
                                ) {
                                    values[param['attributes'][0]] = param['default_value'];
                                    results.push({
                                        name: param.name,
                                        type: param.type,
                                        value: values
                                    });
                                }
                                break;
                        }
                        break;
                    case 'checkbox':
                        if (
                            param['default_value'] ||
                            param['default_value'] === false ||
                            param['default_value'] === 0
                        ) {
                            values[param['attributes'][0]] = param['default_value'];
                            results.push({
                                name: param.name,
                                type: param.type,
                                value: values
                            });
                        }
                        break;
                    case 'job-codes':
                        values['Site_Id'] = sites.length !== 0 ? sites[0]['id'] : '';
                        values['Job_Code'] = SELECT_ALL;
                        results.push({
                            name: 'job-codes',
                            type: 'job-codes',
                            value: values
                        });
                        break;
                    case 'tags':
                        values['Tags'] = SELECT_ALL;
                        results.push({
                            name: 'tags',
                            type: 'tags',
                            value: values
                        });
                        break;
                    case 'site':
                        values['Site_Id'] = sites.length !== 0 ? sites[0]['id'] : '';
                        results.push({
                            name: 'site',
                            type: 'site',
                            value: values
                        });
                        break;
                    case 'job-categories':
                        values['Job_Category'] = SELECT_ALL;
                        results.push({
                            name: 'job-categories',
                            type: 'job-categories',
                            value: values
                        });
                        break;
                    case 'employees':
                        values['Employees'] = SELECT_ALL;
                        results.push({
                            name: 'employees',
                            type: 'employees',
                            value: values
                        });
                        break;
                    case 'items':
                        values['Item_Dept'] = SELECT_ALL;
                        values['Item_Type'] = SELECT_ALL;

                        results.push({
                            name: 'items',
                            type: 'items',
                            value: values
                        });
                        break;
                    case 'above-between-below':
                        values['Range'] = 'All';
                        results.push({
                            name: param.name,
                            type: param.type,
                            value: values
                        });
                        break;
                    case 'forecast-definition':
                        values['Forecast_Definition'] = SELECT_ALL;
                        values['Site_Id'] = sites.length !== 0 ? sites[0]['id'] : '';
                        results.push({
                            name: 'forecast-definition',
                            type: 'forecast-definition',
                            value: values
                        });
                        break;
                    case 'static-params':
                        if (param.attributes.length > 0) {
                            param.attributes.forEach(attr => {
                                values[attr['name']] = attr['value'];
                            });
                        }
                        results.push({
                            name: 'static-params',
                            type: 'static-params',
                            value: values
                        });
                        break;
                    case 'combined-select':
                        if (param.attributes.length > 0 && param['default_value']) {
                            values[param['attributes'][0]] = param['default_value'];
                            param.attributes.forEach(attr => {
                                values[attr['param_name']] = attr;
                            });
                        }
                        results.push({
                            name: 'combined-select',
                            type: 'combined-select',
                            value: values
                        });
                        break;
                    default: {
                    }
                }
            });
        }
        return results;
    }
}
