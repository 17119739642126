import { PreferencesService } from '../../../shared/services/preferences/preferences.service';
import { AgGridReportsService } from '../../../shared/services/reports';

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ReportColumnDefsService {
    constructor(private preferencesService: PreferencesService,
                private agGridReportsService: AgGridReportsService) {}

    public getOrderExplorerColumnDefs() {
        return [
            {
                headerName: 'Business Site',
                field: 'site',
                minWidth: 120,
                cellClass: ['custom-cell-left'],
                enableRowGroup: true,
                chartDataType: 'category'
            },
            {
                headerName: 'Open Date/Time',
                field: 'order_time',
                valueFormatter: this.agGridReportsService.dateAndTimeCellRenderer,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-date'],
                minWidth: 135
            },
            {
                headerName: 'Business Date',
                field: 'business_date',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                valueFormatter: this.agGridReportsService.dateCellRenderer,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 125,
            },
            {
                headerName: 'Order #',
                field: 'order_number',
                filter: 'agTextColumnFilter',
                minWidth: 100
            },
            {
                headerName: 'Order Type',
                field: 'order_type',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                minWidth: 110
            },
            {
                headerName: 'Source',
                field: 'order_source',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                hide: true,
                minWidth: 100
            },
            {
                headerName: 'Order State',
                field: 'order_state',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                filter: 'agSetColumnFilter',
                hide: true,
                minWidth: 130
            },
            {
                headerName: 'Destination',
                field: 'destination',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                minWidth: 120
            },
            {
                headerName: 'Guests',
                field: 'guest_count',
                hide: true,
                aggFunc: 'customSum',
                minWidth: 100,
                cellClass: ['custom-count'],
                valueFormatter: this.preferencesService.numberFormatterForAgGrid
            },
            {
                headerName: 'Close Date/Time',
                field: 'close_time',
                valueFormatter: this.agGridReportsService.dateAndTimeCellRenderer,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-date'],
                minWidth: 135
            },
            {
                headerName: 'Day Part',
                field: 'day_part_type_name',
                hide: true,
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                minWidth: 120
            },
            {
                headerName: 'Customer',
                enableRowGroup: true,
                field: 'customer__first_name',
                cellClass: ['custom-cell-left'],
                hide: true,
                minWidth: 120,
                comparator: this.agGridReportsService.customStringComparator,
                valueFormatter: this.agGridReportsService.customerNameFormatter
            },
            {
                headerName: 'Employee',
                field: 'employee',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                hide: true,
                minWidth: 120
            },
            {
                headerName: 'Net Sales',
                field: 'net_sales',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 100
            },
            {
                headerName: 'Tax Total',
                field: 'total_tax',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                hide: true,
                minWidth: 110
            },
            {
                headerName: 'Discount Total',
                field: 'total_discount',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                hide: true,
                minWidth: 125
            },
            {
                headerName: 'Liabilities',
                field: 'liability',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                hide: true,
                minWidth: 125
            },
            {
                headerName: 'Subtotal',
                field: 'subtotal',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                hide: true,
                minWidth: 100
            },
            {
                headerName: 'Tips',
                field: 'tips',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                hide: true,
                minWidth: 100
            },
            {
                headerName: 'Payment Total',
                field: 'payment',
                aggFunc: 'customSum',
                enableRowGroup: false,
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-price'],
                minWidth: 125
            },
            {
                headerName: 'Rounded Adj',
                field: 'rounding_adjustment',
                aggFunc: 'customSum',
                enableRowGroup: false,
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-price'],
                hide: true,
                minWidth: 100
            }
        ];
    }

    public getEnterpriseSalesColumnDefs() {
        return [
            {
                headerName: 'State',
                field: 'state_name',
                minWidth: 180,
                cellClass: ['custom-cell-left'],
                enableRowGroup: true,
                rowGroup: true,
                chartDataType: 'category'
            },
            {
                headerName: 'Site Name',
                field: 'site_name',
                cellClass: ['custom-cell-left'],
                enableRowGroup: true,
                minWidth: 135,
                chartDataType: 'category'
            },
            {
                headerName: 'Site ID',
                field: 'store_number',
                enableRowGroup: true,
                rowGroup: true,
                cellClass: ['custom-cell-left'],
                minWidth: 180
            },
            {
                headerName: 'Business Date',
                field: 'business_date',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                valueFormatter: this.agGridReportsService.dateCellRenderer,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 125,
                cellRenderer: 'agGroupCellRenderer'
            },
            {
                headerName: 'Gross Sales',
                field: 'gross_sales',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 110
            },
            {
                headerName: 'Discount Total',
                field: 'discount_total',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 120
            },
            {
                headerName: 'Liabilities Sold',
                field: 'liability_total',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 120
            },
            {
                headerName: 'Liability Promotions',
                field: 'liability_promotions',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                hide: true,
                minWidth: 150
            },
            {
                headerName: 'Post Payment Voids',
                field: 'post_payment_void_total',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                hide: true,
                minWidth: 120
            },
            {
                headerName: 'Refund Total',
                field: 'refund_total',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 120
            },
            {
                headerName: 'Tax Total',
                field: 'tax_total',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 110
            },
            {
                headerName: 'Voided Total',
                field: 'voided_total',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                hide: true,
                minWidth: 110
            },
            {
                headerName: 'Net Sales',
                field: 'net_sales',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 100
            },
            {
                headerName: 'Net Total',
                field: 'net_total',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                hide: true,
                headerTooltip: 'Net Total = Net Sales - Liability Promotions',
                minWidth: 100
            },
            {
                headerName: 'Total Refunded Tax',
                field: 'refund_tax',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                hide: true,
                minWidth: 130
            },
            {
                headerName: 'Orders',
                field: 'orders',
                hide: true,
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-count'],
                minWidth: 100,
                valueFormatter: this.preferencesService.numberFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
            },
            {
                headerName: 'Order Avg',
                field: 'orders_avg',
                hide: true,
                aggFunc: 'customAvgSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                valueGetter: function(params) {
                    const q1 = params.getValue('net_sales');
                    const q2 = params.getValue('orders');

                    const result = q1 / q2;
                    if (+q2 === 0 || +q1 === 0) {
                        return 0;
                    }
                    return result;
                },
                comparator: this.agGridReportsService.customNumberComparator,
                enableValue: true,
                minWidth: 110
            },
            {
                headerName: 'Guests',
                field: 'guests',
                hide: true,
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-count'],
                minWidth: 100,
                valueFormatter: this.preferencesService.numberFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
            },
            {
                headerName: 'Guest Avg',
                field: 'guest_avg',
                hide: true,
                aggFunc: 'customAvgSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                valueGetter: function(params) {
                    const q1 = params.getValue('net_sales');
                    const q2 = params.getValue('guests');

                    const result = q1 / q2;
                    if (+q2 === 0 || +q1 === 0) {
                        return 0;
                    }
                    return result;
                },
                enableValue: true,
                minWidth: 110
            },
            {
                headerName: 'Tips',
                field: 'tips',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 100
            },
            {
                headerName: 'Payments',
                field: 'payment_total',
                aggFunc: 'customSum',
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-price'],
                minWidth: 125
            },
            {
                headerName: 'Liabilities Sold: Gift Card',
                field: 'liability_gift_card',
                aggFunc: 'customSum',
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-price'],
                minWidth: 125
            },
            {
                headerName: 'Liabilities Sold: Gift Certificate',
                field: 'liability_gift_certificate',
                aggFunc: 'customSum',
                hide: true,
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-price'],
                minWidth: 125
            },
            {
                headerName: 'Deposit Variance: Actual',
                field: 'deposit_actual',
                aggFunc: 'customSum',
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-price'],
                minWidth: 125
            },
            {
                headerName: 'Deposit Variance: Expected',
                field: 'deposit_expected',
                aggFunc: 'customSum',
                hide: true,
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-price'],
                minWidth: 125
            },
            {
                headerName: 'Deposit Variance: Variance',
                field: 'deposit_variance',
                aggFunc: 'customSum',
                hide: true,
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-price'],
                minWidth: 125
            },
            {
                headerName: 'Paid In',
                field: 'paid_in',
                aggFunc: 'customSum',
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-price'],
                minWidth: 125
            },
            {
                headerName: 'Paid Out',
                field: 'paid_out',
                aggFunc: 'customSum',
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-price'],
                minWidth: 125
            }
        ];
    }


    public getEmployeeDiscountDefs() {
        return [
            {
                headerName: 'Site Name',
                field: 'store_name',
                minWidth: 180,
                cellClass: ['custom-cell-left'],
                enableRowGroup: true,
                rowGroup: true,
                chartDataType: 'category'
            },
            {
                headerName: 'Site ID',
                field: 'store_number',
                cellClass: ['custom-cell-left'],
                minWidth: 110,
                enableRowGroup: true,
                chartDataType: 'category'
            },
            {
                headerName: 'Business Date',
                field: 'business_date',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                valueFormatter: this.agGridReportsService.dateCellRenderer,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 125,
                cellRenderer: 'agGroupCellRenderer'
            },
            {
                headerName: 'Performed by',
                enableRowGroup: true,
                field: 'performed_by',
                cellClass: ['custom-cell-left'],
                minWidth: 140,
                comparator: this.agGridReportsService.customStringComparator,
            },
            {
                headerName: 'Received By',
                enableRowGroup: true,
                field: 'received_by',
                cellClass: ['custom-cell-left'],
                minWidth: 140,
                comparator: this.agGridReportsService.customStringComparator,
            },
            {
                headerName: 'Order #',
                field: 'order_number',
                cellClass: ['custom-cell-left'],
                minWidth: 120
            },
            {
                headerName: 'Order Type',
                field: 'order_type',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                filter: 'agSetColumnFilter',
                minWidth: 110,
            },
            {
                headerName: 'Order State',
                field: 'state',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                filter: 'agSetColumnFilter',
                minWidth: 110
            },
            {
                headerName: 'Discount Applied Date/Time',
                field: 'discount_applied',
                valueFormatter: this.agGridReportsService.dateAndTimeCellRenderer,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-date'],
                minWidth: 155
            },
            {
                headerName: 'Discount Total',
                field: 'discount_amount',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 125
            },
            {
                headerName: 'Promotions Total',
                field: 'promotion_total',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 135
            },
            {
                headerName: 'Order Total',
                field: 'order_total',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 125
            },
        ];
    }

    public getOrderPaymentsDefs() {
        return [
            {
                headerName: 'Site Name',
                field: 'store_name',
                minWidth: 180,
                cellClass: ['custom-cell-left'],
                enableRowGroup: true,
            },
            {
                headerName: 'Business Date',
                field: 'business_date',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                valueFormatter: this.agGridReportsService.dateCellRenderer,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 125,
                cellRenderer: 'agGroupCellRenderer'
            },
            {
                headerName: 'Payment Date/Time',
                field: 'payment_time',
                valueFormatter: this.agGridReportsService.dateAndTimeCellRenderer,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-date'],
                minWidth: 155
            },
            {
                headerName: 'Order #',
                field: 'order_number',
                minWidth: 120
            },
            {
                headerName: 'Type',
                field: 'order_type',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                filter: 'agSetColumnFilter',
                minWidth: 110,
            },
            {
                headerName: 'Order State',
                field: 'order_state',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                filter: 'agSetColumnFilter',
                minWidth: 110
            },
            {
                headerName: 'Ext Total',
                field: 'grand_total',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                allowedAggFuncs: [],
                minWidth: 125
            },
            {
                headerName: 'Liabilities',
                field: 'liability',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                allowedAggFuncs: [],
                minWidth: 125
            },
            {
                headerName: 'Pre-Payment Tips',
                field: 'pre_payment_tips',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                allowedAggFuncs: [],
                minWidth: 125
            },
            {
                headerName: 'Payment Tips',
                field: 'payment_tips',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 125
            },
            {
                headerName: 'Payment',
                field: 'payment',
                aggFunc: 'customSum',
                cellClass: ['custom-full-width custom-price'],
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                minWidth: 125
            },
            {
                headerName: 'Rounded Adj',
                field: 'rounding_adjustment',
                aggFunc: 'customSum',
                enableRowGroup: false,
                cellRenderer: this.preferencesService.currencyFormatterForAgGrid,
                comparator: this.agGridReportsService.customNumberComparator,
                cellClass: ['custom-full-width custom-price'],
                hide: true,
                minWidth: 100
            },
            {
                headerName: 'Payment Status',
                field: 'payment_status',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                filter: 'agSetColumnFilter',
                minWidth: 110
            },
            {
                headerName: 'SAF',
                field: 'saf',
                filter: 'agSetColumnFilter',
                minWidth: 110
            },
            {
                headerName: 'Pay Type',
                field: 'pay_type',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                filter: 'agSetColumnFilter',
                minWidth: 110
            },
            {
                headerName: 'Acquisition Type',
                field: 'acquisition_type',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                filter: 'agSetColumnFilter',
                minWidth: 110
            },
            {
                headerName: 'Trans Type',
                field: 'transaction_type',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                filter: 'agSetColumnFilter',
                minWidth: 110
            },
            {
                headerName: 'Trans ID',
                field: 'trans_id',
                filter: 'agSetColumnFilter',
                minWidth: 110
            },
            {
                headerName: 'Card Brand',
                field: 'card_brand_name',
                enableRowGroup: true,
                cellClass: ['custom-cell-left'],
                filter: 'agSetColumnFilter',
                minWidth: 110
            },
            {
                headerName: 'Auth #',
                field: 'auth_number',
                filter: 'agSetColumnFilter',
                minWidth: 110
            },
            {
                headerName: 'Last 4 of CC#',
                field: 'cc_number',
                filter: 'agSetColumnFilter',
                minWidth: 110
            },
        ];
    }
}
