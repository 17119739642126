import {
    Component,
    AfterViewInit,
    OnDestroy,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    Output, EventEmitter
} from '@angular/core';
import { AgGridPdfExportService, IPdfReportOptions } from '@services/reports';
import { Column, GridOptions } from '@ag-grid-enterprise/all-modules';
import { IToolbarButtonDefinition } from '@shared/types';
import { isEmpty } from 'lodash';

@Component({
    selector: 'pdf-export-modal',
    templateUrl: 'pdf-export-modal.component.html',
    styleUrls: ['pdf-export-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PdfExportModalComponent implements AfterViewInit, OnDestroy {
    @Input()
    set gridOptions(options: GridOptions) {
        if (!isEmpty(options)) {
            this._gridOptions = options;
            this.changeDetectorRef.markForCheck();
            this.updateColumnArraysToExport();
        }
    }

    get gridOptions(): GridOptions {
        return this._gridOptions;
    }

    @Input()
    set pdfReportOptions(data: IPdfReportOptions) {
        this._pdfReportOptions = { ...data };
        this.changeDetectorRef.markForCheck();
    }

    get pdfReportOptions(): IPdfReportOptions {
        return this._pdfReportOptions;
    }

    @Input()  isShowModal: boolean;

    @Output() closeModal = new EventEmitter();

    public oversizeWidthForTable = false;
    public ExportingModalTitle = 'Select Columns to Export';
    public selectedColumnsToExport: any[] = [];
    public selectedGroupedColumnsToExport: any[] = [];
    public hiddenColumnsToExport: any[] = [];
    public hiddenBottomModalBlock = true;
    public buttons: IToolbarButtonDefinition[] = [
        {
            caption: 'Export',
            value: 'done',
            disabled: true,
            class: 'btn-primary pull-right',
            click: () => this.exportReportToPDF()
        },
        {
            caption: 'Cancel',
            value: 'cancel',
            class: 'btn-default pull-right',
            click: () => this.closePdfModal()
        }
    ];
    private _gridOptions: GridOptions;
    private _pdfReportOptions: IPdfReportOptions;

    constructor(
        private agGridPdfExportService: AgGridPdfExportService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngAfterViewInit() {}

    ngOnDestroy() {}

    public exportReportToPDF() {
        this.agGridPdfExportService.generatePDF(this.gridOptions, this.pdfReportOptions);
    }

    public closePdfModal(): void {
        this.closeModal.emit();
    }

    public removeColumn(id) {
        this.gridOptions.columnApi.setColumnVisible(id, false);

        this.updateColumnArraysToExport();
    }

    public removeGroupedColumn(id) {
        this.gridOptions.columnApi.removeRowGroupColumn(id);

        this.updateColumnArraysToExport();
    }

    public addColumn(id) {
        this.gridOptions.columnApi.setColumnVisible(id, true);

        this.updateColumnArraysToExport();
    }

    private updateColumnArraysToExport() {
        let gridWidth = 0;
        const maxLandscapeWidth = 1601;
        this.selectedColumnsToExport = [];
        this.hiddenColumnsToExport = [];
        this.selectedGroupedColumnsToExport = [];
        this.gridOptions.columnApi.getRowGroupColumns().forEach((column: Column) => {
            this.selectedGroupedColumnsToExport.push({
                value: column.getUserProvidedColDef().headerName,
                id: column.getColId()
            });
            gridWidth += column.getMinWidth();
        });
        this.gridOptions.columnApi.getAllColumns().forEach((item: Column, index) => {
            if (item.isVisible()) {
                this.selectedColumnsToExport.push({
                    value: item.getUserProvidedColDef().headerName,
                    id: item.getColId()
                });
                gridWidth += item.getMinWidth();
            } else {
                if (this.selectedGroupedColumnsToExport.filter(col => col.id === item.getColId()).length === 0) {
                    this.hiddenColumnsToExport.push({
                        value: item.getUserProvidedColDef().headerName,
                        id: item.getColId()
                    });
                }
            }
        });

        if (gridWidth >= maxLandscapeWidth) {
            this.oversizeWidthForTable = true;
        } else {
            this.oversizeWidthForTable = false;
        }

        if (maxLandscapeWidth > gridWidth && this.selectedGroupedColumnsToExport.length <= 2) {
            this.buttons[0].disabled = false;
        } else {
            this.buttons[0].disabled = true;
        }
    }
}
