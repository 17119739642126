<div class="report_calendars_filter">
  <div *ngIf="tags.length">
    <span class="calendar_label">Tags</span>

    <angular2-multiselect [data]="tags" [(ngModel)]="inputTagValue"
                          [settings]="dropdownSettings"
                          (onSelect)="handleDataChange()"
                          (onDeSelect)="handleDataChange()"
                          (onSelectAll)="handleDataChange()"
                          (onDeSelectAll)="handleDataChange()"></angular2-multiselect>

  </div>
  <div class="errors-block">
    <div  class="error" *ngFor="let error of errors">{{error}}</div>
  </div>
</div>
