import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Cancel HTTP requests Service
 */

@Injectable()
export class HttpCancelService implements OnDestroy {
    private cancelPendingRequests$ = new Subject<void>();

    constructor() {
    }

    public cancelPendingRequests() {
        this.cancelPendingRequests$.next();
    }

    public onCancelPendingRequests() {
        return this.cancelPendingRequests$.asObservable();
    }

    public ngOnDestroy(): void {
        this.cancelPendingRequests$.next();
        this.cancelPendingRequests$.complete();
    }
}

