<h3>
    <b *ngIf="!customTitle; else customTemplateTitle">Sales Analysis</b>
    <ng-template #customTemplateTitle>
        <b>{{ customTitle }}</b>
    </ng-template>
</h3>
<tools-panel *ngIf="csvData.length" [csvData]="csvData"></tools-panel>
<div class="row" id="ReportCustom">
    <div class="col-md-12 statistics_block">
    <div class="statistics_block_header">

    </div>
    <div class="row is-nowrap">
      <div class="col-md-6 measure_block">
        <h4>Left measure</h4>
        <angular2-multiselect [data]="itemsLeftMeasure"
          [(ngModel)]="selectedItemLeftMeasure"
          [settings]="dropdownSettings"
          (onSelect)="onLeftMeasureSelect($event)"
          (onDeSelect)="onLeftMeasureDeselect($event)"
          ngDefaultControl>
        </angular2-multiselect>
      </div>
      <div class="col-md-6 measure_block">
        <h4>Right measure</h4>
        <angular2-multiselect [data]="itemsRightMeasure"
          [(ngModel)]="selectedItemRightMeasure"
          [settings]="dropdownSettings"
          (onSelect)="onRightMeasureSelect($event)"
          (onDeSelect)="onRightMeasureDeselect($event)"
          ngDefaultControl>
        </angular2-multiselect>
      </div>
    </div>
    <div id="sales_analysis_chart" class="sales-analysis-chart"></div>
  </div>

</div>
