import { Component, Input, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CustomReportsService } from '@shared/services';
import { LocalDbStorageService } from '@shared/services';
import { AgGridReportsService, IPdfReportOptions } from '@shared/services/reports';
import { PreferencesService } from '@shared/services';
import { getDefaultGridOptions, ReportColumnDefsService } from '../helpers';
import { ToasterService, Toast, BodyOutputType } from 'angular2-toaster';
import { DisplayingConflictPopupComponent } from './displaying-conflict-popup/displaying-conflict-popup.component';
import * as moment from 'moment';
import { UiActions } from '@store/actions';
import { GridOptions, AllModules, Module, RowNode } from '@ag-grid-enterprise/all-modules';
import { maxPageSize } from '@shared/utils';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'order-explorer',
    templateUrl: 'order-explorer.component.html',
    styleUrls: ['order-explorer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderExplorerComponent implements OnInit, OnDestroy {
    @Input()
    set currentCompanyId(val: string) {
        this._currentCompanyId = val;
    }

    get currentCompanyId(): string {
        return this._currentCompanyId;
    }

    @Input()
    public set data(data) {
        if (this.isNewRequestData(data)) {
            this.toasterService.clear();
            this.getOrderExplorerData(data);
        }
    }

    @Input()
    public set gridOptions(value: GridOptions) {
        this._gridOptions = value;
    }

    public get gridOptions(): GridOptions {
        return this._gridOptions;
    }

    public reportTitle = 'Order Explorer';
    public dateTo: string = '';
    public dateFrom: string = '';
    public ordersCount: number;
    public currentOrder: any;
    public isPopupActive = false;
    public modules: Module[] = AllModules;
    public limitReached: boolean = false;
    public limitReachedTooltipText: string;
    protected businessDate: string = '';
    protected siteIds: string[] | string = 'All';
    protected activeFilters: any;
    private buildVersion = '';
    private _currentCompanyId: string;
    private possibleBuildConflicts = true;
    private hasMoreCertainRequest = true;
    private loadedDBStorageConfig = false;
    private ordersAmount: number;
    private gridApi;
    private unsubscribe$: Subject<void> = new Subject<void>();

    public openedExportDropdownMenu = false;

    // Modal props
    public _showModal = false;
    public pdfReportOptions: IPdfReportOptions;
    public gridOptionsForExportToPdf: GridOptions;

    // Ag-Grid configs
    public domLayout;
    public rowData: RowNode[] = [];
    public defaultColDef;
    public columnDefs;
    public _gridOptions: GridOptions;

    constructor(
        private uiActions: UiActions,
        private reportService: CustomReportsService,
        private toasterService: ToasterService,
        public agGridReportsService: AgGridReportsService,
        public localDbStorageService: LocalDbStorageService,
        private preferencesService: PreferencesService,
        private reportColumnDefsService: ReportColumnDefsService,
        private cdRef: ChangeDetectorRef
    ) {}

    public ngOnInit() {
        this.initializeGrid();
    }

    public ngOnDestroy() {
        this.uiActions.disableContentLoader();
        this.isNewRequestData({});
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public onRowSelected(event) {
        if (!event.node.selected || !event.data) {
            return;
        }
        const currentNode = event.node;
        const [selectedRow] = this.gridOptions.api.getSelectedRows();
        const selectedOrderId = selectedRow.order_id;
        const selectedBusinessDate = selectedRow.business_date;
        this.uiActions.enableContentLoader();

        this.reportService
            .fetchOrderExplorerDetail(this.currentCompanyId, selectedRow.site_id, selectedOrderId, selectedBusinessDate)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (response: any) => {
                    if (response[0].data && response[0]?.data.length) {
                        response.push(selectedRow.payment);
                        this.currentOrder = response;
                        this.isPopupActive = true;
                    } else {
                        this.toasterService.pop('error', 'Oops... sorry! No details information on this order.');
                    }
                    this.uiActions.disableContentLoader();
                    currentNode.setSelected(false);
                },
                error => {
                    this.uiActions.disableContentLoader();
                    this.toasterService.pop('error', error.statusText);
                }
            );
    }

    public onClosePopup(value) {
        this.isPopupActive = value;
    }

    public dateFormatter(value) {
        if (value === undefined || value === null) {
            return '';
        } else if (value === '(None)') {
            return '(None)';
        } else {
            return this.preferencesService.dateFormatter(value);
        }
    }

    public numberFormatter(val) {
        return this.preferencesService.numberFormatterForAgGrid(val);
    }

    // Exporting
    // *******************************
    public exportReportToCSV() {
        this.agGridReportsService.exportReportToCSV(this.gridOptions, this.reportTitle);
    }

    public exportReportToXLS() {
        this.agGridReportsService.exportReportToXLS(this.gridOptions, this.reportTitle);
    }

    public openPdfModal() {
        this._gridOptions.columnApi.getDisplayedLeftColumns().forEach(column => {
            this._gridOptions.columnApi.setColumnPinned(column.getColId(), null);
        });

        this._gridOptions.columnApi.getDisplayedRightColumns().forEach(column => {
            this._gridOptions.columnApi.setColumnPinned(column.getColId(), null);
        });

        this.gridOptionsForExportToPdf = this.gridOptions;

        this.pdfReportOptions = {
            reportTitle: this.reportTitle,
            dateTo: this.dateTo,
            dateFrom: this.dateFrom,
            ordersCount: this.ordersCount
        };

        this._showModal = true;
    }

    public closeModal(): void {
        this._showModal = false;
        this.gridOptionsForExportToPdf = {};
    }

    public closeDropdownMenu(): void {
        if (!this.openedExportDropdownMenu) {
            return;
        }
        this.openedExportDropdownMenu = false;
    }

    public toggleDropdownMenu(event): void {
        this.openedExportDropdownMenu = !this.openedExportDropdownMenu;
        event.stopPropagation();
    }

    private isNewRequestData(data) {
        return this.reportService.isNewRequestData(data);
    }

    public onGridReady(params) {
        this.gridApi = params.api;
        this.domLayout = 'normal';
    }

    public sortByDefault() {
        const sort = [
            {
                colId: 'order_time',
                sort: 'asc'
            }
        ];
        this._gridOptions.api.setSortModel(sort);
    }

    public areEqualDates(dates: any[]): boolean {
        return this.agGridReportsService.areEqualDates(dates);
    }

    private async initGrid(tempData) {
        this.ordersCount = tempData.length;
        this.rowData = this.getNormalizeData(tempData);

        await this.syncReportState();
        this.clearByDefault();

        this.addCustomBottomLabel('Total');

        if (this.hasMoreCertainRequest) {
            let renderedNodesCount = 0;

            this._gridOptions.api.forEachNodeAfterFilterAndSort(node => {
                if (node.group === false) {
                    ++renderedNodesCount;
                }
            });

            if (this.ordersAmount !== undefined && renderedNodesCount !== this.ordersAmount) {
                this.showWarningMessageAboutDisplayingConflicts();
            }
        }
    }

    private resetToDefault(): void {
        this.clearFilters();
        this.sortByDefault();
    }

    private async syncReportState(changed = false) {
        const currentState = await this.localDbStorageService.getItem('order_explorer_config');
        if (this.loadedDBStorageConfig === false) {
            this.loadedDBStorageConfig = true;
        }
        const filters = this._gridOptions.api.getFilterModel();
        const sorts = this._gridOptions.api.getSortModel();
        const selectedColumns = this._gridOptions.columnApi.getAllDisplayedColumns().map(item => item.getColId());
        const columnsState = this._gridOptions.columnApi.getColumnState();

        const configObj = {
            filterModel: filters,
            sortModel: sorts,
            columnsState: columnsState,
            selectedColumns: selectedColumns
        };

        if (!currentState) {
            this.sortByDefault();
            this.setClosedStateForOrdersByDefault();
            configObj['buildVersion'] = this.buildVersion;
            await this.localDbStorageService.setItem('order_explorer_config', configObj);
        } else {
            // check if deployed app version has conflicts with sync app version
            if (currentState && currentState.buildVersion) {
                if (currentState.buildVersion !== this.buildVersion && this.possibleBuildConflicts) {
                    this._gridOptions.columnApi.resetColumnState();
                    this.resetToDefault();
                    this.possibleBuildConflicts = false;
                }
                configObj['buildVersion'] =
                    currentState.buildVersion !== this.buildVersion ? this.buildVersion : currentState.buildVersion;
            } else {
                configObj['buildVersion'] = this.buildVersion;
            }
        }

        if (changed) {
            await this.localDbStorageService.setItem('order_explorer_config', configObj);
        } else if (currentState) {
            this._gridOptions.api.setFilterModel(currentState.filterModel);
            this._gridOptions.api.setSortModel(currentState.sortModel);
            this._gridOptions.columnApi.setColumnState(currentState.columnsState);
        }
    }

    private getNormalizeData(data) {
        return data.reduce((filtered, item) => {
            const tempObj = {
                ...item,
                net_sales: this.agGridReportsService.prepareCurrencyField(item.net_sales, item.order_type === 'refund'),
                total_tax: this.agGridReportsService.prepareCurrencyField(item.total_tax),
                total_discount: this.agGridReportsService.prepareCurrencyField(item.total_discount),
                subtotal: this.agGridReportsService.prepareCurrencyField(item.subtotal),
                order_total: this.agGridReportsService.prepareCurrencyField(item.order_total),
                tips: this.agGridReportsService.prepareCurrencyField(item.tips, item.order_type === 'refund'),
                payment: this.agGridReportsService.prepareCurrencyField(item.payment),
                rounding_adjustment: this.agGridReportsService.prepareCurrencyField(item.rounding_adjustment)
            };
            filtered.push(tempObj);

            return filtered;
        }, []);
    }

    private getOrderExplorerData(data) {
        this.uiActions.enableContentLoader();
        this.siteIds = data.Site_Id || 'All';
        this.dateFrom = data.Start_Date;
        this.dateTo = data.End_Date;

        const orderNumber = data.Order_Number;
        const cardNumber = data.Card_Number;
        const tableNumber = data.Table_Number;
        if (data.Calendar_Period === 'true' && data.Start_Date !== data.End_Date) {
            this.dateTo = moment(data.End_Date, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD');
        }

        const request = {
            company: this.currentCompanyId,
            sites: this.siteIds,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            perPage: maxPageSize
        };

        if (this.hasMoreCertainRequest === false) {
            this.hasMoreCertainRequest = true;
        }
        if (!cardNumber && !orderNumber && !tableNumber) {
            request['orderNumber'] = 'All';
            this.hasMoreCertainRequest = false;
        } else if (orderNumber && orderNumber.length) {
            request['orderNumber'] = orderNumber;
        } else if (cardNumber && cardNumber.length) {
            request['cardNumber'] = cardNumber;
        } else if (tableNumber && tableNumber.length) {
            request['tableNumber'] = tableNumber;
        }
        this.reportService
            .fetchOrderExplorerOrderCount(request)
            .pipe(
                takeUntil(this.unsubscribe$),
                tap((tempData: { amount: string }) => {
                    this.ordersAmount = +tempData.amount;
                }),
                switchMap(() => this.reportService.fetchOrderExplorer(request))
            )
            .subscribe(
                (tempData: { build: string; data: any }) => {
                    this.buildVersion = tempData.build;
                    this.initGrid(tempData.data).then(() => {
                        this.uiActions.disableContentLoader();
                        this.cdRef.detectChanges();
                        if (this.ordersAmount > request.perPage) {
                            this.showWarningMessageAboutLimits();
                            this.limitReachedTooltipText = `The Max Row Limit (${this.numberFormatter(
                                maxPageSize
                            )}) has been exceeded. Adjust your parameter selections to reduce the scope of your query.`;
                            this.limitReached = true;
                        } else {
                            this.limitReached = false;
                        }
                    });
                },
                error => {
                    this.uiActions.disableContentLoader();
                    this.toasterService.pop('error', error.statusText);
                },
                () => {
                    this.cdRef.detectChanges();
                }
            );
    }

    private clearByDefault() {
        const filters = this._gridOptions.api.getFilterModel();

        if (filters.order_number) {
            const instance = this._gridOptions.api.getFilterInstance('order_number');

            instance.setModel({
                filterType: 'text',
                type: 'contains',
                filter: ''
            });

            this._gridOptions.api.onFilterChanged();
        }
    }

    private clearFilters() {
        this._gridOptions.api.setFilterModel(null);

        this.setClosedStateForOrdersByDefault();
    }

    private setClosedStateForOrdersByDefault() {
        const filterInstance = this.gridApi.getFilterInstance('order_state');
        filterInstance.selectNothing();

        filterInstance.selectValue('closed');
        filterInstance.applyModel();
        this.gridApi.onFilterChanged();
    }

    private showWarningMessageAboutLimits() {
        const toast: Toast = {
            type: 'warning',
            title: `Whoa! That's Alot of Data!`,
            body: `The Max Row Limit (${this.numberFormatter(
                maxPageSize
            )}) has been exceeded. Adjust your parameter selections to reduce the scope of your query.`,
            timeout: 15000,
            showCloseButton: true,
            closeHtml: '<button>Close</button>'
        };

        this.toasterService.pop(toast);
    }

    private showWarningMessageAboutDisplayingConflicts() {
        const columns = this._gridOptions.columnApi.getAllColumns();
        let filters = Object.keys(this._gridOptions.api.getFilterModel());
        filters = columns
            .filter(item => filters.includes(item.getUserProvidedColDef().field))
            .map(item => item.getUserProvidedColDef().headerName);
        this.agGridReportsService.generateDisplayConflictsPopupContent({
            filters: filters,
            count: this.ordersAmount,
            api: this._gridOptions
        });

        const toast: Toast = {
            type: 'warning',
            title: `Not all orders are displaying!`,
            body: DisplayingConflictPopupComponent,
            bodyOutputType: BodyOutputType.Component,
            timeout: 15000,
            showCloseButton: true,
            tapToDismiss: true,
            closeHtml: '<button>Close</button>'
        };

        this.toasterService.pop(toast);
    }

    private addCustomBottomLabel(name: string) {
        this.agGridReportsService.addCustomBottomLabel(name, this.rowData);
    }

    private generateAllGridEvents() {
        return {
            onColumnRowGroupChanged: params => {
                params.api.sizeColumnsToFit();
                this.addCustomBottomLabel('Total');
                this.syncReportState(true);
            },
            onRowGroupOpened: params => {
                this.addCustomBottomLabel('Total');
            },
            onFilterChanged: params => {
                this.addCustomBottomLabel('Total');
                this.syncReportState(true);
            },
            onSortChanged: params => {
                this.syncReportState(true);
            },
            onDisplayedColumnsChanged: params => {
                params.api.sizeColumnsToFit();
                if (this.loadedDBStorageConfig) {
                    this.syncReportState(true);
                }
            },
            onColumnPinned: params => {
                this.syncReportState(true);
            },
            onColumnMoved: params => {
                this.syncReportState(true);
            },
            onColumnEverythingChanged: params => {
                if (params.source === 'contextMenu') {
                    this.resetToDefault();
                }
            },
            onExpandOrCollapseAll: () => {
                this.addCustomBottomLabel('Total');
            }
        };
    }

    private initializeGrid() {
        this._gridOptions = {
            ...getDefaultGridOptions({
                customFooterValueGetter: this.agGridReportsService.customFooterValueGetter,
                customSumAggFunc: this.agGridReportsService.customSumAggFunc
            }),
            ...this.generateAllGridEvents()
        };

        this.columnDefs = this.reportColumnDefsService.getOrderExplorerColumnDefs();

        this.defaultColDef = {
            sortable: true,
            resizable: true,
            filter: true
        };
    }
}
