import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import * as json2csv from 'json2csv';

@Component({
    selector: 'tools-panel',
    templateUrl: 'tools.panel.component.html',
    styleUrls: ['tools.panel.component.css']
})
export class ToolsPanelComponent implements OnInit, OnDestroy {
    @Input() private mode: string = 'p';
    @Input() private csvData: any = [];
    @Input() private format: string = 'a4';
    @Input() private reportName: string = 'dashboard';
    @Input() private dateFrom: string = '';
    @Input() private dateTo: string = '';

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}

    public async exportReportToPdf() {
        const childList = document.getElementById('ReportCustom').children as unknown as [];
        const pdf = new jsPDF(this.mode, 'mm', this.format); // A4 size page of PDF
        const imgWidth = 206;
        const pageHeight = 792 * (imgWidth / 612); // 'letter': [612, 792]
        let position = 0;
        const generatedImages = [];
        for (const child of childList as []) {
            await html2canvas(child).then(canvas => {
                const contentDataURL = canvas.toDataURL('image/png');
                generatedImages.push({data: contentDataURL, height: (canvas.height * imgWidth) / canvas.width});
            });
        }
        let heightLeft = pageHeight;
        generatedImages.forEach(image => {
            if (heightLeft <= image.height) {
                heightLeft = pageHeight;
                pdf.addPage();
            }
            position = pageHeight - heightLeft + 5;
            pdf.addImage(image.data, 'PNG', 2, position, imgWidth, image.height);
            heightLeft -= image.height;
        });

        pdf.save(`${this.reportName}.pdf`); // Generated PDF
    }

    public exportReportToCsv() {
        let result = null;
        const JSONPARSER = json2csv.Parser;
        this.csvData.forEach(csvData => {
            if (csvData.headers && csvData.data) {
                const fields = csvData.headers;
                const data = csvData.data;
                const json2csvParser = new JSONPARSER({ fields });
                const csv = json2csvParser.parse(data);
                if (!result) {
                    result = csvData.name + '\n' + csv;
                } else {
                    result += '\n\n\n' + csvData.name + '\n' + csv;
                }
            }
        });
        if (result) {
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(result);
            hiddenElement.target = '_blank';
            hiddenElement.download = `${this.reportName}.csv`;
            hiddenElement.click();
        }
    }

    protected getStartDate() {
        return this.dateFrom;
    }

    protected getEndDate() {
        return this.dateTo;
    }
}
