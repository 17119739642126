import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { CustomHttpClient } from '../http-client';
import { ReportsActions, FiltersActions, SitesActions } from '@store/actions';
import { ERRORS } from '../../../../config';
import * as moment from 'moment';
import * as _ from 'lodash';
import { HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { ErrorHandlerService } from '../helper';
import { Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ISite } from '@shared/types/site';

@Injectable()
export class ReportsService {
    private currentReportURI: string = '';
    private currentSiteIds: string = '';
    public loadReport$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: CustomHttpClient,
        private reportsActions: ReportsActions,
        private router: Router,
        private sitesActions: SitesActions,
        private filtersActions: FiltersActions,
        private errorHandlerService: ErrorHandlerService
    ) {
    }

    public set loadReport(value) {
        this.loadReport$.next(value);
    }

    public getCurrentSiteIds(): string {
        return this.currentSiteIds;
    }

    public fetchReports() {
        return this.http.getReports();
    }

    public setFilters(filters) {
        this.reportsActions.setFilters(filters);
    }

    public selectReport(report) {
        this.reportsActions.selectReport(report);
    }

    public reloadReport() {
        if (this.currentReportURI) {
            this.filtersActions.setReportUri('');
            this.filtersActions.setReportUri(this.currentReportURI);
        }
    }

    public fetchSites() {
        // @ts-ignore
        return this.http.get('/sites', {params: { $select: 'name,id'} }).pipe(
            map((res: any) => {
                if (res.total && res.items) {
                    return this.prepareSites(res.items);
                } else {
                    return [];
                }
            }),
            tap(sites => {
                if (!sites || sites.length === 0) {
                    this.router.navigate([ '/pages/no-sites' ]);
                } else {
                    this.sitesActions.setSites(sites);
                }
            })
        );
    }

    public prepareSites(items) {
        return items.map(item => ({itemName: item.name, id: item.id}));
    }

    public fetchDepartments(sites) {
        return this.http
            .post('/filters/departments', {
                sites: sites.map(site => site.id)
            }).pipe(
                tap((res: any) => {
                    const results = [];
                    if (res && res.length) {
                        res.forEach(item => {
                            results.push({itemName: item.dept_name, id: item.dept_name});
                        });
                    }
                })
            );
    }

    public fetchItemTypes(sites, departments) {
        return this.http
            .post('/filters/items_types', {
                sites: sites.map(site => site.id),
                departments: departments.map(department => department.id)
            }).pipe(
                tap((res: any) => {
                    const results = [];
                    if (res && res.length) {
                        res.forEach(item => {
                            results.push({itemName: item.type_name, id: item.type_name});
                        });
                    }
                })
            );
    }

    public fetchJobCodes(site) {
        this.deleteError('job_code');
        return this.http
            .post('/job-codes', {site, date: moment()})
            .pipe(
                map((res: any) => {
                    if (res.total && res.items) {
                        const results = [];
                        res.items.forEach(item => {
                            results.push({itemName: item.name, id: item.name});
                        });
                        return results;
                    } else {
                        return [];
                    }
                })
            ).pipe(
                map(jobs => {
                    const jobCodes = _.uniqBy(jobs, 'itemName');
                    if (!jobCodes.length) {
                        this.addError('job_code', ERRORS.NO_JOB_CODES);
                    } else {
                        this.deleteError('job_code');
                    }
                    return jobCodes || [];
                }),
                catchError(err => {
                    this.resetJobCodes();
                    if (err) {
                        this.addError('job_category', this.getDmErrorMessage(err, ERRORS.NO_JOB_CODES));
                    } else {
                        this.addError('job_category', ERRORS.NO_JOB_CODES);
                    }
                    return of([]);
                })
            );
    }

    public fetchTags() {
        this.deleteError('tags');
        return this.http
            .post('/tags', {date: moment()})
            .pipe(
                map((res: any) => {
                    if (!res.total || !res.items) {
                        return [];
                    }
                    return res.items.map(item => ({itemName: item.name, id: item.name}));
                })
            )
            .subscribe(
                tags => {
                    this.filtersActions.setTags(tags);
                    if (!tags.length) {
                        this.addError('tags', ERRORS.NO_TAGS);
                    } else {
                        this.deleteError('tags');
                    }
                },
                err => {
                    this.resetTags();
                    if (err) {
                        this.addError('tags', this.getDmErrorMessage(err, ERRORS.NO_TAGS));
                    } else {
                        this.addError('tags', ERRORS.NO_TAGS);
                    }
                }
            );
    }

    public fetchEmployeeBySiteIds(company: string, sites: string | Array<string>) {
        const headers = new HttpHeaders({
            'x-company-id': company,
            'x-site-ids': sites
        });

        return this.http.get('/employees', {headers}).pipe(
            map((response: any) => {
                if (response.Data && response.Data.length) {
                    const results = [];
                    response.Data.forEach(item => {
                        results.push({itemName: item.Name, id: item.EmployeeId, code: item.EmployeeCorporateCode});
                    });
                    return results;
                } else {
                    return [];
                }
            }),
            catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error)))
        );
    }

    public fetchJobCategories(site) {
        site = site[0];
        this.deleteError('job_category');
        return this.http
            .post('/job-categories', {
                site,
                date: moment()
            })
            .pipe(
                map((res: any) => {
                    if (res.total && res.items) {
                        const results = [];
                        res.items.forEach(item => {
                            results.push({itemName: item.name, id: item.name});
                        });
                        return results;
                    } else {
                        return [];
                    }
                }),
                tap(jobs => {
                    const jobCategories = _.uniqBy(jobs, 'itemName');
                    if (!jobCategories.length) {
                        this.addError('job_category', ERRORS.NO_JOB_CATEGORIES);
                    } else {
                        this.deleteError('job_category');
                    }
                }),
                catchError(err => {
                    this.resetJobCategories();
                    if (err) {
                        this.addError('job_category', this.getDmErrorMessage(err, ERRORS.NO_JOB_CATEGORIES));
                    } else {
                        this.addError('job_category', ERRORS.NO_JOB_CATEGORIES);
                    }
                    return of([]);
                })
            );
    }

    public fetchCalendars(site, calendarType) {
        this.deleteError('calendar');
        return this.http
            .post('/calendars', {
                site,
                date: moment(),
                calendar_type: calendarType
            })
            .pipe(
                map((res: any) => {
                    if (res && res.length > 0) {
                        return this.prepareCalendars(res);
                    } else {
                        return [];
                    }
                })
            );
    }

    public prepareCalendars(items) {
        const results = [];
        items.forEach(item => {
            if (item.calendar_details && item.calendar_details.length) {
                item.calendar_details.forEach(calendar => {
                    results.push({
                        dateStart: moment(calendar.start_business_date_time.split('T')[0]).toDate(),
                        dateEnd: moment(calendar.end_business_date_time.split('T')[0]).toDate()
                    });
                });
            }
        });
        return results;
    }

    public fetchForecastDefinitions(site) {
        this.deleteError('forecast_definition');
        return this.http
            .post('/forecast-definitions', {
                date: moment(),
                site: site
            })
            .pipe(
                map((res: any) => res || []),
                map(items => items.map(item => ({itemName: item.name, id: item.entity_id}))),
                tap(definitions => {
                    this.filtersActions.setForecastDefinitions(definitions);
                    if (!definitions.length) {
                        this.addError('forecast_definition', ERRORS.NO_FORECAST_DEFINITIONS);
                    } else {
                        this.deleteError('forecast_definition');
                    }
                }),
                catchError(err => {
                    if (err && err.message) {
                        this.addError('forecast_definition', err.message);
                    } else {
                        this.addError('forecast_definition', ERRORS.NO_FORECAST_DEFINITIONS);
                    }
                    return of([]);
                })
            );
    }

    public fetchBusinessDate(category: string, siteIds, allSites: ISite[]): Observable<any> {
        this.deleteError('business_date');
        const data: any = {category: category};
        if (siteIds) {
            data.sites = siteIds;
            data.allSites = allSites.map(site => site.id);
            this.currentSiteIds = siteIds;
        }

        return this.http
            .post('/filters/business_date', data)
            .pipe(take(1))
            .pipe(
                map(res => res || ''),
                catchError((error: any) => ErrorObservable.create(this.errorHandlerService.getErrorMessage(error)))
            );
    }

    public fetchBusinessRuleGroup(site) {
        this.deleteError('business_rule_group');
        return this.http
            .post('/filters/business-rule-group', {
                date: moment(),
                site: site
            })
            .pipe(
                map((res: any) => res || []),
                tap(   ruleGroups => {
                    if (typeof ruleGroups.value === 'undefined') {
                        this.addError('business_rule_group', ERRORS.NO_BUSINESS_RULE_GROUPS);
                    } else {
                        this.deleteError('business_rule_group');
                    }
                }),
                catchError(err => {
                    if (err && err.message) {
                        this.addError('business_rule_group', err.message);
                    } else {
                        this.addError('business_rule_group', ERRORS.NO_BUSINESS_RULE_GROUPS);
                    }
                    return of([]);
                })
            );
    }

    public fetchEtlStatisitcs(reportName) {
        this.http
            .post('/etl-statistics', {
                name: reportName
            })
            .pipe(map((res: any) => res || []))
            .subscribe(etlStatistics => {
                this.reportsActions.selectReportEtlStatistics({
                    jobStatus: etlStatistics.jobStatus,
                    jobStarted: etlStatistics.jobStarted,
                    jobFinished: etlStatistics.jobFinished,
                    jobDuration: etlStatistics.jobDuration,
                    needShow: true
                });
            });
    }

    public resetEtlStatistics() {
        this.reportsActions.selectReportEtlStatistics({
            jobStatus: '',
            jobStarted: '',
            jobFinished: '',
            jobDuration: '',
            needShow: false
        });
    }

    public resetBusinessRuleGroup() {
        this.filtersActions.setBusinessRuleGroup([]);
    }

    public resetBusinessDate() {
        return this.filtersActions.setBusinessDate(null);
    }

    public acceptFilters(uri) {
        this.currentReportURI = uri;
        return this.filtersActions.setReportUri(uri);
    }

    public resetDepartments() {
        return this.filtersActions.setDepartments([]);
    }

    public resetItemTypes() {
        return this.filtersActions.setItemTypes([]);
    }

    public resetJobCategories() {
        return this.filtersActions.setJobCategories([]);
    }

    public resetJobCodes() {
        return this.filtersActions.setJobCodes([]);
    }

    public resetTags() {
        return this.filtersActions.setJobCodes([]);
    }

    public resetCalendars() {
        return this.filtersActions.resetCalendars();
    }

    public resetErrors(): void {
        this.reportsActions.resetErrors();
    }

    public addError(attr: string, error: string): void {
        this.reportsActions.addError(attr, error);
    }

    public resetFilters() {
        this.filtersActions.resetFilters();
    }

    public deleteError(attr: string): void {
        this.reportsActions.deleteError(attr);
    }

    public getDmErrorMessage(err, defaultError) {
        let error = {};
        if (err['_body']) {
            error = JSON.parse(err['_body']);
            if (error) {
                if (error['error']) {
                    return error['error'];
                } else {
                    return defaultError;
                }
            } else {
                return defaultError;
            }
        } else {
            return defaultError;
        }
    }

    public prepareSiteIdFromDependencies(selectedSite, sites, singleSiteSelection, type) {
        let sitesToWork = selectedSite;
        if (selectedSite === 'All') {
            sitesToWork = sites;
        }

        if (!sitesToWork) {
            return null;
        }

        if (singleSiteSelection) {
            return sitesToWork ? [sitesToWork.value[0]['id']] : null;
        } else {
            switch (type) {
                case 'string':
                    return sitesToWork.value.map(site => site.id || site).join(',');
                case 'array':
                    return sitesToWork.value.map(site => site.id || site);
            }
        }
        return null;
    }
}
