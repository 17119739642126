import { Injectable } from '@angular/core';
import { ActionsObservable, combineEpics, Epic } from 'redux-observable';

import { IAction } from '@shared/types';
import { IEpicsService } from '@shared/types';
import { SitesActions } from '../../actions';
import { map, switchMap, take } from 'rxjs/operators';
import { NgRedux } from '@angular-redux/store';
import { ISiteState } from '@shared/types/site';
import { IReport } from '@shared/types/report';
import { of } from 'rxjs';

@Injectable()
export class SitesEpics implements IEpicsService {
    constructor(
        private store: NgRedux<ISiteState>
    ) {
    }

    /**
     * Return combined session epics
     * @return { Epic }
     */
    public getEpic(): Epic<any, any> {
        return combineEpics(
            this.getSelectedSites.bind(this),
            this.selectSitesRequest.bind(this),
            this.updateSelectedSitesRequest.bind(this),
            this.addSitesRequest.bind(this)
        );
    }

    private getSelectedSites(action$: ActionsObservable<IAction>) {
        return action$.ofType(SitesActions.SITE_SELECTION_SUCCESS).pipe(
            switchMap((sites) => this.store.select(['reports', 'selectedReport']).pipe(
                take(1),
                map((report: IReport) => {
                    return SitesActions.updateSelectedSites(
                        {sites: sites.payload.payload.items, isMultiple: !!report ? report?.additional_path_parameters[0]?.multiple : true}
                    );
                }))
            ));
    }

    private selectSitesRequest(action$: ActionsObservable<any>): any {
        return action$.ofType(SitesActions.SITE_SELECTION_REQUEST).pipe(
            switchMap((data: IAction) => of(SitesActions.siteSelectionSuccessAction(data.payload)))
        );
    }

    private addSitesRequest(action$: ActionsObservable<any>): any {
        return action$.ofType(SitesActions.ADD_SITES).pipe(
            switchMap((data: IAction) => of(SitesActions.addSitesSuccessAction()))
        );
    }

    private updateSelectedSitesRequest(action$: ActionsObservable<any>): any {
        return action$.ofType(SitesActions.UPDATE_SITE_SELECTION).pipe(
            switchMap((data: IAction) => of(SitesActions.updateSelectedSitesSuccess(data.payload)))
        );
    }
}
