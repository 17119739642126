/**
 * PreferencesActions actions
 */

import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IAppState } from '../../../store/reducers';
import { IPreferences } from '../../../shared/types';
import { ISite } from '../../../shared/types/';
import { get } from 'lodash';
import { generateId } from '../../../shared/utils';
import { IDocumentOperationOptions } from '../../../shared/types/base/base.type';
import { find } from 'rxjs/operators';

@Injectable()
export class PreferencesActions {
    public static PREFERENCES_SITE_LIST_SELECTION_UPDATE = 'PREFERENCES_SITE_LIST_SELECTION_UPDATE';

    public static SHOW_SELECTED_SITES_MODAL = 'SHOW_SELECTED_SITES_MODAL';
    public static HIDE_SELECTED_SITES_MODAL = 'HIDE_SELECTED_SITES_MODAL';

    public static FETCH_PREFERENCES_FOR_COMPANY = 'FETCH_PREFERENCES_FOR_COMPANY';
    public static FETCH_PREFERENCES_FOR_COMPANY_SUCCESS = 'FETCH_PREFERENCES_FOR_COMPANY_SUCCESS';
    public static FETCH_PREFERENCES_FOR_COMPANY_ERROR = 'FETCH_PREFERENCES_FOR_COMPANY_ERROR';

    public static FETCH_PREFERENCES_FOR_SITE = 'FETCH_PREFERENCES_FOR_SITE';
    public static CLEAR_PREFERENCES_FOR_SITE = 'CLEAR_PREFERENCES_FOR_SITE';
    public static FETCH_PREFERENCES_FOR_SITE_SUCCESS = 'FETCH_PREFERENCES_FOR_SITE_SUCCESS';
    public static FETCH_PREFERENCES_FOR_SITE_ERROR = 'FETCH_PREFERENCES_FOR_SITE_ERROR';

    public static UPDATE_PREFERENCES_FOR_COMPANY = 'UPDATE_PREFERENCES_FOR_COMPANY';
    public static UPDATE_PREFERENCES_FOR_COMPANY_SUCCESS = 'UPDATE_PREFERENCES_FOR_COMPANY_SUCCESS';
    public static UPDATE_PREFERENCES_FOR_COMPANY_ERROR = 'UPDATE_PREFERENCES_FOR_COMPANY_ERROR';

    public static UPDATE_PREFERENCES_FOR_SITE = 'UPDATE_PREFERENCES_FOR_SITE';
    public static UPDATE_PREFERENCES_FOR_SITE_SUCCESS = 'UPDATE_PREFERENCES_FOR_SITE_SUCCESS';
    public static UPDATE_PREFERENCES_FOR_SITE_ERROR = 'UPDATE_PREFERENCES_FOR_SITE_ERROR';

    public static ENTITY_TYPE: string = 'preferences';

    public ENTITY_TYPE: string = PreferencesActions.ENTITY_TYPE;

    /**
     * FETCH_PREFERENCES_FOR_COMPANY action creator.
     */
    public static fetchPreferencesForCompanyAction(query = null, options: IDocumentOperationOptions = {}) {
        return {
            type: PreferencesActions.FETCH_PREFERENCES_FOR_COMPANY,
            payload: { query, options }
        };
    }

    /**
     * FETCH_PREFERENCES_FOR_COMPANY_SUCCESS action creator.
     */
    public static fetchPreferencesForCompanySuccessAction(response, options = {}) {
        return {
            type: PreferencesActions.FETCH_PREFERENCES_FOR_COMPANY_SUCCESS,
            payload: {
                response,
                options
            }
        };
    }

    /**
     * FETCH_PREFERENCES_FOR_COMPANY_ERROR action creator.
     */
    public static fetchPreferencesForCompanyErrorAction(errors, options = {}) {
        return {
            type: PreferencesActions.FETCH_PREFERENCES_FOR_COMPANY_ERROR,
            payload: {
                errors,
                options
            }
        };
    }

    /**
     * FETCH_PREFERENCES_FOR_SITE action creator.
     */
    public static fetchPreferencesForSiteAction(siteIds, query = null) {
        return {
            type: PreferencesActions.FETCH_PREFERENCES_FOR_SITE,
            payload: {
                siteIds,
                query
            }
        };
    }

    /**
     * FETCH_PREFERENCES_FOR_SITE_SUCCESS action creator.
     */
    public static fetchPreferencesForSiteSuccessAction(payload) {
        return {
            payload,
            type: PreferencesActions.FETCH_PREFERENCES_FOR_SITE_SUCCESS
        };
    }

    /**
     * CLEAR_PREFERENCES_FOR_SITE action creator.
     */
    public static clearPreferencesForSiteAction() {
        return {
            type: PreferencesActions.CLEAR_PREFERENCES_FOR_SITE
        };
    }

    /**
     * FETCH_PREFERENCES_FOR_SITE_ERROR action creator.
     */
    public static fetchPreferencesForSiteErrorAction(errors) {
        return {
            type: PreferencesActions.FETCH_PREFERENCES_FOR_SITE_ERROR,
            payload: {
                errors
            }
        };
    }

    /**
     * UPDATE_PREFERENCES_FOR_COMPANY action creator.
     */
    public static updatePreferencesForCompanyAction(
        preferences: IPreferences,
        options: IDocumentOperationOptions = {}
    ) {
        return {
            type: PreferencesActions.UPDATE_PREFERENCES_FOR_COMPANY,
            payload: {
                preferences,
                options
            }
        };
    }

    /**
     * UPDATE_PREFERENCES_FOR_COMPANY_SUCCESS action creator.
     */
    public static updatePreferencesForCompanySuccessAction(response, options = {}) {
        return {
            type: PreferencesActions.UPDATE_PREFERENCES_FOR_COMPANY_SUCCESS,
            payload: {
                response,
                options
            }
        };
    }

    /**
     * UPDATE_PREFERENCES_FOR_COMPANY_ERROR action creator.
     */
    public static updatePreferencesForCompanyErrorAction(errors, options = {}) {
        return {
            type: PreferencesActions.UPDATE_PREFERENCES_FOR_COMPANY_ERROR,
            payload: {
                errors,
                options
            }
        };
    }

    /**
     * UPDATE_PREFERENCES_FOR_SITE action creator.
     */
    public static updatePreferencesForSiteAction(
        preferences: IPreferences,
        siteId: string | string[],
        options: IDocumentOperationOptions = {}
    ) {
        return {
            type: PreferencesActions.UPDATE_PREFERENCES_FOR_SITE,
            payload: {
                preferences,
                siteId,
                options
            }
        };
    }

    /**
     * UPDATE_PREFERENCES_FOR_SITE_SUCCESS action creator.
     */
    public static updatePreferencesForSiteSuccessAction(response, options: IDocumentOperationOptions = {}) {
        return {
            type: PreferencesActions.UPDATE_PREFERENCES_FOR_SITE_SUCCESS,
            payload: {
                response,
                options
            }
        };
    }

    /**
     * UPDATE_PREFERENCES_FOR_SITE_ERROR action creator.
     */
    public static updatePreferencesForSiteErrorAction(errors, options: IDocumentOperationOptions = {}) {
        return {
            type: PreferencesActions.UPDATE_PREFERENCES_FOR_SITE_ERROR,
            payload: {
                errors,
                options
            }
        };
    }
    /**
     * SITE_LIST_SELECTION_UPDATE action creator.
     */
    public static preferencesSiteListSelectionAction(selectedSites) {
        return {
            type: PreferencesActions.PREFERENCES_SITE_LIST_SELECTION_UPDATE,
            payload: {
                selectedSites
            }
        };
    }

    public static showSelectedSitesModalAction() {
        return {
            type: PreferencesActions.SHOW_SELECTED_SITES_MODAL
        };
    }

    public static hideSelectedSitesModalAction() {
        return {
            type: PreferencesActions.HIDE_SELECTED_SITES_MODAL
        };
    }

    constructor(private ngRedux: NgRedux<IAppState>) {}

    resultAsPromised(requestId): Promise<any> {
        const selectionPath = [PreferencesActions.ENTITY_TYPE];
        return this.ngRedux
            .select(selectionPath.concat('operationResult'))
            .pipe(find((result: any) => get(result, 'options.requestId') === requestId))
            .toPromise()
            .then((operationResult: any): any => {
                if (!operationResult.response) {
                    return new Error('operationResult.response is not valid');
                }
                return operationResult.response;
            });
    }

    public fetchPreferencesForCompany(query = null, options: IDocumentOperationOptions = {}): Promise<any> {
        const reqOptions = Object.assign({ requestId: generateId() }, options);
        this.ngRedux.dispatch(PreferencesActions.fetchPreferencesForCompanyAction(query, reqOptions));
        return this.resultAsPromised(reqOptions.requestId);
    }

    public fetchPreferencesForSite(siteId: string[], query = null) {
        this.ngRedux.dispatch(PreferencesActions.fetchPreferencesForSiteAction(siteId, query));
    }

    public clearPreferencesForSite() {
        this.ngRedux.dispatch(PreferencesActions.clearPreferencesForSiteAction());
    }

    public updatePreferencesForCompany(
        preferences: IPreferences,
        options: IDocumentOperationOptions = {}
    ): Promise<any> {
        const reqOptions = Object.assign({ requestId: generateId() }, options);
        this.ngRedux.dispatch(PreferencesActions.updatePreferencesForCompanyAction(preferences, reqOptions));

        return this.resultAsPromised(reqOptions.requestId);
    }

    public updatePreferencesForSite(
        preferences: IPreferences,
        id: string | string[],
        options: IDocumentOperationOptions = {}
    ): Promise<any> {
        const reqOptions = Object.assign({ requestId: generateId() }, options);
        this.ngRedux.dispatch(PreferencesActions.updatePreferencesForSiteAction(preferences, id, reqOptions));

        return this.resultAsPromised(reqOptions.requestId);
    }

    public preferencesSiteListSelection(selectedSites: ISite[]) {
        this.ngRedux.dispatch(PreferencesActions.preferencesSiteListSelectionAction(selectedSites));
    }

    public showSelectedSitesModal() {
        this.ngRedux.dispatch(PreferencesActions.showSelectedSitesModalAction());
    }

    public hideSelectedSitesModal() {
        this.ngRedux.dispatch(PreferencesActions.hideSelectedSitesModalAction());
    }
}
