/**
 * Defines a component for rendering Vega and Ag-grid custom-reports
 */
import { Component, Input } from '@angular/core';

@Component({
    selector: 'opa-custom-report',
    templateUrl: 'custom-report.component.html'
})
export class CustomReportComponent {
    public content: string;

    @Input()
    public set data(data) {
        this.content = data;
    }
}
