import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export * from '@ag-grid-enterprise/core';
import { ModuleRegistry, AllCommunityModules } from '@ag-grid-enterprise/all-modules';
import { LicenseManager } from '@ag-grid-enterprise/core';
LicenseManager.setLicenseKey(
    'CompanyName=Xenial, Inc,LicensedGroup=Denys-Artem,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=2,AssetReference=AG-008802,ExpiryDate=30_July_2021_[v2]_MTYyNzU5OTYwMDAwMA==5a81e1d89e26e8c186e81110b4cb7e0b'
);

if (environment.production) {
    enableProdMode();
}

ModuleRegistry.registerModules(AllCommunityModules);
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));

