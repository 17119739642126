import { Routes } from '@angular/router';
import { AuthGuard } from './shared/helpers/auth-guard';

import { ROOT_ROUTE, REPORTS_ROOT_ROUTE, LOGIN_ROUTE, PAGES_ROOT_ROUTE, ERROR_PAGE_ROUTE, IFRAME_MODE } from './routes';
import { ExtraLayoutContainerComponent } from './containers/layouts/extra/extra.container';
import { LoginComponent } from './components/app/login';
import { DmErrorPageComponent } from './components/app/error-page';
import { DefaultLayoutComponent } from './containers/layouts/default/default.component';
import { NoContentComponent } from './components/app/no-content';
import { IframeModeComponent } from './components/app/iframe-mode';

export * from './routes';

export const ROUTES: Routes = [
    {
        path: ROOT_ROUTE,
        redirectTo: `/${LOGIN_ROUTE}`,
        pathMatch: 'full'
    },
    {
        path: LOGIN_ROUTE,
        component: ExtraLayoutContainerComponent,
        children: [{ path: '', component: LoginComponent }]
    },
    {
        path: IFRAME_MODE,
        component: IframeModeComponent
    },
    {
        path: ERROR_PAGE_ROUTE,
        component: DefaultLayoutComponent,
        children: [{ path: '', component: DmErrorPageComponent }]
    },
    {
        path: REPORTS_ROOT_ROUTE,
        canActivate: [AuthGuard],
        component: DefaultLayoutComponent,
        loadChildren: () => import('./containers/reports/reports.module').then(m => m.ReportsModule),
    },
    {
        path: PAGES_ROOT_ROUTE,
        loadChildren: () => import('./containers/extra/extra.module').then(m => m.ExtraModule)
    },
    { path: '**', component: NoContentComponent }
];
