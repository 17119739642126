import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { iif, Observable, of, Subject } from 'rxjs';
import { ReportsService } from '@shared/services';
import { NgRedux } from '@angular-redux/store';
import { ISiteState } from '@shared/types/site';
import { SELECT_ALL } from '../../../../../config';
import { take, takeUntil, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { FiltersActions } from '@store/actions';
import { prepareSitesForRequest } from '@components/report-filters/utils/filter.utils';

@Component({
    selector: 'job-categories-filter',
    templateUrl: 'job-categories-filter.html',
    styleUrls: ['style.css']
})
export class JobCategoriesFilterComponent implements OnInit, OnDestroy {
    @Input() filter: any;
    @Input() type: string = '';
    @Output() handleChanges = new EventEmitter();
    public jobs: any[] = [];
    public errors: any[] = [];
    protected label: string = '';
    protected dropdownSettings = {
        singleSelection: false,
        text: 'Select Jobs',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        classes: 'myclass custom-class'
    };
    public inputJobValue: string[] | string = [];
    public isLoaded = false;

    private attributes: string[] = [];
    private name: string = '';

    private filtersState: Observable<any>;

    private sitesState$: Observable<ISiteState>;
    private isSiteChanged = false;
    private unsubscribe$: Subject<void> = new Subject();
    private multipleSites = false;

    constructor(private reportService: ReportsService,
                store: NgRedux<ISiteState>,
                private cdr: ChangeDetectorRef,
                private filtersActions: FiltersActions) {
        this.filtersState = store.select('filters');
        this.sitesState$ = store.select('sites');
    }

    ngOnInit() {
        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];
        this.label = this.filter['label'];
        this.multipleSites = this.filter['multipleSites'];

        const keys = Object.keys(this.filter['value']);
        this.inputJobValue = JSON.parse(JSON.stringify(this.filter['value'][keys[0]]));

        this.sitesState$.pipe(
            takeUntil(this.unsubscribe$),
            distinctUntilChanged((prev, next) => {
                if (this.multipleSites) {
                    return JSON.stringify(prev.selectedSites) === JSON.stringify(next.selectedSites);
                } else {
                    return JSON.stringify(prev.selectedSite) === JSON.stringify(next.selectedSite);
                }
            }),
            tap(siteState => {
                if (this.multipleSites) {
                    this.isSiteChanged = JSON.stringify(siteState.selectedSites) !== JSON.stringify(siteState.prevSelectedSites);
                } else {
                    this.isSiteChanged = JSON.stringify(siteState.selectedSite) !== JSON.stringify(siteState.prevSelectedSite);
                }
            }),
            switchMap((siteState) => this.filtersState.pipe(
                take(1),
                switchMap(({job_categories}) => iif(
                    () => !!job_categories && !this.isSiteChanged,
                    of(job_categories),
                    this.reportService.fetchJobCategories(
                        prepareSitesForRequest(this.multipleSites ? siteState.selectedSites : [siteState.selectedSite])
                    )
                ))
            ))
        ).subscribe((job_categories) => {
            this.jobs = job_categories || [];
            this.inputJobValue = [...this.jobs];
            this.handleChange();
            this.isLoaded = true;
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public handleChange() {
        const value = {};
        const errors = [];

        if (this.inputJobValue.length === this.jobs.length) {
            value[this.attributes[0]] = SELECT_ALL;
        } else {
            value[this.attributes[0]] = this.inputJobValue;
        }

        if (!this.inputJobValue.length) {
            errors.push('Choose job categories please');
        }
        this.errors = errors;
        const res = { value, errors, name: this.name, type: this.filter['type'] };
        this.filtersActions.setJobCategories(this.inputJobValue);
        this.handleChanges.emit(res);
    }
}
