import { Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { select } from '@angular-redux/store';
import { environment } from '../../../../environments/environment';
import { WINDOW } from '@services/window';
import { filter } from 'rxjs/operators';
// import { HttpCancelService } from './http-cancel.service';

export interface IRequestOptions {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: any;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
}

@Injectable()
export class CustomHttpClient {
    private token;
    private companyId;
    private api = `${environment.backendURI}/api`;

    @select(['userSession', 'token']) private token$: Observable<string>;
    @select(({ userSession }) => userSession.currentCompanyId)
    private currentCompanyId$: Observable<string>;

    constructor(public http: HttpClient,
                @Inject(WINDOW) private window: Window) {
        this.token$.subscribe(token => (this.token = token));
        this.currentCompanyId$.pipe(filter(id => !!id)).subscribe(id => {
            this.companyId = id;
        });
    }

    /**
     * GET request
     * @param {string} endPoint it doesn't need / in front of the end point
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @param {string} api use if there is needed to send request to different back-end than the default one.
     * @returns {Observable<T>}
     */
    public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
        return this.http.get<T>(this.api + endPoint, options);
    }

    /**
     * POST request
     * @param {string} endPoint end point of the api
     * @param {Object} params body of the request.
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public post<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
        return this.http.post<T>(this.api + endPoint, params, options);
    }

    /**
     * PUT request
     * @param {string} endPoint end point of the api
     * @param {Object} params body of the request.
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public put<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
        return this.http.put<T>(this.api + endPoint, params, options);
    }

    /**
     * DELETE request
     * @param {string} endPoint end point of the api
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
        return this.http.delete<T>(this.api + endPoint, options);
    }

    public getAccessToken() {
        return this.token;
    }

    public getCompany() {
        return this.companyId;
    }

    public getReports() {
        const headers = new HttpHeaders({
            'x-company-id': this.companyId,
            'x-site-ids': 'All'
        });

        return this.get('/reports', { headers });
    }

    public getHostname(): string {
        return this.window.location.hostname;
    }
}

export function opraHttpFactory(http: HttpClient, window: Window) {
    return new CustomHttpClient(http, window);
}
