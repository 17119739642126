<xux-widget-boilerplate [field]="field">
  <!-- see dropdown documentation at https://valor-software.com/ngx-bootstrap/#/dropdowns -->
  <div [isDisabled]="disabled" class="btn-group dropdown xux-dropdown" dropdown #dropdown="bs-dropdown">
    <button #defaultFocusElement class="btn dropdown-toggle" type="button" #selectBtn dropdownToggle [tabindex]="(field.disabledObs | async) ? -1 : 0">
      {{selectedItemTextObs | async}}<span class="glyphicon glyphicon-menu-down"></span>
    </button>
    <ul class="dropdown-menu" *dropdownMenu>
      <li *ngFor="let option of selectOptionsObs | async" class="dropdown-menu-item" [class.selected]="option.selected">
        <a (click)="setValue(option.value)">{{option.text}}</a>
      </li>
    </ul>
  </div>
</xux-widget-boilerplate>
