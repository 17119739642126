import { ISiteState } from '../../../shared/types/site';
import { IAction } from '../index';
import { get } from 'lodash';
import { SitesActions } from '../../actions/sites';

export const ADD_SITES = 'ADD_SITES';

const initialState: ISiteState = {
    sites: [],
    prevSelectedSites: [],
    selectedSites: [],
    prevSelectedSite: null,
    selectedSite: null,
    enablePicker: false,
    isSelectionUpdated: false
};

export function sitesReducer(state: ISiteState = initialState, action: IAction) {
    switch (action.type) {
        case ADD_SITES:
            return Object.assign({}, state, {
                sites: action.payload,
                selectedSites: action.payload,
                selectedSite: action.payload[0],
                prevSelectedSite: state.prevSelectedSite,
                prevSelectedSites: state.prevSelectedSites,
                isSelectionUpdated: false
            });
        case SitesActions.ADD_SITES_SUCCESS:
            return Object.assign({}, state, {
                isSelectionUpdated: true
            });

        case SitesActions.GET_RECENT_SITES_LIST_SUCCESS:
            return Object.assign({}, state, {
                enablePicker: true
            });

        case SitesActions.SITE_SELECTION_REQUEST:
            return Object.assign({}, state, {
                isSelectionUpdated: false
            });

        case SitesActions.REMEMBER_SELECTED_SITES:
            return Object.assign({}, state, {
                prevSelectedSite: action.payload.selectedSite,
                prevSelectedSites: action.payload.selectedSites,
            });

        case SitesActions.SITE_SELECTION_SUCCESS:
            return Object.assign({}, state, {
                iframePostMessageResult: {
                    response: get(action, 'payload', {}),
                    options: {
                        messageId: get(action, 'payload.messageId', null),
                    },
                    errors: [],
                },
                enablePicker: true,
                isSelectionUpdated: true
            });

        case SitesActions.UPDATE_SITE_SELECTION:
            return Object.assign({}, state, {
                [action.payload.isMultiple ? 'selectedSites' : 'selectedSite']:
                    action.payload.isMultiple ? action.payload.sites :  action.payload.sites[0],
                isSelectionUpdated: false,
                prevSelectedSite: state.selectedSite,
                prevSelectedSites: state.selectedSites
            });

        case SitesActions.UPDATE_SITE_SELECTION_SUCCESS:
            return Object.assign({}, state, {
                isSelectionUpdated: true
            });

        case SitesActions.SITE_SELECTION_ERROR:
            return Object.assign({}, state, {
                iframePostMessageResult: {
                    response: get(action, 'payload', {}),
                    options: {
                        messageId: get(action, 'payload.messageId', null),
                    },
                    errors: [ {
                        code: get(action, 'payload.payload.code', ''),
                        message: get(action, 'payload.payload.message', ''),
                    } ]
                },
                enablePicker: true
            });

        case SitesActions.SITE_SELECTION_UNSELECT:
            return Object.assign({}, state, {
                iframePostMessageResult: {},
                selectedSites: [],
                prevSelectedSite: state.selectedSite,
                prevSelectedSites: state.selectedSites
            });

        default :
            return state;
    }
}
