import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { IEtlStatisticsState, IReport, IReportState } from '../../shared/types/report';
import { ReportsService } from '../../shared/services/reports';
import { NgRedux } from '@angular-redux/store';
import { takeWhile } from 'rxjs/operators';

import { Observable } from 'rxjs';

@Component({
    selector: 'etl-statistics',
    templateUrl: 'etl-statistics.component.html',
    styleUrls: ['style.css']
})
export class EtlStatisticsComponent implements OnInit, OnDestroy {
    @Input() report: IReport;
    public jobStatus: IEtlStatisticsState;
    private reportsState: Observable<any>;
    private isActive: boolean = true;

    constructor(private reportsService: ReportsService, private store: NgRedux<IReportState>) {
        this.reportsState = this.store.select('etl-statistics');
    }

    public ngOnInit(): void {
        if (this.report['etl_job_info'] && this.report['etl_job_info']['name']) {
            this.reportsService.fetchEtlStatisitcs(this.report['etl_job_info']['name']);
        }
        this.reportsState.pipe(takeWhile(() => this.isActive)).subscribe(etlState => {
            this.jobStatus = etlState;
        });
    }

    public ngOnDestroy(): void {
        this.isActive = false;
        this.reportsService.resetEtlStatistics();
    }
}
