<div *ngIf="isVisible" [class.content]="contentLoader" id='loader' class="loader-wrapper">
  <div class="closer" *ngIf="isVisibleCancelButton" (click)="close()">Cancel</div>
    <div id="loader-inner">
        <svg id="Layer_1" viewBox="0 0 444 283.6">
            <g>
                <path stroke-linecap="round" stroke-width="30" stroke="#DDDDDD" class="st0 gray-anim" d="M342.7,95.1C338.1,52.9,302.4,20,258.9,20c-43.8,0-79.8,33.5-83.9,76.3c-4.6-0.8-9.4-1.2-14.2-1.2 c-36.9,0-68.3,23.8-79.7,56.8c-1.7-0.2-3.4-0.2-5.2-0.2c-30.9,0-56,25.1-56,56c0,29.2,22.3,53.1,50.8,55.7c0,0,266.2,0.2,268.9,0.2 c46.5,0,84.3-37.7,84.3-84.3C424,133.7,387.9,96.7,342.7,95.1z"></path>
                <path stroke-linecap="round" stroke-width="30" stroke="#9cc568" class="st0 green-anim" d="M342.7,95.1C338.1,52.9,302.4,20,258.9,20c-43.8,0-79.8,33.5-83.9,76.3c-4.6-0.8-9.4-1.2-14.2-1.2 c-36.9,0-68.3,23.8-79.7,56.8c-1.7-0.2-3.4-0.2-5.2-0.2c-30.9,0-56,25.1-56,56c0,29.2,22.3,53.1,50.8,55.7c0,0,266.2,0.2,268.9,0.2 c46.5,0,84.3-37.7,84.3-84.3C424,133.7,387.9,96.7,342.7,95.1z"></path>
                <path stroke-linecap="round" stroke-width="35" stroke="#004c82" class="st0 blue-anim" d="M342.7,95.1C338.1,52.9,302.4,20,258.9,20c-43.8,0-79.8,33.5-83.9,76.3c-4.6-0.8-9.4-1.2-14.2-1.2 c-36.9,0-68.3,23.8-79.7,56.8c-1.7-0.2-3.4-0.2-5.2-0.2c-30.9,0-56,25.1-56,56c0,29.2,22.3,53.1,50.8,55.7c0,0,266.2,0.2,268.9,0.2 c46.5,0,84.3-37.7,84.3-84.3C424,133.7,387.9,96.7,342.7,95.1z"></path>
            </g>
        </svg>
    </div><!--  #loader-inner  -->
</div><!--  #loader  .loader-wrapper -->
