import { Routes, RouterModule } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// containers
import { DashboardReportContainerComponent } from './dashboard_vega/dashboard.container';

//  import entity config
import { vegaRoutes } from './vega.routes';
import { FormsModule } from '@angular/forms';
import { DmComponentsModule } from '../../../components/dm.components.module';
import { DashboardFiltersComponent } from './dashboard_vega/dashboard-filters/dashboard-filters.component';
import { DMSharedComponentsModule } from '@hc/xenial-ui-shared/modules/xdm-shared-components/index';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

// routes
const routes: Routes = vegaRoutes;

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(routes),
        DmComponentsModule,
        DMSharedComponentsModule,
        AngularMultiSelectModule
    ],
    declarations: [DashboardReportContainerComponent, DashboardFiltersComponent],
    exports: [RouterModule, CommonModule, FormsModule, DmComponentsModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VegaModule {}
