import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { iif, Observable, of, Subject } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { ReportsService } from '@shared/services';
import { ISite, ISiteState } from '../../../../shared/types/site';
import { SELECT_ALL } from '../../../../../config';
import { distinctUntilChanged, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FiltersActions } from '@store/actions';
import { prepareSitesForRequest } from '@components/report-filters/utils/filter.utils';

@Component({
    selector: 'items-filter',
    templateUrl: 'items-filter.html',
    styleUrls: ['style.css']
})
export class SitesItemsFilterComponent implements OnInit, OnDestroy {
    @Input() filter: any;
    @Output() handleChanges = new EventEmitter();
    public inputDepartmentsValue: string[] = [];
    public inputTypesValue: string[] = [];
    public departments: any[] = [];
    public errors: { site?: string; departments?: string; types?: string } = {};
    public itemTypes: any[] = [];
    public dropdownSettings = {
        singleSelection: false,
        text: 'Select...',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        classes: 'myclass custom-class'
    };
    protected sitesState: Observable<any>;
    protected label: string = '';

    private attributes: string[] = [];
    private name: string = '';
    private inputSiteValue: ISite[] = [];
    private filtersState: Observable<any>;

    private sitesState$: Observable<ISiteState>;
    private isSiteChanged = false;
    private unsubscribe$: Subject<void> = new Subject();
    private multipleSites = false;
    private sites: Array<ISite>;

    constructor(
        store: NgRedux<ISiteState>,
        private reportService: ReportsService,
        private filtersActions: FiltersActions
    ) {
        this.sitesState = store.select('sites');
        this.sitesState$ = store.select('sites');
        this.filtersState = store.select('filters');
    }

    ngOnInit() {
        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];
        this.label = this.filter['label'];
        this.multipleSites = this.filter['multipleSites'];

        this.sitesState$.pipe(
            takeUntil(this.unsubscribe$),
            distinctUntilChanged((prev, next) => {
                if (this.multipleSites) {
                    return JSON.stringify(prev.selectedSites) === JSON.stringify(next.selectedSites);
                } else {
                    return JSON.stringify(prev.selectedSite) === JSON.stringify(next.selectedSite);
                }
            }),
            tap(siteState => {
                this.sites = siteState.sites;
                if (this.multipleSites) {
                    this.isSiteChanged = JSON.stringify(siteState.selectedSites) !== JSON.stringify(siteState.prevSelectedSites);
                    this.inputSiteValue = siteState.selectedSites;
                } else {
                    this.isSiteChanged = JSON.stringify(siteState.selectedSite) !== JSON.stringify(siteState.prevSelectedSite);
                    this.inputSiteValue = [siteState.selectedSite];
                }
            }),
            switchMap(() => this.filtersState.pipe(
                take(1),
                switchMap(({departments, item_types }) => iif(
                    () => !!departments && !this.isSiteChanged,
                    of([departments, item_types]),
                    this.reportService.fetchDepartments(prepareSitesForRequest(this.inputSiteValue)).pipe(
                        tap(departments => {
                            this.departments = [...departments];
                            this.inputDepartmentsValue = [...this.departments];
                        }),
                        switchMap(() => this.getTypes())
                    )
                ))
            ))
        ).subscribe(() => {
            this.handleChange();
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public getTypes(): Observable<any> {
        return this.reportService.fetchItemTypes(this.inputSiteValue, this.inputDepartmentsValue).pipe(
            takeUntil(this.unsubscribe$),
            tap(item_types => {
                this.itemTypes = [...item_types];
                this.inputTypesValue = [...this.itemTypes];
            })
        );
    }

    public onDepartmentChange(event = '') {
        if (!this.inputDepartmentsValue.length) {
            const error = 'Choose departments please';
            this.errors.departments = error;
            this.emitError(error);
        } else {
            this.getTypes().subscribe(() => this.handleChange());
            delete this.errors.departments;
        }
    }

    public onTypeChange(event = '') {
        if (!this.inputTypesValue.length) {
            const error = 'Choose types please';
            this.errors.types = error;
            this.emitError(error);
        } else {
            this.handleChange();
            delete this.errors.types;
        }
    }

    private handleChange() {
        const value = {};
        value[this.attributes[0]] = this.inputSiteValue.length === this.sites.length ? SELECT_ALL : this.inputSiteValue;
        value[this.attributes[1]] =
            this.inputDepartmentsValue.length === this.departments.length ? SELECT_ALL : this.inputDepartmentsValue;
        value[this.attributes[2]] =
            this.inputTypesValue.length === this.itemTypes.length ? SELECT_ALL : this.inputTypesValue;
        this.filtersActions.setDepartments(this.inputDepartmentsValue);
        this.filtersActions.setItemTypes(this.inputTypesValue);

        this.emitChanges(value);
    }

    private emitError(error: string) {
        this.handleChanges.emit({
            name: this.name,
            type: this.filter['type'],
            value: [],
            errors: [error]
        });
    }

    private emitChanges(value: any[] | object) {
        this.handleChanges.emit({ value, name: this.name, type: this.filter['type'] });
    }
}
