import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
// import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { select } from '@angular-redux/store';
import { Router } from '@angular/router';
import { ERRORS } from '../../../../config';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private token;

    @select(['userSession', 'token']) private token$: Observable<string>;
    constructor(private router: Router) {
        this.token$.subscribe(token => {
            this.token = token;
        });
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<
        HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any
    > {
        return this.handleRequest(req, this.token, next);
    }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        if (req.url.indexOf('token') !== -1) {
            const modifiedReq = req.clone({
                responseType: 'text',
                headers: req.headers.append('Authorization', `Bearer ${token}`)
            });

            return modifiedReq;
        } else {
            const modifiedReq = req.clone({
                headers: req.headers.append('Authorization', `Bearer ${token}`)
            });

            return modifiedReq;
        }
    }

    handleRequest(req: HttpRequest<any>, token: string, next: HttpHandler) {
        return next.handle(this.addToken(req, token)).pipe(catchError(error => this.errorHandler(error)));
    }

    errorHandler(res) {
        if (res.status === 401 || res.status === 403) {
            if (res && res['_body'] && res['_body']['error'] && res['_body']['error'] === ERRORS.EXPIRED_TOKEN) {
                this.router.navigate(['/login']);
            }
        }
        return ErrorObservable.create(res);
    }
}
