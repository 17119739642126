/**
 * Login component
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { IAppState } from '../../../store/reducers';
import { Router } from '@angular/router';
import { SessionActions } from '../../../store/actions';
import { IUserSession } from '../../../store/reducers/session/session.reducer';
import { ILoginClient } from '../../../shared/types/login-client.model';
import { environment } from '../../../../environments/environment';
import { filter } from 'rxjs/operators';
@Component({
    selector: 'login',
    styleUrls: ['./login.scss'],
    templateUrl: './login.template.html'
})
export class LoginComponent implements OnInit, OnDestroy {
    public userSession: Observable<IUserSession> = this.ngRedux.select('userSession');
    public client: ILoginClient = {
        email: '',
        password: ''
    };
    public errorMessage: string = '';
    public disabledButton: boolean = false;
    public isProduction = environment.production;
    @select(['userSession']) private session$: Observable<IUserSession>;
    private sessionSubscription: Subscription;
    /**
     * Initialize the component
     * Components need initialization in the constructor.
     */
    constructor(private sessionActions: SessionActions, private ngRedux: NgRedux<IAppState>, private router: Router) {}

    /**
     * This function is called right after the directive's
     * data-bound properties have been checked for the first time,
     * and before any of its children have been checked.
     * It is invoked only once when the directive is instantiated.
     * In this function we subscribe to user session state changes.
     * And once user session is changed we check if it contains errors
     * and show them
     */
    public ngOnInit() {
        this.sessionActions.checkUserAuth();
        this.sessionSubscription = this.session$
            .pipe(
                filter(({ user, errors }) => {
                    const errorMessage = errors && errors[0] && (errors[0].message || errors[0].title);
                    if (errorMessage) {
                        this.disabledButton = false;
                        this.errorMessage = errorMessage;
                    }
                    return user && !!user.email;
                })
            )
            .subscribe(
                (data) => {
                    if (data.isAuthenticated) {
                       this.router.navigate(['/custom-reports', 'home']);
                    }
                },
                error => {
                    console.log(error);
                }
            );
    }

    public ngOnDestroy() {
        this.sessionSubscription.unsubscribe();
    }

    /**
     * Perform a login operation
     */
    public onSubmit() {
        this.disabledButton = true;
        this.errorMessage = '';
        const credentials = {
            user: this.client.email,
            password: this.client.password
        };
        this.sessionActions.loginUser(credentials);
    }
}
