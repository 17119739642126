<div class="report_select_filter">
  <span>{{label}}</span>
  <select (change)="handleRangeChange()" [(ngModel)]="inputValue" class="report_date_range_filter">
    <option *ngFor="let item of items" [value]="item.value">{{item.name}}</option>
  </select>
</div>
<div class="report_input_filter" *ngIf="(inputValue==='Between'||inputValue==='Above')">
  <span>Above</span>
  <input type="number" [(ngModel)]="above" (change)="handleAboveBelowChange()">
</div>
<div class="report_input_filter" *ngIf="(inputValue==='Between'||inputValue==='Below')">
  <span>Below</span>
  <input type="number" [(ngModel)]="below" (change)="handleAboveBelowChange()">
</div>
