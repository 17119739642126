<div class="filter_block_main">
  <div *ngFor="let filter of filters">
    <text-input-filter *ngIf="showFilterByType(filter, 'string') && isSitePickerReady$ | async" [filter]="filter"
                       (handleChanges)="handleFilterChange($event)"></text-input-filter>
    <select-input-filter *ngIf="showFilterByType(filter, 'select') && isSitePickerReady$ | async" [filter]="filter"
                         (handleChanges)="handleFilterChange($event)"></select-input-filter>
    <checkbox-input-filter *ngIf="showFilterByType(filter, 'checkbox') && isSitePickerReady$ | async" [filter]="filter"
                           (handleChanges)="handleFilterChange($event)"></checkbox-input-filter>
    <calendar-filter *ngIf="showFilterByType(filter, 'calendars') && isSitePickerReady$ | async" [filter]="filter" [selectedReport]="selectedReport"
                     (handleChanges)="handleFilterChange($event)"></calendar-filter>
    <job-categories-filter *ngIf="showFilterByType(filter, 'job-categories') && isSitePickerReady$ | async" [filter]="filter"
                           (handleChanges)="handleFilterChange($event)"></job-categories-filter>
    <job-codes-filter *ngIf="showFilterByType(filter, 'job-codes') && isSitePickerReady$ | async" [filter]="filter"
                      (handleChanges)="handleFilterChange($event)"></job-codes-filter>
    <business-rule-group-filter *ngIf="showFilterByType(filter, 'business-rule-group') && isSitePickerReady$ | async" [filter]="filter"
                                (handleChanges)="handleFilterChange($event)"></business-rule-group-filter>
    <site-picker-filter *ngIf="showFilterByType(filter, 'sites')" [filter]="filter" [selectedReport]="selectedReport"
                        (handleChanges)="handleFilterChange($event)"></site-picker-filter>
    <forecast-definition-filter *ngIf="showFilterByType(filter, 'forecast-definition') && isSitePickerReady$ | async" [filter]="filter"
                                (handleChanges)="handleFilterChange($event)"></forecast-definition-filter>
    <items-filter *ngIf="showFilterByType(filter, 'items') && isSitePickerReady$ | async" [filter]="filter"
                  (handleChanges)="handleFilterChange($event)"></items-filter>
    <above-between-below-filter *ngIf="showFilterByType(filter, 'above-between-below') && isSitePickerReady$ | async" [filter]="filter"
                                (handleChanges)="handleFilterChange($event)"></above-between-below-filter>
    <tags-filter *ngIf="showFilterByType(filter, 'tags') && isSitePickerReady$ | async" [filter]="filter"
                 (handleChanges)="handleFilterChange($event)"></tags-filter>
      <combined-select-filter *ngIf="showFilterByType(filter, 'combined-select') && isSitePickerReady$ | async" [filter]="filter"
                              (handleChanges)="handleFilterChange($event)"></combined-select-filter>
      <employees-filter *ngIf="showFilterByType(filter, 'employees') && isSitePickerReady$ | async" [filter]="filter"
                             (handleChanges)="handleFilterChange($event)"></employees-filter>
  </div>
  <div class="row report_filter_button" *ngIf="(reportFilters$ | async )?.length">
    <div class="col-sm-6">
      <button class="btn btn-default" *ngIf="!(isLoading$ | async)" [disabled]="!isValidFilters()" (click)="acceptFilters()">Submit</button>
    </div>
  </div>
</div>
