import { IPreferences } from '../app/shared/types';

export const COMPANY_PREFERENCES_DEFAULTS: IPreferences = {
  preferences: {
    common: {
      money_precision: 2,
      rounding_strategy: 'roundup',
      currency: {
        code: 'USD',
        sign: '$',
      },
    },
  },
};
