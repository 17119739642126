/**
 * Notifications container
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { UiActions } from '../../../store/actions/index';
import { select } from '@angular-redux/store';
import { DEFAULT_TOAST_TIMEOUT } from '../../../../config/global-constants';

const nonActiveEntities = [];

@Component({
  selector: 'dm-notifications',
  templateUrl: 'notifications.container.html',
})

export class NotificationsContainerComponent implements OnInit, OnDestroy {
  public errorConfig: ToasterConfig = new ToasterConfig(({ timeout: DEFAULT_TOAST_TIMEOUT }));
  @select('notifications') store;
  constructor(public toastService: ToasterService,
              public uiActions: UiActions) {}
  ngOnInit() {
    this.store.subscribe((allNotifications) => {
      const { notifications, latestNotification } = allNotifications;
      if (latestNotification && this.isNotificationAllowed(latestNotification.entityType)) {
        const newNotification = notifications
          .find(notification => notification.id === latestNotification.id);
        const toast: Toast = Object.assign({}, latestNotification);
        this.toastService.pop(toast);

        if (newNotification) {
          newNotification.toastId = toast.toastId;
        }
        // Don't block the screen on failure. Refreshes within portal frame are not ideal
        if (latestNotification.type === 'error') {
          this.uiActions.disableLoader();
        }

        if (latestNotification.type === 'success') {
          notifications
            .filter(notification => notification.type === 'error' &&
              notification.entityType === latestNotification.entityType)
            .forEach((notification) => {
              this.toastService.clear(notification.toastId);
            });
        }
      }
    });
  }
  ngOnDestroy() {
    this.store.unsubscribe();
  }
  private isNotificationAllowed(entityType) {
    return !nonActiveEntities.includes(entityType);
  }
}
