/**
 * SERVICE_PROVIDERS
 */

import {
    ReportsService,
    CustomReportsService,
    VegaConfigsService,
    VegaLiteConfigsService,
} from './reports';
import { CacheService } from './cache';
import { LocalDbStorageService } from './local-db-storage';

export const BASE_ENTITY_SERVICES: any[] = [
    ReportsService,
    CustomReportsService,
    VegaConfigsService,
    VegaLiteConfigsService,
    CacheService,
    LocalDbStorageService,
];

export const ENTITY_SERVICES: any[] = [...BASE_ENTITY_SERVICES];
