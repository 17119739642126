/**
 * SessionActions
 */

import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../store/reducers';

/**
 * # SessionActions
 * Defines the class for creating user session actions
 */

@Injectable()
export class SessionActions {
    public static LOGIN_USER = 'LOGIN_USER';
    public static LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
    public static LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
    public static RECEIVE_ACCESS_TOKEN = 'RECEIVE_ACCESS_TOKEN';
    public static RECEIVE_ACCESS_TOKEN_SUCCESS = 'RECEIVE_ACCESS_TOKEN_SUCCESS';
    public static RECEIVE_ACCESS_TOKEN_ERROR = 'RECEIVE_ACCESS_TOKEN_ERROR';
    public static LOGOUT_USER = 'LOGOUT_USER';
    public static RESTORE_TOKEN = 'RESTORE_TOKEN';
    public static SET_IFRAME_MODE = 'SET_IFRAME_MODE';
    public static TOKEN_RETRIEVED = 'TOKEN_RETRIEVED';
    public static TOKEN_REMOVED = 'TOKEN_REMOVED';
    public static CHECK_USER_AUTH = 'CHECK_USER_AUTH';
    public static CHECK_USER_AUTH_SUCCESS = 'CHECK_USER_AUTH_SUCCESS';
    public static CHECK_USER_AUTH_FAILURE = 'CHECK_USER_AUTH_FAILURE';
    public static SET_COMPANY = 'SET_COMPANY';
    public static SET_COMPANY_SUCCESS = 'SET_COMPANY_SUCCESS';
    public static SET_COMPANY_FAILURE = 'SET_COMPANY_FAILURE';

    public static loginUserAction(credentials) {
        return {
            type: SessionActions.LOGIN_USER,
            payload: credentials,
        };
    }

    public static receiveAccessTokenAction(companyId) {
        return {
            type: SessionActions.RECEIVE_ACCESS_TOKEN,
            payload: companyId,
        };
    }

    public static setCompanyAction(companyId) {
        return {
            type: SessionActions.SET_COMPANY,
            payload: companyId,
        };
    }

    public static setCompanySuccessAction(companyId) {
        return {
            type: SessionActions.SET_COMPANY_SUCCESS,
            payload: companyId,
        };
    }

    public static setCompanyFailureAction() {
        return {
            type: SessionActions.SET_COMPANY_FAILURE,
            payload: {
                errors: 'Company id is missed'
            },
        };
    }

    public static receiveAccessTokenSuccessAction(token) {
        return {
            type: SessionActions.RECEIVE_ACCESS_TOKEN_SUCCESS,
            payload: token,
        };
    }

    public static receiveAccessTokenErrorAction(errors) {
        return {
            type: SessionActions.RECEIVE_ACCESS_TOKEN_ERROR,
            payload: errors,
        };
    }

    public static logoutUserAction() {
        return {
            type: SessionActions.LOGOUT_USER,
        };
    }

    public static loginUserSuccessAction(user) {
        return {
            type: SessionActions.LOGIN_USER_SUCCESS,
            payload: {
                user,
            },
        };
    }

    public static loginUserErrorAction(errors) {
        return {
            type: SessionActions.LOGIN_USER_ERROR,
            payload: {
                errors,
            },
        };
    }

    public static setIframeModeAction(mode) {
        return {
            type: SessionActions.SET_IFRAME_MODE,
            payload: mode,
        };
    }

    public static checkUserAuth() {
        return {
            type: SessionActions.CHECK_USER_AUTH,
        };
    }

    public static checkUserSuccessAction(user, token) {
        return {
            type: SessionActions.CHECK_USER_AUTH_SUCCESS,
            payload: {
                user,
                token
            }
        };
    }

    public static checkUserAuthFailure() {
        return {
            type: SessionActions.CHECK_USER_AUTH_FAILURE
        };
    }

    /**
     * TOKEN  ACTIONS
     */
    public static restoreTokenAction() {
        return {
            type: SessionActions.RESTORE_TOKEN,
        };
    }

    public static tokenRetrievedAction(servicePayload) {
        return {
            type: SessionActions.TOKEN_RETRIEVED,
            payload: servicePayload,
        };
    }


    public static tokenRemovedAction() {
        return {
            type: SessionActions.TOKEN_REMOVED,
        };
    }

    /**
     * Initialize the instance
     */
    constructor(
        private ngRedux: NgRedux<IAppState>
    ) {
    }

    /**
     * Dispatch action for doing request
     *
     */
    public checkUserAuth() {
        this.ngRedux.dispatch(SessionActions.checkUserAuth());
    }

    public loginUser(credentials) {
        this.ngRedux.dispatch(SessionActions.loginUserAction(credentials));
    }

    public getAccessToken(companyId) {
        this.ngRedux.dispatch(SessionActions.receiveAccessTokenAction(companyId));
    }

    public setCompany(companyId) {
        this.ngRedux.dispatch(SessionActions.setCompanyAction(companyId));
    }

    public restoreToken() {
        this.ngRedux.dispatch(SessionActions.restoreTokenAction());
    }

    public setIframeMode(mode) {
        this.ngRedux.dispatch(SessionActions.setIframeModeAction(mode));
    }

    public logoutUser() {
        this.ngRedux.dispatch(SessionActions.logoutUserAction());
    }

    public setUser(user) {
        this.ngRedux.dispatch({type: 'SET_USER', payload: user});
    }

    public setToken(token) {
        this.ngRedux.dispatch({type: 'SET_TOKEN', payload: token});
    }

    public setAccessToken(token) {
        this.ngRedux.dispatch({type: 'SET_ACCESS_TOKEN', payload: token});
    }

    public setPermissions(token) {
        this.ngRedux.dispatch({type: 'SET_PERMISSIONS', payload: token});
    }

    public setBearerToken(token) {
        this.ngRedux.dispatch({type: 'SET_BEARER_TOKEN', payload: token});
    }

    public setIframeIntegrationState(state: boolean) {
        this.ngRedux.dispatch({type: 'IFRAME_INTEGRATION', payload: state});
    }

    public logout() {
        this.ngRedux.dispatch({type: 'LOGOUT'});
    }
}
