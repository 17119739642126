/**
 * Token saving service
 */
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable()
export class TokenSessionService {
  private AUTH_KEY: string = 'AUTH_KEY';

  constructor(
    private storage: LocalStorageService
  ) {}

  public saveToken(token, key?) {
    return this.storage.setItem(key ? key : this.AUTH_KEY, token.token);
  }

  public getToken(key?) {
    return this.storage.getItem(key ? key : this.AUTH_KEY);
  }

  public removeToken(key?) {
    this.storage.removeItem(key ? key : this.AUTH_KEY);
  }

}
