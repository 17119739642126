/**
 * PreferencesReducer
 */

import { IActionReducer, IAction } from '../';
import { IPreferences, IPreferencesFormData } from '../../../shared/types';
import { PreferencesActions } from '../../actions';
import { COMPANY_PREFERENCES_DEFAULTS } from '../../../../config';
import { merge, get } from 'lodash';

/**
 * PreferencesState interface
 */
export interface IPreferencesState {
  company: IPreferences;
  site: IPreferences;
  companyId: string;
  siteId: string;
  formData: IPreferencesFormData;
  errors: any;
  loader: boolean;
  showSelectedSitesModal: boolean;
  operationResult: any;
  schema: any;
  selectedSites: string[];
}

export const INITIAL_STATE_PREFERENCES: IPreferencesState = {
  company: COMPANY_PREFERENCES_DEFAULTS,
  companyId: null,
  siteId: null,
  site: null,
  formData: null,
  errors: null,
  loader: false,
  showSelectedSitesModal: false,
  operationResult: null,
  schema: null,
  selectedSites: [],
};

/**
 * This function updates preferences state
 *
 */
export const preferencesReducer: IActionReducer<IPreferencesState> =
  (state: IPreferencesState = INITIAL_STATE_PREFERENCES, action: IAction) => {

    const { payload } = action;
    switch (action.type) {

      case PreferencesActions.UPDATE_PREFERENCES_FOR_SITE:
      case PreferencesActions.UPDATE_PREFERENCES_FOR_COMPANY:
        return Object.assign({}, state, {
          loader: true,
        });
      case PreferencesActions.UPDATE_PREFERENCES_FOR_SITE_SUCCESS:
      case PreferencesActions.UPDATE_PREFERENCES_FOR_COMPANY_SUCCESS:
        return Object.assign({}, state, {
          loader: false,
          preferences: payload.preferences,
          operationResult: payload,
        });
      case PreferencesActions.CLEAR_PREFERENCES_FOR_SITE:
        return Object.assign({}, state, {
          site: null,
          siteId: null,
        });

      case PreferencesActions.UPDATE_PREFERENCES_FOR_SITE_ERROR:
      case PreferencesActions.UPDATE_PREFERENCES_FOR_COMPANY_ERROR:
        return Object.assign({}, state, {
          loader: false,
          errors: payload,
          operationResult: payload,
        });
      case PreferencesActions.PREFERENCES_SITE_LIST_SELECTION_UPDATE:
        return Object.assign({}, state, {
          selectedSites: payload.selectedSites,
        });
      case PreferencesActions.FETCH_PREFERENCES_FOR_COMPANY_SUCCESS:
        const newState = merge({}, INITIAL_STATE_PREFERENCES, {
          company: payload.response.preferences,
          companyId: payload.response._id,
          formData: payload.response.preferences,
          operationResult: payload,
          site: INITIAL_STATE_PREFERENCES.site,
          siteId: INITIAL_STATE_PREFERENCES.siteId,
        });
        return newState;
      case PreferencesActions.FETCH_PREFERENCES_FOR_SITE:
        return Object.assign({}, state, {
          site: null,
          siteId: payload.siteIds[0] || null,
        });
      case PreferencesActions.FETCH_PREFERENCES_FOR_SITE_SUCCESS:
        return Object.assign({}, state, {
          sites: payload,
          formData: payload.formData,
          operationResult: payload,
          site: get(payload, 'preferences.0', INITIAL_STATE_PREFERENCES.site),
          siteId: get(payload, 'siteIdList.0', INITIAL_STATE_PREFERENCES.siteId),
        });
      case PreferencesActions.SHOW_SELECTED_SITES_MODAL:
        return Object.assign({}, state, {
          showSelectedSitesModal: true,
        });
      case PreferencesActions.HIDE_SELECTED_SITES_MODAL:
        return Object.assign({}, state, {
          showSelectedSitesModal: false,
        });

      default:
        return state;
    }
  };
