export const environment = {
    production: '${IS_PRODUCTION}',
    backendURI: '${REPORTING_BACKEND_URI}',
    portalBackendURI: '${PORTAL_BACKEND_URI}',
    baseURI: '${BASE_URI}',
    powerBIURI: '${POWER_BI_URI}',
    emailNotifierURI: '${EMAIL_NOTIFIER_URI}',
    logViewerURI: '${LOG_VIEWER_URI}',
    envName: '${ENV_NAME}',
    version: '${BUILD_TAG_UI}',
};
