import { SessionEpics } from './session/session.epics';
import { OpraDataEpics } from './opra-data/opra-data.epics';
import { SitesEpics } from './sites/sites.epics';

export * from './session/session.epics';
export * from './opra-data/opra-data.epics';
export * from './sites/sites.epics';

export const EPIC_PROVIDERS: any[] = [
  SessionEpics,
  OpraDataEpics,
  SitesEpics
];
