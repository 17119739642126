<h3>
    <b *ngIf="!customTitle; else customTemplateTitle">Sales and Labor by Time Visualization</b>
    <ng-template #customTemplateTitle>
        <b>{{ customTitle }}</b>
    </ng-template>
</h3>
<tools-panel *ngIf="csvData.length" [dateFrom]="dateFrom" [dateTo]="dateTo" [csvData]="csvData"></tools-panel>
<div class="row" id="ReportCustom">
  <div class="col-md-12 statistics_block">
    <div class="statistics_block_header">
      <h3><b>Weekly Metrics</b></h3>
    </div>
    <div id="chart_average_order_preparation"></div>
  </div>

  <div class="col-md-12 statistics_block">
    <div id="chart_average_order_fulfillment"></div>
  </div>

  <div class="col-md-12 statistics_block">
      <div class="statistics_block_header">
          <h3><b>Active Orders: {{getCorrectLabelForMeasure()}}</b></h3>
      </div>
      <table class="table table-striped table-hover">
          <thead>
          <tr>
              <th>Order #</th>
              <th>Fulfillment Time</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let activeOrder of activeOrdersTop">
              <td>{{activeOrder['order_number']}}</td>
              <td>{{convertSecondsToText(activeOrder['order_prep_sec'])}}</td>
          </tr>
          </tbody>
      </table>
  </div>

  <div class="col-md-12 statistics_block">
    <div class="statistics_block_header">
      <h3><b>Production Screens</b></h3>
    </div>
    <div id="chart_prodaction_screens"></div>
  </div>

  <div class="col-md-12 statistics_block">
    <div class="statistics_block_header">
      <h3><b>Lead Fulfillment Product Time: {{getCorrectLabelForMeasure()}}</b></h3>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Product Name</th>
          <th>Fulfillment Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of leadFulfilmentProductTime">
          <td>{{row['items__name']}}</td>
          <td>{{convertSecondsToText(row['item_prep_sec']/row['quantity'])}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 statistics_block">
    <div class="statistics_block_header">
      <h3><b>Highest Fulfillment Product Time: {{getCorrectLabelForMeasure()}}</b></h3>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Product Name</th>
          <th>Fulfillment Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of highestFulfilmentProductTime">
          <td>{{row['items__name']}}</td>
          <td>{{convertSecondsToText(row['item_prep_sec']/row['quantity'])}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
