import { IMenuItem } from '../interfaces';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class XUXMenuPinnedService {
  menuObs = new Subject<string[]>();
  private pinnedItems: string[] = [];
  /**
   * get all pinned nav items on load
   */
  getPinnedMenu(): string[] {
    if (!localStorage.getItem('pinnedMenu')) {
      localStorage.setItem('pinnedMenu', JSON.stringify([]));
    }
    return JSON.parse(localStorage.getItem('pinnedMenu'));
  }

  removePinnedItems(pinnedItem: string) {
    const itemKey = pinnedItem;
    const pinnedMenu: string[] = JSON.parse(localStorage.getItem('pinnedMenu'));
    if (!pinnedMenu) {
      localStorage.setItem('pinnedMenu', JSON.stringify([itemKey]));
    } else {
      pinnedMenu.splice(pinnedMenu.indexOf(itemKey), 1);
      localStorage.setItem('pinnedMenu', JSON.stringify(pinnedMenu));
      this.pinnedItems = pinnedMenu;
    }
    this.menuObs.next(this.pinnedItems);
  }

  /**
   * Getting pinned item from localstorage
   */
  togglePin(menuItem: IMenuItem) {
    const itemKey = menuItem.title;
    const pinnedMenu: string[] = JSON.parse(localStorage.getItem('pinnedMenu'));
    if (!pinnedMenu) {
      localStorage.setItem('pinnedMenu', JSON.stringify([itemKey]));
    } else {
      if (pinnedMenu.indexOf(itemKey) > -1) {
        menuItem.pinned = false;
        pinnedMenu.splice(pinnedMenu.indexOf(itemKey), 1);
      } else {
        menuItem.pinned = true;
        pinnedMenu.push(itemKey);
      }
      localStorage.setItem('pinnedMenu', JSON.stringify(pinnedMenu));
      this.pinnedItems = pinnedMenu;
    }
    this.menuObs.next(this.pinnedItems);
  }
}
