import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'checkbox-input-filter',
    templateUrl: 'checkbox-filter.html',
    styleUrls: ['style.css']
})
export class CheckboxFilterComponent implements OnInit {
    @Input() filter: object;
    @Input() type: string = '';
    @Output() handleChanges = new EventEmitter();

    public inputValue: boolean = true;
    public label: string = '';
    protected data: object = {};
    private attributes: string[] = [];
    private name: string = '';

    ngOnInit() {
        this.name = this.filter['name'];
        this.attributes = this.filter['attributes'];
        this.label = this.filter['label'];
        this.data = this.filter['data'];
        if (this.filter['value']) {
            this.inputValue = this.filter['value'][this.attributes[0]];
        }
        this.handleCheckboxFilterChange();
    }

    public clickHandler(event): void {
        // event.stopPropagation();
        this.inputValue = !this.inputValue;
        this.handleCheckboxFilterChange();
    }

    protected handleCheckboxFilterChange() {
        const value = {};
        value[this.attributes[0]] = this.inputValue;
        const res = { value, name: this.name, type: this.filter['type'] };
        this.handleChanges.emit(res);
    }
}
