<dm-modal-wrapper
  [title]="title"
  [isShowModal]="_showModal"
  [buttons]="buttons" >

<div class="row vertical-align">
  <div class="col-md-12">
  <div class="row grid-tool">
    <div class="col-sm-12">
      <div class="input-group ">
          <span class="input-group-addon">
            <i class="glyphicon glyphicon-filter"></i>
          </span>
        <input type="text"
               class="form-control"
               (keyup)="onListQuickFilterChanged($event)"
               type="text" id="listQuickFilterInput"
               placeholder="Filter companies"/>
      </div>
    </div>
  </div>
    <ag-grid-angular
      [gridOptions]="listGridOptions"
      [columnDefs]="headerNames"
      [rowData]="companiesList"
        class="company-selection-grid">
    </ag-grid-angular>
  </div>
</div>
</dm-modal-wrapper>
