import { NgRedux } from '@angular-redux/store';
import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { ToasterService } from 'angular2-toaster';
import { CustomReportsService } from '../../../shared/services';
import { VegaConfigsService } from '../../../shared/services/reports';
import { PreferencesService } from '../../../shared/services/preferences/preferences.service';
import { UiActions } from '../../../store/actions';
import { takeWhile } from 'rxjs/operators';
import { isBoolean } from 'util';
import { HttpCancelService } from '../../../shared/services/http-client';

interface IReportData {
    Site_Id: { [x: string]: string };
    Start_Date: Date | string;
    End_Date: Date | string;
    Calendar_Period: string;
    custom_title?: string;
}

@Component({
    selector: 'sales-analysis',
    templateUrl: 'sales-analysis.component.html',
    styleUrls: ['sales-analysis.component.scss']
})
export class SalesAnalysisComponent implements OnInit, OnDestroy {
    @Input()
    public set data(data: IReportData) {
        if (data && data.Site_Id[0] !== this.siteIds) {
            this.uiActions.enableContentLoader();
            setTimeout(() => {
                this.fetchSalesAnalysisData(data);
            }, 100);
        }
    }

    public itemsLeftMeasure: any[] = [];
    public itemsRightMeasure: any[] = [];
    public selectedItemLeftMeasure: any[] = [];
    public selectedItemRightMeasure: any[] = [];
    public dropdownSettings = {};
    protected defaultValueForLeftMeasure = 'net_sales';
    protected defaultValueForRightMeasure = 'order_qty';
    protected isActive = true;
    public csvData: any[] = [];
    public dateFrom: Date | string = moment().format('YYYY-MM-DD');
    public dateTo: Date | string = moment().format('YYYY-MM-DD');
    public customTitle: string = '';
    protected businessDate = '';
    protected siteIds: string[] | string = 'All';
    protected chartSalesAnalysis: any;
    protected allData: any[] = [];
    public loader$: Observable<any>;
    private isLoading = false;

    constructor(
        private uiActions: UiActions,
        private reportService: CustomReportsService,
        private vegaConfigService: VegaConfigsService,
        private preferencesService: PreferencesService,
        private toasterService: ToasterService,
        private store: NgRedux<any>,
        private httpCancelService: HttpCancelService,
        private cdRef: ChangeDetectorRef
    ) {
        this.loader$ = this.store.select(['ui', 'contentLoader']);
        this.loader$.pipe(takeWhile(() => this.isActive)).subscribe(value => {
            if (isBoolean(value)) {
                if (this.isLoading === true && value === false) {
                    this.httpCancelService.cancelPendingRequests();
                }
                this.isLoading = value;
            }
        });
    }

    public ngOnInit() {
        this.businessDate = moment().format('YYYY-MM-DD');
        this.dropdownSettings = {
            singleSelection: true,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            badgeShowLimit: 1,
            allowSearchFilter: false
        };
        this.itemsLeftMeasure = this.vegaConfigService
            .getCrossfilterMeasures()
            .filter(item => item.fieldname !== this.defaultValueForRightMeasure)
            .map(item => {
                return { id: item.fieldname, itemName: item.fulltext };
            });
        this.itemsRightMeasure = this.vegaConfigService
            .getCrossfilterMeasures()
            .filter(item => item.fieldname !== this.defaultValueForLeftMeasure)
            .map(item => {
                return { id: item.fieldname, itemName: item.fulltext };
            });
        this.selectedItemLeftMeasure = [
            this.itemsLeftMeasure.find(item => item.id === this.defaultValueForLeftMeasure)
        ];
        this.selectedItemRightMeasure = [
            this.itemsRightMeasure.find(item => item.id === this.defaultValueForRightMeasure)
        ];
    }

    public ngOnDestroy() {
        this.isActive = false;
        this.uiActions.disableContentLoader();
    }

    public onLeftMeasureSelect(selectItem: any) {
        this.itemsRightMeasure = this.vegaConfigService
            .getCrossfilterMeasures()
            .filter(item => item.fieldname !== selectItem.id)
            .map(item => {
                return { id: item.fieldname, itemName: item.fulltext };
            });

        this.initChart();
    }

    public onLeftMeasureDeselect(item: any) {
        if (!this.selectedItemLeftMeasure.length) {
            this.selectedItemLeftMeasure = [
                this.itemsLeftMeasure.find(el => el.id === this.defaultValueForLeftMeasure)
            ];
            this.initChart();
        }
    }

    public onRightMeasureSelect(selectItem: any) {
        this.itemsLeftMeasure = this.vegaConfigService
            .getCrossfilterMeasures()
            .filter(item => item.fieldname !== selectItem.id)
            .map(item => {
                return { id: item.fieldname, itemName: item.fulltext };
            });
        this.initChart();
    }

    public onRightMeasureDeselect(item: any) {
        if (!this.selectedItemRightMeasure.length) {
            this.selectedItemRightMeasure = [
                this.itemsRightMeasure.find(el => el.id === this.defaultValueForRightMeasure)
            ];
            this.initChart();
        }
    }

    public initChart() {
        this.chartSalesAnalysis = this.vegaConfigService.vegaInit(
            'sales_analysis_chart',
            this.vegaConfigService.getChartCustomCrossfilterSales(
                this.selectedItemLeftMeasure[0].id,
                this.selectedItemLeftMeasure[0].itemName,
                this.selectedItemRightMeasure[0].id,
                this.selectedItemRightMeasure[0].itemName
            ),
            this.allData,
            850,
            1600
        );
        this.cdRef.detectChanges();
    }

    @HostListener('window:resize', ['$event'])
    public onResize() {
        if (this.chartSalesAnalysis) {
            this.chartSalesAnalysis
                .signal('width', this.vegaConfigService.getChartWidth('sales_analysis_chart'))
                .run('enter');
        }
    }

    private fetchSalesAnalysisData(data): void {
        this.siteIds = data.Site_Id[0] || 'All';
        this.dateFrom = data.Start_Date;
        this.dateTo = data.End_Date;

        if (data.custom_title) {
            this.customTitle = data.custom_title;
        }
        if (data.Calendar_Period === 'true' && data.Start_Date !== data.End_Date) {
            this.dateTo = moment(data.End_Date, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD');
        }
        this.reportService
            .fetchSalesAnalysis(this.siteIds, this.dateFrom)
            .pipe(takeWhile(() => this.isActive))
            .subscribe(
                (data: any) => {
                    this.allData = this.getNormalizeData(data);
                    this.allData = this.preferencesService.sortByDayParts(this.allData, 'daypart');

                    const dataForCSV = this.vegaConfigService.prepareCsvForBarChart(data, 'All Data');
                    this.csvData = [dataForCSV];

                    if (data.length) {
                        this.initChart();
                        this.uiActions.disableContentLoader();
                    }
                },
                error => {
                    this.uiActions.disableContentLoader();
                    this.toasterService.pop('error', error.statusText);
                }
            );
    }

    private getNormalizeData(data) {
        return data.reduce((filtered, item) => {
            const tempObj = {
                ...item,
                discount_amt: this.preferencesService.correctRounding(item.discount_amt),
                net_sales: this.preferencesService.correctRounding(item.net_sales),
                refund_amt: this.preferencesService.correctRounding(item.refund_amt),
                tax_exempt_amt: this.preferencesService.correctRounding(item.tax_exempt_amt),
                voided_postpayment_amt: this.preferencesService.correctRounding(item.voided_postpayment_amt),
                time_hour: this.preferencesService.timeFormatterForVega(item.time_hour, 'LT', false),
                date_short: this.preferencesService.dayAndDateFormatterForVega(item.business_date)
            };
            filtered.push(tempObj);

            return filtered;
        }, []);
    }
}
