import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { XUXDrawerSidebarService } from '../services';
import { XUXDrawerContainerComponent } from './xux-drawer-container';
import { XUXDrawerContentComponent } from './xux-drawer-content';
import { XUXDrawerSidebarComponent } from './xux-drawer-sidebar';
import { XUXNavigationComponent } from '../xux-navigation';
import { XuxInputSelectComponent } from '../xux-input-select';
import { XUXMenuItemComponent } from '../xux-menu-item';
import { SearchMenuItemsPipe } from '../pipes';
import { ReportFilterComponent } from '../../components/report-filters/report-filter.component';
import { OpaToggleComponent } from '../../components/form/toggle/toggle.component';
import { CheckboxFilterComponent } from '../../components/report-filters/filters/checkbox-filter/checkbox-filter';
import { TextInputFilterComponent } from '../../components/report-filters/filters/text-filter/text-input-filter';
import { SelectInputFilterComponent } from '../../components/report-filters/filters/select-filter/select-input-filter';
import { PortalSitePickerFilterComponent } from '../../components/report-filters/filters/portal-site-picker/portal-site-picker.filter';
import { AboveBetweenBelowFilterComponent } from '../../components/report-filters/filters/above-between-below/above-between-below-filter';
import { CalendarsFilterComponent } from '../../components/report-filters/filters/calendars-filter/calendar-filter';
import { SitesJobCodesFilterComponent } from '../../components/report-filters/filters/job-codes-filter/job-codes-filter';
import { CombinedSelectFilterComponent } from '../../components/report-filters/filters/combined-select-filter/combined-select-filter';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GetArrayPipe } from '../../pipes/get-array.pipe';
import { JobCategoriesFilterComponent } from '../../components/report-filters/filters/job-categories-filter/job-categories-filter';
import { EmployeesFilterComponent } from '../../components/report-filters/filters/employees-filter/employees-filter';
import { SitesItemsFilterComponent } from '../../components/report-filters/filters/items-filter/items-filter';
import { ForecastDefinitionFilterComponent } from '../../components/report-filters/filters/forecast-definition-filter/forecast-definition-filter.component';
import { BusinessRuleGroupFilterComponent } from '../../components/report-filters/filters/business-rule-group-filter/business-rule-group-filter';
import { TagsFilterComponent } from '../../components/report-filters/filters/tags-filter/tags-filter';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DMSharedComponentsModule } from '@hc/xenial-ui-shared/modules/xdm-shared-components/index';
import { TruncateEmployeePipe } from '@components/report-filters/filters/employees-filter/pipes/truncate-employee.pipe';

export * from './xux-drawer-container/xux-drawer-container.component';
export * from './xux-drawer-sidebar/xux-drawer-sidebar.component';
export * from './xux-drawer-content/xux-drawer-content.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        AngularMultiSelectModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        ReactiveFormsModule,
        DMSharedComponentsModule
    ],
    declarations: [
        XUXDrawerContainerComponent,
        XUXDrawerContentComponent,
        XUXDrawerSidebarComponent,
        XUXNavigationComponent,
        XUXMenuItemComponent,
        XuxInputSelectComponent,
        ReportFilterComponent,
        SearchMenuItemsPipe,

        OpaToggleComponent,
        CheckboxFilterComponent,
        CombinedSelectFilterComponent,
        EmployeesFilterComponent,
        TextInputFilterComponent,
        SelectInputFilterComponent,
        CalendarsFilterComponent,
        SitesJobCodesFilterComponent,
        JobCategoriesFilterComponent,
        PortalSitePickerFilterComponent,
        SitesItemsFilterComponent,
        AboveBetweenBelowFilterComponent,
        ForecastDefinitionFilterComponent,
        BusinessRuleGroupFilterComponent,
        TagsFilterComponent,
        GetArrayPipe,
        TruncateEmployeePipe
    ],
    exports: [
        XUXDrawerContainerComponent,
        XUXDrawerContentComponent,
        XUXDrawerSidebarComponent,
        XUXNavigationComponent,
        XUXMenuItemComponent,
        XuxInputSelectComponent,
        ReportFilterComponent,
        SearchMenuItemsPipe,
        OpaToggleComponent,
        CheckboxFilterComponent,
        CombinedSelectFilterComponent,
        EmployeesFilterComponent,
        TextInputFilterComponent,
        SelectInputFilterComponent,
        CalendarsFilterComponent,
        SitesJobCodesFilterComponent,
        JobCategoriesFilterComponent,
        SitesItemsFilterComponent,
        AboveBetweenBelowFilterComponent,
        ForecastDefinitionFilterComponent,
        BusinessRuleGroupFilterComponent,
        TagsFilterComponent,
        PortalSitePickerFilterComponent,
        ModalModule,
        GetArrayPipe
    ]
})
export class XUXDrawerModule {
    public static forRoot(): ModuleWithProviders<XUXDrawerModule> {
        return {
            ngModule: XUXDrawerModule,
            providers: [XUXDrawerSidebarService]
        };
    }
}
