
        <ng-container *ngIf="type !== 'no-page'; else noPage">
            <div class="error-container">
                <h1>You are not assigned to any sites within this company. No reports are available. Please make sure you are assigned to at
                    least 1 site before accessing reports.</h1>
            </div>
        </ng-container>
        <ng-template #noPage>
            <div class="error-container">
                <h1>404: page is missing</h1>
            </div>
        </ng-template>
    