import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { NgRedux } from '@angular-redux/store';
import { ISiteState } from '@shared/types/site';
import { ICompanyPreferences, PreferencesService } from '@shared/services';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class CompanyGuard implements CanActivate {
    private companyId$: Observable<string>;

    constructor(
        private store: NgRedux<ISiteState>,
        private preferencesService: PreferencesService
        ) {
        this.companyId$ = this.store.select([ 'userSession', 'currentCompanyId' ]);
    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.companyId$.pipe(
            switchMap((id) => this.preferencesService.getCompanyPreferences(id).pipe(
                map((data: ICompanyPreferences) => {
                    this.preferencesService.setCompanyPreferences(data);
                    moment.locale(this.preferencesService.timeLocale);
                    return true;
                }),
                catchError(() => of(false))
            )),
            catchError(() => of(false))
        );
    }
}
