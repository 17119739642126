import { get, set, merge, mapValues, compact, includes } from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';
import {
  getDefaultValueForField,
  createSchemaValidators,
  createSchemaAsyncValidators,
  normalizeDynamicProperty
} from './schema-form-functions';
import { IFieldPreferences } from './schema-form-interfaces';

/**
 * Creates a new control for a leaf node of schema.
 * @param {IFieldPreferences} field - combined field preferences
 * @returns {FormControl}
 */
const buildControl = (field: IFieldPreferences) => {
  // TODO: remove defaultValue property. replace it with 'default' everywhere
  const defaultValue = get(field, 'defaultValue',
    get(field, 'default', getDefaultValueForField(field)));
  const validators = createSchemaValidators(field);
  const asyncValidators = createSchemaAsyncValidators(field);
  return new FormControl(defaultValue, validators, asyncValidators);
};

/**
 * Builds an angular form based on provided JSON schema
 *
 * @param {Object} schema - current chema node
 * @param {Object} rules - user defined form rules
 * @returns {FormGroup}
 */
export const createFormGroupFromSchema = (schema = {}, rules = {}): FormGroup => {
  const omitFunctions: { omit: Function, control: FormControl }[] = [];

  /**
   * Combines schema node and formRules for field. Creates either a leaf control or
   * a group. Creates group children recursively.
   * @param {IFieldPreferences} schemaNode - schema node
   * @param {string} nodePath - dot separated schema node path
   * @returns {AbstractFormControl}
   */
  const buildFormNode = (field, schemaPath: string = '') => {
    const isNode = field.type === 'object' && field.properties;
    const control = !isNode ? buildControl(field) : new FormGroup(
      mapValues(field.properties, (value, name) => {
        const childPath = compact([schemaPath, name]).join('.');
        return buildFormNode(
          merge(
            includes(field.required, name) ? { required: true } : {},
            value,
            get(rules, ['fields', childPath])
          ), childPath);
      }));

    const omit = normalizeDynamicProperty(field.omit);
    if (typeof omit === 'function') {
      omitFunctions.push({ omit, control });
    } else {
      if (omit) {
        control.disable();
      }
    }
    return control;
  };

  if (get(rules, 'virtual')) {
    set(schema, 'properties.virtual', merge(
      { type: 'object', properties: {} },
      get(schema, 'properties.virtual'),
      get(rules, 'virtual')
    ));
  }
  const form = buildFormNode(Object.assign(
    { type: 'object' },
    schema
  ));

  // set root node validators. Can be passed with schema or formRules
  // may be used for completely custom value validation with dependent fields etc...
  form.setValidators(get(rules, 'validators', get(schema, 'validators')));

  if (omitFunctions.length) {
    form.valueChanges.subscribe(value =>
      setTimeout(() => omitFunctions.forEach(
        ({ control, omit }) => {
          if (control.disabled === omit(value)) { return; }
          if (control.disabled) {
            control.enable({ emitEvent: false });
          } else {
            control.disable({ emitEvent: false });
          }
        }), 0)
    );
  }

  return form;
};
