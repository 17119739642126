import { IAction } from '../';
import { IEtlStatisticsState } from '../../../shared/types/report';
export const SET_REPORT_ETL_STATISTICS = 'SET_REPORT_ETL_STATISTICS';

const initialState: IEtlStatisticsState = {
  jobStatus: '',
  jobStarted: '',
  jobFinished: '',
  jobDuration: '',
  needShow: false
};


export const etlReducer = (state: IEtlStatisticsState = initialState, action: IAction) => {
  switch (action.type) {
    case SET_REPORT_ETL_STATISTICS:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
};
