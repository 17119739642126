import { AbstractControl, FormControl } from '@angular/forms';
import { Observable, from, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment';
import {
    sortBy,
    uniqBy,
    get,
    uniq,
    find,
    isFunction,
    sample,
    forOwn,
    omit,
    isArray,
    isObject,
    mergeWith,
    isEmpty,
    difference,
    isUndefined,
    isString,
    has
} from 'lodash';
import { ValidationService } from '../../components/form/services';
import { IMappingsType, ISelectOptionsType } from '../types';
import { ISelectOption } from '../../components/form/services/schema-form/schema-form-interfaces';

export const isCloseToInteger = (value: number) => {
    const zeroCount = Number.MAX_SAFE_INTEGER.toString().length - value.toFixed(0).length - 1;
    const multiplier = Math.pow(10, zeroCount);
    return value < Number.MAX_SAFE_INTEGER && Math.round(value * multiplier) === Math.round(value) * multiplier;
};

export const masks = {
    time: (rawValue: string): (RegExp | string)[] => {
        let reg: RegExp;
        if (rawValue) {
            Number(rawValue[0]) === 2 ? (reg = /[0-3]/) : (reg = /[0-9]/);
        }
        return [/[0-2]/, reg, ':', /[0-5]/, /\d/, ':', /[0-5]/, /\d/];
    }
};

export const patterns = {
    time: '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$'
};

export const maxPageSize = 20000;

/**
 * Function for removing all null properties from object recursively
 * @param {object} data - object to be edited
 * @returns {object} - result object
 */
export const removeNullPropertiesFromObject = data => {
    Object.keys(data).forEach(prop => {
        if (data[prop] === null) {
            delete data[prop];
        } else if (typeof data[prop] === 'object') {
            data[prop] = removeNullPropertiesFromObject(data[prop]);
        }
    });

    return data;
};

/**
 * Function for converting 'true' or 'false' to boolean values
 * @param {string|number|boolean} val - value to be converted
 * @returns {boolean}
 */
export const getBool = (val: string | boolean | number) => {
    if (!val) {
        return false;
    }
    const num = +val;
    return !isNaN(num)
        ? !!num
        : !!String(val)
              .toLowerCase()
              .replace(String(!!0), '');
};

/**
 * Function for converting array to hash object where key = value
 * @param {Array<string>} data - data array to be converted
 * @returns {object}
 */
export const arrayToHash = (data: string[]): Object => {
    if (!data || !data.length) {
        return {};
    }
    return data.reduce((total, current: string) => {
        total[current] = current;
        return total;
    }, {});
};

export const getCurrentMappings = (mappings: IMappingsType[], currentDate = new Date()): IMappingsType[] => {
    let currentMappings = mappings
        .filter((mapping: IMappingsType) => moment(currentDate).isSameOrAfter(mapping.effective_date))
        .map((mapping: IMappingsType) => ({
            ...mapping,
            effective_date_calculated: -new Date(mapping.effective_date).getTime(),
            updated_at_calculated: -new Date(mapping.updated_at).getTime() || -Infinity
        }));

    currentMappings = sortBy(currentMappings, ['effective_date_calculated', 'updated_at_calculated']);

    const uniqMappings = uniqBy(currentMappings, (mapping: IMappingsType) => mapping.site_id).map(mapping =>
        omit(mapping, ['effective_date_calculated', 'updated_at_calculated'])
    );

    return uniqMappings;
};

export const entityObservableToOptions = (inputList: any[]): ISelectOptionsType[] => {
    if (!Array.isArray(inputList)) {
        return [];
    }

    if (isString(sample(inputList))) {
        return inputList.map(item => ({
            text: item,
            value: item
        }));
    }

    return inputList.map(item => ({
        text: item['name'],
        value: item.entity_id
    }));
};

/**
 * Helper function sets oneOf Validator for control that permits only values from selectOptions
 * Consider moving it to the basic select control updateOptions method.
 * In this case validator will be added automatically even with empty option.
 * However this may affect user validators
 */
export const setOneOfValidatorForOptions = (control, options = {}) => selectOptions => {
    let allowedValues = selectOptions.map(({ value }) => value);
    if (options && 'default' in options) {
        allowedValues = allowedValues.concat(options['default']);
    }
    setTimeout(() => {
        control.setValidators(new ValidationService().oneOf(allowedValues));
        control.updateValueAndValidity();
    }, 1);
};

/**
 * helper function that can be used as a mapper to map
 * plain entityId list to entities with corresponding id from provided list
 * usage:
 * entities = ids.map(byEntityIdFromList(items));
 */
export const byEntityIdFromList = (list = []) => id => find(list, { entity_id: id });

// create and returns query forfilter to fetch items with entity_id from provided list
export const queryWithEntityIds = (idList: string[]) => ({
    filter: uniq(idList)
        .map(id => `entity_id eq objectid(${id})`)
        .join(' or ')
});

// create and returns query forfilter to fetch items with lookup_type from provided list
export const queryWithLookupTypes = (idList: string[]) => ({
    filter: idList.map(type => `lookup_type eq '${type}'`).join(' or ')
});

export const generateId = () =>
    Date.now().toString(16) +
    Math.random()
        .toString(16)
        .substr(3);

const lookupItemToOption = (lookupItem: { _id: string; name: string }): ISelectOptionsType => {
    const value = get(lookupItem, '_id', null);
    return value ? { value, text: get(lookupItem, 'name', value) } : null;
};

export const optionsFromLookupType = (lookupType: string) => (lookups: any[]) => {
    const lookupValues = get(lookups.find(item => item.lookup_type === lookupType), 'values');
    if (Array.isArray(lookupValues)) {
        return lookupValues.map(lookupItemToOption).filter(Boolean);
    }
    return [];
};

export const getControlPath = (control: FormControl) => {
    return Object.keys(control.parent.controls).find(key => control.parent.controls[key] === control);
};

export const getSyncPropertyName = (entityType, entityId, ngRedux) => {
    const items = get(ngRedux.getState(), ['entities', entityType, 'items'], { entity_id: null });
    const item = find(items, { entity_id: entityId });
    return get(item, 'name', '');
};

export const defaultOptionMapper = rawOptions => {
    const options = Array.isArray(rawOptions) ? rawOptions.filter(Boolean) : [];
    if (!Array.isArray(options) || isEmpty(options)) {
        return [];
    }
    // if sample option item does not contain value and text fields,
    // then map values with default entity mapper
    if (has(sample(options), 'value') && has(sample(options), 'text')) {
        return options;
    }
    return entityObservableToOptions(options);
};

export const deleteIdsRecursively = (product, isIdNeeded: Boolean = false) => {
    forOwn(product, (val, key) => {
        if (key === 'mappings') {
            product[key] = val.map(mapping =>
                omit(mapping, [
                    'document_id',
                    '_id',
                    'entity_id',
                    'created_at',
                    'created_by',
                    'updated_at',
                    'updated_by'
                ])
            );
        } else if (isArray(val)) {
            val.forEach(el => this.deleteIdsRecursively(el));
        } else if (isObject(val)) {
            this.deleteIdsRecursively(val);
        } else {
            if ((key === '_id' && !isIdNeeded) || key === 'entity_id') {
                delete product[key];
            }
        }
    });

    return product;
};

/**
 * helper function that can be used as a converter from string to hex
 */
export const stringTo16 = (str: string) => {
    let hex = 0xfff;
    str.split('').forEach((letter, i) => {
        hex *= str.charCodeAt(i);
    });
    return '#' + hex.toString(16).slice(0, 6);
};

export const hasMethod = (subject: any, methodName: string) => {
    return isFunction(get(subject, methodName));
};

export const isObservable = (subject: any) => {
    return hasMethod(subject, 'subscribe');
};

export const asObservable = (src: Observable<any> | Promise<any> | any): Observable<any> => {
    if (hasMethod(src, 'then')) {
        return from(src);
    }
    return hasMethod(src, 'subscribe') ? (src as Observable<any>) : of(src);
};

export const isValidURL = str => {
    /* tslint:disable:max-line-length */
    const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    /* tslint:enable:max-line-length */
    if (regexp.test(str)) {
        return true;
    }
    return false;
};

export const isValidImageURL = url => {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

export const objectDifference = (first, second) => {
    if (!isObject(first) || !isObject(second)) {
        return first === second ? undefined : second;
    }
    const removedProps = difference(Object.keys(first), Object.keys(second));

    return Object.assign(
        {},
        ...Object.keys(second).map(key => {
            const diff = objectDifference(first[key], second[key]);
            return isUndefined(diff) || (isObject(diff) && isEmpty(diff)) ? null : { [key]: diff };
        }),
        ...removedProps.map(propName => ({ [propName]: 'REMOVED' }))
    );
};

export const asSelectOptions = (input): Observable<ISelectOption[]> => {
    return asObservable(input).pipe(map(defaultOptionMapper));
};

/**
 * mergeReplaceArrays function should work same way as lodash merge except it completely
 * replaces arrays with new ones
 * @param result - object to be merged in
 * @param sources - other sourceObject to be merged
 */
export const mergeReplaceArrays = (result: object, ...sources: object[]) => {
    return mergeWith(result, ...sources, (value: any, srcValue: any) =>
        isArray(srcValue) || isArray(value) ? srcValue : undefined
    );
};

/**
 * controlValueObs
 * Helper that SAFELY returns an observable for passed control values
 * STARTING from the current value of the control.
 * Default value can be passed for cases when control is undefined
 */
export const controlValueObs = (control: AbstractControl, defaultValue = null): Observable<any> => {
    if (!get(control, 'valueChanges')) {
        return of(defaultValue);
    }
    return control.valueChanges.pipe(startWith(control.value));
};

/**
 * Function for converting array to hash object where key = value
 * @param name: string - string to be converted
 * @returns string
 */
export const stringToPropForDB = (name: string): string => {
    if (!name || !name.length) {
        return '';
    }
    return name.toLowerCase().replace(' ', '_');
};

/**
 * Function for converting array to hash object where key = value
 * @param name: string - string to be converted
 * @returns string
 */
export const stringToTitle = (name: string): string => {
    if (!name || !name.length) {
        return '';
    }
    const tempName: string[] = name.split(' ').map(value => {
        return value.charAt(0).toUpperCase() + value.slice(1, value.length).toLowerCase();
    });
    return tempName.join(' ');
};
