export const ROOT_ROUTE = '';
export const LOGIN_ROUTE = 'login';

export const REPORTS_ROOT_ROUTE = 'custom-reports';
export const PAGES_ROOT_ROUTE = 'pages';

export const HOME_ROUTE = 'home';
export const LOG_OUT_ROUTE = 'log-out';
export const IFRAME_MODE = 'external_login';
export const ERROR_PAGE_ROUTE = 'error';
