import { IAction } from '../';
import { IReportState } from '../../../shared/types/report';
import { ReportsActions } from '@store/actions';

export const ADD_REPORTS = 'ADD_REPORTS';
export const SELECT_REPORT = 'SELECT_REPORT';
export const RESET_REPORTS_STATE = 'RESET_REPORTS_STATE';
export const RESET_REPORTS_FILTERS = 'RESET_REPORTS_FILTERS';
export const ADD_REPORT_ERROR = 'ADD_REPORT_ERROR';
export const DELETE_REPORT_ERROR = 'DELETE_REPORT_ERROR';
export const RESET_REPORT_ERRORS = 'RESET_REPORT_ERRORS';
export const SET_REPORT_URL = 'SET_REPORT_URL';

const initialState: IReportState = {
    reports: [],
    reportFilters: [],
    errors: [],
    reportUrl: '',
    selectedReport: null
};

export const reportsReducer = (state: IReportState = initialState, action: IAction) => {
    switch (action.type) {
        case ADD_REPORTS:
            return Object.assign({}, state, {
                reports: action.payload
            });

        case ReportsActions.SET_FILTER:
            return Object.assign({}, state, {
                reportFilters: action.payload,
            });

        case SELECT_REPORT:
            return Object.assign({}, state, {
                selectedReport: action.payload.report,
                reportFilters: action.payload.filters,
                reportUrl: '',
                errors: []
            });

        case RESET_REPORTS_FILTERS:
            return Object.assign({}, state, {
                reportFilters: [],
                errors: []
            });

        case RESET_REPORTS_STATE:
            return {
                reports: [],
                reportFilters: [],
                selectedReport: {},
                errors: [],
                reportUrl: ''
            };

        case ADD_REPORT_ERROR:
            return Object.assign({}, state, {
                errors: state.errors.concat(action.payload),
                reportUrl: ''
            });

        case DELETE_REPORT_ERROR:
            return Object.assign({}, state, {
                errors: state.errors.filter(obj => obj['attr'] !== action.payload)
            });

        case RESET_REPORT_ERRORS:
            return Object.assign({}, state, {
                errors: []
            });

        case SET_REPORT_URL:
            return Object.assign({}, state, {
                reportUrl: action.payload,
            });
        default:
            return state;
    }
};
